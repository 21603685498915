import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'
import { resources as resourcesApi } from '../../../../lib/api'
import Audio from './audio'

class ResourceAudio extends Component {
  onEdit = (start, end, newVolume) => {
    const resource = toJS(this.props.resource)
    resource.options = { ...(resource.options || {}) }
    const { seek, duration, volume } = resource.options
    let changed = false
    if (start !== seek || seek + duration !== end) {
      changed = true
      resource.options.seek = start
      resource.options.duration = end - start
      resource.options.hd = resource.options.hd || {}
      resource.options.ld = resource.options.ld || {}

      resource.options.hd.seek = start
      resource.options.ld.seek = start
      resource.options.hd.duration = end - start
      resource.options.ld.duration = end - start
    }
    if (volume !== newVolume) {
      changed = true
      resource.options.volume = newVolume
    }

    if (changed) {
      const promise = resourcesApi.upload(resource)
      const transfertUid = promise.__transfert_uid
      promise.then(json => {
        this.props.onChange && this.props.onChange(json)
      })
      this.props.onChange && this.props.onChange('__transfert__' + transfertUid)
    }
  }

  render() {
    const resource = toJS(this.props.resource)
    if (!resource) {
      return <span className="bg-danger">Resource doesn't exists</span>
    }
    resource.options = { ...(resource.options || {}) }
    const { seek, duration, volume } = resource.options
    let existingCut = null
    if (typeof seek !== 'undefined' && duration) {
      existingCut = {
        start: seek,
        end: seek + duration
      }
    }
    return (
      <Audio
        uploadProgress={resource.progress}
        src={resourcesApi.getUrl(resource.hd ? resource.hd.file : resource.original.file)}
        srcOriginal={resourcesApi.getUrl(resource.original.file)}
        onEdit={this.onEdit}
        existingVolume={volume || 1.0}
        existingCut={existingCut}
        name={resource.name}
        remove={e => {
          this.props.onChange('')
        }}
      />
    )
  }
}

const MobxResourceAudio = observer(ResourceAudio)
export default MobxResourceAudio
