import React, { Component } from 'react'
import chroma from 'chroma-js'

import { Page } from '../'
import * as api from '../../lib/api'

import Loading from '../../components/loading'
import { Form } from '../../components/form'
import { NavInApplication } from '../../components/nav'

export default class Variant extends Component {
  state = {
    loading: true,
    variant: {},
    resources: {}
  }

  async populateState(props) {
    const applicationId = props.match.params.applicationId
    const variantId = props.match.params.variantId
    if (!applicationId || !variantId) {
      return this.setState({ variant: {} })
    }
    this.setState({ loading: true })
    this.setState({ variant: await api.variants.get(variantId) })
    this.setState({ application: await api.applications.get(applicationId) })
    const res = await api.resourcesApplication.getFrom('variants', variantId)
    const resources = {}
    res.forEach(resource => {
      resources[resource.name] = resource._id
    })
    this.setState({
      resources,
      loading: false
    })
  }

  componentDidMount() {
    this.populateState(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.populateState(nextProps)
  }

  save = formData => {
    const { resources } = this.state

    const originalTheme = this.state.variant || { colors: {} }
    originalTheme.colors = originalTheme.colors || {}

    if (formData.colors) {
      delete formData.colors.primary
      delete formData.colors.primaryLight
      delete formData.colors.primaryLightTransparent
      delete formData.colors.primaryDark
      delete formData.colors.primaryDarkTransparent
      delete formData.colors.secondary
      delete formData.colors.secondaryLight
      delete formData.colors.secondaryLightTransparent
      delete formData.colors.secondaryDark
      delete formData.colors.secondaryDarkTransparent

      if (
        formData.colors.color_primary &&
        (formData.colors.color_primary !== originalTheme.colors.color_primary || !formData.colors.color_primary_transparent)
      ) {
        let primary = formData.colors.color_primary
        if (primary.length === 9) {
          primary = '#' + primary.substr(3, 6) + primary.substr(1, 2)
        }
        formData.colors.color_primary = chroma(primary).hex('argb')
        formData.colors.color_primary_transparent = chroma(primary)
          .alpha(0.5)
          .hex('argb')
        formData.colors.color_primary_light = chroma(primary)
          .brighten()
          .hex('argb')
        formData.colors.color_primary_light_transparent = chroma(primary)
          .brighten()
          .alpha(0.5)
          .hex('argb')
        formData.colors.color_primary_dark = chroma(primary)
          .darken()
          .hex('argb')
        formData.colors.color_primary_dark_transparent = chroma(primary)
          .darken()
          .alpha(0.5)
          .hex('argb')
      }
      if (
        formData.colors.color_accent &&
        (formData.colors.color_accent !== originalTheme.colors.color_accent || !formData.colors.color_accent_light)
      ) {
        let accent = formData.colors.color_accent
        if (accent.length === 9) {
          accent = '#' + accent.substr(3, 6) + accent.substr(1, 2)
        }
        formData.colors.color_accent = chroma(accent).hex('argb')
        formData.colors.color_accent_light = chroma(accent)
          .brighten()
          .hex('argb')
        formData.colors.color_accent_light_transparent = chroma(accent)
          .brighten()
          .alpha(0.5)
          .hex('argb')
        formData.colors.color_accent_transparent = chroma(accent)
          .alpha(0.5)
          .hex('argb')
        formData.colors.color_accent_dark = chroma(accent)
          .darken()
          .hex('argb')
        formData.colors.color_accent_dark_transparent = chroma(accent)
          .darken()
          .alpha(0.5)
          .hex('argb')
      }
    }

    const promises = [api.variants.upsert(formData)]
    Object.keys(resources).forEach(name => {
      var oldValue = resources[name]
      if (!formData.resources[name]) {
        promises.push(api.resourcesApplication.remove(oldValue))
      }
    })
    return Promise.all(promises)
  }

  delete = () => {
    const variantId = this.props.match.params.variantId
    return api.variants
      .remove(variantId)
      .then(() => this.props.history.push('/application/' + this.props.match.params.applicationId))
  }

  render() {
    const { loading, variant, application, resources } = this.state
    console.log(this.state)
    return (
      <Page>
        <NavInApplication />
        <div id="content">
          {loading && <Loading />}
          {!loading && (
            <Form
              formPath={`variants/${application.type}`}
              formContext={{ variantId: variant && variant._id }}
              initialFormData={{
                ...variant,
                resources,
                applicationId: application.id
              }}
              onDuplicate={this.duplicate}
              onDelete={this.delete}
              onSubmit={this.save}
            />
          )}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}
