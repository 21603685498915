import generify from './generic'
import * as stores from '../../stores'

export const releases = generify('experiences-service', 'releases', 'release')

releases.listFromExperience = function (environnement, experienceId) {
  return fetch(
    stores.conf.API_URL +
      '/experiences-service/releases/' +
      environnement +
      '/experience/' +
      experienceId,
    {
      headers: {
        Authorization: 'Bearer ' + stores.user.token
      }
    }
  )
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        throw json.msg
      }
      return json
    })
}
releases.listFromSite = function (environnement, experienceId) {
  return fetch(
    stores.conf.API_URL +
      '/experiences-service/releases/' +
      environnement +
      '/site/' +
      experienceId,
    {
      headers: {
        Authorization: 'Bearer ' + stores.user.token
      }
    }
  )
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        throw json.msg
      }
      return json
    })
}
