import React, { Component } from 'react'
import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js'

export default class AudioCutter extends Component {
  state = {
    loaded: false,
    errored: false,
    cutting: false,
    start: 0,
    end: 0
  };

  wavesurfer = null;
  currentRegion = null;
  initWaveSurfer = src => {
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformContainer,
      waveColor: 'rgba(178,232,123,1)',
      progressColor: 'rgba(98,195,68,1)',
      minPxPerSec: 5,
      responsive: true,
      plugins: [
        TimelinePlugin.create({
          container: this.waveformTimelineContainer
        }),
        // MinimapPlugin.create(),
        RegionsPlugin.create({ dragSelection: true })
      ]
    })
    this.wavesurfer.load(src)

    this.wavesurfer.on('ready', () => {
      this.setState({
        errored: false,
        loaded: true,
        end: Math.round(this.wavesurfer.getDuration() * 1000) / 1000
      })
      if (this.props.existingCut) {
        this.wavesurfer.addRegion(this.props.existingCut)
      }
    })
    this.wavesurfer.on('region-created', region => {
      if (this.currentRegion) {
        this.currentRegion.remove()
      }
      this.currentRegion = region
      setTimeout(() => {
        this.wavesurfer.setCurrentTime(region.start)
      }, 10)
    })
    this.wavesurfer.on('audioprocess', () => {
      if (
        this.currentRegion &&
        this.wavesurfer.getCurrentTime() > this.currentRegion.end
      ) {
        this.wavesurfer.pause()

        this.wavesurfer.setCurrentTime(this.currentRegion.start)
      }
    })
    this.wavesurfer.on('play', () => {
      this.setState({ playing: true })
    })
    this.wavesurfer.on('pause', () => {
      this.setState({ playing: false })
    })
    this.wavesurfer.on('region-updated', region => {})
    this.wavesurfer.on('region-update-end', region => {
      this.setState({
        start: Math.round(region.start * 1000) / 1000,
        end: Math.round(region.end * 1000) / 1000
      })
      setTimeout(() => {
        this.wavesurfer.setCurrentTime(region.start)
      }, 10)
    })
  };

  componentDidMount () {
    const { src, existingCut, existingvolume } = this.props
    if (src) {
      this.initWaveSurfer(this.props.src)
      this.setState({
        start: existingCut && existingCut.start,
        end: existingCut && existingCut.start,
        volume: (existingvolume || 1) * 100
      })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({ loaded: false, errored: false, playing: false })
      this.initWaveSurfer(nextProps.src)
    }
  }

  componentWillUnmount () {
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
  }

  togglePlay = e => {
    if (!this.wavesurfer) {
      return
    }
    if (!this.state.loaded) {
      return
    }
    if (this.state.playing) {
      this.wavesurfer.pause()
    } else {
      this.wavesurfer.play()
    }
  };

  render () {
    const { name, onEdit } = this.props
    const { loaded, errored, playing, volume } = this.state

    return (
      <div>
        <div
          className='form-inline'
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div className='form-group' style={{ flex: 1 }}>
            <label htmlFor='audiocutterstart'>Début</label>
            <input
              type='text'
              className='form-control'
              id='audiocutterstart'
              value={this.state.start || 0}
              onChange={e => {
                this.setState({ start: e.target.value })
                this.wavesurfer.addRegion({
                  start: parseFloat(e.target.value),
                  end: this.state.end
                })
              }}
            />
          </div>
          <div className='form-group' style={{ flex: 1 }}>
            <label htmlFor='audiocutterend'>Fin</label>
            <input
              type='text'
              className='form-control'
              id='audiocutterend'
              value={this.state.end || 0}
              onChange={e => {
                this.setState({ end: e.target.value })
                this.wavesurfer.addRegion({
                  end: parseFloat(e.target.value),
                  start: this.state.start
                })
              }}
            />
          </div>
          <div className='form-group' style={{ flex: 1 }}>
            <label htmlFor='audiocutterend'>Volume</label>
            <input
              type='number'
              className='form-control'
              id='audiocutterend'
              min='0'
              max='200'
              step='1'
              value={Math.round(volume)}
              onChange={e => {
                this.setState({ volume: e.target.value })
              }}
            />
          </div>
          <span
            className='btn btn-primary'
            style={{ flex: '40px 0 0' }}
            title='Enregistrer les modifications'
            onClick={e =>
              onEdit(this.state.start, this.state.end, this.state.volume / 100)}
          >
            <i className='fa fa-save' />
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{ width: '40px', display: 'flex', flexDirection: 'column' }}
          >
            {loaded && playing && (
              <span
                className='btn btn-block btn-default'
                onClick={this.togglePlay}
              >
                <i className='fa fa-pause' />
              </span>
            )}
            {loaded && !playing && (
              <span
                className='btn btn-block btn-default'
                onClick={this.togglePlay}
              >
                <i className='fa fa-play' />
              </span>
            )}
          </div>
          <div style={{ flex: 1, position: 'relative', height: '100%' }}>
            {!loaded && !errored && (
              <span>
                <i className='fa fa-spinner fa-spin' /> Chargement en cours{' '}
              </span>
            )}

            <div
              id='waveform'
              ref={div => {
                this.waveformContainer = div
              }}
            />
            <div
              id='wave-timeline'
              ref={div => {
                this.waveformTimelineContainer = div
              }}
            />
            {name && (
              <h4
                style={{
                  background: 'rgba(255,255,255,0.75)',
                  margin: '0.5em'
                }}
              >
                {name}
              </h4>
            )}
          </div>
        </div>
      </div>
    )
  }
}
