import * as api from '../api'
import { toJS } from 'mobx'
import checkModule from './module'
import checkAction from './action'
export default async scenario => {
  const checkResult = {
    warnings: [],
    errors: [],
    nb: 0
  }
  const xp = await api.experiences.get(scenario.experienceId)
  const link =
    '/site/' +
    xp.siteId +
    '/experience/' +
    xp._id +
    '/scenario/' +
    scenario._id
  const modules = toJS(await api.modules.getFrom('scenarios', scenario._id)).sort(
    (m1, m2) => m1.order - m2.order
  )
  const usedOrders = {}
  for (let index = 0; index < modules.length; index++) {
    const m = modules[index]
    const res = await checkModule(m)
    checkResult.warnings = checkResult.warnings.concat(res.warnings)
    checkResult.errors = checkResult.errors.concat(res.errors)
    checkResult.nb += res.nb
    checkResult.nb++
    if (m.type === 'choice' && (scenario.onEnd || []).length && index === modules.length - 1) {
      checkResult.warnings.push({
        text: `les actions  situées après un module choix dans  ${scenario.name} seront executées dès le lancement du choix par l'utilisateur, car le module choix est le dernier module `,
        link: link,
        _id: scenario._id
      })
    }
    checkResult.nb++
    if (typeof m.order === 'undefined') {
      checkResult.warnings.push({
        text: `le modules ${m.name || m.internalName || m.type} du scenario  ${
          scenario.name
        } n'a pas d'ordre`,
        link: link,
        _id: scenario._id
      })
    } else {
      checkResult.nb++
      if (usedOrders[m.order]) {
        checkResult.warnings.push({
          text: `le modules ${m.name || m.internalName || m.type} et ${
            usedOrders[m.order]
          } ont le même ordre`,
          link: link,
          _id: scenario._id
        })
      } else {
        usedOrders[m.order] = m.name || m.internalName || m.type
      }
    }
  }

  for (let index = 0; index < (scenario.onEnd || []).length; index++) {
    const res = await checkAction(scenario.onEnd[index], link)
    checkResult.warnings = checkResult.warnings.concat(res.warnings)
    checkResult.errors = checkResult.errors.concat(res.errors)
    checkResult.nb += res.nb
  }
  return checkResult
}
