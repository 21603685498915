import { toJS } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import { Page } from '../'

class ExperienceDuplicate extends Component {
  state = {
    interval: null,
    progress: 0,
    loading: true,
    sites: []
  };

  async componentDidMount () {
    const sites = await api.sites.list()
    this.setState({ sites: toJS(sites), loading: false })
  }

  duplicate = async ({ siteUid }) => {
    const { experienceUid } = this.props.match.params
    const DURATION = 2 * 60 * 1000
    const interval = setInterval(() => {
      this.setState({ progress: this.state.progress + 1 })
    }, DURATION / 100)
    this.setState({ interval })
    const { siteId, _id } = await api.experiences.duplicate(
      experienceUid,
      siteUid
    )
    clearInterval(this.state.interval)

    this.props.history.push('/site/' + siteId + '/experience/' + _id)
  };

  render () {
    const { experienceUid, siteUid } = this.props.match.params
    const { loading, sites, interval, progress } = this.state
    return (
      <Page>
        <NavInExperience />
        <div id='content'>
          {!loading && !interval && (
            <>
              <Form
                formPath='duplicate'
                onSubmit={this.duplicate}
                experienceUid={experienceUid}
                initialFormData={{
                  siteUid
                }}
                formContext={{ sites }}
              />

              <span>
                Vers quel site voulez vous dupliquer cette expérience ?
              </span>
            </>
          )}
          {interval && (
            <span>
              {' '}
              Duplication en cours
              {progress} %{' '}
            </span>
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default observer(ExperienceDuplicate)
