import React, { Component } from 'react'
import { observer } from 'mobx-react'
import QRCodeReact from 'qrcode.react'
import { NavInSites } from '../../components/nav'
import { Form } from '../../components/form'
import Loading from '../../components/loading'
import * as api from '../../lib/api'
import { apiCache } from '../../stores'
import { Page } from '../'

class Segment extends Component {
  async populateState(bundleUid) {
    await api.experiences.list()
    await api.sites.list()
    if (bundleUid) {
      await api.bundles.get(bundleUid)
    }
    this.setState({ loaded: true })
  }

  componentDidMount() {
    const { bundleUid } = this.props.match.params
    this.populateState(bundleUid)
  }

  componentWillReceiveProps(newProps) {
    const { bundleUid } = newProps.match.params
    this.populateState(bundleUid)
  }

  delete = async () => {
    const { siteUid, experienceUid, bundleUid } = this.props.match.params
    await api.bundles.remove(bundleUid)
    const url = '/site/' + siteUid + '/experience/' + experienceUid
    this.props.history.push(url)
  }

  save = async formData => {
    formData.experienceId = this.props.match.params.experienceUid
    const bundle = await api.bundles.upsert(formData)
    const url = this.props.match.url + (this.props.match.params.bundleUid ? '' : bundle._id)
    this.props.history.push(url)
  }

  render() {
    const { bundleUid, siteUid } = this.props.match.params
    const bundle = apiCache.bundles.get(bundleUid) || {}
    const experiences = apiCache.experiences.list()
    const sites = apiCache.sites.list()
    const loading = bundleUid && !bundle
    return (
      <Page>
        <NavInSites />
        <div id="content">
          {loading && <Loading />}
          {!loading && (
            <Form
              key={bundle.id || 'new'}
              formPath="bundle"
              onSubmit={this.save}
              onDelete={this.delete}
              initialFormData={bundle}
              formContext={{ experiences, sites, siteUid }}
            />
          )}
          {bundleUid && (
            <>
              <h4>QrCode à scanner pour ouvrir ce site directement dans l'application explorgames</h4>
              <QRCodeReact
                value={'https://workshop.explor.games/bundle/' + bundleUid}
                style={{ padding: '10px' }}
                size={148}
                includeMargin
                level="M"
              />
            </>
          )}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}

export default observer(Segment)
