import React, { Component } from 'react'
import { ImageOverlay, Marker, Rectangle } from 'react-leaflet'
import Control from 'react-leaflet-control'
import BaseMap from './../../map'

import * as api from '../../../lib/api'

function distance (lat1, lon1, lat2, lon2) {
  var R = 6378137 // Radius of the earth in m
  var dLat = deg2rad(lat2 - lat1) // deg2rad below
  var dLon = deg2rad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}

function deg2rad (deg) {
  return deg * (Math.PI / 180)
}

class BoundingBox extends Component {
  state = {
    polylineKey: Math.random(),
    dragging: false,
    url: '',
    opacity: 0.5,
    places: [],
    mapDraggable: true
  };

  constructor (props) {
    super(props)
    this.refSE = React.createRef()
    this.refNW = React.createRef()
    this.refMap = React.createRef()
  }

  drag = e => {
    if (this.state.mapDraggable) {
      return
    }
    if (this.state.dragStartlocation) {
      const currentLocation = e.latlng
      const latDiff = currentLocation.lat - this.state.dragStartlocation.lat
      const lngDiff = currentLocation.lng - this.state.dragStartlocation.lng
      const { north, south, east, west } = this.props.formData
      this.setState({
        draggingBBox: {
          north: north + latDiff,
          south: south + latDiff,
          east: east + lngDiff,
          west: west + lngDiff
        }
      })
    } else {
      /* const draggingBBox = this.computeCornerBounds()
      this.setState({ draggingBBox }) */
    }
  };

  dragStart = e => {
    if (this.props.readOnly && !this.state.mapDraggable) {
      return
    }
    const { north, south, east, west } = this.props.formData
    const dragStartlocation = e.latlng
    const { lat, lng } = dragStartlocation

    if (north < lat || south > lat || east < lng || west > lng) {
      return
    }
    const nw = this.refNW.current.leafletElement.getLatLng()
    const se = this.refSE.current.leafletElement.getLatLng()
    if (this.refMap.current.pixelDistance({ lat, lng }, nw) < 20) {
      return
    }
    if (this.refMap.current.pixelDistance({ lat, lng }, se) < 20) {
      return
    }

    if (dragStartlocation.lat) { this.setState({ mapDraggable: false, dragStartlocation }) }
  };

  dragEnd = e => {
    if (
      this.props.readOnly ||
      !this.props.onChange ||
      this.state.mapDraggable ||
      !this.state.dragStartlocation
    ) {
      return
    }
    const currentLocation = e.latlng
    const latDiff = currentLocation.lat - this.state.dragStartlocation.lat
    const lngDiff = currentLocation.lng - this.state.dragStartlocation.lng
    const { north, south, east, west } = this.props.formData
    this.props.onChange &&
      this.props.onChange({
        north: north + latDiff,
        south: south + latDiff,
        east: east + lngDiff,
        west: west + lngDiff
      })
    this.setState({
      mapDraggable: true,
      dragStartlocation: null,
      draggingBBox: null
    })
  };

  computeCornerBounds () {
    const nw = this.refNW.current.leafletElement.getLatLng()
    const se = this.refSE.current.leafletElement.getLatLng()
    const bounds = {
      north: nw.lat,
      south: se.lat,
      east: se.lng,
      west: nw.lng
    }
    const horizontalDistance = distance(se.lat, se.lng, se.lat, nw.lng)
    var verticalDistance = distance(nw.lat, nw.lng, se.lat, nw.lng)

    if (
      horizontalDistance / verticalDistance !==
      this.state.width / this.state.height
    ) {
      verticalDistance =
        (horizontalDistance * this.state.height) / this.state.width
      // from https://stackoverflow.com/questions/2839533/adding-distance-to-a-gps-coordinate
      //
      bounds.north =
        bounds.south + (180 / Math.PI) * (verticalDistance / 6378137)
    }
    return bounds
  }

  cornerDragStart = e => {
    this.setState({ mapDraggable: false })
    return false
  };

  cornerDragEnd = () => {
    const bounds = this.computeCornerBounds()
    this.props.onChange(bounds)
    this.setState({ mapDraggable: true })
  };

  populateResource (resourceUid) {
    if (!resourceUid || resourceUid.indexOf('__transfert__') === 0) {
      return
    }
    api.resources.get(resourceUid).then(resource => {
      if (resource) {
        var url = api.resources.getUrl(
          resource.hd ? resource.hd.file : resource.original.file
        )
        var img = document.createElement('img')
        img.src = url
        img.onload = () => {
          this.setState({
            url,
            width: img.naturalWidth,
            height: img.naturalHeight
          })
        }
      }
    })
  }

  componentWillReceiveProps (props) {
    const uiSchema = this.props.uiSchema || {}
    const uiOptions = uiSchema['ui:options'] || {}
    const backgroundFieldName = uiOptions.background

    if (
      backgroundFieldName &&
      props.formContext.formData[backgroundFieldName] !==
        this.props.formContext.formData[backgroundFieldName]
    ) {
      this.populateResource(props.formContext.formData[backgroundFieldName])
    }
    // this.mapRef && this.mapRef.fitBounds(props.formData)
  }

  componentDidMount () {
    const uiSchema = this.props.uiSchema || {}
    const uiOptions = uiSchema['ui:options'] || {}
    const backgroundFieldName = uiOptions.background

    if (
      backgroundFieldName &&
      this.props.formContext.formData[backgroundFieldName]
    ) {
      this.populateResource(
        this.props.formContext.formData[backgroundFieldName]
      )
    }
  }

  render () {
    const { north, south, east, west } = this.props.formData
    const { url, opacity, mapDraggable, draggingBBox } = this.state
    const center = {
      lat: south / 2 + north / 2,
      lng: east / 2 + west / 2
    }
    if (!center.lat && !center.lng) {
      return null
    }
    const bounds = [
      [south, west],
      [north, east]
    ]
    const draggingBBoxBounds = draggingBBox && [
      [draggingBBox.south, draggingBBox.west],
      [draggingBBox.north, draggingBBox.east]
    ]

    // this.mapRef && this.mapRef.fitBounds(bounds)
    return (
      <BaseMap
        center={center}
        style={{ flex: 1 }}
        dragging={mapDraggable}
        onMouseUp={this.dragEnd}
        onMouseDown={this.dragStart}
        onMouseOut={this.dragEnd}
        onMouseMove={this.drag}
        ref={this.refMap}
      >
        {url && (
          <ImageOverlay
            opacity={opacity}
            url={url}
            bounds={bounds}
            interactive={false}
            key={JSON.stringify(bounds) + url}
            ref={ref => (this.refGround = ref)}
          />
        )}

        {draggingBBoxBounds && <Rectangle bounds={draggingBBoxBounds} />}
        {!draggingBBoxBounds && <Rectangle bounds={bounds} />}

        <Marker
          draggable
          position={[north, west]}
          ref={this.refNW}
          onDragStart={this.cornerDragStart}
          onDragend={this.cornerDragEnd}
        />
        <Marker
          draggable
          position={[south, east]}
          ref={this.refSE}
          onDragStart={() => {
            this.cornerDragStart()
          }}
          onDragend={() => {
            this.cornerDragEnd()
          }}
        />
        <Control position='topleft'>
          <input
            type='range'
            min='0'
            max='100'
            defaultValue={Math.round(opacity * 100)}
            onChange={e =>
              this.setState({
                opacity: parseInt(e.target.value, 10) / 100
              })}
          />
        </Control>
      </BaseMap>
    )
  }
}

export default function (props) {
  return (
    <div className='form-group field '>
      <label className='control-label' htmlFor={props.name}>
        {props.title || props.name}
      </label>
      <div
        className='row'
        style={{
          minHeight: '400px',
          width: '80%',
          margin: '0 auto',
          display: 'flex'
        }}
      >
        <BoundingBox {...props} />
      </div>
    </div>
  )
}
