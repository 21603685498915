import React, { Component } from 'react'
import GenericForm from './generic'
import Loading from '../loading'
import * as api from '../../lib/api'
import './form.css'
import { user } from '../../stores'
import { t } from 'stores/i18n.store'
import { copyElement } from 'stores/clipboard.store'

class ListHistory extends Component {
  state = {
    history: [],
    loading: false,
    restauring: false
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.props.listHistory().then(history => {
      this.setState({ history: history.reverse(), loading: false })
    })
  }

  render() {
    const { history, loading, restauring } = this.state
    const { rollBacktoPatch } = this.props
    const now = new Date()
    if (loading) {
      return <Loading />
    }
    return (
      <div>
        <h1>Restauration d'une ancienne version</h1>
        {history.map((h, index) => {
          const date = new Date(h.date)
          let dateText = ''

          if (date.getFullYear() !== now.getFullYear() || date.getMonth() !== now.getMonth() || date.getDay() !== now.getDay()) {
            dateText = 'du ' + date.toLocaleDateString() + ' à '
          } else {
            dateText = 'de ' // 'de '
          }
          const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
          const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
          dateText += hour + 'h' + minute

          return (
            <div
              style={{
                height: '3em',
                borderBottom: '1px solid gray',
                marginBottom: '0.25em'
              }}
              key={h._id}
            >
              <span style={{ padding: '1em' }}>
                {index === 0 && 'Version actuelle '}
                {index > 0 && 'Version '}
                {dateText}
              </span>
              {index > 0 && rollBacktoPatch && !restauring && (
                <button
                  className="btn btn-warning pull-right"
                  onClick={() => {
                    this.setState({ restauring: true })
                    rollBacktoPatch(h._id)
                  }}
                >
                  Restaurer cette version
                </button>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

const ConfirmDelete = props => {
  return (
    <form>
      <div className="form-group col-xs-6 col-xs-offset-3">
        <label htmlFor="deleteKey">
          Voulez vous vraiment effacer <b>{props.name}</b> ?
        </label>
      </div>
      <div className="col-xs-3 col-xs-offset-3">
        <span
          className="btn btn-default btn-block"
          onClick={e => {
            e.preventDefault()
            props.cancel()
          }}
        >
          Ne pas effacer
        </span>
      </div>
      <div className="col-xs-3">
        <button
          type="submit"
          className="btn btn-danger btn-block"
          onClick={e => {
            e.preventDefault()
            props.delete()
          }}
        >
          Confimer l'effacement
        </button>
      </div>
    </form>
  )
}

class RawJsonForm extends Component {
  state = {
    value: JSON.stringify(this.props.value, null, 2).replace(/\\n/g, '\n'),
    valid: true,
    confirmingDelete: false
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  toJson(val) {
    const l = val.length
    let inString = false
    let precIsSlash = false
    let res = ''
    for (let i = 0; i < l; i++) {
      if (val[i] === '\\' && !precIsSlash) {
        precIsSlash = true
        res += val[i]
        continue
      }
      if (val[i] === '"' && !precIsSlash) {
        inString = !inString
        res += val[i]
        continue
      }
      if (val[i] === '\n' && inString) {
        res += '\\n'
        continue
      }
      res += val[i]
      precIsSlash = false
    }

    try {
      const json = JSON.parse(res)
      return json
    } catch (e) {
      return false
    }
  }

  handleChange(e) {
    const value = e.target.value
    const json = this.toJson(value)

    this.setState({
      value: value,
      valid: !!json,
      json: json
    })
    this.props.onChange && this.props.onChange(value)
  }

  handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    this.props.onSubmit && this.props.onSubmit(this.toJson(this.state.value))
  }

  render() {
    const props = this.props
    const { value, valid } = this.state
    return (
      <div style={props.style}>
        <textarea
          className="form-control"
          style={{
            height: '100%'
          }}
          value={value}
          onChange={this.handleChange}
        />{' '}
        {valid && <input type="button" value="Enregistrer" className="btn btn-primary btn-block" onClick={this.handleSubmit} />}
        {!valid && <span className="alert">Le json n'est pas valide</span>}{' '}
      </div>
    )
  }
}

export class Form extends Component {
  state = {
    def: null,
    modified: false,
    formData: this.props.initialFormData || {},
    showForm: user.formMode || 'normal',
    isExpert: false
  }

  handleDelete = () => {
    this.setState({
      showForm: 'confirmDelete',
      deleteKey: Math.round(Math.random() * 1000000)
    })
  }

  handleDeleteConfirmation = e => {
    e && e.preventDefault()
    if (parseInt(this.state.deleteKeyConfirmation, 10) === parseInt(this.state.deleteKey, 10)) {
      const p = this.props.onDelete && this.props.onDelete()

      this.setState({ deleting: true })
      if (p && p.then && p.catch) {
        p.then(() => {})
      }
    } else {
      this.setState({
        confirmingDelete: true,
        deleteKey: Math.round(Math.random() * 1000000),
        deleteKeyConfirmation: ''
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.updatedAt !== this.props.initialFormData.updatedAt) {
      this.setState({ formData: nextProps.initialFormData })
    }
  }

  componentDidMount() {
    api.users.amIAnExpert().then(isExpert => this.setState({ isExpert }))
  }

  handleRollback = patchId => {
    this.props.rollBackToPatch(patchId).then(() => {
      this.setState({ showForm: 'normal' })
    })
  }

  showAdvanced = () => {
    this.setState({ showForm: 'advanced' })
    user.formMode = 'advanced'
  }

  showNormal = () => {
    this.setState({ showForm: 'normal' })
    user.formMode = 'normal'
  }

  render() {
    const { isExpert, loading, deleteKey, showForm, formData } = this.state
    const { onDuplicate, onDelete, onSubmit, listHistory, style, formPath } = this.props
    const canDuplicate = !!onDuplicate && !!isExpert

    const onModule = /^modules\/[a-zA-Z_]*$/.test(formPath)

    if (loading) {
      return <div>Loading</div>
    }
    if (showForm === 'confirmDelete') {
      return (
        <ConfirmDelete
          deleteKey={deleteKey}
          delete={this.props.onDelete}
          cancel={() => this.setState({ showForm: 'normal' })}
          name={formData.name || formData.internalName || formData.title || ''}
        />
      )
    }
    if (showForm === 'history') {
      return <ListHistory listHistory={listHistory} rollBacktoPatch={this.handleRollback} />
    }

    return (
      <div style={style || {}}>
        {isExpert && (
          <div className="btn-group" role="group" aria-label="...">
            <button
              className={showForm === 'json' ? 'btn-info btn' : 'btn-default btn'}
              onClick={() => this.setState({ showForm: 'json' })}
            >
              Json
            </button>
            <button className={showForm === 'normal' ? 'btn-info btn' : 'btn-default btn'} onClick={this.showNormal}>
              Formulaire
            </button>
            {
              <button className={showForm === 'advanced' ? 'btn-info btn' : 'btn-default btn'} onClick={this.showAdvanced}>
                Mode expert
              </button>
            }
          </div>
        )}
        {formData && formData._id && (
          <div className="btn-group pull-right" role="group" aria-label="...">
            {onDelete && (
              <button className="btn btn-danger" onClick={this.handleDelete}>
                <i className="fa fa-trash" />
              </button>
            )}

            {canDuplicate && (
              <button className="btn btn-warning" onClick={onDuplicate}>
                <i className="fa fa-clone" />
              </button>
            )}

            {canDuplicate && onModule && formData?._id && (
              <button
                className={onModule ? 'btn btn-info' : 'btn btn-warning'}
                onClick={() => copyElement('module', formData?._id)}
              >
                <i className={onModule ? 'fa fa-copy' : 'fa fa-clone'} /> {onModule ? t('btn-copy') : ''}
              </button>
            )}

            {/* listHistory &&
                isExpert && (
                  <button className="btn btn-default" onClick={() => this.setState({ showForm: 'history' })}>
                    <i className="fa fa-history" />
                  </button>
                ) */}
          </div>
        )}
        {showForm === 'json' && (
          <RawJsonForm
            value={formData}
            style={{
              height: '500px'
            }}
            onChange={() => this.setState({ modified: true })}
            onSubmit={onSubmit}
          />
        )}
        {showForm !== 'json' && <GenericForm {...this.props} advanced={showForm === 'advanced'} />}
      </div>
    )
  }
}
