import React from 'react'
import { Line } from 'react-chartjs-2'
import { t } from 'stores/i18n.store'
import { StatApi as Stat } from './stat'

export default class extends React.Component {
  state = {
    group: 'week'
  }

  render() {
    const group = this.state.group
    return (
      <div>
        <select className="form-control" value={group} onChange={e => this.setState({ group: e.target.value })}>
          <option value="week">{t('week')}</option>
          <option value="month">{t('month')}</option>
          <option value="year">{t('year')}</option>
        </select>
        <Stat
          {...this.props}
          axis="sessions"
          axisProperties={[group]}
          group={group}
          labelSession={t('sessions')}
          labelPeople={t('players')}
        >
          <Line />
        </Stat>
      </div>
    )
  }
}
