import React from 'react'
import { Link } from 'react-router-dom'

export default props => {
  const xpOptions = (props.formContext.sites || []).map(xp => {
    return (
      <option value={xp._id} key={xp._id}>
        {xp.name || xp._id}{' '}
      </option>
    )
  })
  return (
    <div className='form-group field field-string'>
      <label className='control-label' htmlFor={props.name}>
        {props.schema.title}
      </label>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <select
          className='form-control'
          value={props.formData}
          onChange={e => props.onChange(e.target.value)}
        >
          <option />
          {xpOptions}
        </select>
        {props.formData && (
          <Link
            style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
            to={'/site/' + props.formData}
            className='btn btn-default'
          >
            Voir
          </Link>
        )}
      </div>
    </div>
  )
}
