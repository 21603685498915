import React from 'react'
import { Link } from 'react-router-dom'

export default props => {
  const siteUid = props.formContext.siteUid || []
  const modules = (props.formContext.modules || []).sort(
    (a, b) => (a.order || 0) - (b.order || 0)
  )
  const currentModule = modules.find(m => m._id === props.formData)
  return (
    <div className='form-group field field-string'>
      <label className='control-label' htmlFor={props.name}>
        {props.schema.title}
      </label>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <select
          className='form-control'
          value={props.formData}
          onChange={e => props.onChange(e.target.value)}
        >
          <option />
          {modules.map(m => {
            return (
              <option value={m._id} key={m._id}>
                {m.internalName || m.title}{' '}
              </option>
            )
          })}
          <option value='end'>Terminer le scénario</option>
        </select>

        {currentModule && (
          <Link
            style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
            to={
              '/site/' +
              siteUid +
              '/experience/' +
              currentModule.experienceId +
              '/scenario/' +
              currentModule.scenarioId +
              '/module/' +
              props.formData
            }
            className='btn btn-default'
          >
            Voir
          </Link>
        )}
      </div>
    </div>
  )
}
