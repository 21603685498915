import React, { Component } from 'react'

export default class TimerChooser extends Component {
  change = e => {
    this.props.onChange(e.target.value)
  };

  render () {
    const experience = this.props.formContext.experience || {}
    let { timers } = experience
    timers = timers || {}
    const options = Object.keys(timers).map(key => {
      const timer = timers[key]
      return (
        <option value={timer._id} key={timer._id}>
          {timer.name || timer._id}
        </option>
      )
    })
    return (
      <div className='form-group field field-string' key={this.props}>
        <label className='control-label' htmlFor={this.props.name}>
          {this.props.schema.title}
        </label>
        <div className='col-xs-10'>
          <select
            className='form-control'
            value={this.props.formData}
            onChange={this.change}
          >
            <option /> {options}
          </select>
        </div>
      </div>
    )
  }
}
