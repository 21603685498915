import React, { Component } from 'react'
import { toJS } from 'mobx'
import chroma from 'chroma-js'
import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import Loading from '../../components/loading'
import { Page } from '../'
import { user } from '../../stores'

export default class ExperienceTheme extends Component {
  state = {
    experience: {},
    loading: true,
    saving: false
  }

  populateState (experienceUid) {
    this.setState({ loading: true })

    if (experienceUid) {
      api.experiences.get(experienceUid).then(experience => {
        if (experience._id === this.props.match.params.experienceUid) {
          this.setState({ loading: false, experience })
        }
      })
    } else {
      this.setState({ loading: false })
    }
  }

  componentDidMount () {
    this.populateState(this.props.match.params.experienceUid)
  }

  save = formData => {
    const experienceUid = this.props.match.params.experienceUid
    formData.siteId = this.props.match.params.siteUid
    formData.colors = formData.colors || {}
    if (user.formMode === 'normal' && formData.colors.color_primary) {
      let primary = formData.colors.color_primary
      if (primary.length === 9) {
        primary = '#' + primary.substr(3, 6) + primary.substr(1, 2)
      }
      formData.colors.color_primary = chroma(primary).hex('argb')
      formData.colors.color_primary_transparent = chroma(primary)
        .alpha(0.5)
        .hex('argb')
      formData.colors.color_primary_light = chroma(primary)
        .brighten()
        .hex('argb')
      formData.colors.color_primary_light_transparent = chroma(primary)
        .brighten()
        .alpha(0.5)
        .hex('argb')
      formData.colors.color_primary_dark = chroma(primary)
        .darken()
        .hex('argb')
      formData.colors.color_primary_dark_transparent = chroma(primary)
        .darken()
        .alpha(0.5)
        .hex('argb')

      let accent = formData.colors.color_accent
      if (accent.length === 9) {
        accent = '#' + accent.substr(3, 6) + accent.substr(1, 2)
      }
      formData.colors.color_accent = chroma(accent).hex('argb')
      formData.colors.color_accent_light = chroma(accent)
        .brighten()
        .hex('argb')
      formData.colors.color_accent_light_transparent = chroma(accent)
        .brighten()
        .alpha(0.5)
        .hex('argb')
      formData.colors.color_accent_transparent = chroma(accent)
        .alpha(0.5)
        .hex('argb')
      formData.colors.color_accent_dark = chroma(accent)
        .darken()
        .hex('argb')
      formData.colors.color_accent_dark_transparent = chroma(accent)
        .darken()
        .alpha(0.5)
        .hex('argb')
    }

    return api.experiences
      .upsert({ _id: experienceUid, theme: formData })
      .then(experience => {
        this.setState({ experience })
      })
  }

  generatePrimary = e => {
    e.preventDefault()
    const { experience } = this.state
    const theme = toJS(experience.theme) || {}

    let primary = theme.colors.color_primary
    if (primary.length === 9) {
      primary = '#' + primary.substr(3, 6) + primary.substr(1, 2)
    }
    theme.colors.color_primary = chroma(primary).hex('argb')
    theme.colors.color_primary_transparent = chroma(primary)
      .alpha(0.5)
      .hex('argb')
    theme.colors.color_primary_light = chroma(primary)
      .brighten()
      .hex('argb')
    theme.colors.color_primary_light_transparent = chroma(primary)
      .brighten()
      .alpha(0.5)
      .hex('argb')
    theme.colors.color_primary_dark = chroma(primary)
      .darken()
      .hex('argb')
    theme.colors.color_primary_dark_transparent = chroma(primary)
      .darken()
      .alpha(0.5)
      .hex('argb')
    theme.updatedAt = Date.now()
    this.setState({
      experience: { ...experience, theme, updatedAt: new Date() }
    })
  }

  generateAccent = e => {
    e.preventDefault()
    const { experience } = this.state
    const theme = toJS(experience.theme) || {}
    let accent = theme.colors.color_accent
    if (accent.length === 9) {
      accent = '#' + accent.substr(3, 6) + accent.substr(1, 2)
    }
    theme.colors.color_accent = chroma(accent).hex('argb')
    theme.colors.color_accent_light = chroma(accent)
      .brighten()
      .hex('argb')
    theme.colors.color_accent_light_transparent = chroma(accent)
      .brighten()
      .alpha(0.5)
      .hex('argb')
    theme.colors.color_accent_transparent = chroma(accent)
      .alpha(0.5)
      .hex('argb')
    theme.colors.color_accent_dark = chroma(accent)
      .darken()
      .hex('argb')
    theme.colors.color_accent_dark_transparent = chroma(accent)
      .darken()
      .alpha(0.5)
      .hex('argb')
    theme.updatedAt = Date.now()
    this.setState({
      experience: { ...experience, theme, updatedAt: new Date() }
    })
  }

  render () {
    const { match } = this.props
    const { experience, loading } = this.state
    const theme = toJS(experience.theme) || {}
    theme.colors = theme.colors || {}
    console.log('THEME', theme)
    theme.colors.adventure_background =
      theme.colors.adventure_background || theme.colors.adventureBackground
    theme.colors.color_primary =
      theme.colors.color_primary || theme.colors.primary
    theme.colors.color_accent =
      theme.colors.color_accent || theme.colors.secondary
    return (
      <Page>
        {match.params.experienceUid && <NavInExperience />}
        <div id='content'>
          {loading && <Loading />}
          {!loading && (
            <Form
              formPath='theme'
              initialFormData={theme}
              experienceUid={match.params.experienceUid}
              onDuplicate={this.duplicate}
              onDelete={this.delete}
              onSubmit={this.save}
            />
          )}
          <span className='btn btn-default' onClick={this.generatePrimary}>
            Générer les déclinaisons de la couleur primaire
          </span>
          <br />
          <span className='btn btn-default' onClick={this.generateAccent}>
            Générer les déclinaisons de la couleur secondaire
          </span>
          <br />
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}
