import { toJS } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { NavInPoint, NavInTrack } from '../../components/nav'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import Loading from '../../components/loading'
import { Page } from '../'
import { apiCache } from '../../stores'
import checkPoint from '../../lib/check/point'

class PointForm extends Component {
  state = {
    tracks: [],
    track: {},
    point: {},
    tags: [],
    loading: true,
    saving: false
  }

  delete = async () => {
    const { siteUid, experienceUid, trackUid, pointUid } = this.props.match.params
    await api.points.remove(pointUid)
    const url = '/site/' + siteUid + '/experience/' + experienceUid + '/track/' + trackUid
    this.props.history.push(url)
  }

  rollBack = async patchId => {
    const { pointUid } = this.props.match.params
    const point = await api.points.rollBackToPatch(pointUid, patchId)
    this.setState({ point })
  }

  duplicate = async () => {
    const { siteUid, experienceUid, trackUid, pointUid } = this.props.match.params
    const clone = toJS(apiCache.points.get(pointUid))
    delete clone._id
    clone.name = clone.name + ' (copie)'
    const points = await api.points.getFrom('tracks', trackUid)
    clone.order = points.length
    const pointCreated = await api.points.create(clone)
    const url = '/site/' + siteUid + '/experience/' + experienceUid + '/track/' + trackUid + '/point/' + pointCreated._id
    this.props.history.push(url)
  }

  async populateState(experienceUid, trackUid, pointUid) {
    this.setState({ loading: true })
    await api.scenarios.getFromExperience(experienceUid)
    await api.segments.getFrom('experiences', experienceUid)
    await api.maps.getFrom('experiences', experienceUid)
    const tags = await api.tags.getFromExperience(experienceUid)
    const tracks = await api.tracks.getFromExperience(experienceUid)
    const track = tracks.find(t => t._id === trackUid)
    let point = pointUid && (await api.points.get(pointUid))
    point = point || {
      trackId: track._id,
      marker: { type: 'icon' },
      active: true,
      visible: true
    }
    switch (track.type) {
      case 'qrcode_visit':
        point.type = 'qrcode_visit'
        break
      case 'photo_hunt':
        point.type = 'photo_hunt'
        break
      case 'adventure':
        point.type = 'adventure'
        break

      case 'horde':
      case 'chase':
        point.type = 'objective'
        break
      default:
        point.type = 'point'
    }
    const checks = point._id && (await checkPoint(point))
    this.setState({ tags, point, checks, loading: false })
  }

  componentDidMount() {
    this.populateState(this.props.match.params.experienceUid, this.props.match.params.trackUid, this.props.match.params.pointUid)
  }

  componentWillReceiveProps(newProps) {
    console.log('new props', newProps)
    if (newProps.match.params.pointUid !== this.props.match.params.pointUid) {
      console.log('change id ')
      this.populateState(newProps.match.params.experienceUid, newProps.match.params.trackUid, newProps.match.params.pointUid)
    }
  }

  save = async formData => {
    const { siteUid, experienceUid, trackUid, pointUid } = this.props.match.params
    const track = apiCache.tracks.get(trackUid)

    // Pour être sûr que les propriétés "check" et "validation" contiennent la même chose, avec une priorité pour "check".
    formData.validation = formData.check = formData?.check || formData?.validation

    formData &&
      formData.markers &&
      Object.keys(formData.markers).forEach(key => {
        const m = formData.markers[key]
        if (m.resource && !m.size) m.size = 1
      })
    formData.experienceId = experienceUid
    // formData.trackId = formData.trackId || trackUid;
    formData.active = !formData.inactive
    formData.visible = !formData.invisible
    formData.guidanceEnabled = !formData.guidanceDisabled

    delete formData.inactive
    delete formData.invisible
    delete formData.guidanceDisabled

    if (formData.card && !formData.card.text && !formData.card.title && !formData.card.picture) {
      formData.card = null
    }
    if (!pointUid && track.type !== 'horde' && track.type !== 'chase' && track.type !== 'photo_hunt') {
      // création
      const scenarioValidation = {
        name: formData.name + ' après jeu',
        experienceId: experienceUid
      }
      const scenario = api.scenarios.create(scenarioValidation)
      formData.scenario = scenario._id
    }

    const point = await api.points.upsert(formData)

    const checks = await checkPoint(point)
    this.setState({ checks })
    if (!pointUid) {
      const url = this.props.match.url + (this.props.match.params.pointUid ? '' : point._id)
      this.props.history.push(url)
    } else {
      if (point.trackId !== trackUid) {
        console.log('changed track')
        this.props.history.push(
          '/site/' + siteUid + '/experience/' + experienceUid + '/track/' + point.trackId + '/point/' + pointUid
        )
      }
    }
  }

  /* mobx will force a new render */
  componentWillReact() {
    const { pointUid } = this.props.match.params
    if (pointUid && this.state.point) {
      const point = apiCache.points.get(pointUid)
      if (point && this.state.point.updatedAt !== point.updatedAt) {
        this.setState({ point: toJS(point) })
      }
    }
  }

  render() {
    const { siteUid, experienceUid, trackUid, pointUid } = this.props.match.params
    const point = { ...this.state.point } || {}
    const { loading, checks } = this.state
    // console.log("point in render ",point)
    pointUid && apiCache.points.get(pointUid) // to inform mobx I need to watch this in this component

    const maps = toJS(apiCache.maps.getFromExperience(experienceUid))
    const tags = apiCache.tags.getFromExperience(experienceUid)
    const track = toJS(apiCache.tracks.get(trackUid))
    const tracks = apiCache.tracks.getFromExperience(experienceUid)
    const scenarios = apiCache.scenarios.getFromExperience(experienceUid)
    const segments = apiCache.segments.getFromExperience(experienceUid)
    // console.log("guidance enabled ? ",point.guidanceEnabled, this.state.point)
    point.inactive = !point.active
    point.invisible = !point.visible
    point.guidanceDisabled = point.guidanceEnabled === false

    return (
      <Page>
        {point && track && (track.type === 'photo_hunt' || track.type === 'horde' || track.type === 'chase') && <NavInTrack />}
        {point && track && track.type !== 'photo_hunt' && track.type !== 'horde' && track.type !== 'chase' && <NavInPoint />}
        <div id="content">
          {loading && <Loading />}
          {checks &&
            checks.errors.map((e, i) => (
              <p className="bg-danger" key={i}>
                {e.text}
              </p>
            ))}
          {checks &&
            checks.warnings.map((e, i) => (
              <p className="bg-warning" key={i}>
                {e.text}
              </p>
            ))}
          {checks?.nb > 0 && (
            <p className="bg-success">
              {checks.nb - (checks.warnings?.length || 0) - (checks.errors?.length || 0)} / {checks.nb} vérifications réussies
            </p>
          )}
          {!loading && (
            <Form
              formPath={'points/' + point.type}
              initialFormData={point}
              onSubmit={this.save}
              onDelete={this.delete}
              onDuplicate={this.duplicate}
              key={this.props.match.url}
              experienceUid={experienceUid}
              formContext={{
                maps,
                tags,
                track,
                tracks,
                scenarios,
                segments,
                siteUid
              }}
              listHistory={() => api.points.listAvailableRollBack(pointUid)}
              rollBackToPatch={this.rollBack}
            />
          )}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}

export default observer(PointForm)
