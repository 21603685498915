import React from 'react'

export default ({ formData, name, formContext, onChange, schema }) => {
  const { categories } = formContext
  return (
    <>
      <label className="control-label" htmlFor={name}>
        {schema.title}
      </label>
      <select
        className="form-control"
        value={formData}
        multiple={true}
        size={categories.reduce((total, category) => total + category.variants.length + 1, 0)}
        onChange={e => {
          const options = [...e.target.selectedOptions]
          const values = options.map(option => option.value)
          onChange(values)
        }}
      >
        {(categories || []).map(c => {
          return !c ? null : (
            <optgroup label={c.label} k={c._id}>
              {(c.variants || []).map(({ _id, label }) => {
                return (
                  <option value={c._id + '°|°' + _id} key={_id}>
                    {label || _id}
                  </option>
                )
              })}
            </optgroup>
          )
        })}
      </select>
      <div />
    </>
  )
}
