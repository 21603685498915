import { t } from "../../stores/i18n.store";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as api from "../../lib/api";

export default class NavInStatistic extends Component {
  state = {
    isExpert: false,
    sites: [],
    experiences: [],
  };

  componentDidMount() {
    api.users.amIAnExpert().then((isExpert) => this.setState({ isExpert }));
    api.sites.list().then((sites) => this.setState({ sites }));
    api.experiences
      .list()
      .then((experiences) => this.setState({ experiences }));
  }

  render() {
    const { siteUid, experienceUid } = this.props.match.params;

    const sites = this.state.sites.filter(
      (site) => !siteUid || site._id === siteUid
    );
    const experiences = this.state.experiences.filter(
      (xp) =>
        !siteUid ||
        (experienceUid && xp._id === experienceUid) ||
        (!experienceUid && xp.siteId === siteUid)
    );
    return (
      <nav>
        <div className="nav-block">
          <h3>{t("nav-sites")}</h3>
          <ul>
            {sites.map((site) => {
              return <li key={site._id}>{site.name}</li>;
            })}
          </ul>
        </div>
        <div className="nav-block">
          <h3>Expériences</h3>
          <ul>
            {experiences.map((experience) => {
              return <li key={experience._id}>{experience.name}</li>;
            })}
          </ul>
        </div>
        <div className="nav-block">
          <h3>Période</h3>
          <ul>
            <li>
              <Link to="/">3 derniers mois</Link>
            </li>
            <li>
              <Link to="/">12 derniers mois</Link>
            </li>
            <li>
              <input type="date" />
            </li>
          </ul>
        </div>
        <div className="nav-block">
          <h3>Aggrégation</h3>
          <ul>
            <li>
              <Link to="/">par jour</Link>
            </li>
            <li>
              <Link to="/">par semaine</Link>
            </li>
            <li>
              <Link to="/">par mois</Link>
            </li>
          </ul>
        </div>
        <div className="nav-block">
          <h3>Email</h3>
          <ul>
            <li>
              <Link to="/">les deux</Link>
            </li>
            <li>
              <Link to="/">oui</Link>
            </li>
            <li>
              <Link to="/">non</Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
