import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import * as api from '../../lib/api'

export default class NavInSites extends Component {
  state = {
    isExpert: false
  };

  componentDidMount () {
    api.users.amIAnExpert().then(isExpert => this.setState({ isExpert }))
  }

  render () {
    if (!this.state.isExpert) {
      return null
    }
    return (
      <nav>
        <div className='nav-block'>
          <h3>
            <NavLink to='/' exact>
              Sites
            </NavLink>
          </h3>
          <ul />
        </div>

        <div className='nav-block'>
          <h3>
            <NavLink to='/users'>Utilisateurs</NavLink>
          </h3>
        </div>
        <div className='nav-block'>
          <h3>
            <NavLink to='/statistics'>Statistiques</NavLink>
          </h3>
        </div>
        <div className='nav-block'>
          <h3>
            <NavLink to='/applications'>Applications</NavLink>
          </h3>
        </div>
        <div className='nav-block'>
          <h3>
            <NavLink to='/bundle/list'>Groupe d'expériences</NavLink>
          </h3>
        </div>
      </nav>
    )
  }
}
