import React, { Component } from 'react'

import Local from './local'
import ApplicationImage from './applicationimage'

export class ResourcePicker extends Component {
  render () {
    return <Local {...this.props} />
  }
}

export class ImagePicker extends Component {
  render () {
    return (
      <ResourcePicker
        type='image'
        defaultFilter={'\\.png$|\\.jpg$|\\.jpeg$'}
        {...this.props}
      />
    )
  }
}

export class SoundPicker extends Component {
  render () {
    return (
      <ResourcePicker
        type='audio'
        defaultFilter={'\\.mp3$|\\.aac$'}
        {...this.props}
      />
    )
  }
}

export class VideoPicker extends Component {
  render () {
    return (
      <ResourcePicker
        type='video'
        defaultFilter={'\\.mp4$|\\.mkv$|\\.avi$|\\.mov$'}
        {...this.props}
      />
    )
  }
}

export class MapGroundPicker extends Component {
  render () {
    return (
      <ResourcePicker
        type='mapground'
        defaultFilter={'\\.gpx$'}
        {...this.props}
      />
    )
  }
}

export class ApplicationImagePicker extends Component {
  render () {
    return <ApplicationImage {...this.props} />
  }
}
