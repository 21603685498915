import React, { useEffect, useState } from "react";
import * as api from "../../lib/api";
import Loading from "../../components/loading";
import { Page } from "../";
import { NavInExperience } from "../../components/nav";

export default (props) => {
  const [emails, setEmails] = useState(null);
  useEffect(() => {
    const load = async () => {
      const sessions = await api.sessions.getFrom(
        "experiences",
        props.match.params.experienceUid
      );
      setEmails(
        sessions
          .map(({ email }) => email)
          .filter((email) => !!email)
          .filter((elem, pos, arr) => {
            return arr.indexOf(elem) === pos; //uniq
          })
      );
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Page>
      <NavInExperience />
      <div id="content" style={{ overflow: "scroll" }}>
        {emails === null && <Loading />}
        {emails !== null && (
          <table>
            <thead>
              <tr>
                <th> emails</th>
              </tr>
            </thead>
            <tbody>
              {emails.map((email) => (
                <tr key={email}>
                  <td>{email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Page>
  );
};
