import React, { useEffect, useState } from 'react'
import * as api from '../../lib/api'
import Loading from '../../components/loading'
import { Page } from '../'
import { NavInExperience } from '../../components/nav'


export default props=>{
    const [ratings, setRatings] = useState(null)
    const [ratingsKey, setRatingsKeys] = useState(null)
    useEffect(()=>{
        const load = async() => {
            const sessions = await api.sessions.getFrom('experiences', props.match.params.experienceUid)
            const keys = {}
            sessions
                .forEach(({ratings})=>{
                    if(!ratings){
                        return
                    }
                    ratings.forEach(({key})=>{
                        keys[key] = true
                    })
                })
            setRatingsKeys(Object.keys(keys))
            setRatings(sessions
                .filter(({ratings})=> !!ratings && ratings.length > 0 )
                )
        }
        load()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Page>
          <NavInExperience />
          <div id='content' style={{ overflow: 'scroll' }}>
            {ratings === null && <Loading/>}
            {ratings !== null && <table>
                <thead>
                    <tr>
                        <th colspan={ratingsKey.length+1}> Notes</th>
                    </tr>
                    <tr>
                        {ratingsKey.map(key=><th key={key}>{key}</th>)}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {ratings.map(({ratings,_id}, index)=><tr key={index}>
                        {ratingsKey.map(ratingKey=>{
                            let ratingForKey = ratings.find(({key})=>key === ratingKey)
                            return <td>
                                { ratingForKey?.score ?? ''}
                            </td>
                        })}
                        <td>
                        <a
            style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
            href={`https://Scores.explor.games/session/${_id}`}
            className='btn btn-default'
            target="_blank"
          >
            Voir
          </a>
                        </td>
                    </tr>)}
                
                </tbody>
                </table>}
          </div>
        </Page>
      )
}