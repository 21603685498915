import React, { Component } from 'react'
import QRCodeReact from 'qrcode.react'

import { Page } from '../'
import * as api from '../../lib/api'

import Loading from '../../components/loading'
import { Form } from '../../components/form'
import { NavInApplication } from '../../components/nav'
/* global fetch */
class ApplicationReviews extends Component {
  state = {
    loading: false,
    reviews: []
  }

  async populate(props) {
    const { applicationId } = props
    this.setState({ loading: true })
    const res = await fetch('https://itunes.apple.com/fr/rss/customerreviews/id=' + applicationId + '/sortBy=mostRecent/json')
    const json = await res.json()
    let reviews = (json && json.feed && json.feed.entry) || []
    if (!Array.isArray(reviews)) {
      reviews = [reviews] // si il n'y a qu'une review, alors la review n'est pas un tableau
    }
    this.setState({
      loading: false,
      reviews: reviews || []
    })
  }

  componentDidMount() {
    if (this.props.applicationId) {
      this.populate(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.applicationId !== nextProps.applicationId && nextProps.applicationId) {
      this.populate(nextProps)
    }
  }

  render() {
    const { loading, reviews } = this.state
    if (loading) {
      return <Loading />
    }

    return (
      <div>
        {reviews.length > 0 &&
          reviews.map(review => {
            const dateReview = new Date(review.updated.label)
            return (
              <div key={review.id.label} className="clear">
                <h4 className="pull-right">{review['im:rating'].label} / 5</h4>
                <h3>
                  {review.title.label}{' '}
                  <small>
                    par {review.author.name.label}, le {dateReview.getDate()}/{dateReview.getMonth() + 1}/
                    {dateReview.getFullYear()}
                  </small>
                </h3>
                <p>
                  {review.content.label} <br />
                  <a href={review.link.attributes.href} target="_blank" rel="noopener noreferrer">
                    voir
                  </a>
                </p>
              </div>
            )
          })}
        {reviews.length === 0 && " pas de review pour l'instant"}
      </div>
    )
  }
}

export default class Application extends Component {
  state = {
    loading: false,
    application: null
  }

  async populateState(props) {
    const applicationId = props.match.params.applicationId
    this.setState({ loading: true })
    const sites = await api.sites.list()
    let application
    if (applicationId) {
      application = await api.applications.get(applicationId)
    }
    const bundles = await api.bundles.list()

    this.setState({ application, bundles, sites, loading: false })
  }

  componentDidMount() {
    this.populateState(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.populateState(nextProps)
  }

  save = async application => {
    const applicationId = this.props.match.params.applicationId
    application = await api.applications.upsert(application)
    if (!applicationId) {
      return api.variants
        .upsert({
          name: 'default',
          language: 'default',
          applicationId: application._id
        })
        .then(() => this.props.history.push('/application/' + application._id))
    }
  }

  delete = () => {
    const applicationId = this.props.match.params.applicationId
    return api.applications.remove(applicationId).then(() => this.props.history.push('/applications/'))
  }

  render() {
    const { loading, application, sites, bundles } = this.state
    console.log(bundles)
    return (
      <Page>
        {application && <NavInApplication />}
        <div id="content">
          {loading && <Loading />}
          {!loading && (
            <Form
              formPath="application"
              initialFormData={application}
              onDuplicate={this.duplicate}
              onDelete={this.delete}
              onSubmit={this.save}
              formContext={{
                sites,
                bundles
              }}
            />
          )}
          {!loading && application && application.applicationId && (
            <ApplicationReviews applicationId={application.applicationId} />
          )}
          {application && application.siteId && (
            <QRCodeReact value={'https://workshop.explor.games/site/' + application.siteId} size={256} />
          )}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}
