import React from 'react'

export const ChapterLinkTrack = ({ text, trackId, chapters }) => {
  if (!chapters) {
    console.error(' no chapters in ChapterLinkTrack ', trackId, text)
    return null
  }
  const trackChapter = chapters[trackId]

  if (!trackChapter) {
    return null
  }
  return (
    <ChapterLink
      text={text || 'Parcours ' + trackChapter.track.name}
      chapter={trackChapter.chapter}
    />
  )
}
export const ChapterLinkScenario = ({ text, scenarioId, chapters }) => {
  if (!chapters) {
    console.error(' no chapters in ChapterLinkScenario ', scenarioId, text)
    return null
  }
  const scenarioChapter = chapters[scenarioId]
  if (!scenarioChapter) {
    return null
  }
  return (
    <ChapterLink
      text={text || 'Scénario ' + scenarioChapter.scenario.name}
      chapter={scenarioChapter.chapter}
    />
  )
}

export const ChapterLinkPoint = ({ text, pointId, chapters }) => {
  if (!chapters) {
    console.error(' no chapters in ChapterLinkPoint ', pointId, text)
    return null
  }
  const pointChapter = chapters[pointId]
  if (!pointChapter) {
    return null
  }
  return (
    <ChapterLink
      text={text || 'Scénario ' + pointChapter.point.name}
      chapter={pointChapter.chapter}
    />
  )
}

export const ChapterLinkModule = ({ text, moduleId, chapters }) => {
  if (!chapters) {
    console.error(' no chapters in ChapterLinkModule ', moduleId, text)
    return null
  }
  const moduleChapter = chapters[moduleId]
  if (!moduleChapter || !moduleChapter.module) {
    console.error(
      'chaptermodule does not exists  in ChapterLinkModule ',
      moduleId,
      text
    )
    return null
  }
  const module = moduleChapter.module
  const scenarioChapter = chapters[module.scenarioId]
  if (!scenarioChapter || !scenarioChapter.scenario) {
    console.error(
      'chaptermodule scenario does not exists  in ChapterLinkModule ',
      moduleId,
      text
    )
    return null
  }
  const scenario = scenarioChapter.scenario
  return (
    <ChapterLink
      text={`Module ${module.title} du scénario ${scenario.name} `}
      chapter={chapters[module.scenarioId].chapter}
    />
  )
}
export const ChapterLink = ({ text, chapter, style }) => {
  return (
    <a href={'#' + chapter} style={{ ...style }}>
      {text || 'Allez au chapitre ' + chapter}
    </a>
  )
}
