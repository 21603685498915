import { user, conf } from '../../stores'

const SERVICE_NAME = 'leaderboards-service'

export const leaderboards = {
  get: id => {},
  getFromSite: siteId => {
    return fetch(
      conf.API_URL + '/' + SERVICE_NAME + '/sites/' + siteId + '/leaderboards',
      {
        headers: {
          Authorization: 'Bearer ' + user.token
        }
      }
    ).then(res => res.json())
  }
}
