import React, { Component } from 'react'
import uuid from 'uuid'
import * as api from '../../lib/api'

const Zone = props => {
  const { top, left, right, bottom } = props.dimension
  return (
    <div
      style={{
        position: 'absolute',
        top: Math.min(top, bottom) + 'px',
        left: Math.min(left, right) + 'px',
        height: Math.abs(bottom - top) + 'px',
        width: Math.abs(left - right) + 'px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        border: '1px dashed white',
        boxShadow: '1px 1px 12px #555',
        color: 'white',
        textShadow: `-1px 0 0 rgba(0, 0, 0, 0.8), 
        1px 0 0 rgba(0, 0, 0, 0.8), 
        0 -1px 0 rgba(0, 0, 0, 0.8), 
        0 1px 0 rgba(0, 0, 0, 0.8)`,

        textAlign: 'center',
        zIndex: 1,
        overflow: 'hidden',
        borderRadius: '6px',
        pointerEvents: 'none'
      }}
    >
      {props.children}
    </div>
  )
}

const Circle = props => {
  let { top, left, radius } = props.dimension
  radius = radius || 30
  return (
    <div
      style={{
        position: 'absolute',
        top: top - radius + 'px',
        left: left - radius + 'px',
        width: radius * 2 + 'px',
        height: radius * 2 + 'px',
        paddingTop: radius - 10 + 'px',
        borderRadius: radius + 'px',
        backgroundColor: 'rgba(255,255,255,0.25)',
        border: '1px dashed #333',
        color: '#333',
        textAlign: 'center',
        zIndex: 1
      }}
    >
      {props.children}
    </div>
  )
}

export class ZoneOnPicture extends Component {
  state = {
    dimension: null,
    loading: false,
    imgLoaded: false,
    drawing: false,
    current: null,
    zones: [],
    resource: {}
  };

  populateResource (resourceUid) {
    if (!resourceUid || resourceUid.indexOf('__transfert__') === 0) {
      return
    }
    this.setState({ loading: true })
    api.resources.get(resourceUid).then(resource => {
      resource &&
        this.setState({
          url: api.resources.getUrl(
            resource.hd ? resource.hd.file : resource.original.file
          ),
          loading: false
        })
    })
  }

  componentDidMount () {
    const uiSchema = this.props.uiSchema || {}
    const uiOptions = uiSchema['ui:options'] || {}
    const backgroundFieldName = uiOptions.image
    if (
      backgroundFieldName &&
      this.props.formContext.formData[backgroundFieldName]
    ) {
      this.populateResource(
        this.props.formContext.formData[backgroundFieldName]
      )
    }
  }

  componentWillReceiveProps (nextProps) {
    const uiSchema = this.props.uiSchema || {}
    const uiOptions = uiSchema['ui:options'] || {}
    const backgroundFieldName = uiOptions.image
    if (
      backgroundFieldName &&
      !!nextProps.formContext.formData[backgroundFieldName] &&
      this.props.formContext.formData[backgroundFieldName] !==
        nextProps.formContext.formData[backgroundFieldName]
    ) {
      this.populateResource(
        nextProps.formContext.formData[backgroundFieldName]
      )
    }
  }

  normalizeZones (zones) {
    if (!this.state.imgLoaded) {
      return null
    }
    const { width, height } = this.state.dimension
    return zones.map(zone => {
      return {
        top: zone.top / height,
        bottom: (zone.bottom || 0) / height,
        right: (zone.right || 0) / width,
        left: zone.left / width,
        _id: zone._id
      }
    })
  }

  denormalizeZone (zones, width, height) {
    const dnorm = zones.map(zone => {
      return {
        top: zone.top * height,
        bottom: (zone.bottom || zone.top + 0.1) * height,
        right: (zone.right || zone.left + 0.1) * width,
        left: zone.left * width,
        _id: zone._id
      }
    })
    return dnorm
  }

  imgLoaded = e => {
    const uiSchema = this.props.uiSchema
    const uiOptions = uiSchema ? uiSchema['ui:options'] : {}
    const drawCircle = uiOptions && uiOptions.zoneShape === 'circle'

    this.setState({
      dimension: {
        width: e.target.offsetWidth,
        height: e.target.offsetHeight
      },
      drawCircle,
      zones:
        this.denormalizeZone(
          this.props.formData || [],
          e.target.offsetWidth,
          e.target.offsetHeight
        ) || [],
      imgLoaded: true
    })
  };

  onMouseDown = e => {
    if (!this.state.imgLoaded) {
      return
    }
    e.preventDefault()
    this.setState({
      current: {
        top: e.nativeEvent.offsetY,
        left: e.nativeEvent.offsetX,
        bottom: e.nativeEvent.offsetY,
        right: e.nativeEvent.offsetX,
        _id: uuid.v4()
      },
      drawing: true
    })
  };

  onMouseMove = e => {
    if (!this.state.imgLoaded || !this.state.drawing) {
      return
    }
    e.preventDefault()
    const current = this.state.current
    if (!current) {
      return
    }
    current.right = e.nativeEvent.offsetX
    current.bottom = e.nativeEvent.offsetY
    this.setState({ current })
  };

  onMouseUp = e => {
    if (!this.state.imgLoaded || !this.state.drawing) {
      return
    }

    const uiSchema = this.props.uiSchema
    const uiOptions = uiSchema ? uiSchema['ui:options'] : {}
    const drawCircle = uiOptions && uiOptions.zoneShape === 'circle'

    e.preventDefault()
    const current = this.state.current

    let zones = this.state.zones.slice()
    if (
      drawCircle ||
      (Math.abs(current.top - current.bottom) > 10 &&
        Math.abs(current.left - current.right) > 10)
    ) {
      if (this.state.drawCircle) {
        const center = {
          top: Math.min(current.top, current.bottom),
          left: Math.min(current.left, current.right),
          _id: current._id
        }
        zones = zones.concat(center)
      } else {
        const ordered = {
          top: Math.min(current.top, current.bottom),
          left: Math.min(current.left, current.right),
          right: Math.max(current.left, current.right),
          bottom: Math.max(current.top, current.bottom),
          _id: current._id
        }
        zones = zones.concat(ordered)
      }
      const normalized = this.normalizeZones(zones)
      this.props.onChange(normalized)
    } else {
      zones = this.state.zones
    }
    this.setState({ drawing: false, zones, current: null })
  };

  onDblClick = e => {
    const x = e.nativeEvent.offsetX
    const y = e.nativeEvent.offsetY
    const zones = this.state.zones.slice().filter(zone => {
      if (this.state.drawCircle) {
        return (
          x < zone.left - 20 ||
          x > zone.left + 20 ||
          y < zone.top - 20 ||
          y > zone.top + 20
        )
      }

      return (
        x < Math.min(zone.left, zone.right) ||
        x > Math.max(zone.left, zone.right) ||
        y < Math.min(zone.top, zone.bottom) ||
        y > Math.max(zone.top, zone.bottom)
      )
    })
    this.props.onChange(this.normalizeZones(zones))
    this.setState({ zones })
  };

  render () {
    if (this.state.loading) {
      return <span>chargement </span>
    }

    const { current, zones, dimension } = this.state

    const uiSchema = this.props.uiSchema || {}
    const uiOptions = uiSchema['ui:options'] || {}
    const showPixels = uiOptions.showPixels || false
    const drawedZones = []
    zones &&
      zones.forEach((zone, index) => {
        if (this.state.drawCircle) {
          drawedZones.push(
            <Circle key={index} dimension={zone}>
              Zone {index + 1}
            </Circle>
          )
        } else {
          if (zone.right > dimension.width) {
            drawedZones.push(
              <Zone
                key={index + 'overflow'}
                dimension={{
                  left: Math.max(0, zone.left - dimension.width),
                  right: zone.right - dimension.width,
                  bottom: zone.bottom,
                  top: zone.top
                }}
              >
                Zone {index + 1}
              </Zone>
            )

            if (zone.left < dimension.width) {
              drawedZones.push(
                <Zone
                  key={index}
                  dimension={{
                    left: zone.left,
                    right: dimension.width,
                    bottom: zone.bottom,
                    top: zone.top
                  }}
                >
                  Zone {index + 1}
                </Zone>
              )
            }
          } else {
            drawedZones.push(
              <Zone key={index} dimension={zone}>
                Zone {index + 1}
              </Zone>
            )
          }
        }
      })
    return (
      <div className='form-group field field-string'>
        <label className='control-label' htmlFor={this.props.name}>
          {this.props.schema.title}
        </label>
        <p>
          dessinez les zones de drop sur l'image à la souris, double cliquez sur
          une zone pour l'effacer
        </p>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'relative', flex: '1', marginRight: '265px', minHeight: '200px' }}>
            <img
              alt='background of dropzone'
              src={this.state.url}
              key='img'
              style={{ maxWidth: '100%' }}
              onLoad={this.imgLoaded}
            />{' '}
            {current &&
              (this.state.drawCircle ? (
                <Circle dimension={current} />
              ) : (
                <Zone dimension={current} />
              ))}
            {drawedZones}
            {dimension && (
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: dimension.width + 'px',
                  height: dimension.height + 'px',
                  zIndex: 5
                }}
                onMouseDown={this.onMouseDown}
                onMouseMove={this.onMouseMove}
                onMouseUp={this.onMouseUp}
                onMouseOut={this.onMouseUp}
                onDoubleClick={this.onDblClick}
              />
            )}
          </div>
          {showPixels && (
            <div style={{ position: 'absolute', width: '250px', right: 0, top: 0, bottom: 0, overflow: 'scroll' }}>
              {zones &&
                zones.map((zone, index) => {
                  return (
                    <div key={zone._id}>
                      <h3>Zone {index + 1}</h3>
                      <div style={{ display: 'flex' }}>
                        <label style={{ flex: '50px 0 0' }}>haut</label>
                        <input
                          type='number'
                          className='form-control'
                          value={parseInt(zone.top)}
                          step='1'
                          onChange={e => {
                            const top = parseInt(e.target.value, 10)
                            zones[index].top = top
                            const normalized = this.normalizeZones(zones)
                            this.props.onChange(normalized)
                            this.setState({ zones })
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <label style={{ flex: '50px 0 0' }}>gauche</label>
                        <input
                          type='number'
                          className='form-control'
                          value={parseInt(zone.left)}
                          step='1'
                          onChange={e => {
                            const left = parseInt(e.target.value, 10)
                            zones[index].left = left
                            const normalized = this.normalizeZones(zones)
                            this.props.onChange(normalized)
                            this.setState({ zones })
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <label style={{ flex: '50px 0 0' }}>bas</label>
                        <input
                          type='number'
                          className='form-control'
                          value={parseInt(zone.bottom)}
                          step='1'
                          onChange={e => {
                            const bottom = parseInt(e.target.value, 10)
                            zones[index].bottom = bottom
                            const normalized = this.normalizeZones(zones)
                            this.props.onChange(normalized)
                            this.setState({ zones })
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <label style={{ flex: '50px 0 0' }}>right</label>
                        <input
                          type='number'
                          className='form-control'
                          value={parseInt(zone.right)}
                          step='1'
                          onChange={e => {
                            const right = parseInt(e.target.value, 10)
                            zones[index].right = right
                            const normalized = this.normalizeZones(zones)
                            this.props.onChange(normalized)
                            this.setState({ zones })
                          }}
                        />
                      </div>
                      largeur : {Math.round(zone.right - zone.left)} /
                      hauteur : {Math.round(zone.bottom - zone.top)}
                      <br />
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export class ZonePicker extends Component {
  listZones (formData, path) {
    let field = formData
    while (field && path && path.length) {
      field = field[path[0]]
      path = path.slice(1)
    }
    return field || []
  }

  findZoneField (uiSchema) {
    let path = null
    if (!uiSchema) {
      return null
    }
    if (!Array.isArray(uiSchema) && typeof uiSchema !== 'object') {
      return null
    }
    if (uiSchema['ui:field'] === 'zoneonpicture') {
      path = []
    }

    Object.keys(uiSchema).forEach(key => {
      if (path === null) {
        const subPath = this.findZoneField(uiSchema[key])
        if (subPath !== null) {
          path = [key].concat(subPath)
        }
      }
    })
    return path
  }

  change = e => {
    this.props.onChange(e.target.value)
  };

  render () {
    const zonefieldPath = this.findZoneField(this.props.formContext.uiSchema)
    const zones = this.listZones(this.props.formContext.formData, zonefieldPath)
    const options = (zones || []).map((zone, index) => (
      <option key={index} value={zone._id}>
        Zone {index + 1}
      </option>
    ))
    return (
      <div className='form-group field field-string'>
        <label className='control-label' htmlFor={this.props.name}>
          {this.props.schema.title}
        </label>
        <select
          className='form-control'
          value={this.props.formData}
          onChange={this.change}
          readOnly={this.props.readonly}
          disabled={this.props.disabled}
        >
          <option />
          {options}
        </select>
      </div>
    )
  }
}
