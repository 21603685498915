import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Page } from '../'
import * as api from '../../lib/api'

import Loading from '../../components/loading'
import { NavInSites } from '../../components/nav'

export default class Users extends Component {
  state = {
    users: [],
    loading: false
  };

  populateState () {
    this.setState({ loading: true })
    const promises = []
    let promise

    promise = api.users.list().then(users =>
      this.setState({
        users: users.sort((a, b) =>
          (
            (a.lastname || '') +
            ' ' +
            (a.surname || '') +
            ' ' +
            (a.email || '')
          ).localeCompare(
            (b.lastname || '') + ' ' + (b.surname || '') + ' ' + (b.email || '')
          )
        )
      })
    )
    promises.push(promise)
    promise = api.sites.list().then(sites => this.setState({ sites }))
    promises.push(promise)
    promise = api.experiences
      .list()
      .then(experiences => this.setState({ experiences }))
    promises.push(promise)
    promise = api.bundles.list().then(bundles => this.setState({ bundles }))
    promises.push(promise)
    Promise.all(promises).then(() => this.setState({ loading: false }))
  }

  componentDidMount () {
    this.populateState(this.props)
  }

  componentWillReceiveProps (nextProps) {
    this.populateState(nextProps)
  }

  render () {
    const { loading, users } = this.state

    const leveltToText = {
      owner: 'Créateur ',
      viewer: 'Lecteur ',
      editor: 'Editeur '
    }
    const typeToText = {
      bundles: "du groupe d'expériences ",
      sites: 'du site ',
      experiences: "de l'expérience "
    }
    return (
      <Page>
        <NavInSites />
        {loading && <Loading />}
        {!loading && (
          <div id='content'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Email</th>
                  <th>Autorisations</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {users.map(user => {
                  return (
                    <tr key={user._id}>
                      <td>
                        {user.lastname} {user.firstname}
                      </td>
                      <td>{user.email}</td>
                      <td>
                        {user.scopes.map(scope => {
                          if (scope === 'admin') {
                            return 'administrateur'
                          }
                          const [type, id, level] = scope.split('/')
                          console.log([type, id, level])
                          let scopeText =
                            leveltToText[level] + typeToText[type]

                          const o =
                            this.state[type].find(one => one._id === id) || {}
                          scopeText += '"' + (o && o.name) + '" '
                          return (
                            <span key={scope}>
                              {scopeText} <br />
                            </span>
                          )
                        })}
                      </td>
                      <td>
                        <Link to={'/user/' + user._id} title='modifier'>
                          <i className='fa fa-edit' />
                        </Link>
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan='5'>
                    <Link to='/user/' className='btn btn-primary btn-block'>
                      Ajouter
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div id='app-preview' />
      </Page>
    )
  }
}
