import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import chroma from 'chroma-js'

import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'
import { Page } from '../'
import checks from '../../lib/check'

function formatDate (date) {
  let dateText = ''
  const now = new Date()
  if (
    date.getFullYear() !== now.getFullYear() ||
    date.getMonth() !== now.getMonth() ||
    date.getDate() !== now.getDate()
  ) {
    dateText = 'du ' + date.toLocaleDateString() + ' à '
  } else {
    dateText = 'à '
  }
  const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const minute =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  dateText += hour + 'h' + minute
  return dateText
}

function formatSize (size) {
  size = parseInt(size, 10) || 0
  if (size < 1024) {
    return size + 'o'
  }
  if (size < 1024 * 1024) {
    return Math.round((size * 10) / 1024 / 1024) / 10 + 'Ko'
  }
  if (size < 1024 * 1024 * 1024) {
    return Math.round((size * 10) / 1024 / 1024) / 10 + 'Mo'
  }
  return Math.round((size * 10) / 1024 / 1024 / 1024) / 10 + 'Go'
}

const SizeDistribution = ({ distribution }) => {
  if (!distribution) {
    return null
  }
  return (
    <div className='progress'>
      {Object.keys(distribution)
        .sort((e1, e2) => e1.localeCompare(e2))
        .map(ext => {
          if (ext === 'total') {
            return null
          }
          const percent = ([distribution[ext]] * 100) / distribution.total
          const color = chroma.random().hex()
          return (
            <div
              key={ext}
              className='progress-bar'
              role='progressbar'
              style={{ width: percent + '%', backgroundColor: color }}
              aria-valuenow={percent}
              aria-valuemin='0'
              aria-valuemax='100'
              title={formatSize(distribution[ext])}
            >
              {ext}
            </div>
          )
        })}
    </div>
  )
}

const Checks = ({ checks }) => {
  if (!checks) {
    return (
      <p className='bg-info'>
        <i className='fa fa-cog fa-spin fa-3x fa-fw' />
        Contrôle qualité en cours
      </p>
    )
  }
  const { errors, warnings, nb } = checks
  return (
    <>
      {errors &&
        errors.map((e, i) => (
          <p className='bg-danger' key={i}>
            {e.text}
            <Link className='btn btn-default' to={e.link}>
              <i className='fa fa-eye' />{' '}
            </Link>
          </p>
        ))}
      {warnings &&
        warnings.map((e, i) => (
          <p className='bg-warning' key={i}>
            {e.text}
            <Link className='btn btn-default' to={e.link}>
              <i className='fa fa-eye' />{' '}
            </Link>
          </p>
        ))}
      <p className='bg-success'>
        {nb - warnings.length - errors.length} vérifications réussies
      </p>
    </>
  )
}
class ExperienceDeploy extends Component {
  state = {
    experience: {},
    loading: false,
    saving: false,
    environnments: ['staging', 'prod'],
    staging: {
      dateText: 'non déployé',
      size: 0,
      sizeText: ''
    },
    prod: {
      dateText: 'non déployé',
      size: 0,
      sizeText: ''
    },
    test: {
      dateText: 'non déployé',
      size: 0,
      sizeText: ''
    }
  };

  computeSizeDistribution (resources) {
    const index = {}
    const val = Object.keys(resources)
      .map(k => resources[k])
      .reduce((acc, { hd, original }) => {
        const file = hd && hd.file ? hd : original
        if (index[file.file]) {
          return acc
        }
        index[file.file] = true
        const pos = file.file.indexOf('.')
        const ext = file.file.substr(pos + 1, file.file.length - pos - 1)
        return {
          ...acc,
          [ext]: file.size + (acc[ext] || 0),
          total: (acc.total || 0) + file.size
        }
      }, {})
    return val
  }

  async populateState (experienceUid) {
    this.setState({ loading: true })
    if (experienceUid) {
      this.setState({ loading: true })
      const promises = [];
      ['test', 'staging', 'prod'].forEach(env => {
        promises.push(
          api.releases
            .listFromExperience(env, experienceUid)
            .then(deploy => {
              if (deploy && deploy.updatedAt) {
                const date = new Date(deploy.updatedAt)
                const dateText = formatDate(date)
                const sizeDistribution = this.computeSizeDistribution(
                  deploy.resources
                )
                const size = sizeDistribution.total
                const sizeText = formatSize(size)
                this.setState({
                  [env]: {
                    date,
                    dateText,
                    size,
                    sizeText,
                    sizeDistribution,
                    deploy
                  }
                })
              }
            })
            .catch(e => {
              this.setState({
                [env]: {
                  date: null,
                  dateText: null
                }
              })
            })
        )
      })
      Promise.all(promises)
        .then(() => {
          this.setState({ loading: false })
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
    const check = await checks.deepCheck(this.props.match.params.experienceUid)
    this.setState({ check })
  }

  async componentDidMount () {
    this.populateState(this.props.match.params.experienceUid)
  }

  deployStaging () {
    this.setState({ saving: true })
    api.tracks
      .getFrom('experiences', this.props.match.params.experienceUid)
      .then(tracks => {
        const promises = tracks.map(track => {
          let points
          return api.points
            .getFrom('tracks', track.id)
            .then(p => {
              points = p
              return api.tracks.updateBoundingBox(track, points)
            })
            .then(boundingbox => {
              return api.tracks.update(track.id, {
                southWestBound: [boundingbox.south, boundingbox.west],
                northEastBound: [boundingbox.north, boundingbox.east]
              })
            })
        })
        return Promise.all(promises)
      })
      .then(() => {
        return api.experiences.deploy(
          'staging',
          this.props.match.params.experienceUid
        )
      })
      .then(deploy => {
        if (deploy.release && deploy.release.updatedAt) {
          const date = new Date(deploy.release.updatedAt)
          const dateText = formatDate(date)
          this.setState({
            staging: {
              date,
              dateText
            },
            saving: false
          })
        }
      })
  }

  deployProd () {
    return api.experiences
      .deploy('prod', this.props.match.params.experienceUid)
      .then(deploy => {
        if (deploy.release && deploy.release.updatedAt) {
          const date = new Date(deploy.release.updatedAt)
          const dateText = formatDate(date)
          this.setState({
            prod: {
              date,
              dateText
            },
            saving: false
          })
        }
      })
  }

  deploy = env => {
    env === 'prod' ? this.deployProd() : this.deployStaging()
  };

  undeploy = env => {
    return api.experiences
      .undeploy(env, this.props.match.params.experienceUid)
      .then(() => {
        this.populateState(this.props.match.params.experienceUid)
      })
  };

  render () {
    const { saving, staging, prod } = this.state

    const canDeployToProd =
      staging.date && (staging.date > prod.date || !prod.date)
    return (
      <Page>
        <NavInExperience />
        <div id='content'>
          <h2>
            Environnement de test
            <small>
              {' '}
              {this.state.test.dateText}({this.state.test.sizeText})
            </small>
          </h2>
          <SizeDistribution distribution={this.state.test.sizeDistribution} />
          <Checks checks={this.state.check} />
          <p>
            L'environnement de test est directement branché sur les saisies, il
            permet de
            <b>visualiser sa saisie</b>
            au fur et à mesure de l'intégration
            <br />
          </p>
          <p>
            Pensez à rafraichir le contenu de l'application pour avoir les
            nouvelles saisies, soit en coupant et relancant l'application, soit
            en swipant vers le bas à l'écran de choix de l'expérience.
          </p>
          <h2>
            Environnement de validation
            <small>
              {' '}
              {this.state.staging.dateText}({this.state.staging.sizeText})
            </small>
            {this.state.staging.date && (
              <button
                className='btn btn-danger'
                title='dépublier'
                onClick={e => this.undeploy('staging')}
              >
                <i className='fa fa-trash' />
                dépublier
              </button>
            )}
          </h2>
          <SizeDistribution
            distribution={this.state.staging.sizeDistribution}
          />
          <p>
            L'environnement de validation permet de bloquer un état pour{' '}
            <b>valider le contenu avant mise en production</b>.
            <br />
            Il n'est visible que par une application de test ( = mode debug)
          </p>
          <p>
            Le déploiement en validation transfert le contenu de test vers la
            validation
          </p>

          <button
            className='btn btn-primary btn-block'
            onClick={e => {
              this.deploy('staging')
            }}
            disabled={saving}
          >
            déployer le contenu de test vers la validation
          </button>

          <p>
            Pensez à rafraichir le contenu de l'application pour avoir les
            nouvelles saisies, soit en coupant et relancant l'application, soit
            en swipant vers le bas à l'écran de choix de l'expérience.
          </p>

          <h2>
            Environnement de production
            <small>
              {' '}
              {this.state.prod.dateText}({this.state.prod.sizeText})
            </small>
            {this.state.prod.date && (
              <button
                className='btn btn-danger'
                title='dépublier'
                onClick={e => this.undeploy('prod')}
              >
                <i className='fa fa-trash' />
                dépublier
              </button>
            )}
          </h2>
          <SizeDistribution distribution={this.state.prod.sizeDistribution} />

          <p>
            L'environnement de production est le seul qui est accessible par les
            <b>applications publiées</b>
            (= mode release)
          </p>
          <p>
            Le déploiement en production transfert le contenu déployé en
            validation vers la production
          </p>
          {canDeployToProd && (
            <button
              className='btn btn-primary btn-block'
              onClick={() => {
                this.deploy('prod')
              }}
              disabled={saving}
            >
              déployer le contenu de validation vers la production
            </button>
          )}

          <p>
            Pensez à rafraichir le contenu de l'application pour avoir les
            nouvelles saisies, soit en coupant et relancant l'application, soit
            en swipant vers le bas à l'écran de choix de l'expérience.
          </p>
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default observer(ExperienceDeploy)
