import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { Page } from '../'
import * as api from '../../lib/api'

import Loading from '../../components/loading'
import { NavInSites } from '../../components/nav'
import { apiCache } from '../../stores'

class Bundles extends Component {
  async populateState () {
    await api.bundles.list()
    this.setState({ loaded: true })
  }

  componentDidMount () {
    this.populateState()
  }

  componentWillReceiveProps () {
    this.populateState()
  }

  render () {
    const bundles = apiCache.bundles.list() || []
    return (
      <Page>
        <NavInSites />
        {!bundles.length > 0 && <Loading />}
        {bundles.length > 0 && (
          <div id='content'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Description</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {bundles.map(bundle => {
                  return (
                    <tr key={bundle._id}>
                      <td>{bundle.name}</td>
                      <td>{bundle.description}</td>
                      <td>
                        <Link to={'/bundle/' + bundle._id} title='modifier'>
                          <i className='fa fa-edit' />
                        </Link>
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan='5'>
                    <Link to='/bundle/' className='btn btn-primary btn-block'>
                      Ajouter
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div id='app-preview' />
      </Page>
    )
  }
}
export default observer(Bundles)
