import { withRouter } from 'react-router'
import { observer } from 'mobx-react'
import NavInScenarioStatic from './scenario'
import NavInExperienceStatic from './experience'
import NavTrackStatic from './track'
import NavPointStatic from './point'
import NavInSiteStatic from './site'
import NavInSitesStatic from './sites'
import NavInStatisticStatic from './statistic'
import NavInApplicationStatic from './application'
import './nav.css'

const NavInScenario = withRouter(observer(NavInScenarioStatic))
const NavInExperience = withRouter(observer(NavInExperienceStatic))
const NavInTrack = withRouter(observer(NavTrackStatic))
const NavInPoint = withRouter(observer(NavPointStatic))
const NavInSite = withRouter(observer(NavInSiteStatic))
const NavInSites = withRouter(observer(NavInSitesStatic))
const NavInStatistic = withRouter(observer(NavInStatisticStatic))
const NavInApplication = withRouter(observer(NavInApplicationStatic))
export {
  NavInApplication,
  NavInExperience,
  NavInScenario,
  NavInTrack,
  NavInPoint,
  NavInSite,
  NavInSites,
  NavInStatistic
}
