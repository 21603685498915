import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'

export const CategoryElementPicker = ({ formData, name, formContext, onChange, schema }) => {
  const { categories } = formContext

  const options = useRef(
    (categories || []).map(category => ({
      label: category.label,
      options: (category.variants || []).map(variant => ({ value: variant._id, label: variant.label }))
    }))
  ).current
  const [value, setValue] = useState([])

  useEffect(() => {
    options.flatMap(category => category.options || []).filter(o => (formData || []).includes(o.value))

    setValue(options.flatMap(category => category.options || []).filter(o => (formData || []).includes(o.value)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  return (
    <>
      <label className="control-label" htmlFor={name}>
        {schema.title}
      </label>
      <Select
        value={value}
        onChange={options => onChange(options.map(option => option.value))}
        options={options}
        isMulti={true}
        placeholder={'Sélectionnez un élément'}
        noOptionsMessage={() => "Pas d'élément à afficher"}
        menuPlacement="top"
      />
    </>
  )
}
