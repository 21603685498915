import React, { Component } from 'react'
import { CSSTransitionGroup } from 'react-transition-group' // ES6

export class Modal extends Component {
  escFunction = event => {
    if (event.keyCode === 27) {
      this.props.close && this.props.close()
    }
  };

  componentDidMount () {
    document && document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount () {
    document &&
      document.removeEventListener('keydown', this.escFunction, false)
  }

  render () {
    const { isOpen } = this.props

    const height = this.props.height || '33%'
    return (
      <div
        style={{
          pointerEvents: 'none'
        }}
      >
        {isOpen && (
          <div
            className='backdrop'
            style={{
              pointerEvents: 'auto',
              position: 'fixed',
              bottom: '0',
              left: '0',
              right: '0',
              top: '0',
              backgroundColor: 'rgba(0,0,0,0.2)',
              zIndex: '1000'
            }}
            onClick={e => {
              this.props.close && this.props.close()
              e.preventDefault()
            }}
          />
        )}
        <CSSTransitionGroup
          transitionName='slideInFromBottom'
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          {isOpen && (
            <div
              key='father'
              style={{
                pointerEvents: 'auto',
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                height: height,
                backgroundColor: 'white',
                overflowX: 'scroll',
                padding: '1em',
                zIndex: '1001'
              }}
            >
              {this.props.children}
            </div>
          )}
        </CSSTransitionGroup>
      </div>
    )
  }
}
