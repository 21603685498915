import { user, conf, apiCache } from '../../stores'
import generify from './generic'

export const users = generify('users-service', 'users', 'user')

users.me = () => {
  const cached = apiCache.get('users-service', 'user', 'me')
  if (cached) {
    return Promise.resolve(cached)
  }

  return fetch(conf.API_URL + '/users-service/users/me', {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        if (json.code === '401') {
          user.isLoggued = false
          user.token = null
          return {}
        }
        throw json.msg
      }
      apiCache.upsert('users-service', 'user' /* without s */, 'me', json)
      apiCache.users.upsert(json._id, json)
      // json.uid = json._uid
      return json
    })
}

users.amIAnExpert = () => {
  return users.me().then(me => {
    const isExpert = me.expert || !!me.scopes?.find(s => s === 'admin')
    return isExpert
  })
}

users.amIAnAdmin = () => {
  return users.me().then(me => {
    return !!me.scopes.find(s => s === 'admin')
  })
}

users.isExpert = id => {
  users.get(id).then(user => {
    let isExpert = user.expert
    user.scopes &&
      user.scopes.forEach(scope => {
        isExpert = isExpert || scope === 'admin'
      })
    return isExpert
  })
}
