import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

class BundlePicker extends Component {
  change = e => {
    this.props.onChange(e.target.value)
  };

  render () {
    const options = (this.props.formContext.bundles || []).map(xp => {
      return (
        <option value={xp._id} key={xp._id}>
          {xp.name || xp._id}{' '}
        </option>
      )
    })

    return (
      <div className='form-group field field-string'>
        <label className='control-label' htmlFor={this.props.name}>
          {this.props.schema.title}
        </label>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <select
            className='form-control'
            value={this.props.formData}
            onChange={this.change}
          >
            <option />
            {options}
          </select>
          {this.props.formData && (
            <Link
              style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
              to={'/bundle/' + this.props.formData}
              className='btn btn-default'
            >
              Voir
            </Link>
          )}
        </div>
      </div>
    )
  }
}

export default observer(BundlePicker)
