import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import * as api from '../../lib/api'
import { NavInExperience } from '../../components/nav'
import Page from '../page'
import { apiCache } from '../../stores'
import { Form } from '../../components/form'

export class CategoriesStatic extends Component {
  populate(experienceUid) {
    api.experiences.get(experienceUid)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.experienceUid !== nextProps.match.params.experienceUid) {
      this.populate(nextProps.match.params.experienceUid)
    }
  }

  componentDidMount() {
    this.populate(this.props.match.params.experienceUid)
  }

  save = async categories => {
    const { experienceUid } = this.props.match.params
    return api.experiences.update(experienceUid, {
      _id: experienceUid,
      categories
    })
  }

  render() {
    const { experienceUid } = this.props.match.params
    const experience = apiCache.experiences.get(experienceUid)
    if (!experience) {
      return null
    }
    const categories = experience.categories || []
    return (
      <Page>
        <NavInExperience />
        <div id="content">
          <Form
            formPath="categories"
            initialFormData={categories}
            onSubmit={this.save}
            formContext={{
              experienceUid
            }}
          />
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}

export default observer(CategoriesStatic)
