import React, { Component } from 'react'

import * as api from '../../lib/api'
import Coordinates from './map/coordinates'
import BoundingBox from './map/boundingbox'
import { Modal } from '../modal'

function distance(lat1, lon1, lat2, lon2) {
  var R = 6378137 // Radius of the earth in m
  var dLat = deg2rad(lat2 - lat1) // deg2rad below
  var dLon = deg2rad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

class ClickablePicture extends Component {
  state = {
    url: '',
    width: 1,
    height: 1
  }

  populateResource(resourceId) {
    api.resources.get(resourceId).then(resource => {
      if (resource) {
        var url = api.resources.getUrl(resource.original.file)
        var img = document.createElement('img')
        img.src = url
        img.onload = () => {
          this.setState({
            url,
            width: img.clientWidth,
            height: img.clientHeight
          })
        }
      }
    })
  }

  componentDidMount() {
    if (this.props.resourceId) {
      this.populateResource(this.props.resourceId)
    }
  }

  handleClick = e => {
    e.preventDefault()
    this.props.onChange({
      x: e.nativeEvent.offsetX / e.target.clientWidth,
      y: 1 - e.nativeEvent.offsetY / e.target.clientHeight
    })
  }

  render() {
    const { url } = this.state
    const { x, y } = this.props
    if (!url) {
      return <span>Chargement en cours</span>
    }
    return (
      <div style={{ flex: 1 }}>
        <div style={{ position: 'relative' }}>
          <img src={url} style={{ cursor: 'crosshair', width: '100%' }} onClick={this.handleClick} alt="ground overlay" />
          {x && y && (
            <div
              style={{
                position: 'absolute',
                left: x * 100 + '%',
                top: (1 - y) * 100 + '%',
                color: 'white',
                height: '16px',
                width: '13px',
                lineHeight: '15px',
                fontSize: '16px',
                marginLeft: '-8px',
                marginTop: '-8px',
                pointerEvents: 'none'
              }}
            >
              <i className="fa fa-crosshairs" />
            </div>
          )}
        </div>
      </div>
    )
  }
}

class ParallelPosition extends Component {
  handleChangePixelCoordinates = ({ x, y }) => {
    this.props.onChange({ x, y })
  }

  handleChangeGpsCoordinate = ([lat, lng]) => {
    this.props.onChange({ lat, lng })
  }

  render() {
    const { resourceId, lat, lng, x, y } = this.props
    return (
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <ClickablePicture x={x} y={y} resourceId={resourceId} onChange={this.handleChangePixelCoordinates} />
        <Coordinates
          onChange={this.handleChangeGpsCoordinate}
          title=""
          name="coordgps"
          mapContainerStyle={{ width: '90%', flex: 1 }}
          formData={lat && lng ? [lat, lng] : null}
        />
      </div>
    )
  }
}

export default class GroundOverlayLocator extends Component {
  state = {
    anchors: [
      /* labyrinthe geant
      {
        x: 0.6728016359918201,
        y: 0.42155009451795844,
        lat: 43.72313306875448,
        lng: 5.289126077384935
      },{
        x:0.5862619808306709,
        y:0.39290989660265874,
        lat: 43.72301482619794,
        lng: 5.288666078538881
      }

      //bali
      {x:0.12939297124600638,
        y:0.7792792792792793,
        lat:-8.788133816197972,
        lng:115.22641030209957
      },
      {
        x:0.6932907348242812,
        y:0.24549549549549554,
        lat:-8.790378969236643,
        lng:115.22977110999523
      } */
    ],
    editing: null,
    boundingbox: null,
    angle: 0
  }

  componentDidMount() {
    const uiSchema = this.props.uiSchema || {}
    const uiOptions = uiSchema['ui:options'] || {}
    const backgroundFieldName = uiOptions.background
    const resourceId = backgroundFieldName && this.props.formContext.formData[backgroundFieldName]
    resourceId &&
      api.resources.get(resourceId).then(resource => {
        if (resource) {
          var url = api.resources.getUrl(resource.original.file)
          var img = document.createElement('img')
          img.src = url
          img.onload = () => {
            this.setState(
              {
                resource,
                url,
                width: img.naturalWidth,
                height: img.naturalHeight
              },
              () => {
                // this.updateMapPosition()
              }
            )
          }
        }
      })
  }

  angle(v1, v2) {
    var a2 = Math.atan2(v1.y, v1.x)
    var a1 = Math.atan2(v2.y, v2.x)
    var sign = a1 > a2 ? 1 : -1
    var angle = a1 - a2
    var K = -sign * Math.PI * 2
    return Math.abs(K + angle) < Math.abs(angle) ? K + angle : angle
  }

  rotatePoint({ x, y, ...other }, angle) {
    const xMiddle = x - 0.5
    const yMiddle = y - 0.5
    const rotatedPointFromMiddle = {
      x: xMiddle * Math.cos(angle) - yMiddle * Math.sin(angle),
      y: xMiddle * Math.sin(angle) + yMiddle * Math.cos(angle)
    }
    return {
      x: rotatedPointFromMiddle.x + 0.5,
      y: rotatedPointFromMiddle.y + 0.5,
      ...other
    }
  }

  updateMapPosition = () => {
    const { anchors, resource, width, height } = this.state
    if (anchors.length < 2 || !resource) {
      return null
    }

    // compute rotation
    const angles = []
    for (let i = 0; i < anchors.length - 1; i++) {
      const anchorStart = anchors[i]
      for (let j = i + 1; j < anchors.length; j++) {
        const anchorEnd = anchors[j]
        angles.push(
          this.angle(
            {
              x: anchorEnd.x - anchorStart.x,
              y: anchorEnd.y - anchorStart.y
            },
            {
              x: anchorEnd.lng - anchorStart.lng,
              y: anchorEnd.lat - anchorStart.lat
            }
          )
        )
      }
    }
    const angle = 0 // angles.reduce((acc,curr)=>acc+curr,0) / angles.length
    const angleDegree = (angle * 180) / Math.PI

    /**
     * rotation change image dimension
     *
     */
    let bottomLeft = { x: 1, y: 1 }
    let topRight = { x: 0, y: 0 }
    ;[
      { x: 0, y: 0 },
      { x: 0, y: 1 },
      { x: 1, y: 0 },
      { x: 1, y: 1 }
    ].forEach(corner => {
      const rotatedCorner = this.rotatePoint(corner, angle)
      bottomLeft = {
        x: Math.min(bottomLeft.x, rotatedCorner.x),
        y: Math.min(bottomLeft.y, rotatedCorner.y)
      }
      topRight = {
        x: Math.max(topRight.x, rotatedCorner.x),
        y: Math.max(topRight.y, rotatedCorner.y)
      }
    })
    const rotationScale = topRight.x - bottomLeft.x

    const rotatedAnchors = anchors.map(anchor => {
      return this.rotatePoint(anchor, angle)
    })

    // compute translation and scale
    const boxes = []
    for (let i = 0; i < rotatedAnchors.length - 1; i++) {
      const anchorStart = rotatedAnchors[i]
      for (let j = i + 1; j < rotatedAnchors.length; j++) {
        const anchorEnd = rotatedAnchors[j]
        const dx = anchorEnd.x - anchorStart.x
        const dy = anchorEnd.y - anchorStart.y
        const dlat = anchorEnd.lat - anchorStart.lat
        const dlng = anchorEnd.lng - anchorStart.lng
        const scalex = dx / dlng
        const scaley = dy / dlat
        const south = anchorStart.lat - anchorStart.y / scaley
        const west = anchorStart.lng - anchorStart.x / scalex
        if (!isNaN(scalex) && !isNaN(scaley)) {
          boxes.push({
            south,
            west,
            scalex,
            scaley
          })
        }
      }
    }
    if (boxes.length < 1) {
      return null
    }
    const sum = boxes.reduce(
      (acc, current) => {
        return {
          south: current.south + acc.south,
          west: current.west + acc.west,
          scalex: current.scalex + acc.scalex,
          scaley: current.scaley + acc.scaley,
          nb: acc.nb + 1
        }
      },
      { south: 0, west: 0, scalex: 0, scaley: 0, nb: 0 }
    )
    const scalex = sum.scalex / sum.nb
    const scaley = sum.scaley / sum.nb

    const avg = {
      south: Math.min(sum.south / sum.nb, sum.south / sum.nb + 1 / scaley),
      north: Math.max(sum.south / sum.nb, sum.south / sum.nb + 1 / scaley),
      west: Math.min(sum.west / sum.nb, sum.west / sum.nb + 1 / scalex),
      east: Math.max(sum.west / sum.nb, sum.west / sum.nb + 1 / scalex)
    }

    const options = resource.options || {}
    options.ops = options.ops || []
    const currentRotate = options.ops.find(item => item.type === 'rotate')
    if (angleDegree === 0 && (!currentRotate || currentRotate.degrees === 0)) {
      this.setState({ angle, rotatedAnchors })
      this.props.onChange && this.props.onChange(avg)
      return
    }

    // rotate the resource
    if (currentRotate) {
      currentRotate.degrees = angleDegree
    } else {
      options.ops.push({ degrees: angleDegree, type: 'rotate' })
    }
    const currentCrop = options.ops.find(item => item.type === 'crop')
    const rotationRatio = 1 / rotationScale
    const crop = {
      x: (width * (1 - rotationRatio)) / 2,
      y: (height * (1 - rotationRatio)) / 2,
      width: width,
      height: height,
      priority: 5 // I want the crop after the rotation
    }
    if (currentCrop) {
      Object.assign(currentCrop, { ...crop })
    } else {
      options.ops.push({ ...crop, type: 'crop' })
    }

    return api.resources.upload({ _id: resource._id, options }).then(() => {
      this.setState({ angle, rotatedAnchors })
      this.props.onChange && this.props.onChange(avg)
    })
  }

  render() {
    const { anchors, rotatedAnchors, current } = this.state
    const { uiSchema, formData: boundingbox } = this.props

    const uiOptions = (uiSchema || {})['ui:options'] || {}
    const backgroundFieldName = uiOptions.background
    const resourceId = backgroundFieldName && this.props.formContext.formData[backgroundFieldName]
    return (
      <div>
        <p>
          Pour automatiser le placement du fond de carte , vous pouvez placer des marqueurs sur la carte et sur l'image. L'image
          doit être orientée correctement vers le nord. Le calcul se fera automatiquement pour la position et la mise à l'echelle
        </p>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>X</th>
              <th>Y</th>
              <th>latitude</th>
              <th>longitude</th>
              <th>Marge d'erreur</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {anchors.map(({ x, y, lat, lng }, index) => {
              let d
              if (rotatedAnchors) {
                const rotatedAnchor = rotatedAnchors[index]
                if (boundingbox && rotatedAnchor) {
                  const errorLat = boundingbox.south + rotatedAnchor.y * (boundingbox.north - boundingbox.south)
                  const errorLng = boundingbox.west + rotatedAnchor.x * (boundingbox.east - boundingbox.west)
                  d = distance(lat, lng, errorLat, errorLng)
                }
              }
              return (
                <tr key={index}>
                  <td>{x}</td>
                  <td>{y}</td>
                  <td>{lat}</td>
                  <td>{lng}</td>
                  <td>{d ? d.toLocaleString() + ' mètre' : ''}</td>
                  <td>
                    <button
                      className="btn btn-default"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({
                          current: {
                            index,
                            x,
                            y,
                            lat,
                            lng
                          }
                        })
                      }}
                    >
                      <i className="fa fa-edit" />
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={e => {
                        e.preventDefault()
                        const anchors = this.state.anchors.slice()
                        anchors.splice(index, 1)
                        this.setState({ anchors }, () => {
                          this.updateMapPosition()
                        })
                      }}
                    >
                      <i className="fa fa-remove" />
                    </button>
                  </td>
                </tr>
              )
            })}
            <tr>
              <td colSpan="6">
                <button
                  className="btn btn-block btn-default"
                  onClick={e => {
                    e.preventDefault()
                    const location = boundingbox
                      ? {
                          lat: boundingbox.south / 2 + boundingbox.north / 2,
                          lng: boundingbox.east / 2 + boundingbox.west / 2
                        }
                      : {}
                    this.setState({
                      current: {
                        index: this.state.anchors.length,
                        x: 0.5,
                        y: 0.5,
                        ...location
                      }
                    })
                  }}
                >
                  Ajouter
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        {boundingbox && (
          <BoundingBox
            formData={boundingbox}
            onChange={this.props.onChange}
            uiSchema={uiSchema}
            formContext={this.props.formContext}
            markers={anchors}
          />
        )}
        <Modal
          isOpen={!!current}
          close={() => {
            this.setState({ current: null })
          }}
          height="100vh"
        >
          <span
            className="btn btn-primary"
            disabled={!current || !current.x || !current.y || !current.lat || !current.lng}
            onClick={e => {
              if (current) {
                const anchors = this.state.anchors
                anchors[current.index] = current
                this.setState(
                  {
                    current: null,
                    anchors: anchors
                  },
                  () => {
                    this.updateMapPosition()
                  }
                )
              } else {
                this.setState(
                  {
                    current: null,
                    anchors: anchors.concat([current])
                  },
                  () => {
                    this.updateMapPosition()
                  }
                )
              }
            }}
          >
            Enregistrer
          </span>
          <span
            className="btn btn-default"
            onClick={e => {
              this.setState({ current: null })
            }}
          >
            Annuler
          </span>
          <ParallelPosition
            resourceId={resourceId}
            {...current}
            onChange={({ lat, lng, x, y }) => {
              let current = { ...this.state.current }
              current = {
                x: x || current.x,
                y: y || current.y,
                lat: lat || current.lat,
                lng: lng || current.lng,
                index: current.index
              }
              this.setState({ current })
            }}
          />
        </Modal>
      </div>
    )
  }
}
