import Dropzone from 'react-dropzone'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import ResourceUploadProgress from './uploadprogress'
import Loading from '../../loading'
import * as api from '../../../lib/api'
import Uploading from './uploading'

function Preview(props) {
  const { src, name, uploadProgress, remove } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '230px' }}>
      <div style={{ flex: 1, position: 'relative', height: '100%' }}>
        <ResourceUploadProgress progress={uploadProgress} />

        {name && (
          <h4
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: 'rgba(255,255,255,0.75)',
              margin: 0,
              color: 'darkgray'
            }}
          >
            {name}
          </h4>
        )}

        {src && <img src={src} alt={name} style={{ maxWidth: '100%', maxHeight: '100%' }} />}
      </div>

      <div style={{ width: '40px', display: 'flex', flexDirection: 'column' }}>
        {src && (
          <button className="btn btn-block btn-default" href={src} target="_blank">
            <i className="fa fa-download" />
          </button>
        )}

        <div style={{ flex: 1 }} />
        {remove && (
          <span className="btn btn-block btn-danger" onClick={remove} style={{ marginTop: '1em' }}>
            <i className="fa fa-trash-o" />
          </span>
        )}
      </div>
    </div>
  )
}
class ResourceApplicationImage extends Component {
  state = {
    resource: null,
    errored: false,
    loading: false
  }

  populate(resourceId) {
    this.setState({ loadaing: true })
    api.resourcesApplication
      .get(resourceId)
      .then(resource => this.setState({ resource, loading: false }))
      .catch(e => {
        this.setState({ resource: null, loading: false })
      })
  }

  componentDidMount() {
    this.props.formData && this.populate(this.props.formData)
  }

  componentWillReceiveProps(props) {
    if (props.formData !== this.props.formData && props.formData && props.formData.indexOf('__transfert__') < 0) {
      this.populate(props.formData)
    }
    if (!props.formData) {
      this.setState({ resource: null })
    }
  }

  onDropAccepted = files => {
    const uiSchema = this.props.uiSchema || {}
    const uiOptions = uiSchema['ui:options'] || {}
    const maxSize = uiOptions.maxSize
    const options = {
      ops: []
    }
    if (maxSize) {
      options.maxSize = maxSize.width
    }
    const upload = api.resourcesApplication.upload(
      {
        variantId: this.props.formContext.variantId,
        name: this.props.name
      },
      files[0]
    )
    const transfertUid = upload.__transfert_uid

    this.props.onChange('__transfert__' + transfertUid)
    upload.then(json => {
      this.setState({ resource: json })
      json && json._id && this.props.onChange(json._id)
    })
  }

  render() {
    const { formData } = this.props
    const isTransfering = formData && formData.indexOf && formData.indexOf('__transfert__') === 0
    const transfertUid = isTransfering ? formData.substr(13) : null
    const { resource, loading } = this.state

    if (loading) {
      return <Loading />
    }
    return (
      <div>
        <Dropzone
          disableClick={!!resource}
          multiple={false}
          onDropAccepted={this.onDropAccepted}
          accept="image/*"
          style={{}}
          activeStyle={{
            border: '1px solid green',
            background: 'rgba(0,255,0,0.25)'
          }}
          rejectStyle={{
            border: '1px solid red',
            background: 'rgba(255,0,0,0.25)'
          }}
        >
          <label className="control-label">{this.props.schema.title}</label>
          &nbsp;
          {!resource && !isTransfering && (
            <span
              className=" btn btn-default btn-small"
              style={{ marginRight: '1em' }}
              onClick={e => {
                this.setState({ isOpen: true })
                e.preventDefault()
              }}
            >
              <i className="fa fa-upload" />
            </span>
          )}
          {isTransfering && <Uploading uid={transfertUid} />}
          {resource && !isTransfering && (
            <Preview
              uploadProgress={resource.progress}
              src={resource.file && api.resourcesApplication.getUrl(resource.file)}
              name={resource.name}
              remove={e => {
                this.props.onChange('')
              }}
            />
          )}
        </Dropzone>
      </div>
    )
  }
}

const MobxResourceApplicationImage = observer(ResourceApplicationImage)
export default MobxResourceApplicationImage
