import * as api from '../../api'
import findMissingLoadTrack from './findmissingloadtrack'

export default async photoHunt => {
  const checkResult = {
    warnings: [],
    errors: [],
    nb: 0
  }
  const xp = await api.experiences.get(photoHunt.experienceId)
  const xpLink = '/site/' + xp.siteId + '/experience/' + xp._id

  checkResult.nb++
  if (!photoHunt.scenario) {
    checkResult.errors.push({
      text: `la course à la photo ${photoHunt.name} n'a pas de scenario de fin `,
      link: xpLink + '/track/' + photoHunt._id,
      _id: photoHunt._id
    })
  } else {
    const scenario = await api.scenarios.get(photoHunt.scenario)
    if (!scenario) {
      checkResult.errors.push({
        text: `le scenario de fin de  ${photoHunt.name} n'existe pas `,
        link: xpLink + '/track/' + photoHunt._id,
        _id: photoHunt._id
      })
      checkResult.nb++
    } else {
      const modules = await api.modules.getFrom('scenarios', scenario._id)
      checkResult.nb++
      if (modules.length === 0 && (!scenario.onEnd || !scenario.onEnd.length)) {
        checkResult.errors.push({
          text: `le scenario de fin de  ${photoHunt.name} est vide `,
          link: xpLink + '/track/' + photoHunt._id,
          _id: photoHunt._id
        })
      }
      checkResult.nb++

      if (
        modules[0] &&
        (modules[0].type === 'score_conversation' ||
          modules[0].type === 'score_diaporama')
      ) {
        if (modules[0].scoreType !== 'track') {
          checkResult.warnings.push({
            text: `le scenario de fin  ${scenario.name} a la fin de ${photoHunt.name} contient un module de score qui se base sur le score du scenario plutôt que celui du parcours.`,
            link: xpLink + '/scenario/' + scenario._id,
            _id: scenario._id
          })
        }
      }

      checkResult.nb++
      const missings = await findMissingLoadTrack(photoHunt.scenario)
      for (let counter = 0; counter < missings.length; counter++) {
        const missingScenario = await api.scenarios.get(missings[counter])
        checkResult.warnings.push({
          text: `le scenario de fin ${missingScenario.name} a la fin de ${photoHunt.name} ne contient pas de retour vers un parcours.`,
          link: xpLink + '/scenario/' + missingScenario._id,
          _id: missingScenario._id
        })
      }
    }
  }
  const objectives = (await api.points.getFrom('tracks', photoHunt._id)) || []
  for (let counter = 0; counter < objectives.length; counter++) {
    checkResult.nb++
    const objective = objectives[counter]
    if (!objective.picture) {
      checkResult.errors.push({
        text: `le point ${objective.name || objective.internalName} de la chasse à la photo  ${photoHunt.name} n'a pas d'image `,
        link: xpLink + '/track/' + photoHunt._id + '/point/' + objective._id,
        _id: photoHunt._id
      })
    }
  }
  return checkResult
}
