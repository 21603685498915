import generify from './generic'
import * as api from './'
export const tracks = generify('experiences-service', 'tracks', 'track')

tracks.getFromExperience = (experienceUid, showDeleted) => {
  return tracks.getFrom('experiences', experienceUid, showDeleted)
}

tracks.updateBoundingBox = (track, points) => {
  const bbox = {
    south: 90,
    north: -90,
    east: -180,
    west: 180
  }
  const promises = []
  points.forEach(point => {
    if (point && point.location && point.location.length === 2) {
      bbox.east = Math.max(point.location[1], bbox.east)
      bbox.north = Math.max(point.location[0], bbox.north)
      bbox.south = Math.min(point.location[0], bbox.south)
      bbox.west = Math.min(point.location[1], bbox.west)
    }
  })

  track.entities &&
    track.entities.forEach(entity => {
      if (entity && entity.location && entity.location.length === 2) {
        bbox.east = Math.max(entity.location[1], bbox.east)
        bbox.north = Math.max(entity.location[0], bbox.north)
        bbox.south = Math.min(entity.location[0], bbox.south)
        bbox.west = Math.min(entity.location[1], bbox.west)
      }
      if (entity.segment) {
        promises.push(
          api.segments.get(entity.segment).then(segment => {
            segment.points.forEach(point => {
              if (point && point.length === 2) {
                bbox.east = Math.max(point[1], bbox.east)
                bbox.north = Math.max(point[0], bbox.north)
                bbox.south = Math.min(point[0], bbox.south)
                bbox.west = Math.min(point[1], bbox.west)
              }
            })
          })
        )
      }
    })

  track.routes &&
    track.routes.forEach(route => {
      promises.push(
        api.segments.get(route.segment).then(segment => {
          segment.points.forEach(point => {
            if (point && point.length === 2) {
              bbox.east = Math.max(point[1], bbox.east)
              bbox.north = Math.max(point[0], bbox.north)
              bbox.south = Math.min(point[0], bbox.south)
              bbox.west = Math.min(point[1], bbox.west)
            }
          })
        })
      )
    })
  track.route &&
    promises.push(
      api.segments.get(track.route.segment).then(segment => {
        segment.points.forEach(point => {
          if (point && point.length === 2) {
            bbox.east = Math.max(point[1], bbox.east)
            bbox.north = Math.max(point[0], bbox.north)
            bbox.south = Math.min(point[0], bbox.south)
            bbox.west = Math.min(point[1], bbox.west)
          }
        })
      })
    )

  return Promise.all(promises).then(() => {
    return bbox
  })
}
