import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import Loading from '../../components/loading'
import { Page } from '../'
import { apiCache } from '../../stores'
import checkExperience from '../../lib/check/experience'

class ExperienceForm extends Component {
  state = {
    originalExperience: null,
    loading: true
  }

  async populateState(experienceUid) {
    this.setState({ loading: true })

    if (experienceUid) {
      const experience = await api.experiences.get(experienceUid)
      await api.segments.getFromExperience(experienceUid)
      await api.tracks.getFromExperience(experienceUid)
      const originalExperience = experience.originalId && (await api.experiences.get(experience.originalId).catch(() => {})) // don't fucking care
      const checks = await checkExperience(experience)
      this.setState({ originalExperience, checks, loading: false })
    } else {
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    this.populateState(this.props.match.params.experienceUid)
  }

  componentWillReceiveProps(nextProps) {
    this.populateState(nextProps.match.params.experienceUid)
  }

  save = async formData => {
    const { experienceUid, siteUid } = this.props.match.params
    formData.siteId = siteUid
    let location = null
    if (formData.location && formData.location.length === 2) {
      location = formData.location.slice()
      location.reverse()

      // location = formData.location
    } else {
      formData.location = null
    }
    if (!formData.startLocation || formData.startLocation.length !== 2) {
      formData.startLocation = null
    }
    formData.useProgression = !formData.ignoreProgression
    delete formData.ignoreProgression
    const experience = await api.experiences.upsert({ ...formData, location })
    if (experienceUid) {
      return Promise.resolve()
    }
    const s = await api.scenarios.create({
      experienceId: experience._id,
      name: 'introduction'
    })
    await api.experiences.upsert({
      _id: experience._id,
      onStart: [
        {
          type: 'load_scenario',
          scenario: s._id
        }
      ]
    })
    const url = this.props.match.url + experience._id
    const checks = await checkExperience(experience)
    this.setState({ checks })
    this.props.history.push(url)
  }

  rollBack = async patchId => {
    const { experienceUid } = this.props.match.params
    const experience = await api.experiences.rollBackToPatch(experienceUid, patchId)
    this.setState({ experience })
  }

  delete = async () => {
    const { siteUid, experienceUid } = this.props.match.params
    await api.experiences.remove(experienceUid)
    const url = '/site/' + siteUid
    this.props.history.push(url)
  }

  duplicate = () => {
    const { siteUid, experienceUid } = this.props.match.params
    this.props.history.push('/site/' + siteUid + '/experience/' + experienceUid + '/duplicate')
  }

  render() {
    const { siteUid, experienceUid } = this.props.match.params
    const { loading, checks, originalExperience } = this.state

    const fullExperience = apiCache.experiences.get(experienceUid) || {}
    const segments = apiCache.segments.getFromExperience(experienceUid)
    const tracks = apiCache.tracks.getFromExperience(experienceUid)
    let { location, ...experience } = fullExperience

    if (location && location.length === 2) {
      location = location.slice()
      location.reverse()
    }
    if (!experience.length && experience.distance) {
      experience.length = experience.distance
    }
    experience.ignoreProgression = !experience.useProgression
    return (
      <Page>
        {experienceUid && <NavInExperience />}
        <div id="content">
          {loading && <Loading />}
          {!loading &&
            checks &&
            checks.errors.map((e, i) => (
              <p className="bg-danger" key={i}>
                {e.text}
              </p>
            ))}
          {!loading &&
            checks &&
            checks.warnings.map((e, i) => (
              <p className="bg-warning" key={i}>
                {e.text}
              </p>
            ))}
          {!loading && checks && (
            <p className="bg-success">{checks.nb - checks.warnings.length - checks.errors.length} vérifications réussies</p>
          )}
          {!loading && (
            <Form
              formPath="experience"
              initialFormData={{ ...experience, location }}
              experienceUid={experienceUid}
              onDuplicate={this.duplicate}
              onDelete={this.delete}
              onSubmit={this.save}
              formContext={{ siteUid, segments, tracks }}
              listHistory={() => api.experiences.listAvailableRollBack(experienceUid)}
              rollBackToPatch={this.rollBack}
            />
          )}
          {originalExperience && (
            <div>
              Expérience copiée depuis{' '}
              <Link to={'/site/' + siteUid + '/experience/' + originalExperience._id}>{originalExperience.name}</Link>
              <br />
              <Link
                to={'/site/' + siteUid + '/experience/' + originalExperience._id + '/book/compare/' + experienceUid}
                className="btn btn-default"
              >
                comparer avec l'expérience père
              </Link>
            </div>
          )}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}

export default observer(ExperienceForm)
