import React, { Component } from 'react'
// import { TwitterPicker } from 'react-color';
import ColorPicker from 'react-simple-colorpicker'
import './color.css'
import chroma from 'chroma-js'

export default class Color extends Component {
  state = {
    open: false
  }

  change = rgba => {
    const uiSchema = this.props.uiSchema || {}
    const options = uiSchema['ui:options'] || {}
    const format = options.format || 'rgb'
    let formattedColor
    if (rgba.length === 9 || rgba.length === 7) {
      try {
        formattedColor = chroma(rgba).hex(format)
      } catch (e) {
        formattedColor = rgba
      }
    } else {
      formattedColor = rgba
    }
    this.props.onChange(formattedColor)
  }

  render() {
    const { open } = this.state
    const schema = this.props.schema || {}
    const defaultValue = schema.default
    let color = this.props.formData || defaultValue
    if (open) {
      color = color || '#FFFFFF' // color picker dislike empty color
    }
    const uiSchema = this.props.uiSchema || {}
    const options = uiSchema['ui:options'] || {}
    const format = options.format || 'rgb'
    if (format === 'argb' && color && color.length === 9) {
      color = '#' + color.substr(3, 6) + color.substr(1, 2)
    }

    return (
      <div
        className="form-group field field-string"
        style={{
          backgroundColor: color
        }}
      >
        <label className="control-label">
          {this.props.schema.title}
          <i
            className="fa fa-edit pull-left btn btn-default"
            style={{
              marginRight: '1em'
            }}
            onClick={() =>
              this.setState({
                open: !open
              })
            }
          />
        </label>
        {open || (
          <input
            style={{
              flex: 1
            }}
            className="form-control"
            value={color}
            onChange={e => {
              const value = e.target.value
              this.setState({ open: false }, () => this.change(value))
            }}
          />
        )}

        {open && (
          <ColorPicker
            color={color}
            onChange={value => {
              this.change(chroma(value).hex('rgba'))
            }}
            opacitySlider={format === 'rgba' || format === 'argb'}
          />
        )}
      </div>
    )
  }
}
