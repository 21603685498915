import * as api from '../../../lib/api'
import { toJS } from 'mobx'

const patchAndCopy = (object, path, value) => {
  let key = path.shift()
  if (!key) {
    return value
  }
  if (!object) {
    throw new Error('object empty')
  }
  object = toJS(object)

  if (Array.isArray(object)) {
    const res = []
    key = parseInt(key, 10)
    for (var index = 0; index < object.length; index++) {
      if (index === key) {
        res[index] = patchAndCopy(object[index], path, value)
      } else {
        res[index] = object[index]
      }
    }
    return res
  }

  if (typeof object === 'object') {
    const res = {}
    for (var sub in object) {
      if (object.hasOwnProperty(sub)) {
        if (sub === key) {
          res[sub] = patchAndCopy(object[sub], path, value)
        } else {
          res[sub] = object[sub]
        }
      }
    }
    // creating missing
    if (!object[key]) {
      console.log('create missing ')
      res[key] = patchAndCopy(object[sub], path, value)
    }
    return res
  }
}

export default async ({ collection, id, path, value }, experienceUid) => {
  console.log('will save  in index', { collection, id, path, value })
  const original = await api[collection].get(id)

  if (!original) {
    console.error(id, ' de type ', collection, 'inconnu')
  } else {
    id = original._id
    console.log('will patch ', collection, id, original, path)
  }
  const modifiedCopy = patchAndCopy(original, path.split('°|°'), value)
  console.log('modified copy with ', path, value, modifiedCopy)
  return api[collection].update(id, modifiedCopy)
}
