import React from 'react'
import { Line } from 'react-chartjs-2'
import { StatApi as Stat } from './stat'
import { t } from 'stores/i18n.store'

export default props => {
  return (
    <Stat
      {...props}
      axis="days"
      axisProperties={['dayOfWeek']}
      labels={[t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')]}
      labelSession={t('sessions')}
      labelPeople={t('players')}
    >
      <Line />
    </Stat>
  )
}
