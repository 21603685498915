import React, { Component } from 'react'
import QRCodeReact from 'qrcode.react'

export default class QRCode extends Component {
  change = e => {
    this.props.onChange(e.target.value)
  };

  render () {
    return (
      <div
        className='form-group field field-string'
        style={{ minWidth: '300px' }}
      >
        <label className='control-label' htmlFor={this.props.name}>
          {this.props.schema ? this.props.schema.title : this.props.name}
        </label>
        <input
          type='text'
          className='form-control'
          onChange={this.change}
          defaultValue={this.props.formData || ''}
        />

        {this.props.formData && (
          <QRCodeReact
            style={{ padding: '10px' }}
            value={this.props.formData || ''}
            size={276}
            includeMargin
            level='M'
          />
        )}
      </div>
    )
  }
}
