import React, { Component } from 'react'
import { moduleLabel } from '../../stores'

export default class extends Component {
  componentDidMount () {
    if (!this.props.formData) {
      const experience = this.props.formContext.experience
      this.props.onChange(experience.authorizations.modules[0])
    }
  }

  render () {
    const experience = this.props.formContext.experience
    return (
      <div className='form-group field field-string'>
        <label className='control-label' htmlFor={this.props.name}>
          {this.props.schema.title}
        </label>
        <select
          className='form-control'
          value={this.props.formData}
          onChange={e => this.props.onChange(e.target.value)}
        >
          <option />
          {experience.authorizations.modules
            .filter(m => !!moduleLabel[m])
            .sort((t1, t2) =>
              (moduleLabel[t1] || t1).localeCompare(moduleLabel[t2] || t2)
            )
            .map(m => {
              return (
                <option value={m} key={m}>
                  {moduleLabel[m]}{' '}
                </option>
              )
            })}
        </select>
      </div>
    )
  }
}
