import React, { Component } from 'react'

import BaseMap from './map'
import { Polyline, FeatureGroup } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'

import Dropzone from 'react-dropzone'
import gpxParse from 'gpx-parse/lib/gpx-parse'
import * as api from '../../../lib/api'

class Segment extends Component {
  constructor (props) {
    super(props)
    this.refFeatures = React.createRef()
  }

  change = e => {
    if (
      !this.refFeatures ||
      !this.refFeatures.current ||
      !this.props.onChange
    ) {
      return
    }
    const [segment] = this.refFeatures.current.leafletElement.getLayers()
    if (!segment) {
      this.props.onChange([])
    }
    this.props.onChange(segment.getLatLngs().map(({ lat, lng }) => [lat, lng]))
  };

  create = ({ layer: segment }) => {
    if (!segment) {
      this.props.onChange([])
    }
    this.props.onChange(segment.getLatLngs().map(({ lat, lng }) => [lat, lng]))
  };

  async populate (props) {
    const { maps, path } = props
    let center
    if (path && path.length) {
      center = {
        lat: 0,
        lng: 0
      }
      path.forEach(coordinate => {
        center.lat += coordinate.lat
        center.lng += coordinate.lng
      })
      center.lat /= path.length
      center.lng /= path.length
    }

    for (const map of maps || []) {
      if (map.type === 'ground') {
        await api.resources.get(map.resource)
      }
    }
  }

  componentDidMount () {
    this.populate(this.props)
  }

  render () {
    const { path } = this.props
    let center
    if (path && path.length) {
      center = {
        lat: 0,
        lng: 0
      }
      path.forEach(coordinate => {
        center.lat += coordinate.lat
        center.lng += coordinate.lng
      })
      center.lat /= path.length
      center.lng /= path.length
    } else {
      center = {
        lat: 46,
        lng: 4
      }
    }
    return (
      <BaseMap
        center={center}
        style={{ flex: 1 }}
      >
        <FeatureGroup ref={this.refFeatures}>
          <EditControl
            draw={{
              rectangle: false,
              polyline:
                !path || path.length === 0 ? { showLength: true } : false,
              polygon: false,
              circle: false,
              circlemarker: false,
              marker: false
            }}
            onEdited={this.change}
            onCreated={this.create}
            onDeleted={() => this.props.onChange([])}
          />
          <Polyline positions={path} />
        </FeatureGroup>
      </BaseMap>
    )
  }
}
function flatten (arr) {
  return [].concat(...arr)
}
function deepFlatten (arr) {
  return flatten(
    // return shalowly flattened array
    arr.map(
      (
        x // with each x in array
      ) =>
        Array.isArray(x) // is x an array?
          ? deepFlatten(x) // if yes, return deeply flattened x
          : x // if no, return just x
    )
  )
}
class SegmentAndUpload extends Component {
  state = {
    path: (this.props.formData || []).map(p => {
      return { lng: p[1], lat: p[0] }
    }),
    pathId: Math.random()
  };

  onDropAccepted = files => {
    var reader = new FileReader()
    reader.onload = event => {
      gpxParse.parseGpx(event.target.result, (err, gpx) => {
        if (err) {
          this.setState({ error: err.msg })
          return
        }
        let path = gpx.tracks.map(track => {
          return track.segments.map(segment => {
            return segment.map(point => {
              return { lat: point.lat, lng: point.lon, z: point.elevation }
            })
          })
        })

        path = deepFlatten(path)

        this.onChange(path, Math.random())
      })
    }
    reader.onerror = e => this.setState({ error: e.msg })
    reader.readAsText(files[0])
  };

  onChange = (path, pathId) => {
    pathId = pathId || this.state.pathId
    this.props.onChange(
      path.map(p => {
        return [p.lat, p.lng]
      })
    )
    this.setState({ path, pathId })
  };

  componentWillReceiveProps (nextProps) {
    this.setState({
      path: (nextProps.formData || []).map(p => {
        return { lng: p[1], lat: p[0] }
      })
    })
  }

  render () {
    const mapContainerStyle = this.props.mapContainerStyle || {}
    return (
      <Dropzone
        disableClick
        multiple={false}
        onDropAccepted={
          this.onDropAccepted
        } /* accept="application/gpx+xml,application/gpx,text/xml,application/xml" */
        style={{
          minHeight: '600px',
          width: '90%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          ...mapContainerStyle
        }}
        activeStyle={{
          border: '1px solid green',
          background: 'rgba(0,255,0,0.25)'
        }}
        rejectStyle={{
          border: '1px solid red',
          background: 'rgba(255,0,0,0.25)'
        }}
      >
        <label className='control-label'>{this.props.schema.title}</label>&nbsp;
        <Segment
          {...this.props}
          path={this.state.path}
          pathId={this.state.pathId}
        />
      </Dropzone>
    )
  }
}

export default SegmentAndUpload
