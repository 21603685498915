import React from 'react'
import { Link } from 'react-router-dom'
import BookAction from './action'
import BookUsage from './usage'

export default props => {
  const { scenario, _id, url, chapters, usedBy, children } = props
  const { name } = scenario
  const moduleTypes = Object.keys(chapters)
    .map(key => chapters[key])
    .filter(({ type, module: m }) => {
      return type === 'module' && m.scenarioId === scenario._id
    })
    .map(({ module: m }) => m.type)
  return (
    <article className={'scenario ' + moduleTypes.join(' ')} id={_id}>
      <h2 className="internal" title="nom interne, non visibe par le joueur">
        Scenario - {name}{' '}
        <Link to={url}>
          <i className="fa fa-edit" />
        </Link>
      </h2>
      {Object.keys(usedBy).length > 0 && (
        <div className="usedBy">
          Utilisé par
          <ul>
            {Object.keys(usedBy).map(id => {
              return (
                <li key={id}>
                  <BookUsage chapterId={id} usages={usedBy[id]} chapters={chapters} />
                </li>
              )
            })}
          </ul>
        </div>
      )}
      {Object.keys(usedBy).length === 0 && "Ce scenario n'est jamais utilisé"}
      <section>
        <div>
          {children}

          {scenario.onEnd && scenario.onEnd.length > 0 && (
            <div>
              Actions à la fin
              <ul>
                {scenario.onEnd.map((action, index) => (
                  <li key={index}>
                    <BookAction action={action} chapters={chapters} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
    </article>
  )
}
