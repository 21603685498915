import React, { Component } from 'react'
// from https://github.com/grvcoelho/react-voice-components/blob/master/lib/VoiceRecognition.js
class VoiceRecognition extends Component {
  constructor (props) {
    super(props)

    const SpeechRecognition =
      window.SpeechRecognition ||
      window.webkitSpeechRecognition ||
      window.mozSpeechRecognition ||
      window.msSpeechRecognition ||
      window.oSpeechRecognition

    if (SpeechRecognition != null) {
      this.recognition = this.createRecognition(SpeechRecognition)
    } else {
      console.warn(
        'The current browser does not support the SpeechRecognition API.'
      )
    }
  }

  createRecognition = SpeechRecognition => {
    const defaults = {
      continuous: true,
      interimResults: false,
      lang: 'en-US'
    }

    const recognition = new SpeechRecognition()

    Object.assign(recognition, defaults, this.props)

    return recognition
  };

  bindResult = event => {
    let interimTranscript = ''
    let finalTranscript = ''

    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        finalTranscript += event.results[i][0].transcript
      } else {
        interimTranscript += event.results[i][0].transcript
      }
    }

    this.props.onResult({ interimTranscript, finalTranscript })
  };

  start = () => {
    this.recognition.start()
  };

  stop = () => {
    this.recognition.stop()
  };

  abort = () => {
    this.recognition.abort()
  };

  componentWillReceiveProps ({ stop }) {
    if (stop) {
      this.stop()
    }
  }

  componentDidMount () {
    const events = [
      { name: 'start', action: this.props.onStart },
      { name: 'end', action: this.props.onEnd },
      { name: 'error', action: this.props.onError }
    ]

    events.forEach(event => {
      this.recognition.addEventListener(event.name, event.action)
    })

    this.recognition.addEventListener('result', this.bindResult)

    this.start()
  }

  componentWillUnmount () {
    this.abort()
  }

  render () {
    return null
  }
}

export default class extends Component {
  constructor (props) {
    super(props)
    this.change = this.change.bind(this)
  }

  change (e) {
    this.props.onChange(e.target.value)
  }

  render () {
    return (
      <div className='form-group field field-string'>
        <label className='control-label' htmlFor={this.props.name}>
          {this.props.schema.title}
        </label>

        <input
          type='text'
          className='form-control'
          onChange={this.change}
          value={this.props.formData}
        />
        <VoiceRecognition
          onResult={e => {
            this.props.onChange(e.finalTranscript || e.interimTranscript)
          }}
          onStart={e => console.log('start', e)}
          onStop={e => console.log('stop', e)}
          continuous
          interimResults
          maxAlternatives='1'
          lang='fr-FR'
        />
      </div>
    )
  }
}
