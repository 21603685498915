import React from 'react'
import { Link } from 'react-router-dom'
import BookResource from './resource'
import BookText from './text'
import { ChapterLink, ChapterLinkScenario } from './link'

export default ({
  experience,
  chapter,
  chapters,
  timeLimit,
  score,
  url,
  highlights
}) => {
  const trackPage = chapters[experience.startTrack] || {}
  const { onStart, theme, tags } = experience
  const actionScenarios = (onStart || []).filter(a => a.type === 'load_scenario')

  return (
    <article className='experience'>
      <h1>
        <BookText
          text={experience.name}
          collection='experiences'
          id={experience._id}
          path='name'
          highlights={highlights}
          style={{ display: 'inline-block' }}
        />
        <Link to={url}>
          <i className='fa fa-edit' />
        </Link>
      </h1>
      <section>
        <aside className='common'>
          <div className='icons-container'>
            {timeLimit > 0 && (
              <span>
                <i className='fa fa-2x fa-clock-o' loop /> {timeLimit} secondes
              </span>
            )}
            {score > 0 && (
              <span>
                <i className='fa fa-2x fa-trophy' /> {score} pts
              </span>
            )}
          </div>
          <p>Mots clés</p>
          <ul>
            {tags && tags.map((tag, index) => <li key={index}>{tag}</li>)}
          </ul>
          <p>
            Niveau de difficulté :{' '}
            {
              { 1: 'facile', 2: 'Intermédiaire', 3: 'Difficile' }[experience.difficulty]
            }
          </p>
          <p>Durée (en minute): {experience.duration || 'non mise'} </p>
          <p>
            Dénivelé positif (en m): {experience.elevationGain || 'non mise'}{' '}
          </p>
          <p>
            Dénivelé négatif (en m): {experience.elevationLoss || 'non mise'}{' '}
          </p>
          <p>Langue : {experience.locale}</p>
          <p>
            Sauvegarder la progression de l'utilisateur ?{' '}
            {experience.useProgression ? 'oui' : 'non'}
          </p>
          <p>
            Attendre la fin du téléchargement avant de lancer le jeu ?{' '}
            {experience.waitForDownload ? 'oui' : 'non'}
          </p>
        </aside>
        <div>
          <BookResource
            id={experience.picture}
            path='picture'
            highlights={highlights}
          />
          <BookText
            collection='experiences'
            id={experience._id}
            path='description'
            text={experience.description}
            highlights={highlights}
          />

          {experience.characters && experience.characters.length > 0 && (
            <div>
              <h4>
                Personnages{' '}
                <Link to={url + '/characters'}>
                  <i className='fa fa-edit' />
                </Link>
              </h4>
              <div className='characters'>
                {experience.characters.map(
                  ({ label, image, _id, variants }, index) => {
                    return (
                      <>
                        <div key={_id}>
                          <BookResource
                            id={image}
                            title={label}
                            path={'characters°|°' + index}
                            highlights={highlights}
                          />
                        </div>
                        {(variants || []).map(
                          ({ label: variantName, image, _id }, index) => {
                            return (
                              <div key={_id}>
                                <BookResource
                                  id={image}
                                  title={label + ' ' + variantName}
                                  path={
                                    'characters°|°' +
                                    index +
                                    '°|°variants°|°' +
                                  index
                                  }
                                  highlights={highlights}
                                />
                              </div>
                            )
                          }
                        )}
                      </>
                    )
                  }
                )}
              </div>
            </div>
          )}
          <h4>
            Thème
            <Link to={url + '/theme'}>
              <i className='fa fa-edit' />
            </Link>
          </h4>
          <div className='theme'>
            {theme &&
              theme.assets &&
              Object.keys(theme.assets)
                .map(key => {
                  return { key, asset: theme.assets[key] }
                })
                .map(({ key, asset }) => (
                  <div key={key}>
                    <BookResource
                      id={asset}
                      title={key.split('_').join(' ')}
                      path={'theme°|°assets°|°' + key}
                      highlights={highlights}
                    />
                  </div>
                ))}
          </div>
          {actionScenarios.length > 0 && (
            <ChapterLinkScenario
              scenarioId={actionScenarios[0].scenario}
              chapters={chapters}
            />
          )}
          {actionScenarios.length === 0 && (
            <ChapterLink chapter={trackPage.chapter} height='200px' />
          )}
        </div>
      </section>
    </article>
  )
}
