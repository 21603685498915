import React, { Component } from 'react'

import * as api from '../../lib/api'

export const Stat = props => {
  const { axisProperties, labelSession, labelPeople, labels, stats } = props
  const axisProperty = axisProperties[0]
  if (!stats) {
    return null
  }
  const labelKeys = stats
    .map(stat => stat[axisProperty])
    .sort((s1, s2) => s1 - s2)

  const data = {
    labels: labels || labelKeys,
    datasets: [
      {
        key: 'sessions',
        label: labelSession,
        borderColor: '#82d25a',
        backgroundColor: '#82d25ade',
        data: labelKeys.map(
          label => stats.find(s => s[axisProperty] === label).sessions
        )
      }
    ]
  }
  if (labelPeople) {
    data.datasets.push({
      key: 'people',
      label: labelPeople,
      borderColor: '#b6a09a',
      backgroundColor: '#b6a09aaa',
      data: labelKeys.map(
        label => stats.find(s => s[axisProperty] === label).people
      )
    })
  }
  return React.Children.only(
    React.cloneElement(props.children, { data, height: 80 })
  )
}

export class StatApi extends Component {
  state = {
    stats: []
  };

  populate (props) {
    const {
      siteId,
      experienceId,
      experienceIds,
      axis,
      group,
      from,
      to,
      excludeZeros,
      axisProperties
    } = props

    return api.sessions
      .stats(axis, {
        group: group,
        from:
          from || new Date(Date.now() - 365 * 24 * 3600 * 1000).toISOString(),
        to: to,
        limit: 100000,
        siteId,
        experienceId,
        experienceIds,
        excludeZeros,
        axisProperties
      })
      .then(stats => this.setState({ stats }))
  }

  componentWillReceiveProps (nextProps) {
    this.populate(nextProps)
  }

  componentDidMount () {
    this.populate(this.props)
  }

  render () {
    return <Stat {...this.props} stats={this.state.stats || []} />
  }
}
