import { toJS, ObservableMap } from 'mobx'

ObservableMap.prototype.filter = function (conditions) {
  const filtered = []
  this.forEach(val => {
    let ok = true
    for (const key in conditions) {
      ok = ok && val[key] === conditions[key]
    }
    if (ok) {
      filtered.push(toJS(val))
    }
  })
  return filtered
}
ObservableMap.prototype.filterOne = function (conditions) {
  return this.filter(conditions)[0]
}
ObservableMap.prototype.map = function (callback) {
  const mapped = {}
  this.forEach((val, key) => {
    mapped[key] = callback(val)
  })
  return mapped
}
