import React, { Component } from 'react'

import uuid from 'uuid'

export default class QRCode extends Component {
  constructor (props) {
    super(props)
    this.change = this.change.bind(this)
  }

  change (e) {
    this.props.onChange(e.target.value)
  }

  componentDidMount () {
    if (!this.props.formData) {
      setTimeout(() => {
        this.props.onChange(uuid.v4())
      }, 0)
    }
  }

  render () {
    return (
      <input
        type='hidden'
        name={this.props.name}
        value={this.props.formData || ''}
      />
    )
  }
}
