import React from 'react'
import { Bar } from 'react-chartjs-2'

import { StatApi as Stat } from './stat'
import { t } from 'stores/i18n.store'

export default props => {
  return (
    <Stat {...props} axis="teams" axisProperties={['people']} labelSession={t('team-size')}>
      <Bar />
    </Stat>
  )
}
