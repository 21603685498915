import React, { Component } from 'react'
import { toJS } from 'mobx'

import * as api from '../../../lib/api'
import checkPath from './lib/checkPath'

export default class BookResource extends Component {
  state = {
    resource: null
  }

  componentDidMount() {
    const { id } = this.props
    id && api.resources.get(id).then(resource => this.setState({ resource: toJS(resource) }))
  }

  render() {
    const { resource } = this.state
    let { id, style, className, title, styleResource, noBackgroundMesh, hd, highlights, path, ...other } = this.props
    if (!resource) {
      return null
    }
    className = (className || '') + highlights && path && checkPath(highlights, path) ? 'highlighted' : ''

    let imageClassName = className + ' resource-image'

    const file = resource.ld ? resource.ld.file : resource.original ? resource.original.file : null
    const hdFile = resource.hd ? resource.hd.file : resource.original ? resource.original.file : null

    if (!noBackgroundMesh) {
      imageClassName += ' background-mesh'
    }
    switch (resource.type) {
      case 'image':
        return (
          // https://bugs.chromium.org/p/chromium/issues/detail?id=875403  loading='lazy'
          <div className={imageClassName} style={style || {}}>
            <img
              style={{ maxWidth: '100%', maxHeight: '100%', ...styleResource }}
              alt={resource.name}
              src={api.resources.getUrl(hd ? hdFile : file)}
              {...other}
            />
            {title && <span className="resource-image-title">{title}</span>}
          </div>
        )
      case 'audio':
        return (
          <audio
            className={className}
            style={{ ...style }}
            controls
            src={api.resources.getUrl(hdFile)}
            preload="none"
            {...other}
          />
        )
      case 'video':
        return (
          <video
            className={className}
            style={{ maxWidth: '100%', ...style }}
            controls
            src={api.resources.getUrl(hdFile)}
            preload="none"
            {...other}
          />
        )

      default:
        return null
    }
  }
}
