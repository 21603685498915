import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Coordinates from './coordinates'

import { ChapterLink, ChapterLinkScenario } from './link'
import BookAction from './action'
import BookResource from './resource'
import BookText from './text'
import Diagnostic from './diagnostic'
import checkers from '../../../lib/check'
import { trackLabel } from '../../../stores'

const TrackMenu = ({ track, chapters, highlights }) => {
  if (!track.menu || track.menu.length === 0) {
    return null
  }
  return (
    <div>
      <h4>Menu</h4>
      <ul>
        {track.menu.map(({ label, icon, action }, index) => {
          return (
            <li key={index}>
              <BookResource id={icon} path={'menu°|°' + index + '°|°icon'} highlights={highlights} />
              <BookText
                text={label}
                collection="modules"
                id={track._id}
                path={'menu°|°' + index + '°|°label'}
                highlights={highlights}
              />
              <BookAction action={action} chapters={chapters} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default class extends Component {
  state = {}
  async componentDidMount() {
    const { track, nonDiagnostic } = this.props
    if (!nonDiagnostic) {
      const checks = await checkers.checkTrack(track)
      this.setState({ checks })
    }
  }

  render() {
    const { checks } = this.state
    const { track, url, chapters, score, usedBy, nonDiagnostic, highlights } = this.props
    const { timeLimit, music, aggroMusic, enrageTime, enrageMusic } = track
    const points = Object.keys(chapters)
      .map(key => chapters[key])
      .filter(({ type, point }) => type === 'point' && point.trackId === track.id)
    const { entities } = track
    return (
      <article className={'track ' + track.type}>
        <h2>
          {track.name && (
            <BookText
              id={track._id}
              collection="tracks"
              path="name"
              text={track.name}
              highlights={highlights}
              style={{ display: 'inline-block' }}
            />
          )}
          {!track.name && (
            <BookText
              id={track._id}
              collection="tracks"
              path="internalName"
              text={track.internalName}
              highlights={highlights}
              style={{ display: 'inline-block' }}
            />
          )}
          <Link to={url}>
            <i className="fa fa-edit" />
          </Link>
        </h2>
        <small>Type : {trackLabel[track.type] || track.type}</small>
        <br />
        <section>
          <aside className="common">
            <div className="icons-container">
              {timeLimit > 0 && (
                <span>
                  {' '}
                  <i className="fa fa-2x fa-clock-o" loop /> {timeLimit} secondes
                </span>
              )}
              {score > 0 && (
                <span>
                  {' '}
                  <i className="fa fa-2x fa-trophy" /> {score} pts
                </span>
              )}
            </div>
            {music && <BookResource id={music} highlights={highlights} path="music" />}

            <h3>Points</h3>
            <ul>
              {points
                .sort((p1, p2) => p1.order || 0 - p2.order || 0)
                .map(({ _id, chapter, point, score: scorePoint }) => {
                  return (
                    <li key={_id}>
                      <span className="fa-stack" title={`Ce point est  ${point.active ? 'actif' : 'inactif'} au début du jeu`}>
                        <i className="fa fa-power-off fa-stack-1x" />
                        {!point.active && <i className="fa fa-ban fa-stack-2x text-danger" />}
                      </span>

                      <span
                        className="fa-stack"
                        title={`Ce point est ${point.visible ? 'visible' : 'invisible'} au début du jeu`}
                      >
                        <i className="fa fa-eye fa-stack-1x" />
                        {!point.visible && <i className="fa fa-ban fa-stack-2x text-danger" />}
                      </span>

                      <ChapterLink chapter={chapter} text={point.name || point.internalName} />

                      {scorePoint > 0 && (
                        <span>
                          {' '}
                          -
                          <i className="fa fa-trophy" /> {scorePoint} pts
                        </span>
                      )}
                      {score > 0 && !scorePoint && (
                        <span
                          className="fa-stack"
                          title="Ce point ne rapporte aucun point alors que les autres points du parcours en rapportent"
                        >
                          <i className="fa fa-trophy fa-stack-1x" />
                          <i className="fa fa-ban fa-stack-2x text-danger" />
                        </span>
                      )}
                    </li>
                  )
                })}
            </ul>
            {entities && entities.length > 0 && (
              <div>
                <h4>Poursuivi par </h4>
                {entities.map((entity, index) => {
                  return (
                    <BookResource
                      path={'entities°|°' + index + '°|°marker'}
                      highlights={highlights}
                      id={entity.marker && entity.marker.resource}
                      key={entity._id}
                    />
                  )
                })}
              </div>
            )}
          </aside>
          <div>
            {(enrageTime || enrageMusic) && (
              <div>
                Au bout de {enrageTime} secondes, la musiqque devient
                <BookResource id={enrageMusic} path="enrageMusic" highlights={highlights} />
              </div>
            )}
            {aggroMusic && (
              <div>
                Musique lorsqu'une entité trouve l'aggro
                <BookResource id={aggroMusic} path="aggroMusic" highlights={highlights} />
              </div>
            )}
            <Coordinates
              track={track}
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: '400px', width: '100%', margin: '0 auto' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              points={points
                .map(p => p.point)
                .filter(p => p.location && p.location.length === 2)
                .concat((entities || []).filter(p => p.location && p.location.length === 2))}
            />
            {track.scenario && <ChapterLinkScenario scenarioId={track.scenario} chapters={chapters} />}
            {track.events &&
              track.events.map((event, index) => {
                const { conditions, actions } = event
                if (conditions.length === 0) {
                  return null
                }
                return (
                  <div key={index}>
                    {`Lorsque le joueur a validé ${conditions[0].number} points alors `}
                    {(actions || []).map((action, index) => (
                      <BookAction action={action} chapters={chapters} key={index} />
                    ))}
                    {(!actions || actions.length === 0) && " pas d'action définie"}
                  </div>
                )
              })}
            <TrackMenu track={track} chapters={chapters} highlights={highlights} />
          </div>
          {!nonDiagnostic && <Diagnostic checks={checks} usedBy={usedBy} chapters={chapters} />}
        </section>
      </article>
    )
  }
}
