import React, { Component } from 'react'
import Dropzone from 'react-dropzone'

import Image from './image'
import Audio from './audio'
import Video from './video'
import Other from './other'
import Loading from '../../loading'
import { resources as resourcesApi } from '../../../lib/api'
import Uploading from './uploading'
import { Modal } from '../../modal'
import { apiCache } from 'stores'

class Preview extends Component {
  render() {
    const { resource, onChange, onClick, options, type } = this.props
    if (!resource) {
      return null
    }
    return (
      <div onClick={onClick}>
        {type === 'audio' && <Audio resource={resource} onChange={onChange} />}
        {type === 'video' && <Video resource={resource} onChange={onChange} />}
        {type === 'image' && <Image resource={resource} onChange={onChange} options={options} />}
        {!type && <Other resource={resource} onChange={onChange} options={options} />}
      </div>
    )
  }
}

export default class Local extends Component {
  state = {
    transfertUid: null,
    resource: null,
    loading: false
  }

  async populateResource(uid) {
    this.setState({ loading: true })
    if (this.props.formContext.experienceUid) {
      await this.props.resourcesApi.getFrom('experiences', this.props.formContext.experienceUid)
    }
    const resource = await this.props.resourcesApi.get(uid)

    this.setState({ resource, loading: false })
  }

  componentDidMount() {
    if (this.props.formData) {
      this.populateResource(this.props.formData)
    }
  }

  componentWillReceiveProps(props) {
    if (props.formData !== this.props.formData && props.formData && props.formData.indexOf('__transfert__') < 0) {
      this.populateResource(props.formData)
    }
  }

  onChange = value => {
    if (value && typeof value === 'object' && value._id) {
      this.setState({ resource: value })
      this.props.onChange(value._id)
    } else {
      this.props.onChange(value)
      if (!value) {
        this.setState({ resource: null })
      }
    }
  }

  onDropAccepted = files => {
    const uiSchema = this.props.uiSchema || {}
    const uiOptions = uiSchema['ui:options'] || {}
    const maxSize = uiOptions.maxSize
    const options = {
      ops: []
    }
    if (maxSize) {
      options.maxSize = maxSize.width
    }
    const upload = this.props.resourcesApi.upload(
      {
        experienceUid: this.props.formContext.experienceUid,
        type: this.props.type || 'other',
        options
      },
      files[0]
    )
    const transfertUid = upload.__transfert_uid

    this.props.onChange('__transfert__' + transfertUid)
    upload.then(json => {
      this.setState({ resource: json })
      json && json._id && this.props.onChange(json._id)
    })
  }

  fromExistingResources = ({ options, type, name, immediate, experienceId, original }) => {
    const promise = resourcesApi.upload({
      options,
      type,
      name,
      immediate,
      experienceId,
      url: resourcesApi.getUrl(original.file)
    })
    const transfertUid = promise.__transfert_uid
    promise.then(json => {
      this.props.onChange(json._id)
    })
    this.props.onChange('__transfert__' + transfertUid)
  }

  render() {
    const { resource, modalOpen, filter } = this.state
    let options

    const accept = []
    const { uiSchema, type, formData, loading, schema } = this.props
    const isTransfering = formData && formData.indexOf && formData.indexOf('__transfert__') === 0
    const transfertUid = isTransfering ? formData.substr(13) : null
    const title = (schema && schema.title) || ''
    switch (type) {
      case 'image':
        accept.push('image/*')
        break

      case 'audio':
        accept.push('audio/*')
        break
      case 'video':
        accept.push('video/*')
        break
      default: {
      }
    }
    options = (uiSchema || {})['ui:options'] || {}
    if (loading) {
      return <Loading />
    }
    const labelCursor = isTransfering || formData ? 'normal' : 'pointer'
    return (
      <div
        style={{
          display: 'flex',
          flexdirection: 'row',
          alignItems: 'center',
          flex: 1
        }}
      >
        {!resource && !isTransfering && (
          <span
            className=" btn btn-default"
            style={{ marginLeft: '-12px' }}
            onClick={e => {
              e.preventDefault()
              this.setState({ modalOpen: true })
            }}
            title="Cliquez pour utiliser un contenu déjà présent dans l'expérience"
          >
            <i className="fa fa-folder-open-o" />
          </span>
        )}
        <Dropzone
          disableClick={!!resource}
          multiple={false}
          onDrop={dropped => {}}
          onDropAccepted={this.onDropAccepted}
          accept={accept.length > 0 ? accept.join(',') : null}
          style={{}}
          activeStyle={{
            border: '1px solid green',
            background: 'rgba(0,255,0,0.25)',
            padding: '1em'
          }}
          rejectStyle={{
            border: '1px solid red',
            background: 'rgba(255,0,0,0.25)',
            padding: '1em'
          }}
        >
          <label
            className="control-label"
            title="Cliquez pour envoyer un contenu depuis votre ordinateur"
            style={{ paddingTop: '6px', cursor: labelCursor }}
          >
            {title}
          </label>
          {!formData && !isTransfering && (
            <span className=" btn btn-default " title="Cliquez pour utiliser un contenu déjà présent dans l'expérience">
              {' '}
              Ajouter un fichier &nbsp;
              <i className="fa fa-upload" />
            </span>
          )}
          {isTransfering && <Uploading uid={transfertUid} />}
          {resource && !isTransfering && <Preview resource={resource} onChange={this.onChange} options={options} type={type} />}
        </Dropzone>
        <Modal
          isOpen={modalOpen}
          close={() => {
            this.setState({ modalOpen: false, filter: '' })
          }}
          height="80vh"
        >
          <div>
            <input type="search" className="form-control" onChange={e => this.setState({ filter: e.target.value })} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              {(apiCache.resources.getFrom('experiences', this.props.formContext.experienceUid) || [])
                .filter(({ type: resourceType }) => type === resourceType)
                .filter(({ name }) => !filter || (name || '').indexOf(filter) >= 0)
                .reduce((resources, resource) => {
                  if (!resources.find(r => r.original.hash && r.original.hash === resource.original.hash)) {
                    resources.push(resource)
                  }
                  return resources
                }, [])
                .sort((a, b) => new Date(b.updatedAt || b.createdAt) - new Date(a.updatedAt || a.createdAt))
                .map(resource => (
                  <Preview
                    resource={resource}
                    type={resource.type || type}
                    key={(resource || {})._id}
                    onClick={e => {
                      this.fromExistingResources(resource)
                      this.setState({ modalOpen: false, filter: '' })
                    }}
                  />
                ))}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

Local.defaultProps = {
  resourcesApi
}
