import React from 'react'
import { Link } from 'react-router-dom'

export default props => {
  const siteUid = props.formContext.siteUid || []
  const maps = props.formContext.maps || []

  const mapOptions = maps.map(map => {
    return (
      <option value={map._id} key={map._id}>
        {map.name || map._id}{' '}
      </option>
    )
  })
  const currentMap = maps.find(m => m._id === props.formData)

  return (
    <>
      <label className="control-label" htmlFor={props.name}>
        {props.schema.title}
      </label>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <select className="form-control" value={props.formData} onChange={e => props.onChange(e.target.value)}>
          <option />
          {mapOptions}
        </select>
        {currentMap && (
          <Link
            style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
            to={'/site/' + siteUid + '/experience/' + currentMap.experienceId + '/map/' + props.formData}
            className="btn btn-default"
          >
            Voir
          </Link>
        )}
      </div>
    </>
  )
}
