import React, { Component } from 'react'

import { NavInExperience } from '../../components/nav'
import { Form } from '../../components/form'
import { Page } from '../'
class CreateForm extends Component {
  state = {
    preview: null
  };

  handleChange = value => {
    /*
    AwesomeQR().create({
      text: 'Makito loves Kafuu Chino.',
      size: 500,
      callback: (data)=>{
        console.log(data)
      }
    }) */
  };

  render () {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Form
          style={{ flex: 1 }}
          formPath='qrcode'
          onChange={this.handleChange}
        />
        <div style={{ flex: '0 0 256px' }}>plop</div>
      </div>
    )
  }
}

export default class QrCodes extends Component {
  render () {
    return (
      <Page>
        <NavInExperience />
        <div id='content'>
          <CreateForm />
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}
