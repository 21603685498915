import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Module } from '../'
import ScenarioForm from './form'
import List from './list'

export default class Scenario extends Component {
  render () {
    const { match } = this.props
    return (
      <Switch>
        <Route path={match.path + '/list/'} component={List} />
        <Route path={match.path + '/module/:moduleUid?'} component={Module} />
        <Route path={match.path} component={ScenarioForm} />
      </Switch>
    )
  }
}
