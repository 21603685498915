import checkHorde from './horde'
import checkChase from './chase'
import checkPhotoHunt from './photo_hunt'
import checkAction from '../action'
import * as api from '../../api'

export default async track => {
  const checkResult = {
    warnings: [],
    errors: [],
    nb: 0
  }
  const maps = await api.maps.getFrom('experiences', track.experienceId)
  const points = await api.points.getFrom('tracks', track._id)
  const xp = await api.experiences.get(track.experienceId)
  const xpLink = '/site/' + xp.siteId + '/experience/' + xp._id
  const mapsArray = Object.keys(track.maps || {}).map(_id => _id)
  for (let counter = 0; counter < mapsArray.length; counter++) {
    const map = maps.find(({ _id }) => _id === mapsArray[counter])
    checkResult.nb++
    if (!map) {
      checkResult.warnings.push({
        text: `le parcours ${track.name || track._id}, utilise une carte qui n'existe pas`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    } else {
      if (map && map.type === 'ground') {
        const res = await api.resources.get(map.resource)
        if (!res) {
          checkResult.errors.push({
            text: `le parcours ${track.name || track._id}, utilise la carte ${map.name || map._id} qui n'existe pas`,
            link: xpLink + '/track/' + track._id,
            _id: track._id
          })
        } else {
          if (res.hd?.size > 1024) {
            checkResult.warnings.push({
              text: `le parcours ${track.name || track._id}, utilise la carte ${map.name || map._id} qui n'est pas tuilée`,
              link: xpLink + '/track/' + track._id,
              _id: track._id
            })
          }
        }
      }
      checkResult.nb++
    }
  }
  if (track.northEastBound) {
    let [latitude, longitude] = track.northEastBound
    checkResult.nb++
    if (isNaN((latitude = parseFloat(latitude)))) {
      checkResult.errors.push({
        text: `le parcours ${track.internalName ||
          track.name ||
          track._id}, a une bounding box incorrect, redeployez le en validation.`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    }
    if (isNaN((longitude = parseFloat(longitude)))) {
      checkResult.errors.push({
        text: `le parcours ${track.internalName ||
          track.name ||
          track._id}, a une bounding box incorrect, redeployez le en validation.`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    }
    checkResult.nb++
    if (longitude < -180 || longitude > 180) {
      checkResult.errors.push({
        text: `le parcours ${track.internalName ||
          track.name ||
          track._id}, a une bounding box incorrect, redeployez le en validation.`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    }
    checkResult.nb++
    if (latitude < -90 || latitude > 90) {
      checkResult.errors.push({
        text: `le parcours ${track.internalName ||
          track.name ||
          track._id}, a une bounding box incorrect, redeployez le en validation.`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    }
  }
  if (track.southWestBound) {
    let [latitude, longitude] = track.southWestBound
    checkResult.nb++
    if (isNaN((latitude = parseFloat(latitude)))) {
      checkResult.errors.push({
        text: `le parcours ${track.internalName ||
          track.name ||
          track._id}, a une bounding box incorrect, redeployez le en validation.`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    }
    if (isNaN((longitude = parseFloat(longitude)))) {
      checkResult.errors.push({
        text: `le parcours ${track.internalName ||
          track.name ||
          track._id}, a une bounding box incorrect, redeployez le en validation.`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    }
    checkResult.nb++
    if (longitude < -180 || longitude > 180) {
      checkResult.errors.push({
        text: `le parcours ${track.internalName ||
          track.name ||
          track._id}, a une bounding box incorrect, redeployez le en validation.`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    }
    checkResult.nb++
    if (latitude < -90 || latitude > 90) {
      checkResult.errors.push({
        text: `le parcours ${track.internalName ||
          track.name ||
          track._id}, a une bounding box incorrect, redeployez le en validation.`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    }
  }

  if (!points || points.length === 0) {
    checkResult.warnings.push({
      text: `le parcours ${track.name || track._id} n'a aucun point`,
      link: xpLink + '/track/' + track._id,
      _id: track._id
    })
  }
  checkResult.nb++

  for (let index = 0; index < (track.menu || []).length; index++) {
    const { label, icon, action } = track.menu[index]
    checkResult.nb++
    if (!label && !icon) {
      checkResult.warnings.push({
        text: `le menu ${index + 1} du toolbutton de ${track.name || track._id}, n'a ni label ni icone`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    }

    checkResult.nb++
    if (!action) {
      checkResult.warnings.push({
        text: `le menu ${index + 1} du toolbutton de ${track.name || track._id} n'a pas d'action`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    } else {
      if (action.type === 'load_scenario') {
        const scenario = action.scenario && (await api.scenarios.get(action.scenario))
        checkResult.nb++
        if (scenario) {
          const modules = await api.modules.getFrom('scenarios', scenario._id)
          checkResult.nb++
          if (modules.length && !modules[0].canGoBack) {
            checkResult.warnings.push({
              text: `le module  ${modules[0].internalName || modules[0].name || modules[0].title}(${
                modules[0].type
              }) du scenario ${scenario.name} lancé par un bouton d'action de ${track.name ||
                track._id} n'autorise pas le retour arrière`,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + modules[0]._id,
              _id: modules[0]._id
            })
          }
        } else {
          checkResult.nb++
          checkResult.warnings.push({
            text: `le scenario du menu ${index + 1} du toolbutton de ${track.name || track._id} n'existe pas `,
            link: xpLink + '/track/' + track._id,
            _id: track._id
          })
        }
      }
      const res = await checkAction(action, xpLink + '/track/' + track._id)
      checkResult.warnings = checkResult.warnings.concat(res.warnings)
      checkResult.errors = checkResult.errors.concat(res.errors)
      checkResult.nb += res.nb
    }
  }
  let specificCheck
  switch (track.type) {
    case 'horde':
      specificCheck = await checkHorde(track)
      break
    case 'chase':
      specificCheck = await checkChase(track)
      break
    case 'photo_hunt':
      specificCheck = await checkPhotoHunt(track)
      break
    default: {
    }
  }
  if (specificCheck) {
    checkResult.warnings = checkResult.warnings.concat(specificCheck.warnings)
    checkResult.errors = checkResult.errors.concat(specificCheck.errors)
    checkResult.nb += specificCheck.nb
  }
  const unordered = []
  const ordered = {}
  for (var counter = 0; counter < points.length; counter++) {
    const point = points[counter]
    checkResult.nb++
    if (!point.order) {
      unordered.push(point)
    } else {
      ordered[point.order] = (ordered[points.order] || []).concat([point])
    }
  }

  if (track.type !== 'photo_hunt' && track.type !== 'horde' && track.type !== 'chase') {
    const conflictOrder = []
    Object.keys(ordered).forEach(order => {
      checkResult.nb++
      if (ordered[order].length > 1) {
        conflictOrder.push(ordered[order])
      }
    })
    conflictOrder.forEach((conflictedPoints, index) => {
      if (index === 6) {
        checkResult.warnings.push({
          text: ` ${conflictOrder.length - 5} ont des problèmes d'ordre dans le parcours ${track.name}`,
          link: xpLink + '/track/' + track._id,
          _id: track._id
        })
      }
      if (index > 5) return
      checkResult.warnings.push({
        text: `les points ${conflictedPoints.map(p => p.internalName || p.name || p._id)} ont le même ordre dans le parcours ${
          track.name
        }`,
        link: xpLink + '/track/' + track._id,
        _id: track._id
      })
    })
    if (unordered.length > 0 && Object.keys(ordered).length > 0) {
      if (unordered.length > 5) {
        checkResult.warnings.push({
          text: `${unordered.length} points du parcours  ${track.name}, n'ont pas d'ordre alors que ${
            Object.keys(ordered).length
          } autres points du même parcours en ont un`,
          link: xpLink + '/track/' + track._id,
          _id: track._id
        })
      } else {
        unordered.forEach(point => {
          checkResult.warnings.push({
            text: `le point ${point.internalName || point.name || point.title || point._id}, n'a pas d'ordre alors que ${
              Object.keys(ordered).length
            } autres points du même parcours en ont un`,
            link: xpLink + '/track/' + track._id + '/point/' + point._id,
            _id: point._id
          })
        })
      }
    }
  }

  if (track.routes) {
    for (let counter = 0; counter < track.routes.length; counter++) {
      const { segment } = track.routes[counter]
      if (!segment) {
        checkResult.errors.push({
          text: `le tracé GPS ${counter + 1} du parcours ${track.name} n'a pas de tracé`,
          link: xpLink + '/track/' + track._id
        })
      } else {
        const r = await api.segments.get(segment)
        if (!r) {
          checkResult.errors.push({
            text: `le tracé GPS ${counter + 1} du parcours ${track.name} n'existe pas `,
            link: xpLink + '/track/' + track._id
          })
        }
      }
    }
  }

  return checkResult
}
