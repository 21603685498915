import React, { Component, useEffect } from 'react'
import { observer } from 'mobx-react'

import { Root, Login } from './pages'
import * as stores from './stores'
import { locale } from 'stores/i18n.store'

class App extends Component {
  render() {
    if (stores.user.isLoggued)
      return (
        <>
          <SaveLang />
          <Root />
        </>
      )
    return (
      <>
        <SaveLang />
        <Login />
      </>
    )
  }
}

const SaveLang = () => {
  useEffect(() => {
    document.documentElement.lang = locale.value
    window.localStorage.setItem('lang', locale.value)
  }, [])
  return null
}

export default observer(App)
