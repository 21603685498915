import React from 'react'
import L from 'leaflet'
import { Marker, Popup } from 'react-leaflet'

import Map from '../../../components/map'
import * as api from '../../../lib/api'
import checkPath from './lib/checkPath'

export default class Coordinates extends React.Component {
  state = {}
  icons = {}

  async populate() {
    this.setState({ loadingIcons: true })
    for (const point of this.props.points || []) {
      if (!this.icons[point._id] && point.markers && point.markers.normal.resource) {
        // icon is deformed, check ratio
        const resource = await api.resources.get(point.markers.normal.resource)
        if (resource) {
          this.icons[point._id] = {
            url: api.resources.getUrl(resource.hd ? resource.hd.file : resource.original.file),
            scaledSize: {
              width: 33,
              height: 39
            }
          }
        } else {
          console.log('icon resource not found')
        }
      } else {
        // console.log('icon no resource')
      }
    }
    this.setState({ loadingIcons: false })
  }

  componentDidMount() {
    this.populate()
  }

  componentWillReceiveProps(newProps) {
    this.populate()
  }

  render() {
    const { points, track, highlights, path, zoom } = this.props
    const center = {
      lat: 0,
      lng: 0
    }
    if (!points || points.length === 0) {
      return null
    }
    points &&
      points.length > 0 &&
      points.forEach(point => {
        if (!point.location || !point.location[0] || !point.location[1]) {
          return
        }
        center.lat += point.location[0] / points.length
        center.lng += point.location[1] / points.length
      })
    const className = highlights && path && checkPath(highlights, path) ? 'highlighted' : ''

    return (
      <Map track={track} center={center} style={{ height: '300px' }} className={className} zoom={zoom}>
        {points.map(point => {
          if (!point.location || !point.location[0] || !point.location[1]) {
            return null
          }
          const { url: iconUrl, scaledSize } = this.icons[point._id] || {}
          const icon = iconUrl
            ? L.icon({
                iconUrl,
                iconSize: [scaledSize.width, scaledSize.height],
                iconAnchor: [scaledSize.width / 2, scaledSize.height],
                popupAnchor: [0, -scaledSize.height + 2]
              })
            : new L.Icon.Default()
          return (
            <Marker
              key={point._id || point.localId}
              position={{ lat: point.location[0], lng: point.location[1] }}
              title={point.name}
              icon={icon}
            >
              <Popup>
                {point.internalName}
                <br />
                {point.name}
              </Popup>
            </Marker>
          )
        })}
      </Map>
    )
  }
}
