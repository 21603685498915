import generify from './generic'

export const modules = generify('experiences-service', 'modules', 'module')

modules.getFromScenario = (scenarioUid, showDeleted) => {
  return modules.getFrom('scenarios', scenarioUid, showDeleted)
}

modules.getFromExperience = (experienceUid, showDeleted) => {
  return modules.getFrom('experiences', experienceUid, showDeleted)
}
