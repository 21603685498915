import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import QRCodeReact from 'qrcode.react'

// import searchPointActions from './searchpointactions';
import BookResource from './resource'
import BookText from './text'

import Diagnostic from './diagnostic'
import Coordinates from './coordinates'
import { ChapterLink, ChapterLinkScenario } from './link'
import checks from '../../../lib/check'

export default class extends Component {
  state = {}
  async componentDidMount() {
    const { point, score, nonDiagnostic } = this.props
    const checkPoint = !nonDiagnostic && (await checks.checkPoint(point))

    if (score && checkPoint && checkPoint.warnings && score < 50) {
      checkPoint.warnings.push({
        text: 'score trop faible',
        _id: point._id
      })
    }
    if (score && checkPoint && checkPoint.warnings && score > 200) {
      checkPoint.warnings.push({
        text: 'score trop élevé',
        _id: point._id
      })
    }
    this.setState({ checkPoint })
  }

  render() {
    const { point, chapters, _id, url, usedBy, nonDiagnostic, highlights, children } = this.props
    const checkPoint = this.state.checkPoint
    let {
      name,
      internalName,
      scenario: scenarioId,
      check,
      active,
      visible,
      triggers,
      picture,
      timeLimit,
      points,
      bonus,
      notes
    } = point
    check = check || {}
    const locationHighlighed = highlights && highlights.location
    let actions
    return (
      <article className="point" id={_id}>
        <h2>
          {name && (
            <BookText
              id={_id}
              collection="points"
              path="name"
              text={name}
              highlights={highlights}
              style={{ display: 'inline-block' }}
            />
          )}
          {!name && (
            <BookText
              id={_id}
              collection="points"
              path="internalName"
              text={internalName}
              highlights={highlights}
              style={{ display: 'inline-block' }}
            />
          )}
          <Link to={url}>
            <i className="fa fa-edit" />
          </Link>
        </h2>
        <section>
          <aside className="common">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              {Object.keys(point.markers || {}).map(key => {
                const marker = point.markers[key]
                if (!marker.resource) {
                  return null
                }
                return (
                  <BookResource
                    id={marker.resource}
                    style={{ flex: 1, margin: '1em' }}
                    title={key}
                    key={key}
                    path={'markers°|°' + key}
                    highlights={highlights}
                  />
                )
              })}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              {Object.keys(point.icons || {}).map(key => {
                const icon = point.icons[key]
                if (!icon) {
                  return null
                }
                return (
                  <BookResource
                    id={icon}
                    style={{ flex: 1, margin: '1em' }}
                    title={key}
                    key={key}
                    path={'icons|°' + key}
                    highlights={highlights}
                  />
                )
              })}
            </div>
            <div className="icons-container">
              {timeLimit > 0 && (
                <span>
                  {' '}
                  <i className="fa fa-2x fa-clock-o" loop /> {timeLimit} secondes
                </span>
              )}
              {(points > 0 || bonus > 0) && (
                <span>
                  {' '}
                  <i className="fa fa-2x fa-trophy" /> {(points || 0) + (bonus || 0)} pts
                </span>
              )}

              <span className="fa-stack fa-lg" title={`Ce point est ${active ? 'actif' : 'inactif'} au début du jeu`}>
                <i className="fa fa-power-off fa-stack-1x" />
                {!active && <i className="fa fa-ban fa-stack-2x text-danger" />}
              </span>
              <span className="fa-stack fa-lg" title={`Ce point est ${visible ? 'visible' : 'invisible'} au début du jeu`}>
                <i className="fa fa-eye fa-stack-1x" />
                {!visible && <i className="fa fa-ban fa-stack-2x text-danger" />}
              </span>
            </div>
          </aside>
          <div>
            {bonus > 0 && (
              <span>
                {' '}
                <i className="fa fa-2x fa-trophy" />
                Dont {bonus} pts bonus si l'utilisateur n'a pas besoin de la bousolle
                <br />
              </span>
            )}

            {
              <Coordinates
                zoom={18}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '400px', width: '100%', margin: '0 auto' }} />}
                mapElement={<div style={{ height: '100%' }} className={locationHighlighed ? 'highlighted' : ''} />}
                points={[point]}
              />
            }
            <BookResource id={picture} style={{ maxWidth: '100%' }} path="picture" highlights={highlights} />
            {actions && actions.length > 0 && (
              <div>
                Ces actions modifient l'état de ce point :
                <ul>
                  {actions.map(({ chapter, action }, index) => {
                    return chapter.type === 'scenario' ? (
                      <li key={index}>
                        <ChapterLinkScenario
                          chapters={chapters}
                          scenarioId={chapter._id}
                          text={`${action.type} dans le chapitre`}
                        />
                      </li>
                    ) : (
                      <li key={index}>
                        <ChapterLink
                          key={index}
                          chapter={chapter.chapter}
                          scene={chapter.scene}
                          text={`${action.type} dans le chapitre`}
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
            {triggers && triggers.find(t => t.type === 'location') && <p>Ce point se lancera automatiquement a proximité</p>}
            {triggers && triggers.find(t => t.type === 'manual') && <p>Ce point se lancera au clic</p>}
            {triggers && triggers.find(t => t.type === 'beacon') && <p>Ce point se lancera lorsque un beacon bluetooth </p>}

            {check.clue && (
              <p>
                Indices : <ChapterLinkScenario chapters={chapters} scenarioId={check.clue} />
              </p>
            )}
            {check.clue && check.type === 'qrcode' && (
              <>
                <span>Le qrcode à trouver est {check.code}</span>
                <br />
                <QRCodeReact value={check.code || ''} size={64} />
              </>
            )}
            {check.clue && check.type === 'code' && <span>Le mot code à trouver est {check.code}</span>}
            {check.clue && check.scenario && (
              <p>
                {' '}
                Jeu : <ChapterLinkScenario chapters={chapters} scenarioId={check.scenario} />
              </p>
            )}

            {scenarioId && (
              <ChapterLinkScenario
                chapters={chapters}
                scenarioId={scenarioId}
                text={check.clue ? "Scénario  d'après jeu" : null}
              />
            )}
          </div>
          {!nonDiagnostic && <Diagnostic checks={checkPoint} usedBy={usedBy} notes={notes} chapters={chapters} />}
        </section>
        <section>
          <div>{children}</div>
        </section>
      </article>
    )
  }
}
