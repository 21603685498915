import { toJS } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { NavInPoint } from '../../components/nav'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import Loading from '../../components/loading'
import { Page } from '../'
import { apiCache } from '../../stores'
import checkPoint from '../../lib/check/point'

class PointCheckForm extends Component {
  state = {
    point: {},
    loading: true,
    lastType: 'none',
    track: {}
  };

  async componentDidMount () {
    const { experienceUid, trackUid, pointUid } = this.props.match.params
    await api.scenarios.getFromExperience(experienceUid)
    await api.tracks.get(trackUid)
    const point = await api.points.get(pointUid)
    const checks = await checkPoint(point)
    let lastType
    if (point && point.check && point.check.type) {
      lastType = point.check.type
    } else {
      lastType = 'none'
    }
    this.setState({ point: point, checks, loading: false, lastType })
  }

  /* mobx will force a new render */
  componentWillReact () {
    const { pointUid } = this.props.match.params
    if (pointUid && this.state.point) {
      const point = apiCache.points.get(pointUid)
      if (point && this.state.point.updatedAt !== point.updatedAt) {
        let lastType
        if (point && point.check && point.check.type) {
          lastType = point.check.type
        } else {
          lastType = 'none'
        }
        this.setState({ point: toJS(point), lastType })
      }
    }
  }

  save = async formData => {
    let { ...point } = this.state.point
    const { experienceUid, pointUid } = this.props.match.params

    let check
    if (!formData.type || formData.type === 'none') {
      check = null
    } else {
      check = formData
      check.clue = check.clue || null
      check.scenario = check.scenario || null
    }
    if (check && !check.scenario) {
      const scenario = await api.scenarios.create({
        name: point.name + ' étapes de jeu ',
        experienceId: experienceUid
      })
      check.scenario = scenario._id
    }
    if (check && !check.clue) {
      const scenario = await api.scenarios.create({
        name: point.name + ' indices ',
        experienceId: experienceUid
      })
      check.clue = scenario._id
      await api.modules.create({
        experienceId: experienceUid,
        scenarioId: scenario._id,
        type: 'gallery',
        canGoBack: true
      })
    }

    point = await api.points.upsert({
      _id: pointUid,
      check,
      validation: check
    })
    const checks = await checkPoint(point)
    this.setState({ point, checks })
  };

  change = formData => {
    if (formData.type !== this.state.type) {
      this.setState({ lastType: formData.type })
    }
  };

  render () {
    const {
      siteUid,
      experienceUid,
      trackUid,
      pointUid
    } = this.props.match.params

    const { point, lastType, checks, loading } = this.state
    const scenarios = apiCache.scenarios.getFromExperience(experienceUid) || []
    const track = apiCache.tracks.get(trackUid)
    const check = point.check || point.validation
    const formData = point && check ? { ...check } : { type: lastType }
    if (point.check) {
      formData.scenario = formData.scenario || ''
      formData.clue = formData.clue || ''
    }
    return (
      <Page>
        <NavInPoint point={point} />
        <div id='content'>
          {loading && <Loading />}
          {!loading &&
            checks &&
            checks.errors.map((e, i) => (
              <p className='bg-danger' key={i}>
                {e.text}
              </p>
            ))}
          {!loading &&
            checks &&
            checks.warnings.map((e, i) => (
              <p className='bg-warning' key={i}>
                {e.text}
              </p>
            ))}
          {!loading && checks && (
            <p className='bg-success'>
              {checks.nb - checks.warnings.length - checks.errors.length}{' '}
              vérifications réussies
            </p>
          )}
          {!loading && (
            <Form
              key={pointUid + '/validation'}
              formPath='points/check'
              initialFormData={formData}
              onSubmit={this.save}
              onChange={this.change}
              experienceUid={experienceUid}
              formContext={{
                scenarios,
                track,
                siteUid
              }}
            />
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default observer(PointCheckForm)
