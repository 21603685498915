import React from "react";
import { apiCache } from "../../../stores";
import ActionSummary from "./action";
import ModuleSummary from "./module";

export default ({ scenario, experienceUid, siteUid }) => {
  return (
    <ul>
      {apiCache.modules
        .getFromExperience(experienceUid)
        .filter((m) => m.scenarioId === scenario.id)
        .sort((m1, m2) => m1.order - m2.order)
        .map((m) => (
          <ModuleSummary
            module={m}
            experienceUid={experienceUid}
            siteUid={siteUid}
          />
        ))}
      {scenario.onEnd && scenario.onEnd.length > 0 && (
        <>
          {" "}
          Actions{" "}
          <ul>
            {" "}
            {scenario.onEnd.map((action) => (
              <ActionSummary
                action={action}
                experienceUid={experienceUid}
                siteUid={siteUid}
              />
            ))}{" "}
          </ul>
        </>
      )}
    </ul>
  );
};
