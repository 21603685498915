import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

import { uploadHistory } from '../../../stores'
import ProgressBar from './uploadprogress'
class Uploading extends Component {
  render () {
    const { uid } = this.props
    if (!uid) {
      return <div>pas d'uid</div>
    }
    const upload = uploadHistory.get(uid)
    if (!upload) {
      return <div>Upload fini {uid} </div>
    }
    const {
      status,
      uploadLoaded,
      uploadTotal,
      downloadLoaded,
      downloadTotal
    } = toJS(upload)

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '300px',
          margin: '0 auto'
        }}
      >
        <div style={{ width: '20px' }}>
          <i className='fa fa-laptop' style={{ fontSize: '30px' }} />
        </div>
        <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
          <ProgressBar loaded={uploadLoaded} total={uploadTotal} />
          <ProgressBar loaded={downloadLoaded} total={downloadTotal} reverse />
        </div>
        <div style={{ width: '20px' }}>
          {status !== 'uploaded' && (
            <i className='fa fa-cloud' style={{ fontSize: '30px' }} />
          )}
          {status === 'uploaded' && (
            <i className='fa fa-cog fa-spin' style={{ fontSize: '30px' }} />
          )}
        </div>
      </div>
    )
  }
}

export default observer(Uploading)
