import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { apiCache } from '../stores'
import * as api from '../lib/api'

class Picture extends Component {
  static defaultProps = { service: 'resources' };

  componentDidMount () {
    this.props.resourceId && api.resources.get(this.props.resourceId)
  }

  componentWillReceiveProps (nextProps) {
    if (
      nextProps.ressourceId &&
      nextProps.ressourceId !== this.props.resourceId
    ) {
      api.resources.get(nextProps.ressourceId)
    }
  }

  render () {
    const { resourceId, ld, service, ...other } = this.props
    const resource = apiCache[service].get(resourceId)
    if (!resource) {
      return null
    }
    var src = resource.original && resource.original.file
    if (ld) {
      if (resource.ld) {
        src = resource.ld.file
      }
    } else {
      if (resource.hd) {
        src = resource.hd.file
      } else {
        if (resource.ld) {
          src = resource.ld.file
        }
      }
    }
    if (!src) {
      return null
    }
    return (
      <img
        src={api[service].getUrl(src)}
        alt={resource.name || resource.title || 'sans titre'}
        {...other}
      />
    )
  }
}

export default observer(Picture)
