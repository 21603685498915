import React, { Component } from 'react'

import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import Loading from '../../components/loading'
import { Page } from '../'

export default class TimerForm extends Component {
  state = {
    experience: {},
    segments: [],
    loading: false
  };

  populateState (experienceUid, timerUid) {
    this.setState({ loading: true })
    api.experiences.get(experienceUid).then(experience => {
      const { timers } = experience
      const timer = timers ? timers[timerUid] : {}
      this.setState({ experience, timer, loading: false })
    })
  }

  componentWillReceiveProps (nextProps) {
    const { experienceUid, timerUid } = nextProps.match.params
    this.populateState(experienceUid, timerUid)
  }

  componentDidMount () {
    const { experienceUid, timerUid } = this.props.match.params
    this.populateState(experienceUid, timerUid)
  }

  save = formData => {
    const { experienceUid } = this.props.match.params
    const modifiedTimers = {} // {...timers}
    modifiedTimers[formData._id] = formData
    return api.experiences.upsert({
      _id: experienceUid,
      timers: modifiedTimers
    })
  };

  render () {
    const { siteUid, experienceUid } = this.props.match.params
    const { timer, loading } = this.state
    if (loading) {
      return <Loading />
    }
    return (
      <Page>
        {experienceUid && <NavInExperience />}
        <div id='content'>
          {loading && <Loading />}
          {!loading && (
            <Form
              formPath='timer'
              initialFormData={timer}
              experienceUid={experienceUid}
              onDelete={this.delete}
              onSubmit={this.save}
              formContext={{ siteUid }}
            />
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}
