import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Line extends Component {
  state = {
    editingContent: this.props.txt.text,
    editing: false,
    saving: false
  };

  edit = txt => {
    this.setState({ editing: true })
    this.setState({ editingContent: this.props.txt.text })
  };

  stopEdit = txt => {
    this.setState({ editing: false })
  };

  change = (e, txt) => {
    this.setState({ editingContent: e.target.value })
  };

  render () {
    const { txt, onChange } = this.props
    const { editing, editingContent, saving } = this.state
    return (
      <tr key={txt.id /* id */ + txt.path /* position in object */}>
        <td>{txt.type}</td>
        <td>{txt.title}</td>
        <td>{txt.container}</td>
        <td>
          {editing && (
            <textarea
              onChange={e => this.change(e, txt)}
              className='form-control'
              value={editingContent}
            />
          )}
          {!editing && txt.text}
        </td>
        {editing && (
          <td
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            <button
              className='btn btn-primary'
              onClick={e => {
                this.setState({ saving: true })
                onChange(this.state.editingContent).then(() => {
                  this.setState({ saving: false })
                  this.stopEdit()
                })
              }}
              title='edit'
              disabled={saving}
            >
              <i className='fa fa-check' />
            </button>
            &nbsp;
            <button
              className='btn btn-default'
              onClick={e => {
                this.stopEdit(txt)
                e.preventDefault()
              }}
              title='edit'
            >
              <i className='fa fa-times' />
            </button>
          </td>
        )}
        {!editing && (
          <td
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            <Link className='btn btn-default' to={txt.link} title='see'>
              <i className='fa fa-eye' />
            </Link>
            &nbsp;
            <button
              className='btn btn-primary'
              onClick={e => {
                e.preventDefault()
                this.edit(txt)
              }}
              title='edit'
            >
              <i className='fa fa-edit' />
            </button>
          </td>
        )}
      </tr>
    )
  }
}
