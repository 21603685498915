import { NavLink } from 'react-router-dom'
import React from 'react'
import ActionSummary from './action'
import { moduleLabel } from '../../../stores'

export default ({ experienceUid, siteUid, module }) => {
  return (
    <li key={module._id}>
      <NavLink
        to={
          '/site/' +
          siteUid +
          '/experience/' +
          experienceUid +
          '/scenario/' +
          module.scenarioId +
          '/module/' +
          module._id
        }
      >
        {(module.internalName || module.title || '') + ' (' + (moduleLabel[module.type] || module.type) + ')'}
        {module.type === 'choice' && module.choices && (
          <ul>
            {module.choices.map(choice => {
              return (
                <>
                  {choice.label}
                  <ul>
                    {(choice.actions || []).map(action => (
                      <ActionSummary
                        action={action}
                        experienceUid={experienceUid}
                        siteUid={siteUid}
                      />
                    ))}
                  </ul>
                </>
              )
            })}
          </ul>
        )}
      </NavLink>
      {module.toolButton && (
        <>
          <br />
          Bouton d'action
          <ul>
            {
              <ActionSummary
                action={module.toolButton.action}
                experienceUid={experienceUid}
                siteUid={siteUid}
              />
            }
          </ul>
        </>
      )}
    </li>
  )
}
