import React from 'react'
import JsonSchemaForm from 'react-jsonschema-form'

import ScenarioPicker from './scenariopicker'
import { PointValidation } from './pointValidation'
import PointTrigger from './pointtrigger'
import Action from './action'

export const PointTriggerForm = ({ formData, onChange, formContext }) => {
  return (
    <div>
      <legend>Paramètres de déclenchement du point</legend>
      <p className="field-description">
        Cette page contient des modifications dans la structure des données qui fonctionneront sur l'application mobile pour les
        versions <b>4.5.0 et supérieures</b>.<br />
        Pour les versions antérieures :
        <ul>
          <li>Les actions et validations en RA et par position ne sont pas prises en compte.</li>
          <li>
            En l'absence de validation, le comportement des scénarios d'indice et au 1er déclenchement ne sont pas garantis.
          </li>
          <li>S'il y a une validation, les scénarios d'indice et au 1er déclenchement sont obligatoires.</li>
        </ul>
      </p>
      <br />
      <br />

      <legend>Scenario d'indice pour aider à trouver le point</legend>
      <ScenarioPicker
        formData={formData?.check?.clue || ''}
        schema={{ title: '' }}
        formContext={{ ...formContext }}
        onChange={uid => onChange({ ...formData, check: { ...formData.check, clue: uid } })}
      />
      <br />

      <legend>Déclenchement du point</legend>
      <p className="field-description">
        Un point peut être déclenché de plusieurs manières. La première fois il faut soit une méthode de validation, soit un ou
        plusieurs déclencheurs.
        <br />
        Pour les fois suivante il suffit de cliquer sur le POI sur la carte.
      </p>

      <PointValidation
        onChange={data => onChange({ ...formData, check: { ...formData.check, ...data } })}
        formData={formData?.check || {}}
        schema={{ title: 'Méthode de validation' }}
        formContext={formContext}
      />
      <label className="control-label">Méthodes de déclenchement sans validation</label>
      <JsonSchemaForm
        // Formulaire personnalisé dans le composant pour avoir accès aux listes.
        schema={{ type: 'array', items: { type: 'object' } }}
        uiSchema={{ items: { 'ui:field': 'pointtrigger' }, 'ui:options': { orderable: false } }}
        formData={formData?.triggers || []}
        onChange={({ formData: triggers }) => onChange({ ...formData, triggers })}
        fields={{ pointtrigger: PointTrigger }}
      />
      <br />

      <legend>Scénario démarré lors du déclenchement du point</legend>

      <ScenarioPicker
        formData={formData?.check?.scenario || ''}
        schema={{ title: 'Au premier déclenchement uniquement' }}
        formContext={{ ...formContext }}
        onChange={uid => onChange({ ...formData, check: { ...formData.check, scenario: uid } })}
      />
      <ScenarioPicker
        formData={formData?.scenario || ''}
        schema={{ title: 'Pour tous les déclenchements (sauf le premier si le scénario précedent est renseigné)' }}
        formContext={{ ...formContext }}
        onChange={uid => onChange({ ...formData, scenario: uid })}
      />
      <br />

      <br />
      <legend>Actions au déclenchement du point</legend>
      <p className="field-description">
        <b>Disponible sur l'application mobile à partir de la version 4.5.0.</b>
        <br />
        Les actions s'exécutent avant les scénarios, en suivant le même principe : une liste pour le premier déclenchement, et une
        liste par défaut.
        <br />
      </p>

      <label className="control-label">Premier déclenchement uniquement</label>
      <JsonSchemaForm
        schema={{ title: '', type: 'array', items: { type: 'object' } }}
        uiSchema={{ items: { 'ui:field': 'action' } }}
        formData={formData?.firstActions || []}
        formContext={formContext}
        onChange={({ formData: data }) => onChange({ ...formData, firstActions: data || [] })}
        fields={{ action: Action }}
      />
      <label className="control-label">
        Pour tous les déclenchements (sauf le premier si les actions précedentes sont renseignées)
      </label>
      <JsonSchemaForm
        schema={{ title: '', type: 'array', items: { type: 'object' } }}
        uiSchema={{ items: { 'ui:field': 'action' } }}
        formData={formData?.actions || []}
        formContext={formContext}
        onChange={({ formData: data }) => onChange({ ...formData, actions: data || [] })}
        fields={{ action: Action }}
      />
    </div>
  )
}
