import { observable, observe, toJS } from 'mobx'

const localStorage = window.localStorage || {}

const user = observable({
  email: '',
  password: '',
  isLoggued: !!localStorage.getItem('token'),
  token: localStorage.getItem('token') || '',
  formMode: localStorage.getItem('formMode'),
  scope: [],
  expert: false,
  favs: observable((localStorage.getItem('favs') || '').split(','))
})
observe(user, 'token', ({ newValue }) => {
  if (newValue) {
    localStorage.setItem('token', newValue)
  } else {
    localStorage.removeItem('token')
  }
})
observe(user, 'formMode', ({ newValue }) => {
  if (newValue) {
    localStorage.setItem('formMode', newValue)
  } else {
    localStorage.removeItem('formMode')
  }
})

observe(user.favs, () => {
  localStorage.setItem('favs', (toJS(user.favs) || []).join(','))
})

export default user
