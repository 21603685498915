import React, { Component } from 'react'
import { t } from 'stores/i18n.store'
import * as api from '../../lib/api'

export default class extends Component {
  state = {
    stats: []
  }

  populate(props) {
    const { siteId, experienceId, experienceIds, from, to, excludeZeros } = props

    api.sessions
      .stats('sessions', {
        group: 'year',
        from: from || new Date(Date.now() - 365 * 24 * 3600 * 1000).toISOString(),
        to: to,
        limit: 100000,
        siteId,
        experienceId,
        experienceIds,
        excludeZeros,
        axisProperties: ['year']
      })
      .then(stats => this.setState({ stats }))
  }

  componentDidMount() {
    this.populate(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.populate(nextProps)
  }

  render() {
    const { stats } = this.state
    if (!stats || stats.length === 0) {
      return null
    }
    const total = stats.reduce(
      (acc, s) => {
        return {
          emails: s.emails + acc.emails,
          notificationLinkClicked: s.notificationLinkClicked + acc.notificationLinkClicked,
          notificationSeen: s.notificationSeen + acc.notificationSeen,
          people: s.people + acc.people,
          pictures: (s.pictures || 0) + acc.pictures,
          sessions: s.sessions + acc.sessions
        }
      },
      {
        emails: 0,
        notificationLinkClicked: 0,
        notificationSeen: 0,
        people: 0,
        pictures: 0,
        sessions: 0
      }
    )

    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <h2>
            {total.people}
            <br />
            <small>{t('players')}</small>
          </h2>
        </div>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <h2>
            {total.sessions}
            <br />
            <small>{t('sessions')}</small>
          </h2>
        </div>
        <div style={{ flex: 1, textAlign: 'center' }}>
          <h2>
            {total.pictures}
            <br />
            <small>{t('selfies')}</small>
          </h2>
        </div>
        <div
          style={{
            flex: 1,
            textAlign: 'center'
          }}
        >
          <h2>
            {total.emails}
            <br />
            <small>{t('emails')}</small>
          </h2>
        </div>
      </div>
    )
  }
}
