import { observable, intercept } from 'mobx'
import { apiCache, notifications } from './'
import * as api from '../lib/api'

const webSocketStatus = observable({
  status: '',
  lastConnection: null,
  experienceId: null,
  ws: null
})

export default webSocketStatus

intercept(webSocketStatus, 'experienceId', changes => {
  if (changes.newValue !== webSocketStatus.experienceId && changes.newValue) {
    connect(changes.newValue)
  }
  return changes
})

function cleanNull(item) {
  if (item === false) {
    return false
  }
  if (!item) {
    return null
  }
  if (Array.isArray(item)) {
    return item.map(cleanNull).filter(o => !!o)
  }
  if (typeof item === 'object') {
    const processed = {}
    for (var key in item) {
      const p = cleanNull(item[key])
      if (p) {
        processed[key] = p
      }
    }
    return processed
  }
  return item
}

function connect(experienceId) {
  // only the change of the current experience are up to date
  // since I change, I mark everything as dirty
  apiCache.dirty()
  api.experiences.getMessagingChannel(experienceId).then(channel => {
    if (!channel || !channel.url) {
      return
    }
    if (webSocketStatus.ws) {
      webSocketStatus.ws.close()
    }
    const ws = (webSocketStatus.ws = new WebSocket(channel.url))

    ws.onmessage = message => {
      try {
        const data = JSON.parse(message.data)
        switch (data.type) {
          case 'dataUpdate':
            const id = Date.now()
            notifications.set(id, {
              id,
              type: 'info',
              title: 'Mise à jour - ' + data.entity,
              content: data.document.name || data.document.title,
              autoClose: 2000
            })
            apiCache.upsert('experiences-service', data.entity + 's', data.document._id, cleanNull(data.document))
            break
          default:
            console.warn("don't know what to do with message type", data.type)
        }
      } catch (e) {
        console.error(e)
      }
    }
  })
}
