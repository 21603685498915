import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'

export const CategoryPicker = ({ formData, name, formContext, onChange, schema }) => {
  const { categories } = formContext

  const options = useRef((categories || []).map(category => ({ value: category._id, label: category.label }))).current
  const [value, setValue] = useState([])

  useEffect(() => {
    setValue(options.filter(o => (formData || []).includes(o.value)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  return (
    <>
      <label className="control-label" htmlFor={name}>
        {schema.title}
      </label>
      <Select
        value={value}
        onChange={options => onChange((options || []).map(option => option.value))}
        options={options}
        isMulti={true}
        placeholder={'Sélectionnez une catégorie'}
        noOptionsMessage={() => 'Pas de catégorie à afficher'}
      />
    </>
  )
}
