import React from 'react'
import {
  ChapterLink,
  ChapterLinkModule,
  ChapterLinkPoint,
  ChapterLinkScenario,
  ChapterLinkTrack
} from './link'

export default ({ chapterId, usages, chapters, ...other }) => {
  const chapter = chapters[chapterId]
  const obj = chapter[chapter.type]

  switch (chapter.type) {
    case 'point':
      return <ChapterLinkPoint pointId={chapterId} chapters={chapters} />
    case 'scenario':
      return <ChapterLinkScenario scenarioId={chapterId} chapters={chapters} />
    case 'track':
      return <ChapterLinkTrack trackId={chapterId} chapters={chapters} />
    case 'module':
      return <ChapterLinkModule moduleId={chapterId} chapters={chapters} />
    default:
      return (
        <ChapterLink
          chapter={chapter.chapter}
          text={
            (obj.name || obj.title || obj.internalName || obj._id) +
            ' (' +
            chapter.type +
          ')'
          }
        />
      )
  }
}
