import * as api from '../../api'

const findMissingLoadTrack = async (scenarioId, checked) => {
  const scenario = await api.scenarios.get(scenarioId)
  const modules = await api.modules.getFrom('scenarios', scenario._id)
  let childs = []
  if ((scenario.onEnd || []).find(({ type }) => type === 'load_track')) {
    return []
  }
  if (modules.length > 0 && modules[modules.length - 1].type === 'choice') {
    const m = modules[modules.length - 1]
    childs = (m.choices || [])
      .filter(
        ({ actions }) =>
          !(actions || []).find(({ type }) => type === 'load_track')
      ) // les actions qui ne lancent pas un load_track
      .map(({ actions }) =>
        (actions || []).filter(({ type }) => type === 'load_scenario')
      ) // mais peut etre que les scenario lance un load_track
      .flat()
      .filter(({ type }) => type === 'load_scenario')
      .map(({ scenario }) => scenario)
  }
  // add on end of scenario
  childs = childs.concat(
    (scenario.onEnd || [])
      .filter(({ type }) => type === 'load_scenario')
      .map(({ scenario }) => scenario)
  )

  // don't infinte loop
  childs = childs.filter(scenario => {
    return !(checked || {})[scenario]
  })
  if (childs.length > 0) {
    let missings = []
    for (var counter = 0; counter < childs.length; counter++) {
      const { ...subChecked } = checked || {}
      subChecked[scenarioId] = true
      subChecked[childs[counter]] = true
      const missing = await findMissingLoadTrack(childs[counter], subChecked)
      missings = missings.concat(missing)
    }
    return missings
  } else {
    // warning
    return [scenarioId]
  }
}

export default findMissingLoadTrack
