import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import TrackChecks from './checks'
import TrackForm from './form'
import Point, { HordeEntity } from '../point'

export default class Track extends Component {
  render () {
    const { match } = this.props
    return (
      <Switch>
        <Route path={match.path + '/checks'} component={TrackChecks} />
        <Route path={match.path + '/point/:pointUid?'} component={Point} />
        <Route
          path={match.path + '/hordeentity/:entityUid?'}
          component={HordeEntity}
        />
        <Route path={match.path} component={TrackForm} />
      </Switch>
    )
  }
}
