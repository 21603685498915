import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { toJS } from 'mobx'

import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import { Page } from '../'
import Picture from '../../components/picture'

export class ExperienceTagForm extends Component {
  state = {
    experience: {},
    points: [],
    loading: false,
    icons: {}
  };

  componentDidMount () {
    if (this.props.match.params.experienceUid) {
      this.populate(
        this.props.match.params.experienceUid,
        this.props.match.params.tagUid
      )
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.match.params.tagUid !== nextProps.match.params.tagUid) {
      this.populate(
        nextProps.match.params.experienceUid,
        nextProps.match.params.tagUid
      )
    }
  }

  populate (experienceUid, tagUid) {
    this.setState({ loading: true })
    api.points
      .getFrom('experiences', experienceUid)
      .then(points => {
        this.setState({ points })
        if (tagUid) {
          return api.tags.get(tagUid)
        }
        return Promise.resolve({})
      })
      .then(tag => this.setState({ loading: false, tag }))
  }

  save = formData => {
    const { experienceUid, tagUid } = this.props.match.params

    api.tags.upsert({ ...formData, experienceId: experienceUid }).then(tag => {
      const url = this.props.match.url + (tagUid ? '' : tag._id)
      this.props.history.push(url)
    })
  };

  render () {
    const { tag, points } = this.state
    const { experienceUid } = this.props.match.params
    return (
      <Page>
        <NavInExperience />
        <div id='content'>
          Tag Form
          <Form
            formPath='points/tag'
            initialFormData={tag || {}}
            formContext={{
              points,
              experienceUid
            }}
            onSubmit={this.save}
          />
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export class ExperienceTags extends Component {
  state = {
    tags: [],
    points: [],
    loading: true,
    saving: false
  };

  populateState (experienceUid) {
    this.setState({ loading: true })

    api.tags
      .getFrom('experiences', experienceUid)
      .then(tags => {
        this.setState({ tags })

        const promises = []
        tags.forEach(tag => {
          if (tag.icon) {
            promises.push(api.resources.get(tag.icon))
          }
        })
        return Promise.all(promises)
      })
      .then(resources => {
        const icons = {}
        resources.forEach(resource => (icons[resource._id] = resource))

        this.setState({ icons })
        return api.points.getFrom('experiences', experienceUid)
      })
      .then(points => {
        this.setState({ points, loading: false })
      })
  }

  componentDidMount () {
    this.populateState(this.props.match.params.experienceUid)
  }

  componentWillReceiveProps (nextProps) {
    if (
      nextProps.match.params.experienceUid !==
      this.props.match.params.experienceUid
    ) {
      this.populateState(nextProps.match.params.experienceUid)
    }
  }

  save = formData => {};
  render () {
    const { tags, points, loading } = this.state
    const { siteUid, experienceUid } = this.props.match.params || {}
    if (loading) {
      return (
        <Page>
          <NavInExperience />
          <div id='content'>Tags</div>
          <div id='app-preview' />
        </Page>
      )
    }
    const { match } = this.props
    return (
      <Page>
        {match.params.experienceUid && <NavInExperience />}
        <div id='content'>
          <h1>Tags</h1>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Label</th>
                <th>Icone</th>
                <th>Utilisé dans </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {tags.map(tag => (
                <tr key={tag._id}>
                  <td>{tag.label}</td>
                  <td>
                    <Picture resourceId={tag.icon} style={{ height: '60px' }} />
                  </td>

                  <td>
                    {points
                      .filter(point => {
                        console.log(
                          'point ',
                          toJS(point.tags),
                          point.tags && point.tags.indexOf(tag._id) >= 0
                        )
                        return point.tags && point.tags.indexOf(tag._id) >= 0
                      })
                      .map(point => {
                        return (
                          <Link
                            key={point._id}
                            to={
                              '/site/' +
                              siteUid +
                              '/experience/' +
                              experienceUid +
                              '/track/' +
                              point.trackId +
                              '/point/' +
                              point._id
                            }
                          >
                            {point.name}
                            <br />
                          </Link>
                        )
                      })}
                  </td>
                  <td>
                    <Link
                      to={
                        '/site/' +
                        siteUid +
                        '/experience/' +
                        experienceUid +
                        '/tag/' +
                        tag._id
                      }
                      className='btn btn-primary'
                    >
                      Modifier
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Link
            to={'/site/' + siteUid + '/experience/' + experienceUid + '/tag/'}
            className='btn btn-primary btn-block'
          >
            Ajouter un tag
          </Link>
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}
