import React from 'react'
import { Link } from 'react-router-dom'

export default props => {
  const scenarios = props.formContext.scenarios || []
  const siteUid = props.formContext.siteUid || []
  const soptions = scenarios
    .filter(s => !!s)
    .sort((s1, s2) => (s1.name || '').localeCompare(s2.name || ''))
    .map(s => (
      <option value={s._id} key={s._id}>
        {s.name || s._id}
      </option>
    ))
  const currentScenario = scenarios.find(s => s._id === props.formData)

  return (
    <div className="form-group field field-string" key={props}>
      <label className="control-label" htmlFor={props.name}>
        {props.schema.title}
      </label>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <select
          style={{ flex: 3 }}
          className="form-control"
          value={'' || props.formData}
          onChange={e => props.onChange(e.target.value)}
        >
          <option />
          <option value={'*new-scenario*'}>-- Nouveau scénario --</option>
          {soptions}
        </select>
        {currentScenario && (
          <Link
            style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
            to={'/site/' + siteUid + '/experience/' + currentScenario.experienceId + '/scenario/' + props.formData}
            className="btn btn-default"
          >
            Voir
          </Link>
        )}
      </div>
    </div>
  )
}
