import React from 'react'

export default ({ schema, uiSchema }) => {
  const options = (uiSchema || {})['ui:options']
  const { style, classNames } = options || {}
  const { title } = schema
  return (
    <div style={style} className={classNames}>
      {title}
    </div>
  )
}
