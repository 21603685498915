import React, { useState, useEffect } from 'react'
import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import { Page } from '../'

const importVoices = async (
  sourceExperienceUid,
  destExperienceUid,
  progress = () => {}
) => {
  const sourceVoices = {}
  const sourceTextWithoutVoices = {}
  ;(await api.modules.getFrom('experiences', sourceExperienceUid))
    .filter(
      ({ type }) => type === 'conversation' || type === 'score_conversation'
    )
    .forEach(({ dialogs }) => {
      dialogs &&
        dialogs.forEach(({ text, sound }) => {
          if (text && sound) {
            sourceVoices[text] = sound
          }
          if (text && !sound) {
            sourceTextWithoutVoices[(text || '').trim()] = true
          }
        })
    })
  const missings = []
  for (const source of Object.keys(sourceVoices)) {
    console.log(source, sourceVoices[source])
    progress && progress({ desc: 'chargement de la voix de ' + source })
    sourceVoices[source] = await api.resources.get(sourceVoices[source])
  }
  const destDialogs = (
    await api.modules.getFrom('experiences', destExperienceUid)
  ).filter(
    ({ type }) => type === 'conversation' || type === 'score_conversation'
  )
  console.log(destDialogs)
  for (const {
    _id,
    experienceId,
    dialogs,
    title,
    internalName
  } of destDialogs) {
    let changed = false
    const modifiedModule = { _id, experienceId, dialogs: [] }
    for (const dialog of dialogs) {
      const text = dialog.text
      if (sourceVoices[text]) {
        console.log('found corresponding voice', text)
        progress({ desc: 'Maj de la voix de ' + text })
        if (dialog.sound) {
          let current = await api.resources.get(dialog.sound)
          if (
            !current ||
            current.original.hash !== sourceVoices[text].original.hash
          ) {
            changed = true
            const res = await api.resources.upload({
              type: 'audio',
              experienceId: destExperienceUid,
              url: api.resources.getUrl(sourceVoices[text].original.file),
              options: { ...sourceVoices[text].options }
            })
            dialog.sound = res._id
          }
        }
      } else {
        if (!sourceTextWithoutVoices[text.trim()]) {
          missings.push(text)
          console.log(' MISSING ', text)
        }
        dialog.sound = null
      }
      modifiedModule.dialogs.push(dialog)
    }
    if (changed) {
      progress({ desc: 'Enregistrement du module ' + (title || internalName) })
      await api.modules.upsert(modifiedModule)
    }
  }
  progress({ missings })
}

export default props => {
  const { experienceUid, siteUid } = props.match.params
  const [loading, setLoading] = useState(true)
  const [importing, setImporting] = useState(false)
  const [importProgress, setImportProgress] = useState({})
  const [experiences, setExperiences] = useState([])
  const [sites, setSites] = useState([])

  useEffect(() => {
    const load = async () => {
      setSites(await api.sites.list())
      setExperiences(await api.experiences.list())
      setLoading(false)
    }
    load()
  }, [setLoading, setExperiences, setSites])

  return (
    <Page>
      <NavInExperience />
      <div id='content'>
        {JSON.stringify(importProgress)}
        {!loading && !importing && (
          <>
            <Form
              formPath='voice'
              onSubmit={data => {
                console.log('data ', data)
                setImporting(true)
                importVoices(
                  data.experienceUid,
                  experienceUid,
                  setImportProgress
                )
                setImporting(false)
              }}
              experienceUid={experienceUid}
              initialFormData={{
                siteUid
              }}
              formContext={{ sites, experiences }}
            />

            <span>
              Depuis quelle expérience voulez vous intégrer les voix ?
            </span>
          </>
        )}
      </div>
      <div id='app-preview' />
    </Page>
  )
}
