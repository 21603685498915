import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import QRCodeReact from 'qrcode.react'

import { Page } from '../'
import * as api from '../../lib/api'
import { user } from '../../stores'

import Loading from '../../components/loading'
import { NavInSites } from '../../components/nav'
import Picture from '../../components/picture'

const OneExperience = ({ experience, resource }) => {
  const style = {
    height: '120px',
    backgroundSize: 'cover',
    bakgroundPosition: 'center'
  }
  if (resource && resource.hd && resource.hd.file) {
    style.backgroundImage =
      'url(' + api.resources.getUrl(resource.hd.file) + ')'
  }
  return (
    <div style={style}>
      <h4>{experience.name}</h4>
    </div>
  )
}
const TwoExperiences = ({ experiences, resources }) => {
  const [one, two] = experiences

  const style = {
    backgroundSize: 'cover',
    bakgroundPosition: 'center',
    margin: '0.1em',
    flex: 1
  }
  const resourceOne = resources && resources[one.picture]
  const resourceTwo = resources && resources[two.picture]
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1
      }}
    >
      <div
        style={{
          ...style,
          backgroundImage:
            resourceOne && resourceOne.hd
              ? 'url(' + api.resources.getUrl(resourceOne.hd.file) + ')'
              : ''
        }}
      >
        <h4>{one.name}</h4>
      </div>
      <div
        style={{
          ...style,
          backgroundImage:
            resourceTwo && resourceTwo.hd
              ? 'url(' + api.resources.getUrl(resourceTwo.hd.file) + ')'
              : ''
        }}
      >
        <h4>{two.name}</h4>
      </div>
    </div>
  )
}
const ThreeExperiences = ({ experiences, resources }) => {
  const [one, two, three] = experiences

  const resourceOne = resources && resources[one.picture]
  const resourceTwo = resources && resources[two.picture]
  const resourceThree = resources && resources[three.picture]

  const style = {
    backgroundSize: 'cover',
    bakgroundPosition: 'center',
    margin: '0.1em',
    flex: 1
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          ...style,
          backgroundImage:
            resourceOne && resourceOne.hd
              ? 'url(' + api.resources.getUrl(resourceOne.hd.file) + ')'
              : '',
          height: '70px',
          backgroundSize: 'cover'
        }}
      >
        <h4>{one.name}</h4>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1
        }}
      >
        <div
          style={{
            ...style,
            backgroundImage:
              resourceTwo && resourceTwo.hd
                ? 'url(' + api.resources.getUrl(resourceTwo.hd.file) + ')'
                : ''
          }}
        >
          <h4>{two.name}</h4>
        </div>
        <div
          style={{
            ...style,
            backgroundImage:
              resourceThree && resourceThree.hd
                ? 'url(' + api.resources.getUrl(resourceThree.hd.file) + ')'
                : ''
          }}
        >
          <h4>{three.name}</h4>
        </div>
      </div>
    </div>
  )
}
const MoreExperiences = ({ experiences, resources }) => {
  const [one, two] = experiences
  const resourceOne = resources && resources[one.picture]
  const resourceTwo = resources && resources[two.picture]

  const style = {
    backgroundSize: 'cover',
    bakgroundPosition: 'center',
    margin: '0.1em',
    flex: 1
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          ...style,
          backgroundImage:
            resourceOne && resourceOne.hd
              ? 'url(' + api.resources.getUrl(resourceOne.hd.file) + ')'
              : '',
          height: '70px',
          backgroundSize: 'cover'
        }}
      >
        <h4>{one.name}</h4>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1
        }}
      >
        <div
          style={{
            ...style,
            backgroundImage:
              resourceTwo && resourceTwo.hd
                ? 'url(' + api.resources.getUrl(resourceTwo.hd.file) + ')'
                : ''
          }}
        >
          <h4>{two.name}</h4>
        </div>
        <div
          syle={{
            flex: 1
          }}
        >
          <h4
            style={{
              display: 'block',
              height: '100%',
              fontSize: '24px',
              whiteSpace: 'normal'
            }}
          >
            +{experiences.length - 2}
            <br />
          </h4>
        </div>
      </div>
    </div>
  )
}

class SitePreview extends Component {
  state = {
    resources: {},
    experiences: []
  };

  updateResources (experiences) {
    if (!experiences) {
      return
    }

    const loadedResources = {}
    const promises = Object.values(experiences)
      .filter((experience, index) => index <= 3)
      .filter(experience => !!experience.picture)
      .filter(experience => !this.state.experiences.includes(experience._id))
      .map(experience => {
        return api.resources.get(experience.picture).then(resource => {
          resource && (loadedResources[resource._id] = resource)
        })
      })
    this.setState({ experiences })
    Promise.all(promises).then(() => {
      this.setState({ resources: loadedResources })
    })
  }

  componentWillReceiveProps (nextProps) {
    this.updateResources(nextProps.experiences)
  }

  componentDidMount () {
    this.updateResources(this.props.experiences)
  }

  addToFavs = e => {
    user.favs.push(this.props.site._id)
    e.preventDefault()
  };

  removeFromFavs = e => {
    user.favs.remove(this.props.site._id)
    e.preventDefault()
  };

  render () {
    const { site, experiences } = this.props
    const { resources } = this.state
    const favs = user.favs
    return (
      <Link to={'/site/' + site._id} className='site-preview'>
        <h3>
          {site.name}{' '}
          {favs.includes(site._id) ? (
            <i
              className='fa fa-star pull-right'
              onClick={this.removeFromFavs}
            />
          ) : (
            <i className='fa fa-star-o pull-right' onClick={this.addToFavs} />
          )}
        </h3>
        {experiences.length === 1 && (
          <OneExperience
            experience={experiences[0]}
            resource={resources[experiences[0].picture]}
          />
        )}
        {experiences.length === 2 && (
          <TwoExperiences experiences={experiences} resources={resources} />
        )}
        {experiences.length === 3 && (
          <ThreeExperiences experiences={experiences} resources={resources} />
        )}
        {experiences.length > 3 && (
          <MoreExperiences experiences={experiences} resources={resources} />
        )}
      </Link>
    )
  }
}

const MobxSitePreview = observer(SitePreview)

class ExperiencePreview extends Component {
  render () {
    const experience = this.props.experience
    return (
      <Link
        to={'/site/' + experience.siteId + '/experience/' + experience._id}
        className='experience-preview'
      >
        <Picture resourceId={experience.picture} />
        <h3>{experience.name + '-' + experience.locale}</h3>
      </Link>
    )
  }
}

export default class Sites extends Component {
  state = {
    sites: [],
    filter: '',
    filteredSites: [],
    experiences: [],
    loading: false,
    isAdmin: false,
    isExpert: false,
    pageSize: 15,
    currentPage: 0
  };

  componentDidMount () {
    this.setState({ loading: true })
    api.sites
      .list()
      .then(sites => {
        this.setState({ sites, filteredSites: sites })
        return api.experiences.list()
      })
      .then(experiences => {
        this.setState({ experiences, loading: false })
      })
      .then(() => this.setState({ loading: false }))

    api.users.amIAnAdmin().then(isAdmin => {
      this.setState({ isAdmin })
    })
    api.users.amIAnExpert().then(isExpert => {
      this.setState({ isExpert })
    })
  }

  normalize (str) {
    return (str || '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
  }

  render () {
    let {
      error,
      loading,
      filter,
      sites,
      pageSize,
      currentPage,
      experiences,
      isAdmin
    } = this.state
    const filteredSites = sites
      .filter(s => {
        if (!filter) {
          return true
        }
        if (this.normalize(s.name).indexOf(this.normalize(filter)) >= 0) {
          return true
        }
        if (
          experiences.filter(
            xp =>
              xp.siteId === s._id &&
              this.normalize(xp.name).indexOf(this.normalize(filter)) >= 0
          ).length > 0
        ) {
          return true
        }
        return false
      })
      .sort((s1, s2) => {
        if (user.favs.includes(s1._id) && !user.favs.includes(s2._id)) {
          return -1
        }
        if (user.favs.includes(s2._id) && !user.favs.includes(s1._id)) {
          return 1
        }
        return this.normalize(s1.name).localeCompare(this.normalize(s2.name))
      })

    const pages = []
    for (
      let index = 0;
      index < Math.ceil(filteredSites.length / pageSize);
      index++
    ) {
      pages.push(index)
    }
    if (currentPage >= pages.length) {
      currentPage = pages.length - 1
    }
    return (
      <Page>
        <NavInSites />
        <div id='content'>
          {error && <p>{error}</p>}
          {loading && <Loading />}
          <input
            type='search'
            className='form-control'
            value={filter}
            onChange={e => this.setState({ filter: e.target.value })}
            placehohder='Rechercher'
          />

          <div className='sites-container'>
            {filteredSites.map((site, index) => {
              if (index < currentPage * pageSize) {
                return null
              }
              if (index >= (currentPage + 1) * pageSize) {
                return null
              }
              return (
                <MobxSitePreview
                  site={site}
                  key={site._id}
                  experiences={experiences.filter(xp => xp.siteId === site._id)}
                />
              )
            })}
          </div>
          {!sites.length && (
            <div className='experience-preview-container'>
              {experiences.map(experience => {
                return (
                  <ExperiencePreview
                    experience={experience}
                    key={experience._id}
                  />
                )
              })}
            </div>
          )}

          <div
            aria-label='Page navigation'
            style={{
              width: '100%',
              textAlign: 'center'
            }}
          >
            <ul className='pagination'>
              {currentPage > 0 && (
                <li>
                  <span
                    aria-label='Previous'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.setState({
                        currentPage: currentPage - 1
                      })}
                  >
                    <span aria-hidden='true'>&laquo;</span>
                  </span>
                </li>
              )}
              {pages.map(page => {
                return (
                  <li
                    key={page}
                    className={page === currentPage ? 'active' : ''}
                  >
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.setState({ currentPage: page })}
                    >
                      {page + 1}
                    </span>
                  </li>
                )
              })}
              {currentPage !== pages.length - 1 && (
                <li>
                  <span
                    style={{ cursor: 'pointer' }}
                    aria-label='Next'
                    onClick={() =>
                      this.setState({
                        currentPage: currentPage + 1
                      })}
                  >
                    <span aria-hidden='true'>&raquo;</span>
                  </span>
                </li>
              )}
            </ul>
          </div>
          {
            <h2>
              QrCode à scanner pour ouvrir la liste des sites dans l'application
              explorgames
            </h2>
          }
          <QRCodeReact
            value='https://workshop.explor.games/site/'
            style={{ padding: '10px' }}
            size={276}
            includeMargin
            level='M'
          />
          {isAdmin && (
            <Link
              to='/site/'
              className='btn btn-block btn-primary'
              style={{
                clear: 'both'
              }}
            >
              Créer un nouveau site
            </Link>
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}
