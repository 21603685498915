import Dropzone from 'react-dropzone'
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { NavInExperience } from '../../../components/nav'
import * as api from '../../../lib/api'
import { apiCache } from '../../../stores'

import { Page } from '../..'
import Loading from '../../../components/loading'

import jsonToArray from './jsontoarray'
import Table from './table'
import RowChooser from './rowchooser'

class ExperienceTexts extends Component {
  state = {
    loading: false,
    texts: [[]],
    mode: 'table',
    imported: {},
    rowId: '',
    rowPath: '',
    rowContent: '',
    rowType: ''
  }

  populateState (experienceUid) {
    this.setState({ loading: true })
    if (experienceUid) {
      this.setState({ loading: true })
      const promises = []

      promises.push(api.experiences.get(experienceUid))
      promises.push(api.modules.getFromExperience(experienceUid))
      promises.push(api.points.getFromExperience(experienceUid))
      promises.push(api.tracks.getFromExperience(experienceUid))
      promises.push(api.scenarios.getFromExperience(experienceUid))

      Promise.all(promises)
        .then(() => {
          this.setState({ loading: false })
          var texts = this.extractTexts()
          this.setState({ texts })
        })
        .catch(e => {
          console.log(e)
          this.setState({ loading: false })
        })
    }
  }

  componentDidMount () {
    this.populateState(this.props.match.params.experienceUid)
  }

  extractTexts () {
    const { siteUid, experienceUid } = this.props.match.params
    let txts = []
    const xp = toJS(apiCache.experiences.get(experienceUid))
    const scenarios = {}
    const tracks = {}
    toJS(apiCache.scenarios.getFrom('experiences', experienceUid)).forEach(
      scenario => {
        scenarios[scenario._id] = scenario
      }
    )
    apiCache.tracks.getFrom('experiences', experienceUid).forEach(track => {
      tracks[track._id] = track
    })
    const addScenario = scenario => {
      apiCache.modules.getFrom('scenarios', scenario._id).forEach(m => {
        const txtM = jsonToArray('', toJS(m)).map(line => {
          line.container = scenario.name
          line.link =
            '/site/' +
            siteUid +
            '/experience/' +
            experienceUid +
            '/scenario/' +
            m.scenarioId +
            '/module/' +
            m.id
          line.type = 'modules'
          line.title = m.name || m.title || ''
          line.id = m.id
          return line
        })
        txts = txts.concat(txtM)
        extractScenarioAndTracks(toJS(m))
      })
    }
    const addTrack = track => {
      apiCache.points
        .getFrom('tracks', track._id)
        .sort((a, b) =>
          (a.internalName || a.name || '').localeCompare(
            b.internalName || b.name || ''
          )
        )
        .forEach(p => {
          const txtM = jsonToArray('', toJS(p)).map(line => {
            line.container = track.name
            line.link =
              '/site/' +
              siteUid +
              '/experience/' +
              experienceUid +
              '/track/' +
              p.trackId +
              '/point/' +
              p.id
            line.type = 'points'
            line.title = p.name || p.title || ''
            line.id = p.id
            return line
          })
          txts = txts.concat(txtM)
          extractScenarioAndTracks(toJS(p))
        })
      const txtM = jsonToArray('', toJS(track)).map(line => {
        line.container = ''
        line.link =
          '/site/' +
          siteUid +
          '/experience/' +
          experienceUid +
          '/track/' +
          track.id
        line.type = 'tracks'
        line.title = track.name || track.title || ''
        line.id = track.id
        return line
      })
      txts = txts.concat(txtM)
      extractScenarioAndTracks(toJS(track))
    }
    const extractScenarioAndTracks = json => {
      if (!json) {
        return
      }
      if (typeof json === 'object') {
        Object.keys(json).map(key => extractScenarioAndTracks(json[key]))
        return
      }
      if (scenarios[json]) {
        const scenario = scenarios[json]
        scenarios[json] = null
        addScenario(scenario)
      }
      if (tracks[json]) {
        const track = tracks[json]
        tracks[json] = null
        addTrack(track)
      }
    }
    jsonToArray('', xp).forEach(line => {
      line.type = 'experiences'
      line.id = xp.id
      line.title = xp.name
      line.container = ''
      line.link = '/site/' + siteUid + '/experience/' + experienceUid
      txts = txts.concat([line])
    })
    extractScenarioAndTracks(xp.onStart)
    extractScenarioAndTracks(xp.startTrack)
    extractScenarioAndTracks(xp)
    Object.keys(tracks).forEach(trackId => {
      const track = tracks[trackId]
      if (!track) {
        return
      }
      tracks[trackId] = null
      addTrack(track)
    })
    Object.keys(scenarios).forEach(scenarioId => {
      const scenario = scenarios[scenarioId]
      if (!scenario) {
        return
      }
      scenarios[scenarioId] = null
      addScenario(scenario)
    })
    txts.forEach(txt => {
      const [characterId] = (txt.character || '').split('°|°')
      const c = (xp.characters || []).find(c => c._id === characterId)
      // let v = c && (c.variants || []).find(v => v._id === variantId)
      // if (v) {
      //  txt.character = v.label
      // } else {
      if (c) {
        txt.character = c.label
      } else {
        txt.character = ''
      }
      // }
    })
    return txts
  }

  save = async (txt, newValue) => {
    // console.log('will save  in index', txt, newValue)
    // const { experienceUid } = this.props.match.params
    const patchAndCopy = (object, path, value) => {
      let key = path.shift()
      if (!key) {
        return value
      }
      if (!object) {
        throw new Error('object empty')
      }
      object = toJS(object)

      if (Array.isArray(object)) {
        const res = []
        key = parseInt(key, 10)
        for (var index = 0; index < object.length; index++) {
          if (index === key) {
            res[index] = patchAndCopy(object[index], path, value)
          } else {
            res[index] = object[index]
          }
        }
        return res
      }

      if (typeof object === 'object') {
        const res = {}
        for (var sub in object) {
          if (object.hasOwnProperty(sub)) {
            if (sub === key) {
              res[sub] = patchAndCopy(object[sub], path, value)
            } else {
              res[sub] = object[sub]
            }
          }
        }
        return res
      }
    }
    const type = txt.type
    let id = txt.id
    const m = await api[type].get(id)

    if (!m) {
      console.error(id, ' de type ', type, 'inconnu')
    } else {
      id = m._id
      // console.log('will patch ', type, id, m, txt.path)
    }
    const modifiedCopy = patchAndCopy(
      m,
      txt.path.split('°|°'),
      (newValue || '').trim()
    )

    return api[type].update(id, modifiedCopy).then(() => {
      var texts = this.extractTexts()
      this.setState({ texts })
    })
  }

  export = () => {
    import('xlsx').then(XLSX => {
      const json = this.state.texts
      var workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(json)
      XLSX.utils.book_append_sheet(workbook, worksheet, 'textes')
      XLSX.writeFile(workbook, 'textes.xlsx')
    })
  }

  handleFile = files => {
    files.length === 1 && this.setState({ mode: 'rowChooser', file: files[0] })
  }

  render () {
    const { loading, mode, texts, file } = this.state
    const { experienceUid } = this.props.match.params
    if (loading) {
      return (
        <Page>
          <NavInExperience />
          <div id='content'>
            <Loading />
          </div>
        </Page>
      )
    }
    return (
      <Page>
        <NavInExperience />
        <div id='content'>
          {mode === 'table' && (
            <Dropzone
              disableClick={false}
              multiple={false}
              onDropAccepted={this.handleFile}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              style={{}}
              activeStyle={{
                border: '1px solid green',
                background: 'rgba(0,255,0,0.25)'
              }}
              rejectStyle={{
                border: '1px solid red',
                background: 'rgba(255,0,0,0.25)'
              }}
            >
              <label className='control-label'>
                Intégrer un fichier excel de textes .{' '}
              </label>
            </Dropzone>
          )}
          {mode === 'table' && <Table texts={texts} onSave={this.save} />}
          {mode === 'rowChooser' && (
            <RowChooser
              file={file}
              originalTexts={texts}
              onSave={this.save}
              experienceId={experienceUid}
            />
          )}
          {mode === 'table' && (
            <button
              className='btn btn-primary btn-block'
              onClick={this.export}
              title='Télécharger le fichier excel'
            >
              <i className='fa fa-download' />
              Télécharger les textes
            </button>
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default observer(ExperienceTexts)
