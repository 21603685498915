import generify from './generic'
import { user, conf } from '../../stores'

export const applications = generify('applications-service', 'applications', 'application')

applications.package = applicationId => {
  return fetch(conf.API_URL + '/applications-service/applications/' + applicationId + '/package', {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(response => response.arrayBuffer())
    .then(buffer => {
      return new Blob([buffer], { type: 'application/zip' })
    })
}
