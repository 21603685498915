import { toJS } from 'mobx'
import uid from 'uuid'
import React, { Component } from 'react'

import * as api from '../../lib/api'
import createScenarioAction from '../../lib/createscenarioaction'
import { Form } from '../../components/form'
import Header from '../../components/header'
import Footer from '../../components/footer'
import Loading from '../../components/loading'
import { NavInTrack } from '../../components/nav'
import { apiCache } from '../../stores'
import checkTrack from '../../lib/check/track'

export default class TrackForm extends Component {
  state = {
    track: {},
    points: [],
    loading: true
  }

  change = formData => {
    this.setState({ type: formData.type })
  }

  async populateState (props) {
    const { experienceUid, trackUid } = props.match.params
    this.setState({ loading: true })
    await api.experiences.get(experienceUid)
    await api.segments.getFrom('experiences', experienceUid)
    await api.scenarios.getFrom('experiences', experienceUid)
    await api.maps.getFrom('experiences', experienceUid)
    if (trackUid) {
      let track = await api.tracks.get(trackUid)
      const points = await api.points.getFrom('tracks', trackUid)
      const checks = await checkTrack(track)
      if (track.entities) {
        //esnure entities have a unique id
        track = { ...track }
        track.entities = track.entities.map(e => {
          const { _id, id, localId, ...other } = toJS(e)
          return {
            _id: uid.v4(),
            ...other
          }
        })
      }
      this.setState({ track, points, checks, loading: false })
    } else {
      this.setState({ track: { type: 'normal' }, loading: false })
    }
  }

  componentDidMount () {
    this.populateState(this.props)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.match.params.trackUid !== this.props.match.params.trackUid) {
      this.populateState(nextProps)
    }
  }

  save = async formData => {
    //return Promise.resolve()
    const { experienceUid } = this.props.match.params
    let { points, ...newTrackData } = formData

    newTrackData.experienceId = experienceUid

    let pointType = this.props.pointType
    if (!pointType) {
      switch (newTrackData.type) {
        case 'qrcode_visit':
          pointType = 'qrcode_visit'
          break
        case 'photo_hunt':
          pointType = 'photo_hunt'
          break
        case 'adventure':
          pointType = 'adventure'
          break
        case 'horde':
        case 'chase':
          pointType = 'objective'
          break
        default:
          pointType = 'point'
      }
    }
    let index = 1
    const mapObject = {}
    newTrackData.maps &&
      newTrackData.maps.forEach((map, mapIndex) => {
        mapObject[map.mapUid] = index
        // map.zIndex = index
        index++
      })

    delete newTrackData.northEastBound
    delete newTrackData.southWestBound

    if (newTrackData.type === 'photo_hunt') {
      newTrackData.shufflePoints = !newTrackData.noShufflePoints
      delete newTrackData.noShufflePoints
      newTrackData.allowNavigation = !newTrackData.disallowNavigation
      delete newTrackData.disallowNavigation
      newTrackData.guidanceEnabled = !newTrackData.guidanceDisabled
      delete newTrackData.guidanceDisabled
    } else if (newTrackData.type === 'normal') {
      newTrackData.clusterPoints = !newTrackData.noClusterPoints
      delete newTrackData.noClusterPoints
    }

    newTrackData.maps = mapObject
    newTrackData.routes &&
      newTrackData.routes.forEach(route => {
        route.zIndex = index
        index++
      })
    if (newTrackData.entities) {
      newTrackData.entities.forEach(e => (e._id = '' + (e._id || e.localId)))
    }

    if (newTrackData.locationMarker) {
      if (!newTrackData.locationMarker.resource) {
        newTrackData.locationMarker = null
      } else {
        newTrackData.locationMarker.type = 'icon'
      }
    }
    newTrackData = await createScenarioAction(newTrackData)
    const track = await api.tracks.upsert(newTrackData)
    // update track on the server side , especially the routes
    let currentOrder = this.state.points
      .map(p => p.order || 0)
      .reduce((max, curr) => Math.max(curr, max), 0)
    for (let counter = 0; counter < this.state.points.length; counter++) {
      const oldPoint = this.state.points[counter]
      const newPoint = points.find(m => m.id === oldPoint.id && m.id)
      if (!newPoint) {
        if (oldPoint.id) {
          await api.points.remove(oldPoint.id)
        }
      } else {
        await api.points.update(newPoint.id, {
          id: newPoint.id,
          location: newPoint.location,
          trackId: track.id,
          type: pointType
        })
      }
    }
    const toBeCreated = points
      .filter(p => !p.id)
      .sort((p1, p2) => p1.name - p2.name)

    for (let counter = 0; counter < toBeCreated.length; counter++) {
      currentOrder++
      const point = toBeCreated[counter]
      const scenario = await api.scenarios.create({
        name: point.name + ' après jeu ',
        experienceId: experienceUid
      })
      await api.points.create({
        experienceId: experienceUid,
        trackId: track.id,
        location: point.location,
        name: point.name,
        scenario: scenario._id,
        type: pointType,
        active: true,
        visible: true,
        order: currentOrder
      })
    }
    points = await api.points.getFrom('tracks', track.id)
    const checks = await checkTrack(track)
    this.setState({ points, checks })
    if (!this.props.match.params.trackUid) {
      const url = this.props.match.url + track._id
      this.props.history.push(url)
    }
  }

  duplicate = async () => {
    const { siteUid, experienceUid } = this.props.match.params
    const clone = toJS(this.state.track)
    delete clone._id
    clone.name += ' (copie)'
    clone.entities && clone.entities.forEach(e => (e._id = uid.v4()))
    const trackCreated = await api.tracks.create(clone)
    for (let counter = 0; counter < this.state.points.length; counter++) {
      const clone = toJS(this.state.points[counter])
      delete clone._id
      clone.trackId = trackCreated._id
      await api.points.create(clone)
    }
    const url =
      '/site/' +
      siteUid +
      '/experience/' +
      experienceUid +
      '/track/' +
      trackCreated._id
    this.props.history.push(url)
  }

  delete = async () => {
    const { siteUid, experienceUid, trackUid } = this.props.match.params
    await api.tracks.remove(trackUid)
    const url = '/site/' + siteUid + '/experience/' + experienceUid
    this.props.history.push(url)
  }

  rollBack = async patchId => {
    const { trackUid } = this.props.match.params
    const track = await api.tracks.rollBackToPatch(trackUid, patchId)
    this.setState({ track })
  }

  render () {
    const { track, points, loading, checks, type } = this.state
    const { siteUid, experienceUid, trackUid } = this.props.match.params

    const experience = apiCache.experiences.get(experienceUid)
    const segments = apiCache.segments.getFromExperience(experienceUid)
    const maps = apiCache.maps.getFromExperience(experienceUid)
    const scenarios = apiCache.scenarios.getFromExperience(experienceUid)

    const url = 'tracks/' + (type || track.type)
    let trackMaps = []
    if (track && track.maps) {
      trackMaps = Object.keys(track.maps)
        .map(mapUid => {
          return { mapUid, zIndex: track.maps[mapUid] }
        })
        .sort((a, b) => {
          return a.zIndex - b.zIndex
        })
        .map(m => {
          return { mapUid: m.mapUid }
        })
    }

    const initialFormData = Object.assign(
      {},
      track,
      { points: points.slice() },
      { maps: trackMaps }
    )

    if (track && track.type === 'photo_hunt') {
      initialFormData.noShufflePoints = track.shufflePoints === false
      initialFormData.disallowNavigation = track.allowNavigation === false
      initialFormData.guidanceDisabled = track.guidanceEnabled === false
    } else if (track && track.type === 'normal') {
      initialFormData.noClusterPoints = track.clusterPoints === false
    }

    return (
      <div
        id='app'
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Header {...this.props} />
        <div>
          {trackUid && <NavInTrack />}
          <div id='content'>
            {loading && <Loading />}

            {!loading &&
              checks &&
              checks.errors.map((e, i) => (
                <p className='bg-danger' key={i}>
                  {e.text}
                </p>
              ))}
            {!loading &&
              checks &&
              checks.warnings.map((e, i) => (
                <p className='bg-warning' key={i}>
                  {e.text}
                </p>
              ))}
            {!loading && checks && (
              <p className='bg-success'>
                {checks.nb - checks.warnings.length - checks.errors.length}{' '}
                vérifications réussies
              </p>
            )}
            {!loading && (
              <Form
                formPath={url}
                key={track._id || 'new'}
                experienceUid={experienceUid}
                initialFormData={initialFormData}
                onSubmit={this.save}
                onChange={this.change}
                onDelete={this.delete}
                onDuplicate={this.duplicate}
                formContext={{
                  siteUid,
                  trackUid,
                  experience,
                  segments,
                  track,
                  maps,
                  scenarios
                }}
                listHistory={() => api.tracks.listAvailableRollBack(trackUid)}
                rollBackToPatch={this.rollBack}
              />
            )}
          </div>
          <div id='app-preview' />
        </div>

        <Footer />
      </div>
    )
  }
}
