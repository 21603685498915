import { NavLink } from 'react-router-dom'
import React from 'react'
import { apiCache } from '../../../stores'
import { trackLabel } from '../../../stores'

export default ({ action, experienceUid, siteUid }) => {
  switch (action.type) {
    case 'load_scenario':
      const scenario = apiCache.scenarios.get(action.scenario)
      return (
        <li>
          <NavLink
            to={
              '/site/' +
              siteUid +
              '/experience/' +
              experienceUid +
              '/scenario/' +
              action.scenario
            }
          >
            {scenario && scenario.name}(scenario)
          </NavLink>
        </li>
      )
    case 'load_track':
      const track = apiCache.tracks.get(action.track) || {}
      return (
        <li>
          <NavLink
            to={
              '/site/' +
              siteUid +
              '/experience/' +
              experienceUid +
              '/track/' +
              action.track
            }
          >
            {track.name} ({trackLabel[track.type] || track.type})
          </NavLink>
        </li>
      )
    case 'show_point':
    case 'hide_point':
    case 'enable_point':
    case 'disable_point':
      const point = apiCache.points.get(action.point) || {}
      return (
        <li>
          <NavLink
            to={
              '/site/' +
              siteUid +
              '/experience/' +
              experienceUid +
              '/track/' +
              point.trackId +
              '/point/' +
              point._id
            }
          >
            {action.type} {point.internalName || point.name} (point)
          </NavLink>
        </li>
      )
    case 'play_sound':
      return <li>Lire un son</li>
    case 'start_timer':
      const experience = apiCache.experiences.get(experienceUid) || {}
      const timer = experience.timers[action.timer] || {}
      return (
        <li>
          <NavLink
            to={
              '/site/' +
              siteUid +
              '/experience/' +
              experienceUid +
              '/timer/' +
              timer._id
            }
          >
            {action.type} {timer.internalName || timer.name} (compte à rebours)
          </NavLink>
        </li>
      )

    case 'disable_all_points':
    case 'hide_all_points':
    case 'enable_all_points':
    case 'show_all_points':
    case 'stop_all_timers':
      return <li>{action.type}</li>
    default:
      return <li>{JSON.stringify(action)}</li>
  }
}
