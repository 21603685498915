import React, { Component } from 'react'

import { auth } from '../lib/api'

import JsonSchemaForm from 'react-jsonschema-form'
const schema = {
  type: 'object',
  required: ['email', 'password'],
  properties: {
    email: {
      type: 'string',
      title: 'Adresse email'
    },
    password: {
      type: 'string',
      title: 'Mot de passe'
    }
  }
}

const uiSchema = {
  email: {
    'ui:widget': 'email'
  },
  password: {
    'ui:widget': 'password'
  }
}

class Login extends Component {
  state = {
    email: '',
    password: '',
    isLogging: false,
    error: ''
  }

  componentDidMount() {}
  change = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  login = ({ formData }) => {
    this.setState({ isLogging: true })

    auth
      .login(formData.email, formData.password)
      .then(json => {
        if (!json.success) {
          this.setState({ error: json.msg, isLogging: false })
        }
        // if user is loggued, you shouldn't show this componet
      })
      .catch(e => {
        this.setState({ error: e, isLogging: false })
      })
  }

  render() {
    const { isLogging, error } = this.state
    return (
      <div
        id="app"
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <header>
          <h1>Connexion</h1>
        </header>

        {!!error && <p className="error">{error.msg}</p>}
        <JsonSchemaForm onSubmit={this.login} schema={schema} uiSchema={uiSchema}>
          {!isLogging && <input type="submit" value="Connexion" className="btn btn-primary" />}
          {isLogging && <input type="submit" value="Connexion en cours" disabled className="btn btn-primary" />}
        </JsonSchemaForm>
      </div>
    )
  }
}

export default Login
