import { user } from '../../stores'
/*
  POST  /tiles-service/tiles
{
"options": {
"scheme": "http://{switch:a,b,c}.tile.stamen.com/watercolor/{zoom}/{x}/{y}.png",
"ignoreCache": true,
"zoomMin": 14,
"zoomMax": 14,
"points": [[116.1351914,-32.77541],[116.0704445,-32.81139129]],
"forceRectangle": true,
"neighbors": 0
}
}
{
"options": {
"url": "https://alpha.jade.ateliernature.net/sites/treesure_hunt/-KqvsmfUjEpMD3NkUknT.jpg",
"northEastBound": [116.1351914, -32.77541],
"southWestBound": [116.0704445, -32.8139129],
"zoomMin": 14,
"zoomMax": 14,
"points": [[116.1351914,-32.77541],[116.0704445,-32.81139129]],
"forceRectangle": true,
"neighbors": 0
}
} */

function create(opts) {
  return fetch('https://workshop.explor.games/api/gis-service/tiles', {
    method: 'POST',
    body: JSON.stringify({ options: opts }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  }).then(res => res.json())
}
function simulate(opts) {
  return fetch('https://workshop.explor.games/api/gis-service/simulate', {
    method: 'POST',
    body: JSON.stringify({ options: opts }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  }).then(res => res.json())
}
export const tiles = {
  create,
  simulate
}
