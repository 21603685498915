import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { apiCache, user } from '../../stores'

import * as api from '../../lib/api'
import { trackLabel } from '../../stores'
import { t } from '../../stores/i18n.store'

export default class NavInExperienceStatic extends Component {
  state = {
    tracks: [],
    tracksLoading: false,
    segments: [],
    segmentsLoading: false,
    maps: [],
    mapsLoading: false,
    mapsErrors: '',
    isAdmin: false
  }

  componentDidMount() {
    this.setState({ loading: true })
    const { experienceUid } = this.props.match.params
    api.experiences.get(experienceUid).then(experience => this.setState({ experience }))
    api.tracks.getFromExperience(experienceUid).then(tracks => {
      this.setState({ tracks, tracksLoading: false })
    })
    api.segments.getFromExperience(experienceUid).then(segments => {
      this.setState({ segments, segmentsLoading: false })
    })
    api.maps.getFromExperience(experienceUid).then(maps => {
      this.setState({ maps, mapsLoading: false })
    })

    api.users.amIAnAdmin().then(isAdmin => {
      this.setState({ isAdmin })
    })
    api.users.amIAnExpert().then(isExpert => {
      this.setState({ isExpert })
    })
  }

  render() {
    const { siteUid, experienceUid } = this.props.match.params || {}
    const { isAdmin } = this.state
    if (!experienceUid) {
      return <nav />
    }
    const experience = apiCache.experiences.get(experienceUid) || {}
    let tracks = apiCache.tracks.getFrom('experiences', experienceUid)
    let maps = apiCache.maps.getFrom('experiences', experienceUid)
    let segments = apiCache.segments.getFrom('experiences', experienceUid)

    if (tracks) {
      tracks = tracks
        .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        .map(track => {
          return (
            <li key={track._id}>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/track/' + track._id} key={track._id}>
                {track.name || track._id}
                {' (' + (trackLabel[track.type] || track.type) + ')'}
              </NavLink>
            </li>
          )
        })
    }

    if (maps) {
      maps = maps
        .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        .map(map => {
          return (
            <li key={map._id}>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/map/' + map._id} key={map._id}>
                {map.name || map._id}
              </NavLink>
            </li>
          )
        })
    }

    if (segments) {
      segments = segments.map(segment => {
        return (
          <li key={segment._id}>
            <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/segment/' + segment._id} key={segment._id}>
              {segment.name || segment._id}
            </NavLink>
          </li>
        )
      })
    }

    const { timers } = experience || {}
    if (user.formMode !== 'advanced') {
      return (
        <nav>
          <div className="nav-block">
            <h3>{t('nav-track')}</h3>
            <ul>
              {tracks}
              <li key="add">
                <NavLink exact to={'/site/' + siteUid + '/experience/' + experienceUid + '/track/'}>
                  <i className="fa fa-plus" />
                  {t('nav-add-track')}
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="nav-block">
            <h3>{t('nav-scores')}</h3>
            <ul>
              <li>
                <NavLink exact to={'/site/' + siteUid + '/experience/' + experienceUid + '/moderate'}>
                  {t('nav-moderation')}
                </NavLink>
              </li>
              <li>
                <a
                  href={'https://scores.explor.games/leaderboard/experience/' + experienceUid}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('nav-leaderboard')}
                </a>
              </li>
            </ul>
          </div>

          <div className="nav-block">
            <h3>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/statistics/'}>{t('nav-stats')}</NavLink>
            </h3>
          </div>
        </nav>
      )
    }
    return (
      <nav>
        <div className="nav-block">
          <h3>Experience</h3>
          <ul>
            <li>
              <NavLink exact to={'/site/' + siteUid + '/experience/' + experienceUid}>
                détail
              </NavLink>
            </li>
            <li>
              <NavLink exact to={'/site/' + siteUid + '/experience/' + experienceUid + '/book'}>
                aperçu
              </NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/scenario/list/'}>scenarios</NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/theme/'}>thème</NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/themenewapp/'}>thème nouvelle app</NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/tags'}>tags sur les points</NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/texts/'}>textes</NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/resources/'}>ressources</NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/characters/'}>personnages</NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/categories/'}>catégories</NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/popups/'}>popups</NavLink>
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/graph/'}>visualisation</NavLink>
            </li>
          </ul>
        </div>

        <div className="nav-block">
          <h3>Parcours</h3>
          <ul>
            {tracks}
            <li key="add">
              <NavLink exact to={'/site/' + siteUid + '/experience/' + experienceUid + '/track/'}>
                <i className="fa fa-plus" /> Ajouter un parcours
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="nav-block">
          <h3>Cartographie</h3>
          <ul>
            {maps}
            <li key="add">
              <NavLink exact to={'/site/' + siteUid + '/experience/' + experienceUid + '/map/'}>
                <i className="fa fa-plus" /> Ajouter une carte
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="nav-block">
          <h3>Tracés GPS</h3>
          <ul>
            {segments}
            <li key="add">
              <NavLink exact to={'/site/' + siteUid + '/experience/' + experienceUid + '/segment/'}>
                <i className="fa fa-plus" /> Ajouter un tracé
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="nav-block">
          <h3>Administration</h3>
          <ul>
            {isAdmin && (
              <>
                <li>
                  <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/autorisations/'}>autorisations</NavLink>{' '}
                </li>
                <li>
                  <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/email/'}>Extraction des emails</NavLink>{' '}
                </li>
                <li>
                  <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/rating/'}>Extraction des notes</NavLink>{' '}
                </li>
              </>
            )}
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/deploy/'}>déploiement</NavLink>{' '}
            </li>
            <li>
              <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/trash/'}>corbeille</NavLink>{' '}
            </li>
          </ul>
        </div>
        <div className="nav-block">
          <h3>Comptes à rebours</h3>
          <ul>
            {timers &&
              Object.keys(timers).map(timerId => {
                const timer = timers[timerId]
                return (
                  <li key={timer._id}>
                    <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/timer/' + timer._id}>
                      {timer.name}
                    </NavLink>{' '}
                  </li>
                )
              })}

            <li key="add">
              <NavLink exact to={'/site/' + siteUid + '/experience/' + experienceUid + '/timer/'}>
                <i className="fa fa-plus" /> Ajouter un compte à rebours
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="nav-block">
          <h3>Scores</h3>
          <ul>
            <li>
              <a href={`https://scores.explor.games/admin/moderation/${experienceUid}`} target='_blank rel="noopener noreferrer"'>
                Modération
              </a>
            </li>
            <li>
              <a
                href={'https://scores.explor.games/leaderboard/experience/' + experienceUid}
                target='_blank rel="noopener noreferrer"'
              >
                Leaderboard
              </a>
            </li>
          </ul>
        </div>

        <div className="nav-block">
          <h3>
            <NavLink to={'/site/' + siteUid + '/experience/' + experienceUid + '/statistics/'}>Statistiques</NavLink>
          </h3>
        </div>
      </nav>
    )
  }
}
