import React from 'react'
import BookPoint from './point'
import BookTrack from './track'
import BookModule from './module'
import BookExperience from './experience'
import BookScenario from './scenario'

export default props => {
  const { type, chapter } = props
  const obj = props[type]
  switch (type) {
    case 'experience':
      return <BookExperience {...props} />
    case 'track':
      return <BookTrack {...props} />
    case 'point':
      return <BookPoint {...props} />
    case 'module':
      return <BookModule {...props} />
    case 'scenario':
      return <BookScenario {...props} />
    default:
      return (
        <div>
          <h2>
            Chapitre {chapter}- {obj ? obj.internalName || obj.name || obj.title : JSON.stringify(props)}
          </h2>
          <pre>
            {type} - {obj.type}
          </pre>
        </div>
      )
  }
}
