import * as api from './api'
import { toJS } from 'mobx'
export default async formData => {
  const recursiveCreateScenarioAction = async obj => {
    if (!obj || typeof obj !== 'object') {
      return obj
    }
    if (Array.isArray(obj)) {
      const res = []
      for (let counter = 0; counter < obj.length; counter++) {
        res[counter] = await recursiveCreateScenarioAction(obj[counter])
      }
      return res
    }
    // got a non null object
    if (obj.type === 'load_scenario' && !obj.scenario) {
      const scenario = await api.scenarios.create({
        experienceId: formData.experienceId,
        name: (formData.title || formData.internalName || formData.name || formData.type) + ' - action '
      })
      return {
        ...obj,
        scenario: scenario._id
      }
    }
    const keys = Object.keys(obj)
    const res = {}
    for (let counter = 0; counter < keys.length; counter++) {
      const key = keys[counter]
      res[key] = await recursiveCreateScenarioAction(obj[key])
    }
    return res
  }

  return recursiveCreateScenarioAction(toJS(formData))
}
