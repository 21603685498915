import * as api from '../api'

export default async (action, link) => {
  const checkResult = {
    warnings: [],
    errors: [],
    nb: 0
  }
  switch (action.type) {
    case 'load_scenario':
      checkResult.nb++
      if (!action.scenario) {
        checkResult.errors.push({
          text: 'l\'action de chargement d\'un scenario n\'a pas de scenario',
          link
        })
      } else {
        checkResult.nb++
        const s = await api.scenarios.get(action.scenario)
        if (!s || s.deleted) {
          checkResult.errors.push({
            text: 'l\'action de chargement d\'un scenario a un scenario qui n\'existe pas ou qui a été effacé',
            link
          })
        } else {
          checkResult.nb++
          const modules = await api.modules.getFrom('scenarios', s._id)
          if (modules.length === 0 && (!s.onEnd || s.onEnd.length === 0)) {
            checkResult.warnings.push({
              text: 'l\'action de chargement d\'un scenario appelle un scenario vide',
              link
            })
          }
        }
      }
      break
    case 'load_track':
      checkResult.nb++
      if (!action.track) {
        checkResult.errors.push({
          text: 'l\'action de chargement d\'un parcours n\'a pas de parcours',
          link
        })
      } else {
        checkResult.nb++
        const s = await api.tracks.get(action.track)
        if (!s || s.deleted) {
          checkResult.errors.push({
            text: 'l\'action de chargement d\'un parcours  a un parcours qui n\'existe pas ou qui a été effacé',
            link
          })
        }
      }
      break

    case 'enable_point':
    case 'disable_point':
    case 'hide_point':
    case 'show_point':
      checkResult.nb++
      if (!action.point) {
        checkResult.errors.push({
          text: `l'action ${action.type} n'a pas de point`,
          link
        })
      } else {
        checkResult.nb++
        const s = await api.points.get(action.point)
        if (!s || s.deleted) {
          checkResult.errors.push({
            text: `l'action de chargement ${action.type} à un point qui n'existe pas ou qui a été effacé`,
            link
          })
        }
      }
      break
    default: {
    }
  }
  return checkResult
}
