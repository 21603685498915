export const defaultRows = {
  originalContent: '',
  content: ''
}
export const rowLabels = {
  originalContent:
    "le contenu original, tel qu'il est présent dans cette expérience",
  content: 'Le contenu traduit/corrigé'
}

export const check = async ({ rows, originalTexts, imported }) => {
  return imported
    .filter(line => {
      let originalContent = line[rows.originalContent]
      return !originalTexts.find(
        ({ text }) =>
          text.replace(/\s/g, '') === originalContent.replace(/\s/g, '')
      )
    })
    .map(
      line =>
        `Le texte ${
          line[rows.originalContent]
        } n'est pas présent dans cet expérience`
    )
}

export const save = async ({ rows, originalTexts, imported, onSave }) => {
  const doneIndex = {}
  for (const line of imported) {
    console.log('handle line', line)
    let originalContent = line[rows.originalContent]
    if (doneIndex[originalContent]) {
      continue
    }
    let originalLines = originalTexts.filter(
      ({ text }) =>
        text.replace(/\s/g, '') === originalContent.replace(/\s/g, '')
    )
    console.log('found ', originalLines.length, ' originalLine')
    for (const originalLine of originalLines) {
      await onSave(originalLine, line[rows.content])
    }
    doneIndex[originalContent] = true
  }
}
