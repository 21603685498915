import React from 'react'

export default ({ formData, name, formContext, onChange, schema }) => {
  const { characters } = formContext
  const coptions = (characters || []).map(c => {
    return !c ? null : (
      <optgroup label={c.label} k={c._id}>
        <option value={c._id} key={c._id}>
          {c.label || c._id}
        </option>
        {(c.variants || []).map(({ _id, label }) => {
          return (
            <option value={c._id + '°|°' + _id} key={_id}>
              {label || _id}
            </option>
          )
        })}
      </optgroup>
    )
  })
  return (
    <>
      <label className='control-label' htmlFor={name}>
        {schema.title}
      </label>
      <select
        className='form-control'
        value={formData}
        onChange={e => onChange(e.target.value)}
      >
        <option /> {coptions}
      </select>
      <div />
    </>
  )
}
