import * as api from '../api'
import { toJS } from 'mobx'

export default async point => {
  const checkResult = {
    warnings: [],
    errors: [],
    nb: 0
  }
  const track = await api.tracks.get(point.trackId)
  const xp = await api.experiences.get(point.experienceId)
  const xpLink = '/site/' + xp.siteId + '/experience/' + xp._id
  const link = xpLink + '/track/' + track._id + '/point/' + point._id
  checkResult.nb++
  if (!point.location || point.location.length !== 2 || (!point.location[0] && !point.location[1])) {
    checkResult.errors.push({
      text: `le point ${point.internalName || point.name || point._id}, n'a pas de coordonnées dans le parcours ${track.name}`,
      link,
      _id: point._id
    })
  } else {
    let [latitude, longitude] = point.location

    checkResult.nb++
    if (isNaN((latitude = parseFloat(latitude)))) {
      checkResult.errors.push({
        text: `le point ${point.internalName ||
          point.name ||
          point._id}, a une latitude qui n'est pas un nombre ${latitude} [0, 90]`,
        link,
        _id: point._id
      })
    }
    if (isNaN((longitude = parseFloat(longitude)))) {
      checkResult.errors.push({
        text: `le point ${point.internalName ||
          point.name ||
          point._id}, a une longitude qui n'est pas un nombre ${longitude} [-180, 180]`,
        link,
        _id: point._id
      })
    }
    checkResult.nb++
    if (longitude < -180 || longitude > 180) {
      checkResult.errors.push({
        text: `le point ${point.internalName || point.name || point._id}, a une longitude incorrecte ${latitude} [-180, 180]`,
        link,
        _id: point._id
      })
    }
    checkResult.nb++
    if (latitude < -90 || latitude > 90) {
      checkResult.errors.push({
        text: `le point ${point.internalName || point.name || point._id}, a une latitude incorrecte ${latitude} [-90, 90]`,
        link,
        _id: point._id
      })
    }
  }

  if (track.type === 'horde') {
    if (!point.scenario) {
      checkResult.errors.push({
        text: `l'objectif ${point.internalName || point.name || point._id}, n'a pas de scenario  ${track.name}`,
        link,
        _id: point._id
      })
    } else {
      const scenario = await api.scenarios.get(point.scenario)
      if (!scenario) {
        checkResult.errors.push({
          text: `l'objectif ${point.internalName || point.name || point._id}, a un scenario qui n'existe pas  ${track.name}`,
          link,
          _id: point._id
        })
        checkResult.nb++
      }
      const modules = await api.modules.getFromScenario(scenario._id)
      if ((modules || []).length === 0 && (scenario.onEnd || []).length === 0) {
        checkResult.errors.push({
          text: `l'objectif ${point.internalName ||
            point.name ||
            point._id}, n'a pas de scenario ou un a un scenario vide dans le parcours ${track.name}`,
          link,
          _id: point._id
        })
      }
    }
  }
  checkResult.nb++
  if (track.type === 'photo_hunt') {
    checkResult.nb++
    if (!point.points) {
      checkResult.warnings.push({
        text: `le point ${point.internalName || point.name || point._id} de la course à la photo ${track.name} n'a pas de score`,
        link,
        _id: point._id
      })
    }
    checkResult.nb++
    if (!point.bonus) {
      checkResult.warnings.push({
        text: `le point ${point.internalName || point.name || point._id} de la course à la photo ${
          track.name
        } n'a pas de bonus si l'utilisateur n'utilise pas la boussole`,
        link,
        _id: point._id
      })
    }
  }

  if (point.check && point.check.clue) {
    const scenario = await api.scenarios.get(point.check.clue)
    checkResult.nb++
    if (scenario) {
      const modules = await api.modules.getFromScenario(scenario._id)
      if (scenario.deleted) {
        checkResult.warnings.push({
          text: `le scenario d'indice du point ${point.internalName || point.name || point._id}  est effacé`,
          link: xpLink + '/trash/',
          _id: scenario._id
        })
      }
      if (modules.length === 0 && (scenario.onEnd || []).length === 0) {
        checkResult.warnings.push({
          text: `le scenario d'indice du point ${point.internalName || point.name || point._id}  est vide`,
          link: xpLink + '/scenario/' + scenario._id,
          _id: scenario._id
        })
      }
      modules.forEach((m, index) => {
        checkResult.nb++
        if (!m.canGoBack) {
          checkResult.errors.push({
            text: `dans le scenario d'indice du point ${point.internalName || point.name || point._id}, le module ${m.name ||
              m.title ||
              index}  n'autorise pas le retour en arrière`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      })
    } else {
      checkResult.warnings.push({
        text: `le scenario d'indice du point ${point.internalName || point.name || point._id} n'existe pas`,
        link,
        _id: point._id
      })
    }
  }
  if (['code', 'qrcode'].includes(point?.check?.type) && !point?.check?.code) {
    checkResult.warnings.push({
      text: `le point "${point.internalName || point.name || point._id}" n'a pas de code de validation`,
      link: link + '/check/',
      _id: point._id
    })
  }
  if (['location'].includes(point?.check?.type) && !point?.check?.distance) {
    checkResult.warnings.push({
      text: `le point "${point.internalName || point.name || point._id}" n'a pas de distance de validation`,
      link: link + '/check/',
      _id: point._id
    })
  }
  if (['image'].includes(point?.check?.type) && !point?.check?.target) {
    checkResult.warnings.push({
      text: `le point "${point.internalName || point.name || point._id}" n'a pas d'image cible pour la validation en RA`,
      link: link + '/check/',
      _id: point._id
    })
  }
  if (point.check && point.check.type && (point.check.code === 'demo' || point.check.code === 'démo')) {
    checkResult.warnings.push({
      text: `le point ${point.internalName || point.name || point._id}, accepte demo en validation`,
      link: link + '/check/',
      _id: point._id
    })
  }
  checkResult.nb++

  if (point.check && point.check.scenario) {
    const scenario = await api.scenarios.get(point.check.scenario)
    checkResult.nb++
    if (!scenario) {
      checkResult.warnings.push({
        text: `le scenario de jeu du point  ${point.internalName || point.name || point._id}, n'existe pas ou plus`,
        link: link + '/check/',
        _id: point._id
      })
    } else {
      if (scenario.deleted) {
        checkResult.warnings.push({
          text: `le scenario de jeu du point  ${point.internalName || point.name || point._id}  est effacé`,
          link: xpLink + '/trash/',
          _id: scenario._id
        })
      }
      checkResult.nb++
      const modules = toJS(await api.modules.getFromScenario(scenario._id)).sort((m1, m2) => m1.order - m2.order)
      if (modules.length === 0 && (!scenario.onEnd || scenario.onEnd.length === 0)) {
        checkResult.warnings.push({
          text: `le scenario ${scenario.name} de jeu du point  ${point.internalName || point.name || point._id}, est vide`,
          link: link + '/scenario/' + scenario._id,
          _id: point._id
        })
      } else {
        checkResult.nb++
        if (modules.length > 0 && modules[0].canGoBack) {
          checkResult.errors.push({
            text: `le premier module du scenario de jeu du point  ${point.internalName ||
              point.name ||
              point._id}, accepte le retour en arrière`,
            link: link + '/scenario/' + scenario._id + '/module/' + modules[0]._id,
            _id: point._id
          })
        }
      }
    }
  }
  if (point.scenario) {
    checkResult.nb++
    const scenario = await api.scenarios.get(point.scenario)
    if (!scenario) {
      checkResult.warnings.push({
        text: `le scenario après jeu du point  ${point.internalName || point.name || point._id}, n'existe pas ou plus`,
        link: link + '/check/',
        _id: point._id
      })
    } else {
      if (scenario.deleted) {
        checkResult.warnings.push({
          text: `le scenario après jeu du point  ${point.internalName || point.name || point._id}  est effacé`,
          link: xpLink + '/trash/',
          _id: scenario._id
        })
      }
    }
  }
  /* if (
    point.triggers &&
    point.triggers.find(t => t.type === 'manual') &&
    point.triggers.find(t => t.type === 'location') &&
    point.check && point.check.type
  ) {
    checkResult.warnings.push({
      text: `le point ${point.internalName ||
        point.name ||
        point._id}, accepte un déclenchement par localisation et un déclenchement manuel`,
      link,
      _id: point._id
    });
  } */

  return checkResult
}
