import { observer } from 'mobx-react'
import React, { Component, PureComponent } from 'react'
import ResourceUploadProgress from './uploadprogress'
import * as api from '../../../lib/api'

class Other extends PureComponent {
  state = {
    errored: false,
    percentCrop: null
  };

  render () {
    const { src, name, uploadProgress, remove } = this.props
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1, position: 'relative', height: '100%' }}>
            <ResourceUploadProgress progress={uploadProgress} />

            <h4
              style={{ background: 'rgba(255,255,255,0.75)', margin: '0.5em' }}
            >
              {name || 'fichier'}
            </h4>
          </div>

          <div
            style={{ width: '40px', display: 'flex', flexDirection: 'column' }}
          >
            <a
              className='btn btn-block btn-default'
              href={src}
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='fa fa-download' />
            </a>

            <div style={{ flex: 1 }} />
            {remove && (
              <span
                className='btn btn-block btn-danger'
                onClick={remove}
                style={{ marginTop: '1em' }}
              >
                <i className='fa fa-trash-o' />
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
}

class ResourceOther extends Component {
  render () {
    const { resource } = this.props
    if (!resource) {
      return <span className='bg-danger'>Resource doesn't exists</span>
    }

    return (
      <Other
        uploadProgress={resource.progress}
        src={api.resources.getUrl(resource.original.file)}
        name={resource.name}
        remove={e => {
          this.props.onChange('')
        }}
      />
    )
  }
}

const MobxResourceOther = observer(ResourceOther)
export default MobxResourceOther
