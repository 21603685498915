import { Link } from 'react-router-dom'
import React from 'react'
import { toJS } from 'mobx'
import { apiCache } from '../../../stores'
import recursiveContain from './recursivecontains'

export default ({ scenario, experienceUid, siteUid }) => {
  let usages = null;
  ['experiences', 'tracks', 'points', 'modules', 'scenarios'].forEach(
    storeName => {
      let usagesInStore
      if (storeName === 'experiences') {
        const xp = apiCache.experiences.get(experienceUid)
        if (xp && recursiveContain(toJS(xp), scenario.id)) {
          usagesInStore = [xp]
        }
      } else {
        usagesInStore =
          apiCache[storeName] &&
          (apiCache[storeName].getFromExperience(experienceUid) || [])
            .filter(o => recursiveContain(toJS(o), scenario.id))
            .filter(o => o.id !== scenario.id && o.scenarioId !== scenario.id)
      }
      if (usagesInStore && usagesInStore.length > 0) {
        usages = (
          <ul>
            {usagesInStore.map(usage => {
              let link
              switch (storeName) {
                case 'experiences':
                  link = '/site/' + siteUid + '/experience/' + usage._id
                  break
                case 'tracks':
                  link =
                    '/site/' +
                    siteUid +
                    '/experience/' +
                    usage.experienceId +
                    '/track/' +
                    usage._id
                  break
                case 'points':
                  link =
                    '/site/' +
                    siteUid +
                    '/experience/' +
                    usage.experienceId +
                    '/track/' +
                    usage.trackId +
                    '/point/' +
                    usage._id
                  break
                case 'modules':
                  link =
                    '/site/' +
                    siteUid +
                    '/experience/' +
                    usage.experienceId +
                    '/scenario/' +
                    usage.scenarioId +
                    '/module/' +
                    usage._id
                  break
                case 'scenarios':
                  link =
                    '/site/' +
                    siteUid +
                    '/experience/' +
                    usage.experienceId +
                    '/scenario/' +
                    usage._id
                  break
                default:
                  link = ''
                  break
              }
              return (
                <li key={usage._id}>
                  <Link to={link}>
                    {usage.name || usage.title || usage._id} ({storeName})
                  </Link>
                </li>
              )
            })}
          </ul>
        )
      }
    }
  )
  return usages
}
