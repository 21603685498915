import React, { Component } from 'react'

import BaseMap from './map'
import L from 'leaflet'
import { Marker, FeatureGroup, Popup } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import uuid from 'uuid'
import * as api from '../../../lib/api'

class Markers extends Component {
  state = {}
  icons = {}

  constructor(props) {
    super(props)
    this.refFeatures = React.createRef()
  }

  autozoom(points) {
    let north = -90

    let south = 90

    let east = -180

    let west = 180
    if (!points || points.length === 0) {
      return
    }
    points.forEach(point => {
      if (!point.location || !Array.isArray(point.location) || !point.location[0] || !point.location[1]) {
        return
      }
      north = Math.max(north, point.location[0])
      south = Math.min(south, point.location[0])
      east = Math.max(east, point.location[1])
      west = Math.min(west, point.location[1])
    })
    // this.mapRef && this.mapRef.fitBounds({ north, south, east, west })
  }

  async populate(props) {
    this.autozoom(props.formData || [])
    this.setState({ loadingIcons: true })
    for (const point of props.formData || []) {
      const resourceId =
        (point.markers && point.markers.normal && point.markers.normal.resource) || (point.marker && point.marker.resource)

      if (!this.icons[point._id || point.localId] && resourceId) {
        // icon is deformed, check ratio
        const resource = await api.resources.get(resourceId)
        if (resource) {
          this.icons[point._id || point.localId] = {
            url: api.resources.getUrl(resource.hd ? resource.hd.file : resource.original.file),
            scaledSize: {
              width: 33,
              height: 39
            }
          }
        } else {
        }
      } else {
      }
    }
    this.setState({ loadingIcons: false })
  }

  componentDidMount() {
    this.populate(this.props)
  }

  componentWillReceiveProps(newProps) {
    this.populate(newProps)
  }

  change = () => {
    if (!this.refFeatures || !this.refFeatures.current || !this.props.onChange) {
      return
    }
    const precPoints = this.props.formData || []
    const points = []
    let pointIndex = precPoints.length
    // update position

    this.refFeatures.current.leafletElement.getLayers().forEach(layer => {
      var { lat, lng } = layer.getLatLng()
      const layerId = layer.options.id
      const precPointPos = precPoints.findIndex(
        ({ _id, id, localId }) => id === layerId || localId === layerId || _id === layerId
      )
      if (layerId && precPointPos >= 0) {
        const precPoint = precPoints[precPointPos]
        precPoint.location = [lat, lng]
        points.push(precPoint)
      } else {
        // new point
        this.refFeatures.current.leafletElement.removeLayer(layer)
        points.push({
          name: 'point ' + pointIndex++,
          location: [lat, lng],
          localId: uuid.v4()
        })
      }
    })
    this.props.onChange(points)
  }

  render() {
    const points = this.props.formData || []
    const center = {
      lat: 0,
      lng: 0
    }
    let validLocations = 0
    points &&
      points.forEach(point => {
        if (!point.location || !point.location[0] || !point.location[1]) {
          return
        }
        validLocations++
        center.lat += point.location[0]
        center.lng += point.location[1]
      })
    if (validLocations) {
      center.lat /= validLocations
      center.lng /= validLocations
    } else {
      center.lat = 46.3796
      center.lng = 4.7436
    }
    return (
      <BaseMap formContext={this.props.formContext} center={center} style={{ flex: 1 }}>
        <FeatureGroup ref={this.refFeatures}>
          <EditControl
            draw={{
              rectangle: false,
              polyline: false,
              polygon: false,
              circle: false,
              circlemarker: false,
              marker: { repeatMode: true }
            }}
            onEdited={this.change}
            onCreated={this.change}
            onDeleted={this.change}
          />
          {points.map(point => {
            if (!point.location || !point.location[0] || !point.location[1]) {
              return null
            }
            const location = {
              lat: point.location[0],
              lng: point.location[1]
            }
            const { url: iconUrl, scaledSize } = this.icons[point._id] || {}
            const icon = iconUrl
              ? L.icon({
                  iconUrl,
                  iconSize: [scaledSize.width, scaledSize.height],
                  iconAnchor: [scaledSize.width / 2, scaledSize.height],
                  popupAnchor: [0, -scaledSize.height + 2]
                })
              : new L.Icon.Default()
            return (
              <Marker
                key={point.id || point._id || point.localId}
                id={point.id || point._id || point.localId}
                position={location}
                icon={icon}
                title={point.internalName || point.name}
              >
                <Popup>
                  {point.internalName}
                  <br />
                  {point.name}
                </Popup>
              </Marker>
            )
          })}
        </FeatureGroup>
      </BaseMap>
    )
  }
}

export default props => {
  const mapContainerStyle = props.mapContainerStyle || {}
  return (
    <div
      className="form-group field "
      style={{
        minHeight: '400px',
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        ...mapContainerStyle
      }}
    >
      <label className="control-label" htmlFor={props.name}>
        {props.schema.title || props.name}
      </label>
      <Markers {...props} />
    </div>
  )
}
