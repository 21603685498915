import React, { Component } from 'react'

import { observer } from 'mobx-react'
import { notifications } from '../stores'

class Notification extends Component {
  render () {
    const n = notifications.get(this.props.id)
    let className = 'alert alert-' + (n.type || 'info')
    if (n.animateDeleting) {
      className += ' deleting'
    }
    return (
      <div
        className={className}
        onClick={() => {
          n.animateDeleting = true
          setTimeout(() => {
            notifications.delete(n.id)
          }, 1000)
        }}
        role='alert'
      >
        <h3>{n.title}</h3>
        <p>{n.content}</p>
      </div>
    )
  }
}

const MobxNotification = observer(Notification)
class Notifications extends Component {
  render () {
    return (
      <div id='notifications-center'>
        {notifications.values().map(n => (
          <MobxNotification id={n.id} key={n.id} />
        ))}
      </div>
    )
  }
}

const MobxNotifications = observer(Notifications)

export default MobxNotifications
