import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'bootstrap3/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import './style.css'

document.body.className =
  window.location.hostname.indexOf('beta') >= 0 ||
  window.location.hostname.indexOf('localhost') >= 0
    ? 'beta'
    : ''

document.body.className =
  window.location.hostname.indexOf('localhost') >= 0 ||
  window.location.hostname.indexOf('localhost') >= 0
    ? 'localhost'
    : document.body.className

ReactDOM.render(<App />, document.getElementById('root'))
