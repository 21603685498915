import React from 'react'
import { Link } from 'react-router-dom'

export default props => {
  const { tracks, siteUid, experienceUid } = props.formContext

  const trackOptions = (tracks || []).map(track => {
    return (
      <option value={track._id} key={track._id}>
        {track.name || track._id}
      </option>
    )
  })
  return (
    <div className='form-group field field-string'>
      <label className='control-label' htmlFor={props.name}>
        {props.schema.title}
      </label>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <select
          style={{
            flex: 1
          }}
          className='form-control'
          value={props.formData}
          onChange={e => props.onChange(e.target.value)}
        >
          <option /> {trackOptions}
        </select>
        {props.formData && (
          <Link
            style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
            to={
              '/site/' +
              siteUid +
              '/experience/' +
              experienceUid +
              '/track/' +
              props.formData
            }
            className='btn btn-default'
          >
            Voir
          </Link>
        )}
      </div>
    </div>
  )
}
