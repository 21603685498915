import React, { Component } from 'react'
import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'
import { apiCache } from '../../stores'
import Loading from '../../components/loading'
import { Page } from '../'

class ExperienceTrash extends Component {
  state = {
    loading: true,
    collections: [
      'points',
      'tracks',
      'modules',
      'scenarios',
      'maps',
      'segments'
    ]
  };

  populateState (experienceUid) {
    this.setState({ loading: true })
    const promises = []
    const usersIds = []
    this.state.collections.forEach(collectionName => {
      promises.push(
        api[collectionName]
          .getFromExperience(experienceUid, true)
          .then(collection => {
            const deleted = collection.filter(p => p.deleted)
            deleted.forEach(d => {
              if (usersIds.indexOf(d.updatedBy) < 0) {
                usersIds.push(d.updatedBy)
              }
            })
            return this.setState({
              [collectionName]: deleted
            })
          })
      )
    })
    Promise.all(promises)
      .then(() => {
        const promises = usersIds.map(userId =>
          api.users.get(userId).catch(e => {
            return true
          })
        )
        return Promise.all(promises)
      })
      .then(() => {
        console.log('success')

        this.setState({ loading: false })
      })
      .catch(e => {
        console.log('done')
      })
  }

  componentDidMount () {
    this.populateState(this.props.match.params.experienceUid)
  }

  restore = (collectionName, item) => {
    const { siteUid, experienceUid } = this.props.match.params
    const baseUrl = '/site/' + siteUid + '/experience/' + experienceUid
    api[collectionName].update(item.id, { deleted: false }).then(() => {
      switch (collectionName) {
        case 'points':
          this.props.history.push(
            baseUrl + '/track/' + item.trackId + '/point/' + item.id
          )
          break
        case 'tracks':
          this.props.history.push(baseUrl + '/track/' + item.id)
          break
        case 'scenarios':
          this.props.history.push(baseUrl + '/scenario/' + item.id)
          break
        case 'modules':
          this.props.history.push(
            baseUrl + '/scenario/' + item.scenarioId + '/module/' + item.id
          )
          break
        case 'maps':
          this.props.history.push(baseUrl + '/map/' + item.id)
          break
        case 'segments':
          this.props.history.push(baseUrl + '/segment/' + item.id)
          break

        default:
          throw new Error('type unknown')
      }
    })
  };

  render () {
    const { loading } = this.state
    const { experienceUid } = this.props.match.params
    return (
      <Page>
        <NavInExperience />
        <div id='content'>
          <h1>Corbeille</h1>
          {loading && <Loading />}
          {!loading && (
            <div>
              {this.state.collections.map(collectionName => {
                const deleted = apiCache[collectionName]
                  .getFromExperience(experienceUid, true)
                  .filter(p => p.deleted)
                if (!deleted.length) {
                  return null
                }
                return (
                  <div key={collectionName}>
                    <h2>{collectionName}</h2>
                    <table>
                      <thead>
                        <tr>
                          <th>Nom</th>
                          <th>Type</th>
                          <th>effacé le</th>
                          <th>effacé par</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {deleted.map(item => {
                          const user = apiCache.users.get(item.updatedBy)
                          return (
                            <tr key={item.id}>
                              <td>{item.name || item.title}</td>
                              <td>{item.type}</td>
                              <td>{item.updatedAt}</td>
                              <td>
                                {user
                                  ? user.firstname || user.email
                                  : item.updatedBy}
                              </td>
                              <td>
                                <span
                                  className='btn btn-primary'
                                  onClick={() =>
                                    this.restore(collectionName, item)}
                                >
                                  Restaurer
                                </span>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default ExperienceTrash
