import React, { Component } from 'react'
import ExperiencePicker from './experiencepicker'
import SitePicker from './sitepicker'
import BundlePIcker from './bundlepicker'

const LevelChooser = props => {
  const { title, value, onChange } = props

  const availableTypes = [
    {
      value: 'admin',
      label: 'Administrateur'
    },
    {
      value: 'editor',
      label: 'Editeur'
    },
    {
      value: 'viewer',
      label: 'Lecteur'
    },
    {
      value: 'owner',
      label: 'Créateur'
    }
  ]
  return (
    <div className='form-group field field-string'>
      <label className='control-label'>{title}</label>

      <select
        className='form-control'
        value={value}
        onChange={e => {
          onChange(e.target.value)
        }}
      >
        <option />
        {availableTypes.map(at => {
          return (
            <option value={at.value} key={at.value}>
              {at.label}
            </option>
          )
        })}
      </select>
    </div>
  )
}
const TypeChooser = props => {
  const { title, value, onChange } = props
  const availableLevels = [
    {
      value: 'sites',
      label: 'Site'
    },
    {
      value: 'experiences',
      label: 'Expérience'
    },
    {
      value: 'bundles',
      label: "Groupe d'expérience"
    }
  ]
  return (
    <div className='form-group field field-string'>
      <label className='control-label'>{title}</label>

      <select
        className='form-control'
        value={value}
        onChange={e => {
          onChange(e.target.value)
        }}
      >
        <option value='' />
        {availableLevels.map(al => {
          return (
            <option value={al.value} key={al.value}>
              {al.label}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default class Scope extends Component {
  change = e => {
    // this.props.onChange(e.target.value)
  };

  changeLevel = newLevel => {
    // eslint-disable-next-line
    let type, id, level;
    if (newLevel === 'admin') {
      this.props.onChange('admin')
    } else {
      [type, id, level] = (this.props.formData || '').split('/')
      this.props.onChange(type + '/' + id + '/' + newLevel)
    }
    // this.props.onChange(e.target.value)
  };

  changeType = newType => {
    // eslint-disable-next-line
    let type, id, level;
    if (this.props.formData === 'admin') {
      this.props.onChange('admin')
    } else {
      [type, id, level] = (this.props.formData || '').split('/')
      this.props.onChange(newType + '/' + id + '/' + level)
    }
  };

  changeId = newId => {
    // eslint-disable-next-line
    let type, id, level;
    if (this.props.formData === 'admin') {
      this.props.onChange('admin')
    } else {
      [type, id, level] = (this.props.formData || '').split('/')
      this.props.onChange(type + '/' + newId + '/' + level)
    }
  };

  render () {
    let type, id, level
    if (this.props.formData === 'admin') {
      level = 'admin'
    } else {
      [type, id, level] = (this.props.formData || '').split('/')
    }

    return (
      <div className='form-group field field-string'>
        <label className='control-label' htmlFor={this.props.name}>
          {this.props.schema.title}
        </label>
        <LevelChooser
          title='Niveau'
          value={level}
          onChange={this.changeLevel}
        />
        {level && level !== 'admin' && (
          <div>
            <TypeChooser title='Type' value={type} onChange={this.changeType} />
            {type === 'sites' && (
              <SitePicker
                formContext={this.props.formContext}
                schema={{ title: 'Site' }}
                formData={id}
                onChange={this.changeId}
              />
            )}
            {type === 'experiences' && (
              <ExperiencePicker
                formContext={this.props.formContext}
                schema={{ title: 'Expérience' }}
                formData={id}
                onChange={this.changeId}
                showSite
              />
            )}
            {type === 'bundles' && (
              <BundlePIcker
                formContext={this.props.formContext}
                schema={{ title: "Groupe d'expérience" }}
                formData={id}
                onChange={this.changeId}
                showSite
              />
            )}
          </div>
        )}
      </div>
    )
  }
}
