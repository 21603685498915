import generify from './generic'

export const scenarios = generify(
  'experiences-service',
  'scenarios',
  'scenario'
)

scenarios.getFromExperience = (experienceUid, showDeleted) => {
  return scenarios.getFrom('experiences', experienceUid, showDeleted)
}
