import { Link, NavLink } from 'react-router-dom'
import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { NavInExperience } from '../../../components/nav'

import * as api from '../../../lib/api'
import { apiCache } from '../../../stores'
import Loading from '../../../components/loading'
import { Page } from '../..'
import ScenarioUsages from './usages'
import ScenarioContentSummary from './scenario'

class List extends Component {
  state = {
    loading: true
  };

  componentDidMount () {
    const { experienceUid } = this.props.match.params

    this.setState({ loading: true })
    const promises = []

    let promise = api.experiences.get(experienceUid)
    promises.push(promise)

    promise = api.scenarios.getFromExperience(experienceUid)
    promises.push(promise)

    promise = api.tracks.getFromExperience(experienceUid)
    promises.push(promise)
    promise = api.points.getFromExperience(experienceUid)
    promises.push(promise)
    promise = api.modules.getFromExperience(experienceUid)
    promises.push(promise)
    Promise.all(promises).then(() => {
      this.setState({ loading: false })
    })
  }

  render () {
    const match = this.props.match
    const { experienceUid, siteUid } = match.params || {}
    const { loading } = this.state
    const scenarios = loading
      ? []
      : apiCache.scenarios.getFromExperience(experienceUid)
    const s = scenarios
      .sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
      .map(scenario => {
        return (
          <tr key={scenario._id}>
            <td>
              <Link
                to={
                  '/site/' +
                  siteUid +
                  '/experience/' +
                  experienceUid +
                  '/scenario/' +
                  scenario._id
                }
              >
                {scenario.name || scenario._id}
              </Link>
            </td>
            <td>
              <ScenarioContentSummary
                scenario={scenario}
                siteUid={siteUid}
                experienceUid={experienceUid}
              />
            </td>
            <td>
              <ScenarioUsages
                scenario={scenario}
                siteUid={siteUid}
                experienceUid={experienceUid}
              />
            </td>
          </tr>
        )
      })
    return (
      <Page>
        <NavInExperience />{' '}
        {loading && (
          <div id='content'>
            {' '}
            <Loading />
          </div>
        )}
        {!loading && (
          <div id='content'>
            <NavLink
              exact
              to={
                '/site/' +
                siteUid +
                '/experience/' +
                experienceUid +
              '/scenario/'
              }
              className='btn btn-primary btn-block'
            >
              Créer un scenario
            </NavLink>
            <table className='table'>
              <thead>
                <tr>
                  <th>Scenario</th>
                  <th>Modules</th>
                  <th>Usage</th>
                </tr>
              </thead>
              <tbody>{s}</tbody>
            </table>
          </div>
        )}
        <div id='app-preview' />
      </Page>
    )
  }
}

export default observer(List)
