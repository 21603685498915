import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { NavInExperience } from '../components/nav'
import { Form } from '../components/form'
import Loading from '../components/loading'
import * as api from '../lib/api'
import { apiCache } from '../stores'
import { Page } from './'

class Segment extends Component {
  async populateState (experienceUid, segmentUid) {
    await api.experiences.get(experienceUid)
    await api.maps.getFrom('experiences', experienceUid)
    if (segmentUid) {
      await api.segments.get(segmentUid)
    }
  }

  componentDidMount () {
    const { segmentUid, experienceUid } = this.props.match.params
    this.populateState(experienceUid, segmentUid)
  }

  componentWillReceiveProps (newProps) {
    const { segmentUid, experienceUid } = newProps.match.params
    const oldSegmentUid = this.props.match.params.segmentUid
    if (segmentUid !== oldSegmentUid) {
      this.populateState(experienceUid, segmentUid)
    }
  }

  delete = async () => {
    const { siteUid, experienceUid, segmentUid } = this.props.match.params
    await api.segments.remove(segmentUid)
    const url = '/site/' + siteUid + '/experience/' + experienceUid
    this.props.history.push(url)
  };

  save = async formData => {
    formData.experienceId = this.props.match.params.experienceUid
    const segment = await api.segments.upsert(formData)
    const url =
      this.props.match.url +
      (this.props.match.params.segmentUid ? '' : segment._id)
    this.props.history.push(url)
  };

  duplicate = () => {
    const { siteUid, experienceUid } = this.props.match.params
    const clone = toJS(this.state.segment)
    delete clone._id
    clone.name = clone.name + ' (copie)'
    api.segments.create(clone).then(segmentCreated => {
      const url =
        '/site/' +
        siteUid +
        '/experience/' +
        experienceUid +
        '/segment/' +
        segmentCreated._id
      this.props.history.push(url)
    })
  };

  render () {
    const { experienceUid, segmentUid } = this.props.match.params
    const segment = apiCache.segments.get(segmentUid) || {}
    const experience = apiCache.experiences.get(experienceUid)
    const maps = apiCache.maps.getFrom('experiences', experienceUid)
    const loading = segmentUid && !segment
    return (
      <Page>
        <NavInExperience />
        <div id='content'>
          {loading && <Loading />}
          {!loading && (
            <Form
              key={segment.id || 'new'}
              formPath='segment'
              onSubmit={this.save}
              onDelete={this.delete}
              onDuplicate={this.duplicate}
              initialFormData={segment}
              experienceUid={experienceUid}
              listHistory={() => api.segments.listAvailableRollBack(segmentUid)}
              rollBackToPatch={this.rollBack}
              formContext={{ experience, maps }}
            />
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default observer(Segment)
