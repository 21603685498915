import React, { Component } from 'react'

import { Page } from '../'
import * as api from '../../lib/api'

import Loading from '../../components/loading'
import { Form } from '../../components/form'
import { NavInSites } from '../../components/nav'

export default class User extends Component {
  state = {
    loading: false,
    user: {}
  };

  populateState (props) {
    const userUid = props.match.params.userUid
    let promise

    const promises = []
    this.setState({ loading: true })
    promise = api.sites.list().then(sites => this.setState({ sites }))
    promises.push(promise)
    promise = api.experiences
      .list()
      .then(experiences => this.setState({ experiences }))
    promises.push(promise)
    promise = api.bundles.list().then(bundles => this.setState({ bundles }))
    promises.push(promise)

    if (userUid) {
      promise = api.users.get(userUid).then(user => this.setState({ user }))
      promises.push(promise)
    }
    Promise.all(promises).then(() => this.setState({ loading: false }))
  }

  componentDidMount () {
    this.populateState(this.props)
  }

  componentWillReceiveProps (nextProps) {
    this.populateState(nextProps)
  }

  save = newUser => {
    const { scopes, email, password, ...newUserOther } = newUser

    if (password) {
      newUserOther.password = password
      newUserOther.email = email
    }

    return api.users.upsert(newUserOther).then(user => {
      const promises = []
      scopes.forEach(scope => {
        if (!user.scopes.find(s => s === scope)) {
          promises.push(api.scopes.add(user._id, scope))
        }
      })
      user.scopes.forEach(scope => {
        if (!scopes.find(s => s === scope)) {
          promises.push(api.scopes.remove(user._id, scope))
        }
      })
      return Promise.all(promises)
    })
  };

  render () {
    const { user, loading, experiences, sites, bundles } = this.state

    return (
      <Page>
        <NavInSites />
        <div id='content'>
          {loading && <Loading />}
          {!loading && (
            <Form
              formPath='user'
              onSubmit={this.save}
              initialFormData={user}
              key={user._id || 'new'}
              formContext={{ experiences, sites, bundles }}
            />
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}
