import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Modal } from '../../../components/modal'
import save from './save'
import checkPath from './lib/checkPath'

export default class extends React.Component {
  state = {
    isOpen: false,
    value: this.props.text || ''
  };

  save = async e => {
    e.preventDefault()
    const { collection, path, id } = this.props
    const { value } = this.state
    console.log('will save ', this.state, this.props)
    await save({ collection, path, id, value })
    this.setState({ isOpen: false })
  };

  render () {
    const { collection, text, path, id, style, highlights } = this.props
    const { isOpen, value } = this.state
    if (!text) {
      return null
    }
    const className =
      highlights && path && checkPath(highlights, path) ? 'highlighted' : ''
    return (
      <div className={'booktext ' + className} style={{ ...style }}>
        {collection && path && id && (
          <a
            href='#'
            className='edit'
            onClick={e => {
              e.preventDefault()
              this.setState({ isOpen: true })
            }}
            title='modifier ce texte'
          >
            <i className='fa fa-pencil fa-fw' />
          </a>
        )}
        <ReactMarkdown source={text || ''} />
        <Modal
          isOpen={isOpen}
          close={() => {
            this.setState({ isOpen: false, value: this.props.text })
          }}
          height='40vh'
        >
          <form
            onSubmit={this.save}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              {text && text.length > 0 && (
                <div style={{ flex: 1, margin: '1em' }}>
                  <label>Aperçu du texte d'origine </label>{' '}
                  <ReactMarkdown source={text || ''} />
                </div>
              )}
              <div style={{ flex: 1 }}>
                <label>Formulaire </label>
                <textarea
                  value={value}
                  onChange={e => this.setState({ value: e.target.value })}
                  className='form-control'
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  margin: '1em',
                  opacity: value !== text ? 1.0 : 0,
                  transition: 'opacity 0.5s ease'
                }}
              >
                <label>Aperçu du nouveau texte </label>
                <ReactMarkdown source={value || ''} />
              </div>
            </div>
            <input
              type='submit'
              className='btn btn-primary'
              style={{ position: 'auto' }}
            />
          </form>
        </Modal>
      </div>
    )
  }
}
