import React from 'react'

export default props => {
  const experience = props.formContext.experience || {}

  const options = (experience.variables || []).map((variable, index) => {
    return (
      <option value={'experience.' + variable} key={index}>
        {variable}
      </option>
    )
  })

  return (
    <>
      <label className='control-label' htmlFor={props.name}>
        {props.schema.title}
      </label>
      <select
        className='form-control'
        value={props.formData}
        onChange={e => props.onChange(e.target.value)}
      >
        <option />
        <option value='module.scorePercent'>module.scorePercent</option>
        <option value='module.score'>module.score</option>
        <option value='scenario.scorePercent'>scenario.scorePercent</option>
        <option value='scenario.score'>scenario.score</option>
        <option value='track.scorePercent'>track.scorePercent</option>
        <option value='track.score'>track.score</option>
        <option value='experience.scorePercent'>experience.scorePercent</option>
        <option value='experience.score'>experience.score</option>
        {options}
      </select>
    </>
  )
}
