import * as stores from '../../stores'
import { users } from './users'
function login(email, password) {
  return fetch(stores.conf.API_URL + '/users-service/auth/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  })
    .then(res => res.text())
    .then(t => {
      return JSON.parse(t) // response.json()
    })
    .then(json => {
      json.success = !!json.token
      if (json.success) {
        stores.user.token = json.token
        stores.user.isLoggued = true
        users.me().then(json => {})
      }
      return json
    })
}

function logout() {
  return fetch(stores.conf.API_URL + '/users-service/auth/logout', {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + stores.user.token,
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json)
    .then(json => {
      stores.user.token = null
      stores.user.isLoggued = false
      stores.apiCache.clear()
      window.__swRegistration &&
        window.__swRegistration.unregister().then(function(boolean) {
          document.location.reload(true)
        })
    })
    .catch(() => {
      stores.user.token = null
      stores.user.isLoggued = false
      stores.apiCache.clear()
    })
}

export const auth = {
  login,
  logout
}
