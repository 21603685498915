function recursiveContain (object, target, debug) {
  if (!object) {
    debug && console.log('nullish')
    return false
  }
  if (typeof object === 'object' || Array.isArray(object)) {
    for (var key in object) {
      debug && console.log('key ', key, object[key])
      if (recursiveContain(object[key], target)) {
        return true
      }
    }
    return false
  }
  debug && console.log('scalar ', object, target)
  return object === target
}
export default recursiveContain
