import React, { Component } from 'react'
import { observer } from 'mobx-react'
// import { toJS } from "mobx";
import ReactCrop, { makeAspectCrop } from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'

import { Modal } from '../../modal'
import ResourceUploadProgress from './uploadprogress'
import { resources as resourcesApi } from '../../../lib/api'

class Image extends Component {
  state = {
    loaded: false,
    errored: false,
    modalOpen: false,
    percentCrop: this.props.currentCrop
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.srcLd !== this.props.srcLd || nextProps.srcHd !== this.props.srcHd) {
      this.setState({
        loaded: false,
        errored: false,
        modalOpen: false,
        percentCrop: nextProps.currentCrop,
        pixelCrop: nextProps.pixelCrop
      })
    }
  }

  imgloaded = e => {
    const { percentCrop } = this.state

    this.setState({ loaded: true })

    if (percentCrop.aspect && Math.abs(percentCrop.aspect * e.target.naturalHeight - e.target.naturalWidth) > 1) {
      this.setState({ modalOpen: true }) // start crop
    }
  }

  render() {
    const {
      srcOriginal,
      srcHd,
      name,
      horizontalFlip,
      verticalFlip,
      crop,
      remove,
      rotate,
      rotateInverse,
      uploadProgress
    } = this.props
    const { loaded, errored, modalOpen, percentCrop } = this.state
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', height: '230px' }}>
          <div
            style={{
              flex: ' 0 1 40px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {loaded && horizontalFlip && (
              <span className="btn btn-block btn-default" onClick={horizontalFlip}>
                <i className="fa fa-arrows-h" />
              </span>
            )}
            {loaded && verticalFlip && (
              <span className="btn btn-block btn-default" onClick={verticalFlip}>
                <i className="fa fa-arrows-v" />
              </span>
            )}
            {loaded && crop && (
              <span className="btn btn-block btn-default" onClick={e => this.setState({ modalOpen: true })}>
                <i className="fa fa-crop" />
              </span>
            )}
            {loaded && rotate && (
              <span className="btn btn-block btn-default" onClick={rotate}>
                <i className="fa fa-rotate-right" />
              </span>
            )}
            {loaded && rotateInverse && (
              <span className="btn btn-block btn-default" onClick={rotateInverse}>
                <i className="fa fa-rotate-left" />
              </span>
            )}
            {remove && (
              <>
                <div style={{ flex: 1 }} />
                <span className="btn btn-block btn-danger" onClick={remove}>
                  <i className="fa fa-trash-o" />
                </span>
              </>
            )}
          </div>

          <div
            style={{
              flex: 1,
              position: 'relative',
              height: '100%',
              marginLeft: '1em'
            }}
          >
            {name && (
              <h4
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: 'rgba(255,255,255,0.75)',
                  margin: 0,
                  color: 'darkgray'
                }}
              >
                {name}
              </h4>
            )}
            {srcHd && (
              <img
                src={srcHd}
                alt={name}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                onLoad={this.imgloaded}
                onError={e => {
                  srcHd && this.setState({ errored: true })
                }}
              />
            )}
            {!srcHd && <span>Pas de lien</span>}
            <ResourceUploadProgress progress={uploadProgress} />
            {!loaded && !errored && (
              <span>
                <i className="fa fa-spinner fa-spin" /> Chargement en cours{' '}
              </span>
            )}
          </div>
        </div>

        <Modal
          isOpen={modalOpen}
          close={() => {
            this.setState({ modalOpen: false })
          }}
          height="90vh"
        >
          <div>
            <span
              className="btn btn-primary"
              onClick={e => {
                this.setState({ modalOpen: false })
                crop(this.state.pixelCrop, this.state.percentCrop)
              }}
            >
              Valider
            </span>
            <span
              className="btn btn-default"
              onClick={e => {
                this.setState({
                  modalOpen: false,
                  percentCrop: this.props.currentCrop
                })
              }}
            >
              Annuler
            </span>
          </div>
          <ReactCrop
            src={srcOriginal}
            crop={percentCrop}
            onImageLoaded={image => {
              this.setState({
                percentCrop: makeAspectCrop(
                  {
                    aspect: percentCrop.aspect,
                    x: percentCrop.x,
                    y: percentCrop.y,
                    width: percentCrop.width
                  },
                  image.width / image.height
                )
              })
            }}
            onChange={(percentCrop, pixelCrop) => {
              this.setState({ percentCrop, pixelCrop })
            }}
          />
        </Modal>
      </div>
    )
  }
}

class ResourceImage extends Component {
  crop = (pixelCrop, percentCrop) => {
    const { resource, onChange } = this.props
    const options = resource.options || {}
    options.ops = options.ops || []
    const currentCrop = options.ops && options.ops.find(item => item.type === 'crop')
    if (currentCrop) {
      Object.assign(currentCrop, { pixelCrop, percentCrop, ...pixelCrop })
    } else {
      options.ops.push({ pixelCrop, percentCrop, type: 'crop', ...pixelCrop })
    }
    const promise = resourcesApi.upload({ _id: resource._id, options })
    const transfertUid = promise.__transfert_uid
    promise.then(json => {
      onChange(json)
    })
    onChange('__transfert__' + transfertUid)
    this.setState({ modalOpen: false })
  }

  horizontalFlip = e => {
    const { resource, onChange } = this.props
    const options = resource.options || {}
    options.ops = options.ops || []

    const currentFlip = options.ops.find(item => item.type === 'flip')
    if (currentFlip) {
      currentFlip.horizontal = !currentFlip.horizontal
    } else {
      options.ops.push({ horizontal: true, vertical: false, type: 'flip' })
    }

    const promise = resourcesApi.upload({ _id: resource._id, options })
    const transfertUid = promise.__transfert_uid
    promise.then(json => {
      onChange(json)
    })
    onChange('__transfert__' + transfertUid)
    this.setState({ modalOpen: false })
    e.stopPropagation()
  }

  verticalFlip = e => {
    const { resource, onChange } = this.props
    const options = resource.options || {}
    options.ops = options.ops || []

    const currentFlip = options.ops.find(item => item.type === 'flip')
    if (currentFlip) {
      currentFlip.vertical = !currentFlip.vertical
    } else {
      options.ops.push({ horizontal: false, vertical: true, type: 'flip' })
    }

    const promise = resourcesApi.upload({ _id: resource._id, options })
    const transfertUid = promise.__transfert_uid
    promise.then(json => {
      onChange(json)
    })
    onChange('__transfert__' + transfertUid)
    this.setState({ modalOpen: false })
    e.stopPropagation()
  }

  rotate = e => {
    const { resource, onChange } = this.props
    const options = resource.options || {}
    options.ops = options.ops || []

    const currentRotate = options.ops.find(item => item.type === 'rotate')
    if (currentRotate) {
      currentRotate.degrees = ((currentRotate.degrees || 0) + 90) % 360

      /* if (currentRotate.degrees === 0) {
        let rotateIndex = options.ops.findIndex(item => item.type === "rotate");
        options.ops.splice(rotateIndex, 1);
      } */
    } else {
      options.ops.push({ degrees: 90, type: 'rotate' })
    }

    const promise = resourcesApi.upload({ _id: resource._id, options })
    const transfertUid = promise.__transfert_uid
    promise.then(json => {
      onChange(json)
    })
    onChange('__transfert__' + transfertUid)
    this.setState({ modalOpen: false })
    e.stopPropagation()
  }

  rotateInverse = e => {
    const { resource, onChange } = this.props
    const options = resource.options || {}
    options.ops = options.ops || []

    const currentRotate = options.ops.find(item => item.type === 'rotate')
    if (currentRotate) {
      currentRotate.degrees = ((currentRotate.degrees || 0) + 270) % 360

      /* if (currentRotate.degrees === 0) {
        let rotateIndex = options.ops.findIndex(item => item.type === "rotate");
        options.ops.splice(rotateIndex, 1);
      } */
    } else {
      options.ops.push({ degrees: 90, type: 'rotate' })
    }

    const promise = resourcesApi.upload({ _id: resource._id, options })
    const transfertUid = promise.__transfert_uid
    promise.then(json => {
      onChange(json)
    })
    onChange('__transfert__' + transfertUid)
    this.setState({ modalOpen: false })
    e.stopPropagation()
  }

  remove = e => {
    const { onChange } = this.props
    onChange && onChange('')
    e.stopPropagation()
  }

  render() {
    const { options, resource, onChange } = this.props
    const { aspect } = options || {}
    if (!resource) {
      return <span className="bg-danger">Resource doesn't exists</span>
    }
    const currentCrop =
      (resource.options && resource.options.ops && resource.options.ops.find(item => item.type === 'crop')) || {}
    var existingCrop = Object.assign({}, currentCrop.percentCrop)
    if (aspect || existingCrop) {
      if (aspect) {
        existingCrop.aspect = aspect
      }
      if (typeof existingCrop.x !== 'undefined') {
        existingCrop.x = existingCrop.x || 0
      }
      if (typeof existingCrop.y !== 'undefined') {
        existingCrop.y = existingCrop.y || 0
      }
      if (typeof existingCrop.width !== 'undefined') {
        existingCrop.width = existingCrop.width || 20
      }

      if (typeof existingCrop.height !== 'undefined') {
        existingCrop.height = existingCrop.height || 20
      }
      if (existingCrop.aspect && existingCrop.width) {
        existingCrop.height = existingCrop.width / aspect
      }
    }

    if (!aspect && existingCrop.aspect) {
      delete existingCrop.aspect
    }

    return (
      <Image
        srcLd={resourcesApi.getUrl((resource.ld && resource.ld.file) || resource.original.file)}
        srcHd={resourcesApi.getUrl((resource.hd && resource.hd.file) || resource.original.file)}
        srcOriginal={resourcesApi.getUrl(resource.original.file)}
        uploadProgress={resource.progress}
        name={resource.name}
        rotate={onChange && this.rotate}
        rotateInverse={onChange && this.rotateInverse}
        crop={onChange && this.crop}
        currentCrop={existingCrop}
        horizontalFlip={onChange && this.horizontalFlip}
        verticalFlip={onChange && this.verticalFlip}
        remove={onChange && this.remove}
      />
    )
  }
}

const MobxImage = observer(ResourceImage)
export default MobxImage
