import React, { PureComponent } from 'react'
import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js'
// import MinimapPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.minimap.js'
import { saveAs } from 'file-saver'

import { Modal } from '../../../modal'
import ResourceUploadProgress from '../uploadprogress'
import { resources as resourcesApi } from '../../../../lib/api'
import AudioEditor from './audioeditor'

export default class Audio extends PureComponent {
  state = {
    loaded: false,
    errored: false,
    cutting: false,
    modalOpen: false,
    downloading: false
  }

  wavesurfer = null

  initWaveSurfer = src => {
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformContainer,
      waveColor: 'rgba(178,232,123,1)',
      progressColor: 'rgba(98,195,68,1)',
      minPxPerSec: 5,
      responsive: true,
      plugins: [
        TimelinePlugin.create({
          container: this.waveformTimelineContainer
        })
      ]
    })
    this.wavesurfer.load(src)

    this.wavesurfer.on('ready', () => {
      this.setState({ errored: false, loaded: true })
      // this.wavesurfer.enableDragSelection({
      //  color:'red'
      // })
    })
  }

  componentDidMount() {
    if (this.props.src) {
      this.initWaveSurfer(this.props.src)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({ loaded: false, errored: false, playing: false })
      this.initWaveSurfer(nextProps.src)
    }
  }

  componentWillUnmount() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
  }

  togglePlay = e => {
    if (!this.wavesurfer) {
      return
    }
    if (!this.state.loaded) {
      return
    }
    if (this.state.playing) {
      this.wavesurfer.pause()
    } else {
      this.wavesurfer.play()
    }
    this.setState({ playing: !this.state.playing })
  }

  render() {
    const { name, src, srcOriginal, uploadProgress, record, remove, existingCut, onEdit, existingVolume } = this.props
    const { loaded, errored, playing, downloading, modalOpen } = this.state

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', minWidth: 200 }}>
          <div style={{ width: '40px', display: 'flex', flexDirection: 'column' }}>
            {loaded && playing && (
              <span className="btn btn-block btn-default" onClick={this.togglePlay}>
                <i className="fa fa-pause" />
              </span>
            )}
            {loaded && !playing && (
              <span className="btn btn-block btn-default" onClick={this.togglePlay}>
                <i className="fa fa-play" />
              </span>
            )}
            {false && (
              <span className="btn btn-block btn-default" onClick={record}>
                <i className="fa fa-microphone" />
              </span>
            )}
            {loaded && onEdit && (
              <span className="btn btn-block btn-default" onClick={() => this.setState({ modalOpen: true })}>
                <i className="fa fa-edit" />
              </span>
            )}
            {loaded && !downloading && (
              <button
                className="btn btn-block btn-default"
                href={src}
                onClick={e => {
                  this.setState({ downloading: true })
                  resourcesApi.download(src).then(blob => {
                    this.setState({ downloading: false })
                    saveAs(blob, name)
                  })
                }}
              >
                <i className="fa fa-download" />
              </button>
            )}
            {loaded && downloading && <i className="fa fa-spinner fa-spin fa-fw" />}
            <div style={{ flex: 1 }} />
            {remove && (
              <span className="btn btn-block btn-danger" onClick={remove} style={{ marginTop: '1em' }}>
                <i className="fa fa-trash-o" />
              </span>
            )}
          </div>
          <div style={{ flex: 1, position: 'relative', height: '100%' }}>
            <ResourceUploadProgress progress={uploadProgress} />
            {!loaded && !errored && (
              <span>
                <i className="fa fa-spinner fa-spin" /> Chargement en cours{' '}
              </span>
            )}
            <div
              id="waveform"
              ref={div => {
                this.waveformContainer = div
              }}
            />
            <div
              id="wave-timeline"
              ref={div => {
                this.waveformTimelineContainer = div
              }}
            />
            {name && (
              <h4
                style={{
                  background: 'rgba(255,255,255,0.75)',
                  margin: '0.5em'
                }}
              >
                {name}
              </h4>
            )}
          </div>
        </div>

        <Modal
          isOpen={modalOpen}
          close={() => {
            this.setState({ modalOpen: false })
          }}
          height="40vh"
        >
          <div>
            <AudioEditor
              src={srcOriginal}
              name={name}
              existingCut={existingCut}
              existingvolume={existingVolume}
              onEdit={(start, end, volume) => {
                onEdit(start, end, volume)
                this.setState({ modalOpen: false })
              }}
            />
          </div>
        </Modal>
      </div>
    )
  }
}
