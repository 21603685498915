import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { saveAs } from 'file-saver'

import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'

import { Page } from '../'
import Loading from '../../components/loading'

class ExperienceResources extends Component {
  state = {
    loading: false,
    downloading: false,
    downloaded: false,
    downloadProgress: 0,
    startedAt: null
  };

  componentDidMount () {
    api.resources.getFrom('experiences', this.props.match.params.experienceUid)
  }

  downloadProgress = downloadProgress => {
    this.setState({ downloadProgress })
  };

  export = () => {
    const experienceId = this.props.match.params.experienceUid
    this.setState({
      downloading: true,
      startedAt: Date.now()
    })
    api.experiences.export(experienceId, this.downloadProgress).then(blob => {
      this.setState({ downloading: false })
      saveAs(blob, 'package.zip')
    })
  };

  render () {
    const { loading, downloading, downloadProgress, startedAt } = this.state
    let table
    if (loading) {
      return (
        <Page>
          <NavInExperience />
          <div id='content'>
            <Loading />
          </div>
          <div id='app-preview' />
        </Page>
      )
    }

    if (downloading) {
      let progress = downloadProgress
      if (progress > 1024) {
        if (progress > 1024 * 1024) {
          if (progress > 1024 * 1024 * 1024) {
            progress =
              Math.floor((10 * downloadProgress) / (1024 * 1024 * 1024)) / 10 +
              ' Go'
          } else {
            progress =
              Math.floor((10 * downloadProgress) / (1024 * 1024)) / 10 + ' Mo'
          }
        } else {
          progress = Math.floor((10 * downloadProgress) / 1024) / 10 + ' Ko'
        }
      } else {
        progress = downloadProgress + ' o '
      }
      return (
        <Page>
          <NavInExperience />
          <div id='content'>
            <p
              style={{
                padding: '1em',
                borderRadius: '4px'
              }}
              className='bg-warning'
            >
              Merci de ne pas recharger la page pendant que le téléchargement se
              déroule
            </p>
            Téléchargement en cours : {progress}à{' '}
            {Math.round(
              downloadProgress / (((Date.now() - startedAt) * 1024) / 1000)
            )}
            Ko/s
          </div>
          <div id='app-preview' />
        </Page>
      )
    }

    /*
    let hashes = {}
    let resources = (apiCache.resources.getFrom("experiences",experienceUid) || [])
    .filter(resource=>{
      const hash = resource.original.hash
      if(hashes[hash]){
        return 0
      }
      hashes[hash] = resources
    console.log('done',hashes, Object.keys(hashes).length)
    }) */

    return (
      <Page>
        <NavInExperience />
        <div id='content'>
          {loading && <Loading />}
          {!loading && table}
          <button
            className='btn btn-primary btn-block'
            onClick={this.export}
            title="Télécharger toutes les ressources de l'expérience"
          >
            <i className='fa fa-download' />
            Télécharger toutes les ressources de l'expérience
          </button>
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default observer(ExperienceResources)
