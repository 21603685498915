import { observer } from 'mobx-react'
import React, { Component, PureComponent } from 'react'
import { saveAs } from 'file-saver'

import ResourceUploadProgress from './uploadprogress'
import * as api from '../../../lib/api'

class Video extends PureComponent {
  state = {
    loaded: false,
    errored: false,
    percentCrop: null,
    downloading: false
  };

  wavesurfer = null;

  componentWillReceiveProps (nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({ loaded: false, errored: false, playing: false })
    }
  }

  componentWillUnmount () {}
  render () {
    const { src, name, uploadProgress, remove, cut } = this.props
    const { loaded, errored, downloading } = this.state
    const uploading = !!uploadProgress && uploadProgress !== 0
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{ width: '40px', display: 'flex', flexDirection: 'column' }}
          >
            {loaded && cut && (
              <span className='btn btn-block btn-default' onClick={cut}>
                <i className='fa fa-cut' />
              </span>
            )}
            {loaded && !downloading && (
              <button
                className='btn btn-block btn-default'
                href={src}
                onClick={e => {
                  this.setState({ downloading: true })
                  api.resources.download(src).then(blob => {
                    this.setState({ downloading: false })
                    saveAs(blob, name)
                  })
                }}
              >
                <i className='fa fa-download' />
              </button>
            )}
            {loaded && downloading && (
              <i className='fa fa-spinner fa-spin fa-fw' />
            )}

            <div style={{ flex: 1 }} />
            {remove && (
              <span
                className='btn btn-block btn-danger'
                onClick={remove}
                style={{ marginTop: '1em' }}
              >
                <i className='fa fa-trash-o' />
              </span>
            )}
          </div>
          <div
            style={{
              flex: 1,
              position: 'relative',
              height: '100%',
              marginLeft: '1em'
            }}
          >
            <ResourceUploadProgress progress={uploadProgress} />
            {!loaded && !errored && (
              <span>
                <i className='fa fa-spinner fa-spin' /> Chargement en cours{' '}
              </span>
            )}
            {!uploading && (
              <video
                src={src}
                controls
                onCanPlay={() => this.setState({ loaded: true })}
                onError={() => this.setState({ errored: true })}
              />
            )}
            {name && (
              <h4
                style={{
                  background: 'rgba(255,255,255,0.75)',
                  margin: '0.5em'
                }}
              >
                {name}
              </h4>
            )}
          </div>
        </div>
      </div>
    )
  }
}

class ResourceVideo extends Component {
  render () {
    const { resource } = this.props
    if (!resource) {
      return <span className='bg-danger'>Resource doesn't exists</span>
    }

    return (
      <Video
        uploadProgress={resource.progress}
        src={api.resources.getUrl(
          resource.hd ? resource.hd.file : resource.original.file
        )}
        name={resource.name}
        remove={e => {
          this.props.onChange('')
        }}
      />
    )
  }
}

const MobxResourceVideo = observer(ResourceVideo)
export default MobxResourceVideo
