import uuid from 'uuid'

import generify from './generic'
import uploads from '../../stores/uploads'
import { user, conf, apiCache } from '../../stores'
import { toJS } from 'mobx'

import { resize, fixOrientationWithExif } from '../imagetools'

function generifyResources(serviceName) {
  const resources = generify(serviceName, 'resources', 'resource')
  resources.getUrl = uid => {
    if (serviceName === 'experiences-service') {
      return conf.API_URL + '/experiences-service/files/resources/' + uid
    } else {
      return conf.API_URL + '/' + serviceName + '/files/' + uid
    }
  }

  resources.download = url => {
    return fetch(url).then(res => res.blob())
  }

  resources.upload = (resource, file) => {
    let { _id, experienceUid, type, ld, hd, options, name, ...other } = toJS(resource)
    options = toJS(options)
    let promise

    const transfertId = uuid.v4()
    // force resize as the last operation
    if (options && options.ops) {
      const priority = {
        crop: 1,
        flip: 2,
        rotate: 3,
        resize: 4
      }
      options.ops.sort((a, b) => {
        const priorityA = a.priority || priority[a.type] || 0
        const priorityB = b.priority || priority[b.type] || 0
        return priorityA - priorityB
      })
    }
    if (file) {
      promise = fixOrientationWithExif(file)
        .then(oriented => {
          return resize(oriented || file /* sometime orienation fails with huge image */, (options || {}).maxSize)
        })
        .then(resizedFile => {
          var formData = new FormData()
          const json = {
            type,
            experienceId: experienceUid,
            immediate: type !== 'video',
            options,
            name: serviceName === 'experiences-service' ? file.name : name,
            ...other
          }
          formData.append('resource', JSON.stringify(json))

          formData.append('resource', resizedFile, file.name)
          return uploads.add(
            {
              url: conf.API_URL + '/' + serviceName + '/resources' + (_id ? '/' + _id : ''),
              headers: {
                Authorization: 'Bearer ' + user.token
              },
              method: _id ? 'POST' : 'PUT',
              formData
            },
            transfertId
          )
        })
    } else {
      promise = uploads.add(
        {
          url: conf.API_URL + '/' + serviceName + '/resources' + (_id ? '/' + _id : ''),
          headers: {
            Authorization: 'Bearer ' + user.token,
            'Content-Type': 'application/json'
          },
          method: _id ? 'POST' : 'PUT',
          formData: JSON.stringify({
            resource: {
              type,
              experienceId: experienceUid,
              immediate: type !== 'video',
              options,
              ...other
            }
          })
        },
        transfertId
      )
    }

    promise = promise.then(res => {
      const resource = res.resource
      if (resource) {
        apiCache.resources.upsert(resource._id, resource)
      }
      return resource
    })
    promise.__transfert_uid = transfertId
    return promise
  }
  return resources
}

export const resources = generifyResources('experiences-service')
export const resourcesApplication = generifyResources('applications-service')
export const resourcesSession = generifyResources('sessions-service')
