import { toJS } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'

import * as api from '../../lib/api'
import createScenarioAction from '../../lib/createscenarioaction'
import { Form } from '../../components/form'
import Loading from '../../components/loading'
import { NavInScenario } from '../../components/nav'
import { Page } from '../'
import { apiCache } from '../../stores'
import * as transforms from './transform'
import checkModule from '../../lib/check/module'

class Module extends Component {
  state = {
    module: {},
    loading: true
  }

  async populateState(experienceUid, scenarioUid, moduleUid) {
    const experience = await api.experiences.get(experienceUid)
    await api.modules.getFromScenario(scenarioUid)
    await api.scenarios.getFromExperience(experienceUid)
    await api.modules.getFromScenario(scenarioUid)
    const module = moduleUid && (await api.modules.get(moduleUid))
    const checks = module && (await checkModule(module))
    this.setState({
      module: module || {
        experienceUid,
        scenarioUid,
        type: experience.authorizations.modules[0]
      },
      type: module ? module.type : experience.authorizations.modules[0],
      checks,
      loading: false
    })
  }

  componentWillReceiveProps(newProps) {
    if (newProps.match.params.moduleUid !== this.props.match.params.moduleUid) {
      const { experienceUid, scenarioUid, moduleUid } = newProps.match.params
      this.populateState(experienceUid, scenarioUid, moduleUid)
    }
  }

  componentDidMount() {
    const { experienceUid, scenarioUid, moduleUid } = this.props.match.params
    this.populateState(experienceUid, scenarioUid, moduleUid)
  }

  change = formData => {
    this.setState({ type: formData.type })
  }

  duplicate = async () => {
    const { siteUid, experienceUid, scenarioUid, moduleUid } = this.props.match.params

    const modules = await api.modules.getFrom('scenarios', scenarioUid)
    modules.sort((m1, m2) => m1.order - m2.order)
    const clone = { ...modules.find(m => m._id === moduleUid) }
    clone.title = (clone.title || '') + ' (copie)'
    clone.order = (clone.order || 0) + 1
    delete clone._id
    for (let counter = clone.order; counter < modules.length; counter++) {
      const m = modules[counter]
      await api.modules.upsert({ _id: m._id, order: m.order + 1 })
    }
    const moduleCreated = await api.modules.create(clone)
    const url = '/site/' + siteUid + '/experience/' + experienceUid + '/scenario/' + scenarioUid + '/module/' + moduleCreated._id
    this.props.history.push(url)
  }

  delete = async () => {
    const { siteUid, experienceUid, scenarioUid, moduleUid } = this.props.match.params

    await api.modules.remove(moduleUid)
    const url = '/site/' + siteUid + '/experience/' + experienceUid + '/scenario/' + scenarioUid
    this.props.history.push(url)
  }

  rollBack = async patchId => {
    const { moduleUid } = this.props.match.params
    const module = await api.modules.rollBackToPatch(moduleUid, patchId)
    this.setState({ module })
  }

  save = async formData => {
    const { siteUid, experienceUid, scenarioUid } = this.props.match.params

    formData.experienceId = experienceUid
    formData.scenarioId = formData.scenarioId || scenarioUid

    if (!formData.toolButton || !formData.toolButton.action || !formData.toolButton.action.type) {
      formData.toolButton = null
    }

    if (!formData._id) {
      const modules = await api.modules.getFrom('scenarios', formData.scenarioId)
      formData.order = modules && modules.length ? modules.length : 0
    }

    if (transforms[formData.type] && transforms[formData.type].formData2json) {
      formData = await transforms[formData.type].formData2json(formData)
    }

    const module = await api.modules.upsert(await createScenarioAction(formData))

    const checks = module && (await checkModule(module))
    this.setState({ checks })
    if (this.props.match.params.moduleUid !== module._id || this.props.match.params.scenarioUid !== module.scenarioId) {
      const url = '/site/' + siteUid + '/experience/' + experienceUid + '/scenario/' + module.scenarioId + '/module/' + module._id
      this.props.history.push(url)
    }
  }

  /* mobx will force a new render */
  componentWillReact() {
    const { moduleUid } = this.props.match.params
    if (moduleUid && this.state.module) {
      const module = apiCache.modules.get(moduleUid)
      if (module && this.state.module.updatedAt !== module.updatedAt) {
        this.setState({ module: toJS(module), type: module.type })
      }
    }
  }

  render() {
    const { siteUid, experienceUid, scenarioUid, moduleUid } = this.props.match.params
    let { checks, module, type, loading } = this.state
    const scenarios = apiCache.scenarios.getFromExperience(experienceUid) || []
    const experience = apiCache.experiences.get(experienceUid) || {}
    const categories = experience.categories || []
    const characters = experience.characters || []
    moduleUid && apiCache.modules.get(moduleUid) // to inform mobx I need to watch this in this component

    if (transforms[type] && transforms[type].json2formData) {
      module = transforms[type].json2formData(module)
    }
    return (
      <Page>
        <NavInScenario match={this.props.match} />
        <div id="content">
          {loading && <Loading />}
          {!checks && module._id && (
            <p className="bg-info">
              <i className="fa fa-cog fa-spin fa-3x fa-fw" />
              Contrôle qualité en cours
            </p>
          )}
          {checks &&
            checks.errors.map((e, i) => (
              <p className="bg-danger" key={i}>
                {e.text}
              </p>
            ))}
          {checks &&
            checks.warnings.map((e, i) => (
              <p className="bg-warning" key={i}>
                {e.text}
              </p>
            ))}
          {checks && (
            <p className="bg-success">{checks.nb - checks.warnings.length - checks.errors.length} vérifications réussies</p>
          )}
          {!loading && (
            <Form
              formPath={'modules/' + type}
              initialFormData={module}
              key={module._id}
              experienceUid={experienceUid}
              experience={experience}
              scenarioUid={scenarioUid}
              onSubmit={this.save}
              onChange={this.change}
              onDuplicate={this.duplicate}
              onDelete={this.delete}
              formContext={{
                scenarios,
                experience,
                siteUid,
                categories,
                characters,
                scenarioUid
              }}
              listHistory={() => api.modules.listAvailableRollBack(moduleUid)}
              rollBackToPatch={this.rollBack}
            />
          )}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}

export default observer(Module)
