import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { apiCache } from "../../stores";
import * as api from "../../lib/api";
import Picture from "../picture";
import { duplicateActionsInAfterGame } from "../../pages/track/tool";

export default class NavInTrack extends Component {
  state = {
    resources: {},
  };

  componentDidMount() {
    const promises = [];
    const { trackUid } = this.props.match.params;
    this.setState({ loaded: false });
    promises.push(api.tracks.get(trackUid));
    promises.push(api.points.getFrom("tracks", trackUid));
    Promise.all(promises).then(() => this.setState({ loaded: true }));
  }

  pointsToLink(points, type) {
    const { siteUid, experienceUid, trackUid } = this.props.match.params;
    return points
      .sort((a, b) =>
        (a.internalName || a.name || "").localeCompare(
          b.internalName || b.name || ""
        )
      )
      .map((point) => {
        const resourceId =
          (point.markers &&
            point.markers.normal &&
            point.markers.normal.resource) ||
          (point.marker && point.marker.resource);
        return (
          <li
            key={point._id || point.localId}
            className={resourceId && "with-image"}
          >
            <NavLink
              to={
                "/site/" +
                siteUid +
                "/experience/" +
                experienceUid +
                "/track/" +
                trackUid +
                "/" +
                type +
                "/" +
                (point._id || point.localId)
              }
            >
              <Picture resourceId={resourceId} style={{ height: 32 }} />
              {point.internalName || point.name || point._id || "point"}
            </NavLink>
          </li>
        );
      });
  }

  render() {
    const { siteUid, experienceUid, trackUid } = this.props.match.params;
    const track = apiCache.tracks.get(trackUid) || {};
    let points = apiCache.points.getFrom("tracks", trackUid);
    if (!track) {
      return null;
    }
    if (points) {
      points = this.pointsToLink(points, "point");
    }

    const entities = this.pointsToLink(track.entities || [], "hordeentity");

    return (
      <nav>
        <div className="nav-block">
          <h3>Parcours</h3>
          <ul>
            <li>
              <NavLink
                exact
                to={
                  "/site/" +
                  siteUid +
                  "/experience/" +
                  experienceUid +
                  "/track/" +
                  trackUid
                }
              >
                Détail
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to={
                  "/site/" +
                  siteUid +
                  "/experience/" +
                  experienceUid +
                  "/track/" +
                  trackUid +
                  "/checks"
                }
              >
                Validation
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="nav-block">
          <h3>
            {track.type === "horde" || track.type === "chase"
              ? "Objectifs"
              : "Points"}
          </h3>
          <ul>
            {points}
            {(track.type === "normal" || track.type === "adventure") && (
              <li key="add">
                <NavLink
                  exact
                  to={
                    "/site/" +
                    siteUid +
                    "/experience/" +
                    experienceUid +
                    "/track/" +
                    trackUid +
                    "/point/wizard"
                  }
                >
                  <i className="fa fa-plus" /> Ajouter un point
                </NavLink>
              </li>
            )}
            {track.type !== "normal" && track.type !== "adventure" && (
              <li key="add">
                <NavLink
                  exact
                  to={
                    "/site/" +
                    siteUid +
                    "/experience/" +
                    experienceUid +
                    "/track/" +
                    trackUid +
                    "/point/"
                  }
                >
                  <i className="fa fa-plus" /> Ajouter un point
                </NavLink>
              </li>
            )}
          </ul>
        </div>
        {track.type === "horde" && (
          <div className="nav-block">
            <h3>Poursuivants</h3>
            <ul>
              {entities}
              <li key="add">
                <NavLink
                  exact
                  to={
                    "/site/" +
                    siteUid +
                    "/experience/" +
                    experienceUid +
                    "/track/" +
                    trackUid +
                    "/hordeentity/"
                  }
                >
                  <i className="fa fa-plus" /> Ajouter une entité
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        {
          <div className="nav-block">
            <h3>Administration</h3>
            <ul>
              <li>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    duplicateActionsInAfterGame(trackUid);
                  }}
                >
                  Copier toutes les actions dans le parcours après jeu
                </span>
              </li>
            </ul>
          </div>
        }
      </nav>
    );
  }
}
