import React from 'react'
import { Link } from 'react-router-dom'

export const PopupPicker = ({ popups = [], formData, formContext, onChange }) => {
  const currentPopup = popups.find(popup => popup._id === formData)

  return (
    <div className="form-group field field-string">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <select style={{ flex: 3 }} className="form-control" value={formData || ''} onChange={e => onChange(e.target.value)}>
          <option />
          {popups.map(popup => (
            <option value={popup._id} key={popup._id}>
              {popup.name || popup._id}
            </option>
          ))}
        </select>
        {currentPopup && formContext.siteUid && formContext.experienceUid && (
          <Link
            style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
            to={'/site/' + formContext.siteUid + '/experience/' + formContext.experienceUid + '/popups'}
            className="btn btn-default"
          >
            Voir
          </Link>
        )}
      </div>
    </div>
  )
}
