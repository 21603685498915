import { observable } from 'mobx'

const BASE_CONFIG_PATH = '/parameters/forms/'

const forms = observable.map()
forms.mobxGet = forms.get

function mergeSchema(base, specific) {
  const schema = specific.schema || {}
  schema.properties = Object.assign({}, base.schema.properties, specific.schema.properties || {})
  const uiSchema = Object.assign({}, base.uiSchema, specific.uiSchema || {})
  schema.required = base.schema.required
    .concat(schema.required || [])
    .filter((value, index, self) => self.indexOf(value) === index)
  return {
    schema,
    uiSchema
  }
}

function merge(urls) {
  let merged = {
    schema: {
      required: []
    },
    uiSchema: {}
  }
  return new Promise((resolve, reject) => {
    function recursiveMerge(urls) {
      if (urls.length === 0) {
        return resolve(merged)
      }

      const url = urls.shift()
      fetch(url)
        .then(res => res.json())
        .then(json => {
          merged = mergeSchema(merged, json)
          recursiveMerge(urls)
        })
        .catch(e => {
          if (url.indexOf('advanced') >= 0) {
            // no advanced setting, probably not a problem
            recursiveMerge(urls)
          } else {
            reject(e)
          }
        })
    }
    recursiveMerge(urls.slice())
  })
}

forms.get = key => {
  const [base, advanced] = key.split('.')
  const form = forms.mobxGet(key)

  if (form) return form

  forms.set(key, { loading: true })

  if (key.indexOf('modules/') === 0) {
    let urls
    if (advanced) {
      urls = [
        BASE_CONFIG_PATH + 'modules/base.json',
        BASE_CONFIG_PATH + 'modules/base.advanced.json',
        BASE_CONFIG_PATH + base + '.json',
        BASE_CONFIG_PATH + base + '.advanced.json'
      ]
    } else urls = [BASE_CONFIG_PATH + 'modules/base.json', BASE_CONFIG_PATH + base + '.json']

    merge(urls)
      .then(({ schema, uiSchema }) => forms.set(key, { loading: false, schema: schema, uiSchema: uiSchema }))
      .catch(e => {
        console.error(e)
        forms.set(key, { loading: false, error: e })
      })
  } else {
    let urls
    if (advanced) urls = [BASE_CONFIG_PATH + base + '.json', BASE_CONFIG_PATH + base + '.advanced.json']
    else urls = [BASE_CONFIG_PATH + base + '.json']

    merge(urls)
      .then(({ schema, uiSchema }) => forms.set(key, { loading: false, schema: schema, uiSchema: uiSchema }))
      .catch(e => {
        forms.set(key, { loading: false, error: e })
      })
  }
  return forms.mobxGet(key)
}

export default forms
