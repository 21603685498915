import React from 'react'

export default function (props) {
  if (!props.progress && !props.loaded) {
    return null
  }
  var progress = props.progress || 0
  if (!progress && props.total) {
    progress = parseInt(props.loaded, 10) / parseInt(props.total, 10)
  }
  progress = Math.round(progress * 100)

  if (progress < 5) {
    progress = 5
  }
  if (progress === 100) {
    return null
  }
  return (
    <div className='progress'>
      <div
        className='progress-bar-striped progress-bar-info'
        role='progressbar'
        aria-valuenow={progress}
        aria-valuemin='0'
        aria-valuemax='100'
        style={{ width: progress + '%', height: '100%' }}
      />
    </div>
  )
}
