import chroma from 'chroma-js'

import * as api from '../api'

export default async experience => {
  const siteUid = experience.siteId
  const xpLink = '/site/' + siteUid + '/experience/' + experience._id
  const checkResult = {
    errors: [],
    warnings: [],
    nb: 0
  }
  checkResult.nb++
  const tracks = await api.tracks.getFrom('experiences', experience._id)
  if (!experience.startTrack && tracks.length > 1) {
    checkResult.errors.push({
      text:
        "Plusieurs parcours , mais aucun n'est défini comme celui de départ dans l'expéri" +
        'ence',
      link: xpLink
    })
  }
  checkResult.nb++
  if (!experience.picture) {
    checkResult.warnings.push({
      text: "l'experience n'a pas d'image",
      link: xpLink
    })
  }

  if (experience.routes) {
    for (let counter = 0; counter < experience.routes.length; counter++) {
      const { segment } = experience.routes[counter]
      if (!segment) {
        checkResult.errors.push({
          text: `le tracé GPS ${counter + 1} de l'experience n'a pas de tracé`,
          link: xpLink
        })
      } else {
        const r = await api.segments.get(segment)
        if (!r) {
          checkResult.errors.push({
            text: `le tracé GPS ${counter + 1} de l'expérience n'existe pas `,
            link: xpLink
          })
        }
      }
    }
  }

  // let modules = await api.modules.getFrom('experiences', experience._id)

  const hasAdventure = !!(tracks || []).find(t => t.type === 'adventure')
  const hasCompass = (tracks || []).find(
    t => !!(t.points || []).find(p => p.check && p.check.type === 'qrcode')
  )
  /* let hasVoice = (modules || []).find(module => {
    return (module.dialogs || []).find(d => !!d.sound)
  }) */

  checkResult.nb++
  if (!experience.theme) {
    checkResult.warnings.push({
      text: "l'expérience n'a pas de thème défini",
      link: xpLink + '/theme'
    })
  } else {
    const colors = [
      'color_primary',
      'color_primary_transparent',
      'color_primary_light',
      'color_primary_light_transparent',
      'color_primary_dark',
      'color_primary_dark_transparent',
      'color_accent',
      'color_accent_light',
      'color_accent_light_transparent',
      'color_accent_transparent',
      'color_accent_dark',
      'color_accent_dark_transparent'
    ]

    const missingColors = experience.theme.colors
      ? colors.filter(key => !experience.theme.colors[key])
      : colors

    checkResult.nb++
    if (missingColors.length > 0) {
      checkResult.warnings.push({
        text: `il manque ${
          missingColors.length
        } couleur(s) : ${missingColors.join(',')}  `,
        link: xpLink + '/theme'
      })
    }
    checkResult.nb++
    if (
      experience.theme.colors &&
      experience.theme.colors.color_primary &&
      experience.theme.colors.cardview_background &&
      chroma.contrast(
        experience.theme.colors.color_primary,
        experience.theme.colors.cardview_background
      ) < 2
    ) {
      checkResult.warnings.push({
        text:
          "il n'y a pas assez de contraste entre la couleur primaire et la couleur de fond des cartes",
        link: xpLink + '/theme'
      })
    }
    checkResult.nb++
    if (
      experience.theme.colors &&
      experience.theme.colors.dialog_background &&
      experience.theme.colors.dialog_text &&
      chroma.contrast(
        experience.theme.colors.dialog_background,
        experience.theme.colors.dialog_text
      ) < 4.5
    ) {
      checkResult.errors.push({
        text:
          "il n'y a pas assez de contraste entre la couleur de fond des dialogues et celle du texte des dialogues",
        link: xpLink + '/theme'
      })
    }
    checkResult.nb++
    if (
      experience.theme.colors &&
      experience.theme.colors.cardview_background &&
      experience.theme.colors.cardview_text &&
      chroma.contrast(
        experience.theme.colors.cardview_background,
        experience.theme.colors.cardview_text
      ) < 4.5
    ) {
      checkResult.errors.push({
        text:
          "il n'y a pas assez de contraste entre la couleur de fond des cartes et celle du texte des cartes",
        link: xpLink + '/theme'
      })
    }
    checkResult.nb++
    if (
      experience.theme.colors &&
      experience.theme.colors.color_primary &&
      experience.theme.colors.color_accent &&
      chroma.contrast(
        experience.theme.colors.color_primary,
        experience.theme.colors.color_accent
      ) < 1
    ) {
      checkResult.errors.push({
        text:
          "il n'y a pas assez de contraste entre la couleur primaire et la secondaire",
        link: xpLink + '/theme'
      })
    }
    checkResult.nb++
    if (
      experience.theme.colors &&
      experience.theme.colors.color_confirm &&
      experience.theme.colors.color_error &&
      chroma.contrast(
        experience.theme.colors.color_confirm,
        experience.theme.colors.color_error
      ) < 1
    ) {
      checkResult.errors.push({
        text:
          "il n'y a pas assez de contraste entre la couleur des boutons de validation et celle d'annulation",
        link: xpLink + '/theme'
      })
    }
    let resources = ['map_marker']
    if (hasCompass) {
      resources = resources.concat([
        'compass_background',
        'compass_needle',
        'point_check_background',
        'point_check_clues_normal',
        'point_check_clues_pressed',
        'point_check_found_normal',
        'point_check_found_pressed'
      ])
    }
    if (hasAdventure) {
      resources.push('adventure_progress_background')
    }
    const missingRessources = experience.theme.assets
      ? resources.filter(key => !experience.theme.assets[key])
      : resources

    checkResult.nb++
    if (missingRessources.length > 0) {
      checkResult.warnings.push({
        text: `il manque ${
          missingRessources.length
        } resources(s) : ${missingRessources.join(',')}  `,
        link: xpLink + '/theme',
        _id: experience._id
      })
    }
  }
  return Promise.resolve(checkResult)
}
