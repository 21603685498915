import React, { Component } from 'react'
import Line from './line'

export default class Table extends Component {
  state = {
    filterType: '',
    filterTitle: '',
    filterText: ''
  };

  render () {
    const { filterType, filterTitle, filterText } = this.state
    const txts = this.props.texts.filter(txt => {
      if (filterType && txt.type !== filterType) {
        return false
      }
      if (filterTitle && txt.title.toLowerCase().indexOf(filterTitle) < 0) {
        return false
      }
      if (
        filterText &&
        txt.text &&
        txt.text.toLowerCase().indexOf(filterText) < 0
      ) {
        return false
      }

      return true
    })
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Type</th>
            <th>Titre</th>
            <th>Dans</th>
            <th>Texte</th>
          </tr>
          <tr>
            <th>
              <select
                className='form-control'
                onChange={e => {
                  this.setState({ filterType: e.target.value })
                }}
              >
                <option value=''>Tous</option>
                <option value='experiences'>Experience</option>
                <option value='tracks'>Parcours</option>
                <option value='points'>Point</option>
                <option value='modules'>Module</option>
              </select>
            </th>
            <th>
              <input
                type='search'
                placeholder='filtrer dans le titre'
                className='form-control'
                onChange={e => {
                  this.setState({
                    filterTitle: e.target.value.toLowerCase()
                  })
                }}
              />
            </th>
            <th>Dans</th>
            <th>
              <input
                type='search'
                placeholder='filtrer dans le texte'
                className='form-control'
                onChange={e => {
                  this.setState({
                    filterText: e.target.value.toLowerCase()
                  })
                }}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {txts.map((txt, index) => {
            if (index === 0) return null
            return (
              <Line
                key={txt.id /* id */ + txt.path /* position in object */}
                txt={txt}
                onChange={value => {
                  console.log('will save  in table', txt, value)
                  return this.props.onSave(txt, value)
                }}
              />
            )
          })}
        </tbody>
      </table>
    )
  }
}
