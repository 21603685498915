export default {
  automaton: 'Automate',
  compass: 'Boussole',
  choice: 'Choix',
  cluedo: 'Cluedo',
  qrcode_hunt: 'Chasse au QR Code',
  conversation: 'Conversation',
  score_conversation: 'Conversation + score',
  diaporama: 'Diaporama',
  score_diaporama: 'Diaporama + score',
  differences: 'Différences',
  differences_photosphere: 'Différences sur Photosphère',
  dragdrop: 'Drag and Drop',
  gallery: "Galerie d'images",
  clue: 'Indices',
  level: 'Niveau à bulle',
  rating: 'Notation ',
  rating_store: 'Notation store',
  object3d: 'Objet en 3D',
  photosphere: 'Photosphère',
  picture_slider: 'Photo curseur',
  puzzle: 'Puzzle',
  image_mcq: 'Quizz Image',
  mcq: 'Quizz : question à choix multiples',
  quizz: 'Quizz : texte libre',
  augmented_reality: 'Réalité augmentée',
  scratch: 'Grattage',
  shake_contest: 'Secouer',
  selfie: 'Selfie - Photo',
  session: 'Session de jeu',
  silence: 'Silence',
  slasher: 'Slasher',
  sonometer: 'Sonomètre',
  switch: 'Switch',
  markdown: 'Texte',
  video: 'Vidéo'
}
