import React from 'react'

export default props => {
  const menus = props.formContext.menus || []

  const menuOptions = menus.map(menu => {
    return (
      <option value={menu._id} key={menu._id}>
        {menu.label || menu._id}
      </option>
    )
  })

  return (
    <div className="form-group field field-string">
      <label className="control-label" htmlFor={props.name}>
        {props.schema.title}
      </label>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <select
          style={{
            flex: 1
          }}
          className="form-control"
          value={props.formData}
          onChange={e => props.onChange(e.target.value)}
        >
          <option /> {menuOptions}
        </select>
        {/* {props.formData && (
          <Link
            style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
            to={'/site/' + siteUid + '/experience/' + experienceUid + '/track/' + track._id}
            className="btn btn-default"
          >
            Voir
          </Link>
        )} */}
      </div>
    </div>
  )
}
