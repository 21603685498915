import React from 'react'
import { ChapterLinkTrack, ChapterLinkScenario, ChapterLinkPoint } from './link'
import BookResource from './resource'

export default ({ action, chapters }) => {
  const experienceChapter = Object.keys(chapters)
    .map(key => chapters[key])
    .find(({ type }) => type === 'experience')
  const experience = (experienceChapter || {}).experience
  const { timers } = experience || {}
  switch (action.type) {
    case 'load_track':
      return <ChapterLinkTrack trackId={action.track} chapters={chapters} />

    case 'load_scenario':
      return <ChapterLinkScenario scenarioId={action.scenario} chapters={chapters} />

    case 'disable_all_points':
      return 'Désactivation de tous les points du parcours courant'
    case 'hide_all_points':
      return 'Masquage de tous les points du parcours courant'
    case 'enable_all_points':
      return 'Activation de tous les points du parcours courant'
    case 'show_all_points':
      return 'Affichage de tous les points du parcours courant'

    case 'enable_point': {
      const pointChapter = chapters[action.point] || {}
      const point = pointChapter.point || {}
      return (
        <span>
          Activation du point{' '}
          <ChapterLinkPoint pointId={action.point} chapters={chapters} text={' ' + (point.name || point.internalName)} />
        </span>
      )
    }
    case 'disable_point': {
      const pointChapter = chapters[action.point] || {}
      const point = pointChapter.point || {}
      return (
        <span>
          Désctivation du point{' '}
          <ChapterLinkPoint pointId={action.point} chapters={chapters} text={' ' + (point.name || point.internalName)} />
        </span>
      )
    }
    case 'show_point': {
      const pointChapter = chapters[action.point] || {}
      const point = pointChapter.point || {}
      return (
        <span>
          Affichage du point{' '}
          <ChapterLinkPoint pointId={action.point} chapters={chapters} text={' ' + (point.name || point.internalName)} />
        </span>
      )
    }
    case 'hide_point': {
      const pointChapter = chapters[action.point] || {}
      const point = pointChapter.point || {}
      return (
        <span>
          Masquage du point{' '}
          <ChapterLinkPoint pointId={action.point} chapters={chapters} text={' ' + (point.name || point.internalName)} />
        </span>
      )
    }

    case 'play_sound':
      return (
        <div style={{ display: 'inline' }}>
          Lecture du son
          <BookResource id={action.sound} />
        </div>
      )

    case 'add_score':
      return <span>Ajout de {action.score} points</span>

    case 'stop_all_timers':
      return <span>Arreter tous les comptes à rebours</span>
    case 'start_timer': {
      const timer = timers?.[action?.timer]
      return <span>Démarrer le compte a rebours {(timer || {})?.name}</span>
    }
    case 'stop_timer': {
      const timer = timers?.[action?.timer]
      return <span>Arreter le compte a rebours {(timer || {})?.name}</span>
    }
    default:
      return <pre>{JSON.stringify(action, null, 2)}</pre>
  }
}
