import React from 'react'

export default props => {
  const tags = props.formContext.tags || []

  return (
    <div className='form-group field field-string'>
      <label className='control-label' htmlFor={props.name}>
        {props.schema.title}
      </label>
      <select
        className='form-control'
        value={props.formData}
        onChange={e => props.onChange(e.target.value)}
      >
        <option />
        {tags.map(tag => {
          return (
            <option value={tag._id} key={tag._id}>
              {tag.label || 'sans label'}{' '}
            </option>
          )
        })}
      </select>
    </div>
  )
}
