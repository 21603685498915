import React, { Component } from 'react'

import { observer } from 'mobx-react'
import uploads, { uploadHistory } from '../stores/uploads'

class Footer extends Component {
  onUnload = event => {
    const uploading = uploadHistory.filter({ status: 'upload' }).length > 0
    if (uploading) {
      event.returnValue =
        "Vous en train d'envoyer des fichiers .\nSouhaitez vous quitter cette page" +
        ' et perdre les modifications non enregistrée ? '
    }
  };

  componentDidMount () {
    window.addEventListener('beforeunload', this.onUnload)
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  render () {
    const uploading = uploadHistory.filter({ status: 'upload' }).length > 0
    if (!uploading) {
      return <footer />
    }
    return (
      <footer>
        envoi de fichier à {Math.floor(uploads.uploadSpeed / 1024)} ko/s,{' '}
        {Math.ceil(uploads.uploadEta)} s restant
      </footer>
    )
  }
}

export default observer(Footer)
