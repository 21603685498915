import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import {observer} from "mobx-react"
/* import  {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps' */
import { Page } from '../'
import * as api from '../../lib/api'
// import {conf} from "../../stores"
import Loading from '../../components/loading'

import { NavInSites } from '../../components/nav'
/*
let propertiesList = `"check_permission" = "Check permissions >";

"experience_progression_message" = "Continue your progression or start over?";
"experience_progression_continue_label" = "Continue";
"experience_progression_restart_label" = "Restart";
"experience_progression_restart_label_confirm" = "Are you sure ? ";
"experience_progression_restart_label_confirm_yes" = "Yes";
"experience_progression_restart_label_confirm_no" = "No";

"experience_exit_button" =  "< Exit";
"experience_exit_warning_title" =  "End experience?";
"experience_exit_warning_message" =  "Your progression is saved. End experience?";
"experience_exit_warning_action_stop" =  "Exit?";
"experience_exit_warning_action_continue" =  "Continue?";

"score_result_bad_title"="Oh no!";
"score_result_average_title"="So close!";
"score_result_good_title"="Congratulations!";

"quizz_check_my_answer"="Check my answers";
"quizz_confirm"="Are you sure ? ";
"quizz_confirm_yes"="Yes";
"quizz_confirm_no"="No";

"multiplayer_module_solo_score_template"="Score : %d pts";
"multiplayer_module_player_score_template"="Player %d : %d pts";
"multiplayer_module_message_solo_prepare_yourself"="Prepare yourself!";
"multiplayer_module_message_player_prepare_yourself"="Player %d, prepare yourself!";
"multiplayer_module_result_perfect"="Perfect !";
"multiplayer_module_result_good"="Good !";
"multiplayer_module_result_average"="Nice try !";
"multiplayer_module_result_bad"="Come on";
"multiplayer_module_scores_title"="Scores";
"multiplayer_module_average_score_template"="Average score : %d pts";
"multiplayer_module_score_name_template"="Player %d";
"multiplayer_module_number_of_player"="How many players ?";

"photo_hunt_score_template"="Score: %d pts";
"photo_hunt_counter_template"="Locations: %1$d/%2$d";
"photo_hunt_wrong_location"="No! Wrong place!";
"photo_hunt_guidance_button"="I need help";
"photo_hunt_found_button"="I found it";
"photo_hunt_guidance_confirmation"="Sacrifice some points to use the compass?";
"photo_hunt_guidance_confirmation_show"="Show compass";
"photo_hunt_guidance_confirmation_does_not_show"="Keep my points";
"photo_hunt_give_up"="I give up";

"point_check_guidance_compass_inaccurate"="Compass inaccurate!";

"button_default_title"="Inventory";

"selfie_continue"= "Save and continue";
"selfie_shoot" = "Take this selfie";
"selfie_reshoot" = "Retake this selfie";
"selfie_share" = "Share";

"warning_download_before_title" = "Warning";
"warning_download_before_text" = "Don't forget to download the content before starting your adventure";

"stop_while_waiting_for_download_title" = "One moment, please";
"stop_while_waiting_for_download" = "You'll be able to start this game as soon as the download is finished";
"session_module_name_hint" = "Team name";

"permission_storage" = "Access to storage and gallery is required to save selfies and game data";
"permission_camera" = "Acess to camera is required to scan QRCode and take selfies";
"permission_location" = "Access to current location is required to guide you during the game";

"track_default_title"="";

"point_check_clues_label"="Clues";
"point_check_guidance_label"="Guidance";
"point_check_knowledge_label"="Knowledge";
"point_check_found_label"="Found it!";
"point_check_guidance_waiting_gps"="Waiting for GPS";
"point_check_guidance_distance_model"="Distance: %d m";
"point_check_guidance_distance_close"="There you are!";
"point_check_guidance_distance_far"="Way too far!";
"check_code_instructions"="Enter the code you found";
"check_code_hint"="Enter the code";
"check_answer_required_warning"="Answer required!";
"check_qrcode_instructions"="Place a QRCode in the field";
"check_congratulations_message"="Congratulations!";
"check_wrong_code_warning"="Wrong code!";`

let xml =`<resources xmlns:xliff="urn:oasis:names:tc:xliff:document:1.2">
<string name="title_activity_experiences">Ervaringen</string>
<string name="title_activity_permissions">Machtigingen nodig</string>
<string name="title_activity_warnings">Waarschuwingen</string>
<string name="title_activity_continue">Voortgang</string>
<string name="title_activity_admin">Beheer</string>
<string name="title_activity_settings">Instellingen</string>
<string name="title_activity_guide">Koppelen begeleiden</string>

<string name="experiences_tab_list">Lijst</string>
<string name="experiences_tab_map">Kaart</string>

<string name="action_settings">Instellingen</string>

<!-- Generics -->
<string name="ok">OK</string>
<string name="cancel">Annuleren</string>
<string name="work_in_progress">Lopende werkzaamheden…</string>
<string name="no_internet_connection">Geen internetverbinding</string>
<string name="unknown_error">Onbekende fout</string>
<string name="confirm_title">Weet u dit zeker?</string>

<!-- Permissions -->
<string name="permission_location_description">De locatie is vereist om u op de kaart te kunnen weergeven en u voortgang te kunnen valideren.</string>
<string name="permission_location_rationale">Toestemming tot de locatie is nodig.</string>
<string name="permission_location_denied_explanation">Het gebruik van de locatie werd geweigerd, maar is wel nodig. Ga naar Instellingen.</string>
<string name="permission_location_denied">Toestemming voor het gebruik van de locatie geweigerd.</string>
<string name="permission_camera_description">De camera is nodig om foto\'s te kunnen maken en uw reis vast te leggen.</string>
<string name="permission_camera_rationale">De camera heeft toestemming nodig.</string>
<string name="permission_camera_denied_explanation">Het gebruik van de camera werd gewijzigd, maar is wel nodig. Ga naar Instellingen.</string>
<string name="permission_camera_denied">Toestemming voor gebruik van de camera geweigerd.</string>
<string name="permission_storage_description">De opslag is nodig om foto\'s en toepassingsgegevens op te slaan.</string>
<string name="permission_storage_rationale">Toestemming voor de opslag is nodig.</string>
<string name="permission_storage_denied_explanation">Het gebruik van de opslag werd geweigerd, maar is wel nodig. Ga naar Instellingen.</string>
<string name="permission_storage_denied">Toestemming om opslag te mogen gebruiken geweigerd.</string>

<!-- Warnings -->
<string name="warning_download_title">Waarschuwing: voor vertrek</string>
<string name="warning_download_message">Download ervaringe nvoordat u gaat vertrekken zodat problemen met de verbinding worden voorkomen.</string>
<string name="warning_low_end_device">Low-end apparaat: beperkt tot een lage definitie</string>

<!-- Admin -->
<string name="admin_home_label">Ga naar huis</string>
<string name="admin_continue_label">Doorgaan</string>
<string name="admin_lock_device_label" translatable="false">Lock device</string>
<string name="admin_status_version_template" translatable="false">Version: <xliff:g example="0.0.1" id="string">%s</xliff:g></string>
<string name="admin_status_lock_mode_template" translatable="false">Lock Mode: <xliff:g example="true" id="boolean">%b</xliff:g></string>
<string name="admin_status_lock_permitted_template" translatable="false">Lock permitted: <xliff:g id="string">%s</xliff:g></string>
<string name="admin_outdated_android_version_warning" translatable="false">Lock task mode require Android M or more recent</string>
<string name="admin_not_device_owner_warning" translatable="false">This app has not been given Device Owner privileges to manage this device and start lock task mode</string>
<string name="admin_lock_instruction">Beperkte toegang. Voer de combinatie in om het beheerpaneel te openen.</string>
<string name="lock_instruction">Beperkte toegang. Voer de combinatie in om de toepassing af te sluiten.</string>
<string name="lock_try_again_message">Verkeerde combinatie, probeer het nog een keer.</string>
<string name="battery_template">Batterij: <xliff:g example="7" id="number">%d%%</xliff:g></string>
<string name="battery_charging_template">Batterij: opladen (<xliff:g example="7" id="number">%d%%</xliff:g>)</string>
<string name="admin_user_restriction_error" translatable="false">Error setting user restrictions, please reinstall device</string>

<!-- Guide -->
<string name="title_activity_guide_template">Koppelen begeleiden: %s</string>
<string name="guide_stop">Stoppen</string>
<string name="guide_device_empty_list">Er wordt op de gidsen gewacht&#8230;</string>
<string name="visitor_device_empty_list">Er wordt op de bezoekers gewachte&#8230;</string>
<string name="guide_pairing_title">Koppelen&#8230;</string>
<string name="guide_pairing_message">Koppelen met %s, een ogenblik geduld&#8230;</string>
<string name="guide_pairing_error">Fout bij het koppelen aan %s</string>
<string name="guide_pairing_error_template">Fout bij het koppelen aan %s: %s</string>
<string name="guide_paired_template">Gekoppeld aan %s</string>
<string name="guide_disconnect_label">Verbinding verbreken</string>
<string name="guide_device_with_battery_template">%s (%d %%)</string>
<string name="guide_tab_scenarios">Scenario\'s</string>
<string name="guide_tab_tracks">Banen</string>
<string name="guide_tab_visitors">Bezoekers</string>

<!-- Experiences -->
<string name="experience_notification_channel">Ervaring</string>
<string name="experiences_empty_list">Er is geen ervaring in de buurt die voldoet aan uw criteria.</string>
<string name="experience_difficulty_easy">Makkelijk</string>
<string name="experience_difficulty_medium">Gemiddeld</string>
<string name="experience_difficulty_hard">Hard</string>

<!-- Downloads -->
<string name="download_notification_channel">Downloads</string>
<string name="download_progress_notification_summary"><xliff:g example="7" id="number">%1$d</xliff:g> bestanden van <xliff:g example="7" id="number">%2$d</xliff:g></string>
<string name="experience_automatic_download_message">Ervaring wordt gedownload&#8230;</string>
<string name="experience_waiting_for_download_message">Er wordt gewacht totdat het downloaden is voltooid. Een ogenblik geduld&#8230;</string>
<string name="download_complete_notification_message">Ervaring gedownload</string>

<!-- Tags -->
<string name="tag_walking">Lopen</string>
<string name="tag_trail">Wandelen</string>
<string name="tag_bike">Fiets</string>
<string name="tag_handicap">Handicap</string>
<string name="tag_physical_disability">Fysieke beperking</string>
<string name="tag_intellectual_disability">Verstandelijk gehandicapt</string>
<string name="tag_hearing_impairment">Slechthorend</string>
<string name="tag_visual_impairment">Slechtziend</string>

<!-- Filters -->
<string name="experience_filter_none_label">Geen filter</string>
<string name="experience_filter_text_label">Tekst</string>
<string name="experience_filter_text_hint">Tekst te vinden</string>
<string name="experience_filter_locale_label">Lokaal</string>
<string name="experience_filter_difficulty_label">Moeilijkheid</string>
<string name="experience_filter_length_label">Lengte</string>
<string name="experience_filter_duration_label">Tijdsduur</string>
<string name="point_filter_none_label">Geen filter</string>
<string name="point_filter_tags_label">Tags</string>

<!-- Navigation -->
<string name="navigation_app_chooser_title">Navigatie</string>
<string name="no_navigation_application_installed">Geen navigatie-app gevonden op uw apparaat</string>

<!-- Distance -->
<string name="distance_template_meters"><xliff:g example="7" id="number">%d</xliff:g> m</string>
<string name="distance_template_kilometers"><xliff:g example="7" id="number">%.1f</xliff:g> km</string>
<string name="distance_range_template_kilometers"><xliff:g example="7" id="number">%1$d</xliff:g> - <xliff:g example="7" id="number">%2$d</xliff:g> km</string>

<!-- Time -->
<string name="duration_template_hours_minutes"><xliff:g example="7" id="number">%1$d</xliff:g> u <xliff:g example="7" id="number">%2$02d</xliff:g></string>
<string name="duration_template_hours"><xliff:g example="7" id="number">%d</xliff:g> u</string>
<string name="duration_template_minutes"><xliff:g example="7" id="number">%d</xliff:g> min</string>
<string name="duration_range_template_hours"><xliff:g example="7" id="number">%1$d</xliff:g> - <xliff:g example="7" id="number">%2$d</xliff:g> u</string>

<!-- Speed -->
<string name="speed_template_kilometers_hour"><xliff:g example="7" id="number">%.1f</xliff:g> km / u</string>
<string name="avg_speed_template_kilometers_hour"><xliff:g example="7" id="number">%.1f</xliff:g> km / u gem</string>

<!-- Experience Progression -->
<string name="experience_progression_score_template">Score: <xliff:g example="7" id="number">%d</xliff:g> punten</string>
<string name="experience_progression_message">Doorgaan met uw voortgang of opnieuw beginnen?</string>
<string name="experience_progression_continue_label">Doorgaan</string>
<string name="experience_progression_restart_label">Opnieuw beginnen</string>
<string name="experience_progression_restart_confirmation_title">Uw voortgang wordt gereset. Opnieuw beginnen?</string>

<!-- Environment Dialog -->
<string name="environment_dialog_title">Kies een omgeving</string>
<string name="environments_test">Test</string>
<string name="environments_staging">Regie</string>
<string name="environments_prod">Productie</string>
<string name="environment_toast_template">Overgeschakeld naar <xliff:g example="7" id="number">\"%s\"</xliff:g></string>

<!-- Settings -->
<string name="settings_header_general">Algemeen</string>
<string name="settings_header_guide">Gids</string>

<string name="pref_title_test_mode">Testmodus</string>
<string name="pref_description_test_mode_on">Testmodus ingeschakeld</string>
<string name="pref_description_test_mode_off">Testmodus uitgeschakeld</string>
<string name="pref_title_session_report">Sessierapport</string>
<string name="pref_description_session_report_on">Sessies rapporteren</string>
<string name="pref_description_session_report_off">Geen sessies rapporten</string>
<string name="pref_title_environment">Omgeving</string>
<string name="pref_description_environment_template">Data omgeving: %s</string>
<string name="pref_title_site">Plaats</string>
<string name="pref_description_site_template">Plaats: %s</string>
<string name="pref_title_guide_mode">Gidsmodus</string>
<string name="pref_description_guide_mode_template">Gidsmodus: %s</string>
<string name="pref_title_device_name">Naam apparaat</string>
<string name="pref_description_device_name_template">Naam apparaat: %s</string>

<string name="guide_modes_disabled">Uitgeschakeld</string>
<string name="guide_modes_guide">Gids</string>
<string name="guide_modes_visitor">Bezoeker</string>

<!-- Compass -->
<string name="compass_calibration_card_title">Kalibreer uw kompas</string>
<string name="compass_calibration_card_instruction">Kantel en beweeg uw apparaat 3 keers als volgt:</string>
<string name="compass_calibration_card_accuracy_label">Nauwkeurigheid kompas:</string>
<string name="compass_calibration_card_accuracy_high">hoog</string>
<string name="compass_calibration_card_accuracy_low">laag</string>
<string name="compass_calibration_card_accuracy_medium">gemiddeld</string>

<!-- Map -->
<string name="map_score_template">Score: <xliff:g example="7" id="number">%1$d</xliff:g> punten</string>

<!-- Point Check -->
<string name="point_check_clues_label">Aanwijzingen</string>
<string name="point_check_guidance_label">Begeleiding</string>
<string name="point_check_knowledge_label">Kennis</string>
<string name="point_check_found_label">Gevonden!</string>
<string name="point_check_guidance_waiting_gps">Er wordt op de GPS gewacht</string>
<string name="point_check_guidance_distance_model">Afstand: <xliff:g example="7" id="number">%d</xliff:g> m</string>
<string name="point_check_guidance_distance_close">Daar bent u!</string>
<string name="point_check_guidance_distance_far">Veel te ver!</string>
<string name="check_code_instructions">Voer de door u gevonden code in</string>
<string name="check_code_hint">Voer de code in</string>
<string name="check_answer_required_warning">Antwoorden verplicht.</string>
<string name="check_qrcode_instructions">Plaats een QRCode in het veld</string>
<string name="check_congratulations_message">Gefeliciteerd!</string>
<string name="check_wrong_code_warning">Verkeerde code.</string>

<!-- QRCode Activity -->
<string name="qrcode_instruction">Plaats een QRCode in het veld</string>
<string name="qrcode_invalid_code">Ongeldige QRCode.</string>

<!-- Adventure track -->
<string name="adventure_timer_seconds_template">
<xliff:g example="7" id="number">%02d</xliff:g></string>
<string name="adventure_timer_minutes_seconds_template">
<xliff:g example="7" id="number">%1$02d</xliff:g> <xliff:g example="7" id="number">%2$02d</xliff:g></string>
<string name="adventure_timer_hours_minutes_seconds_template">
<xliff:g example="7" id="number">%1$02d</xliff:g> <xliff:g example="7" id="number">%2$02d</xliff:g> <xliff:g example="7" id="number">%3$02d</xliff:g></string>
<string name="adventure_timer_days_hours_minutes_seconds_template"><xliff:g example="7" id="number">%1$02d</xliff:g> <xliff:g example="7" id="number">%2$02d</xliff:g> <xliff:g example="7" id="number">%3$02d</xliff:g>: <xliff:g example="7" id="number">%4$02d</xliff:g></string>
<string name="adventure_score_template"><xliff:g example="7" id="number">%d</xliff:g> ptn</string>
<string name="adventure_objectives_template" translatable="false">%d</string>

<!-- Fitness session -->
<string name="session_stop_warning_title">De huidige sessie stoppen?</string>
<string name="session_stop_warning_action_stop">Stoppen</string>
<string name="fit_session_duration">Tijdsduur</string>
<string name="fit_session_duration_template"><xliff:g example="7" id="number">%1$d</xliff:g> u <xliff:g example="7" id="number">%2$02d</xliff:g> min <xliff:g example="7" id="number">%3$02d</xliff:g> s</string>
<string name="fit_session_distance">Afstand</string>
<string name="fit_session_calories">Calorieën</string>
<string name="fit_session_avg_speed">Gemiddelde snelheid</string>
<string name="fit_session_steps">Stappen</string>

<string name="experience_exit_warning_title">Ervaring beëindigen?</string>
<string name="experience_exit_warning_message">Uw voortgang wordt opgeslagen. Ervaring beëindigen?</string>
<string name="experience_exit_warning_action_stop">Afsluiten</string>

<!-- Augmented Reality Module -->
<string name="no_augmented_reality_build_message">Verbeterde realiteit uitgeschakeld voor deze toepassing.</string>

<!-- Module Confirmation Dialog -->
<string name="confirmation_dialog_title">Is dat uw definitieve antwoord?</string>

<!-- Module Score -->
<string name="module_score_wasted">Oh nee!</string>
<string name="module_score_template">+ <xliff:g example="7" id="number">%d</xliff:g> ptn</string>

<!-- Session Module -->
<string name="session_module_people_label">Aantal personen</string>
<plurals name="session_module_people_template">
    <item quantity="one"><xliff:g example="7" id="number">%d</xliff:g> persoon</item>
    <item quantity="other"><xliff:g example="7" id="number">%d</xliff:g> mensen</item>
</plurals>
<string name="session_module_name_hint">Naam team</string>
<string name="session_module_email_hint">E-mailadres</string>
<string name="session_module_confirmation_title">Deze informatie valideren?</string>

<!-- Quizz Module -->
<string name="quizz_answer_required_warning">Antwoorden verplicht.</string>
<string name="quizz_answer_hint">Uw antwoord</string>

<!-- Differences Module -->
<string name="difference_counter_template">Verschillen: <xliff:g example="7" id="number">%1$d</xliff:g> / <xliff:g example="7" id="number">%2$d</xliff:g></string>

<!-- Multiplayer Modules -->
<string name="multiplayer_module_players_label">Aantal spelers</string>
<plurals name="multiplayer_module_players_template">
    <item quantity="one"><xliff:g example="7" id="number">%d</xliff:g> speler</item>
    <item quantity="other"><xliff:g example="7" id="number">%d</xliff:g> spelers</item>
</plurals>
<string name="multiplayer_module_solo_score_template">Score: %d ptn</string>
<string name="multiplayer_module_player_score_template">Speler <xliff:g example="7" id="number">%1$d</xliff:g>: <xliff:g example="7" id="number">%2$d</xliff:g> ptn</string>
<string name="multiplayer_module_message_solo_prepare_yourself">Bereid u voor!</string>
<string name="multiplayer_module_message_player_prepare_yourself">Speler <xliff:g example="7" id="number">%d</xliff:g>, bereid u voor!</string>
<string name="multiplayer_module_result_perfect">Perfect!</string>
<string name="multiplayer_module_result_good">Goed zo!</string>
<string name="multiplayer_module_result_average">Goed geprobeerd!</string>
<string name="multiplayer_module_result_bad">Dat kan beter&#8230;</string>
<string name="multiplayer_module_scores_title">Scores</string>
<string name="multiplayer_module_average_score_template">Gemiddelde score: <xliff:g example="7" id="number">%d</xliff:g> ptn</string>
<string name="multiplayer_module_score_name_template">Speler %d</string>
<string name="multiplayer_module_score_template"><xliff:g example="7" id="number">%d</xliff:g> ptn</string>

<!-- Shake Contest Module -->
<string name="shake_contest_message_ready">Klaar om te schudden?</string>
<string name="shake_contest_message_shake">Schudden!</string>

<!-- Slasher Module -->
<string name="slasher_message_ready">Klaar?</string>

<!-- Photosphere Module -->
<string name="photosphere_test_widget_template" translatable="false">x: %1$.2f, y: %2$.2f, z: %3$.2f, yaw: %4$.2f, pitch: %5$.2f, roll: %6$.2f</string>

<!-- QRCode Hunt Module -->
<string name="qrcode_hunt_counter_template">Codes: <xliff:g example="7" id="number">%1$d</xliff:g> / <xliff:g example="7" id="number">%2$d</xliff:g></string>
<string name="qrcode_hunt_congratulations">Goed!</string>
<string name="qrcode_hunt_wrong_code_warning">Verkeerde code!</string>

<!-- Selfie Module -->
<string name="selfie_share_label">Foto delen&#8230;</string>

<!-- Photo Hunt Module -->
<string name="photo_hunt_score_template">Score: <xliff:g example="7" id="number">%d</xliff:g> ptn</string>
<string name="photo_hunt_counter_template">Locaties: <xliff:g example="7" id="number">%1$d</xliff:g> / <xliff:g example="7" id="number">%2$d</xliff:g></string>
<string name="photo_hunt_wrong_location">Nee! Verkeerde plaats!</string>
<string name="photo_hunt_guidance_confirmation">Wat punten opofferen om het kompas te gebruiken?</string>

<!-- Slasher Module -->
<string name="slasher_score_template">Score: <xliff:g example="7" id="number">%d</xliff:g> punten</string>

<!-- Score Module -->
<string name="score_result_good_title">Gefeliciteerd!</string>
<string name="score_result_average_title">Zo dichtbij&#8230;</string>
<string name="score_result_bad_title">Oh nee!</string>
<string name="score_template">Score: <xliff:g example="7" id="number">%1$d</xliff:g> punten</string>

<!-- End Module -->
<string name="end_instruction" translatable="false">Enter your name and mail address and submit your score</string>
<string name="end_name_required_warning" translatable="false">Name required!</string>
<string name="end_name_hint" translatable="false">Your name</string>
<string name="end_email_hint" translatable="false">Your email</string>

<!-- Calories -->
<string name="calories_template_cal"><xliff:g example="7" id="number">%d</xliff:g> cal</string>

<!-- Clock accessibility strings -->
<plurals name="Nhours_description">

    <!-- 1 hour -->
    <item quantity="one">1 uur</item>
    <!-- more -->
    <item quantity="other"><xliff:g example="7" id="number">%d</xliff:g> uren</item>
</plurals>
<plurals name="Nminutes_description">

    <!-- 1 minute -->
    <item quantity="one">1 minuut</item>
    <!-- more -->
    <item quantity="other"><xliff:g example="7" id="number">%d</xliff:g> minuten</item>
</plurals>
<plurals name="Nseconds_description">

    <!-- 1 second -->
    <item quantity="one">1 seconde</item>
    <!-- more -->
    <item quantity="other"><xliff:g example="7" id="number">%d</xliff:g> seconden</item>
</plurals>

<string name="check_permission">Controleer de machtigingen</string>
<string name="experience_progression_restart_label_confirm_yes">Ja</string>
<string name="experience_progression_restart_label_confirm_no">Nee</string>
<string name="experience_exit_button">Afsluiten</string>
<string name="quizz_check_my_answer">Controleer mijn antwoorden</string>
<string name="photo_hunt_guidance_button">Ik heb hulp nodig</string>
<string name="photo_hunt_found_button">Ik heb het gevonden</string>
<string name="photo_hunt_guidance_confirmation_show">Kompas weergeven</string>
<string name="photo_hunt_guidance_confirmation_does_not_show">Mijn punten behouden</string>
<string name="selfie_continue">Opslaan en doorgaan</string>
<string name="selfie_shoot">Maak deze selfie</string>
<string name="selfie_reshoot">Maak deze selfie opnieuw</string>
</resources>

`

let parser =new DOMParser()
let xmlDoc = parser.parseFromString(xml, "text/xml");
console.log('parsed',xmlDoc);
let xmlStrings = xmlDoc.getElementsByTagName('string'), xmlTranslation = {}

for (var i = 0; i < xmlStrings.length; i++) {
  var name = xmlStrings[i].getAttribute("name");
  xmlTranslation[name] = xmlStrings[i].innerHTML
  //console.log(name, xmlStrings[i].innerHTML)
}
xmlTranslation["notranslated"] = " "

let map = {
  "experience_progression_restart_label_confirm":"confirmation_dialog_title",
  "experience_exit_warning_action_continue":"experience_progression_continue_label",
  "quizz_confirm":"confirmation_dialog_title",
  "stop_while_waiting_for_download_title":"download_notification_channel",
  "stop_while_waiting_for_download":"experience_waiting_for_download_message",
  "warning_download_before_title":"warning_download_title",
  "warning_download_before_text":"warning_download_message",
  "photo_hunt_give_up":"experience_exit_warning_action_stop",
  "selfie_share":"selfie_share_label",
  "multiplayer_module_number_of_player":"multiplayer_module_players_label",
  "track_default_title":"notranslated",
  "button_default_title":"notranslated",
  "permission_storage":"permission_storage_description",
  "permission_camera":"permission_camera_description",
  "permission_location":"permission_location_description",
  "point_check_guidance_compass_inaccurate":"notranslated",
  "quizz_confirm_yes":"experience_progression_restart_label_confirm_yes",
  "quizz_confirm_no":"experience_progression_restart_label_confirm_no"
}//propertylist:xml

let properties = propertiesList.split("\n")
  .filter(p=>p.length > 10)
  .map(p=>p.split("="))
  //.map(ps=>ps[0])
  .map(([first,...other])=>[first.trim(),...other])
  .map(([first,...other])=>[first.substr(1,first.length-2),...other])
  //.filter(([first,...other])=>!xmlTranslation[first] && !xmlTranslation[map[first]])
  //.map(([first,second])=>`<string name="${first}">${second.trim().substr(1,second.trim().length-3)}</string>`)
  //.join("\n\t")

  .map(([p])=>`"${p}" = "${xmlTranslation[p] || xmlTranslation[map[p]]}";`)
  .join("\n")

console.log("translated properties",properties)

*/

/*
class ApplicationsMap extends Component{
  state = {
    loading: true,
    applications: []
  };
  populateState() {
    this.setState({loading: true});
    let promises = []
    promises.push(api.applications.list().then(applications => this.setState({applications})))

    promises.push(api.variants.list().then(variants => this.setState({variants})))
    promises.push(api.resourcesApplication.list().then(resources => this.setState({resources})))
    promises.push(api.sites.list().then(sites => this.setState({sites})))
    promises.push(api.experiences.list().then(experiences => this.setState({experiences})))

    Promise
      .all(promises)
      .then(() => this.setState({loading: false}))
  }
  render(){
    let {applications, variants, resources, experiences,sites} = this.props;
    let agglomeratedExperiences  = []
    sites && sites
      .forEach(s=>{
        let usedLocation = [];
        (experiences || [])
          .filter(xp=>xp.siteId === s._id)
          .filter(xp=>!!xp.location && xp.location.length === 2)
          .filter(xp=>{
            let near = usedLocation.find(l=> (l[0] - xp.location[0])*(l[0] - xp.location[0]) +  (l[1] - xp.location[1])*(l[1] - xp.location[1]) < 6)
            if(near){
              return 0
            }
            usedLocation.push(xp.location)
            agglomeratedExperiences.push(xp)
            return 1
          })

      })

    return  <GoogleMap
            defaultZoom={6}
            defaultCenter={{lat:46,lng:4}}
          >
          {
            agglomeratedExperiences && agglomeratedExperiences.map(xp=>{
            let location = xp.location

            if(!location  || location.length === 0){
              return null
            }
            let application = applications.find(a=>a.site === xp.siteId && a.type === "android") || {}
            let variant = variants.find(v=>v.applicationId === application._id ) || {}
            let icon = resources.find(r=>r.variantId === variant._id && r.name === "map_marker") || {file:"42828deb-3c6a-4a62-a9c5-e86173ec4850.png"}
            if(!icon){
              console.log("pas d'icone ",xp,application,variant,icon)
              return null
            }

            return <Marker position={{lat:xp.location[1],lng:xp.location[0]}} key={xp._id}
              icon={
                {url:api.resourcesApplication.getUrl(icon.file),
                 // origin:{x:0.5,y:0.5},
                scaledSize:{width:27,height:36}}
              }/>
          })}
          </GoogleMap>
  }
}
*/
// const DecoratedAM = withScriptjs(withGoogleMap(observer(ApplicationsMap)))

class ApplicationsReviews extends Component {
  state = {
    loading: false,
    reviews: []
  }

  populate(props) {
    const { applications } = props
    this.setState({ loading: true })
    const promises = applications
      .filter(a => !!a.applicationId)
      .map(application => {
        const { applicationId } = application
        return fetch('https://itunes.apple.com/fr/rss/customerreviews/id=' + applicationId + '/sortBy=mostRecent/json')
          .then(res => res.json())
          .then(json => {
            let reviews = (json && json.feed && json.feed.entry && json.feed.entry) || []
            if (!Array.isArray(reviews)) {
              reviews = [reviews] // si il n'y a qu'une review, alors la review n'est pas un tableau
            }
            reviews = reviews.map(review => {
              return { ...review, application }
            })
            return reviews
          })
      })

    Promise.all(promises).then(storeReviews => {
      const reviews = [].concat
        .apply([], storeReviews) // flatten
        .sort((a, b) => parseInt(a.id.label, 10) - parseInt(b.id.label, 10))
      this.setState({ reviews, loading: false })
    })
  }

  componentDidMount() {
    this.populate(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.applicationId !== nextProps.applicationId) {
      this.populate(nextProps)
    }
  }

  render() {
    const { loading, reviews } = this.state
    if (loading) {
      return <Loading />
    }
    return (
      <div>
        {reviews.length > 0 &&
          reviews
            .sort((review1, review2) => {
              if (new Date(review1.updated.label) > new Date(review2.updated.label)) return -1
              else return 1
            })
            .map(review => {
              const dateReview = new Date(review.updated.label)
              return (
                <div key={review.id.label} className="clear">
                  <h4 className="pull-right">{review['im:rating'].label} / 5</h4>
                  <h3>
                    {review.title.label}{' '}
                    <small>
                      sur <a href={'/application/' + review.application._id}>{review.application.name}</a> par{' '}
                      {review.author.name.label}, le {dateReview.getDate()}/{dateReview.getMonth() + 1}/{dateReview.getFullYear()}
                    </small>
                  </h3>
                  <p>
                    {review.content.label} <br />
                    <a href={review.link.attributes.href} target="_blank" rel="noopener noreferrer">
                      voir
                    </a>
                  </p>
                </div>
              )
            })}
        {reviews.length === 0 && " pas de review pour l'instant"}
      </div>
    )
  }
}

class Applications extends Component {
  state = {
    loading: false,
    applications: []
  }

  populateState() {
    this.setState({ loading: true })
    const promises = []
    promises.push(api.applications.list().then(applications => this.setState({ applications })))

    promises.push(api.variants.list().then(variants => this.setState({ variants })))
    promises.push(api.resourcesApplication.list().then(resources => this.setState({ resources })))
    // promises.push(api.sites.list().then(sites => this.setState({sites})))
    // promises.push(api.experiences.list().then(experiences => this.setState({experiences})))

    Promise.all(promises).then(() => this.setState({ loading: false }))
  }

  componentDidMount() {
    this.populateState(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.populateState(nextProps)
  }

  render() {
    const { loading, applications, variants, resources /*, experiences,sites */ } = this.state

    // const googleMapURL='https://maps.googleapis.com/maps/api/js?key='+conf.GOOGLE_API_KEY+'&v=3.exp&libraries=geometry,drawing,places'
    /*
    console.log(JSON.stringify(applications
      .filter(a=>a.type === "android")
      .map(a=>{
      let applicationVariants = (variants || []).filter(v=>v.applicationId === a._id)
      let experience = (experiences || []).find(xp=>xp.siteId === a.site) || {}
      let icon = (resources || []).find(r=>applicationVariants.find(v=>v._id === r.variantId) && r.name === "ic_launcher") || {}
      let splashcreen = (resources || []).find(r=>applicationVariants.find(v=>v._id === r.variantId) && r.name === "splashscreen") || {}
      return {
        _id:a._id,
        location:experience.location,
        application:{
          icon:api.resourcesApplication.getUrl(icon.file),
          name:a.name,
          splashcreen:api.resourcesApplication.getUrl(splashcreen.file)
        },
        video:"https://www.youtube.com/watch?v=dQw4w9WgXcQ"
      }
    })))
*/
    return (
      <Page>
        <NavInSites />
        <div id="content">
          {loading && <Loading />}
          <Link to="/application/" className="btn btn-block btn-primary">
            Créer une nouvelle application
          </Link>
          <div className="applications-container">
            {!loading &&
              applications.map(application => {
                const applicationVariants = variants.filter(v => v.applicationId === application._id)
                const icon = resources.find(r => applicationVariants.find(v => v._id === r.variantId) && r.name === 'ic_launcher')
                const iconfg = resources.find(
                  r => applicationVariants.find(v => v._id === r.variantId) && r.name === 'ic_launcher_foreground'
                )
                const iconbg = resources.find(
                  r => applicationVariants.find(v => v._id === r.variantId) && r.name === 'ic_launcher_background'
                )
                const splascreen = resources.find(
                  r => applicationVariants.find(v => v._id === r.variantId) && r.name === 'splashscreen'
                )
                iconbg && console.log(iconbg)
                iconbg && console.log(api.resourcesApplication.getUrl(iconbg.file))
                return (
                  <Link to={'/application/' + application._id} className="application-preview" key={application._id}>
                    <h3>
                      {application.name}({application.type})
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      {iconfg && iconbg && (
                        <div
                          style={{
                            width: '80px',
                            backgroundImage: `url('${api.resourcesApplication.getUrl(iconbg.file)}`,
                            backgroundPosition: 'cover',
                            height: '80px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <img
                            src={api.resourcesApplication.getUrl(iconfg.file)}
                            style={{ width: '40px', height: '40px' }}
                            alt="icône de l'application"
                          />
                        </div>
                      )}
                      {!iconfg && !iconbg && icon && (
                        <img
                          style={{ width: '80px', height: '80px' }}
                          src={api.resourcesApplication.getUrl(icon.file)}
                          alt="icône de l'application"
                        />
                      )}
                      {splascreen && (
                        <img
                          style={{ height: '130px' }}
                          src={api.resourcesApplication.getUrl(splascreen.file)}
                          alt="écran de lancement de l'application"
                        />
                      )}
                    </div>
                  </Link>
                )
              })}
            {!loading && <ApplicationsReviews applications={applications} />}
          </div>
        </div>
        <div id="app-preview" />
      </Page>
    )
    /*

    return <div style={{display:"flex",height:"100vh", backgroundColor:"rgba(128, 106, 100, 1)"}}>
    <div style={{flex:"0 0 300px",overflow:"scroll"}}>
    <div>
      {sites && sites.map(site=>{
        let application = applications.find(a=>a.site === site._id) || {}
        let applicationVariants = variants ? variants.filter(v=>v.applicationId === application._id) : []
        let icon = resources ? resources.find(r=>applicationVariants.find(v=>v._id === r.variantId) && r.name === "ic_launcher"): null

        if(!icon){
          return null
        }
        return <div style={{backgroundColor:"rgba(255,255,255,0.8",clear:"both",minHeight:"70px",margin:"0.5em"}}>
         {icon &&<img style={{width:"60px",height:"60px",float:"left",margin:"0.5em"}} src={api.resourcesApplication.getUrl(icon.file)}/>}
          <h5>{application.name || site.name}</h5>
          {experiences && experiences
            .filter(xp=>xp.siteId === site._id && xp.locale === "fr")
            .filter((xp,index)=>index < 2)
            .map(xp=><span>{xp.name}<br/></span>)}
        </div>
      })}
      </div>
    </div>
      <DecoratedAM

              googleMapURL={googleMapURL}
              loadingElement={ <div style={{ height: `100` }}/>}
              containerElement={ <div style={{ height: `100vh`,flex:1 }} />}
              mapElement={ <div style={{ height: `100%` }} />}
              {...{applications, variants, resources, experiences,sites}}
              />
              <div style={{flex:"0 0 300px"}}
              ></div>
      </div> */
  }
}

export default Applications
