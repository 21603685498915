import React, { Component } from 'react'
import QRCodeReact from 'qrcode.react'

import * as api from '../../lib/api'
import { NavInTrack } from '../../components/nav'
import Page from '../page'
// https://github.com/SumiMakito/Awesome-qr.js/
export default class TrackChecks extends Component {
  state = {
    points: []
  }

  componentDidMount() {
    const { trackUid } = this.props.match.params
    api.points.getFrom('tracks', trackUid).then(points => this.setState({ points }))
  }

  render() {
    const { points } = this.state
    return (
      <Page>
        <NavInTrack />
        <div id="content">
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {points.map(point => {
              if (point.check) {
                if (point.check.type === 'qrcode') {
                  return (
                    <div style={{ backgroundColor: 'white', margin: '1em', padding: '10px' }} key={point._id}>
                      <label className="control-label">{point.name}</label>
                      <br />
                      <label className="control-label">{point.internalName}</label>
                      <br />
                      <small>{point.check.code || ''}</small>
                      <br />
                      <QRCodeReact style={{ padding: '10px' }} value={point.check.code || ''} size={180} level="M" />
                    </div>
                  )
                }
                if (point.check.type === 'code') {
                  return (
                    <div
                      style={{
                        flex: '0 1 300px',
                        backgroundColor: 'white',
                        margin: '1em'
                      }}
                      key={point._id}
                    >
                      <label className="control-label">
                        Code pour {point.name} : {point.check.code}
                      </label>
                    </div>
                  )
                }
              }
              return (
                <div
                  style={{
                    flex: '0 1 300px',
                    backgroundColor: 'white',
                    margin: '1em'
                  }}
                  key={point._id}
                >
                  <label className="control-label">pas de validation pour {point.name}</label>
                </div>
              )
            })}
          </div>
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}
