import checkExperience from './experience'
import checkTrack from './track'
import checkPoint from './point'
import checkScenario from './scenario'
import checkModule from './module'
import * as api from '../api'

const deepCheck = async experienceId => {
  const agglomerated = await api.releases.listFromExperience(
    'test',
    experienceId
  )
  const experience = await api.experiences.get(experienceId)
  await Promise.all([
    api.modules.getFrom('experiences', experienceId),
    api.scenarios.getFrom('experiences', experienceId),
    api.points.getFrom('experiences', experienceId),
    api.scenarios.getFrom('experiences', experienceId),
    api.tracks.getFrom('experiences', experienceId),
    api.segments.getFrom('experiences', experienceId)
  ])
  let checkResult = await checkExperience(experience)

  const tracks = Object.keys(agglomerated.tracks).map(
    key => agglomerated.tracks[key]
  )
  for (let index = 0; index < tracks.length; index++) {
    const { points, ...track } = tracks[index]
    const res = await checkTrack({ experienceId, ...track })
    checkResult = {
      errors: checkResult.errors.concat(res.errors),
      warnings: checkResult.warnings.concat(res.warnings),
      nb: checkResult.nb + res.nb
    }

    for (let pointIndex = 0; pointIndex < points.length; pointIndex++) {
      const res = await checkPoint({
        trackId: track._id,
        experienceId,
        ...points[pointIndex]
      })
      checkResult = {
        errors: checkResult.errors.concat(res.errors),
        warnings: checkResult.warnings.concat(res.warnings),
        nb: checkResult.nb + res.nb
      }
    }
  }

  const scenarios = Object.keys(agglomerated.scenarios).map(
    key => agglomerated.scenarios[key]
  )
  for (let index = 0; index < scenarios.length; index++) {
    const res = await checkScenario({ experienceId, ...scenarios[index] })
    checkResult = {
      errors: checkResult.errors.concat(res.errors),
      warnings: checkResult.warnings.concat(res.warnings),
      nb: checkResult.nb + res.nb
    }
  }
  return checkResult
}

export default {
  deepCheck,
  checkExperience,
  checkTrack,
  checkPoint,
  checkScenario,
  checkModule
}
