import generify from './generic'
import { user, conf } from '../../stores'

export const sessions = generify('sessions-service', 'sessions', 'session')

function recursiveAdd(a1, a2, excludedKeys) {
  if (!a1) {
    return a2 || 0
  }
  if (typeof a1 === 'object') {
    const dest = Array.isArray(a1) ? [] : {}
    Object.keys(a1).forEach(key => {
      if (excludedKeys.indexOf(key) >= 0) {
        dest[key] = a1[key]
        return
      }
      dest[key] = recursiveAdd(a1[key], (a2 || {})[key], excludedKeys)
    })
    return dest
  }
  return a1 + (a2 || 0)
}

sessions.stats = (axis, opts) => {
  let { experienceId, experienceIds, siteId, siteIds, from, to, excludeZeros, group, axisProperties } = opts

  from = from || new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString()
  to = to || new Date().toISOString()
  group = group || ''
  const baseUrls = []
  if (!siteIds && siteId) {
    siteIds = [siteId]
  }
  if ((!experienceIds || experienceIds.length === 0) && experienceId) {
    experienceIds = [experienceId]
  }
  if (experienceIds && experienceIds.length > 0) {
    experienceIds
      .filter(experienceId => !!experienceId)
      .forEach(experienceId => {
        baseUrls.push('/experiences/' + experienceId + '/stats/' + axis)
      })
  } else {
    if (siteIds) {
      siteIds.forEach(siteId => {
        baseUrls.push('/sites/' + siteId + '/stats/' + axis)
      })
    } else {
      baseUrls.push('/stats/' + axis)
    }
  }
  const promises = baseUrls.map(baseUrl => {
    return fetch(
      conf.API_URL +
        '/sessions-service' +
        baseUrl +
        '?' +
        'from=' +
        from +
        '&to=' +
        to +
        '&group=' +
        group +
        (excludeZeros ? '&excludeZeros=true' : ''),
      {
        headers: {
          Authorization: 'Bearer ' + user.token
        }
      }
    )
      .then(res => res.json())
      .then(json => {
        if (json.code && json.msg) {
          throw new Error(json.msg)
        }
        return json
      })
  })
  const index = {}
  if (!axisProperties) {
  }
  return Promise.all(promises).then(stats => {
    stats.forEach(statForAnExperience => {
      // stat for on experience
      statForAnExperience.forEach(statForAKey => {
        const key = axisProperties.map(axisProperty => statForAKey[axisProperty]).join('/')
        const current = index[key] || {}
        if (!current) {
          index[key] = statForAKey
          return
        }
        index[key] = recursiveAdd(statForAKey, current, axisProperties)
      })
    })
    const aggro = []
    Object.keys(index).forEach(key => {
      aggro.push(index[key])
    })
    return aggro
  })
}
