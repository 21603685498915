import { toJS } from 'mobx'
import { apiCache } from '../../../../stores'

const updateMaxScoreFromActions = (actions, chapters, stack) => {
  if (!actions) {
    return 0
  }
  let score = 0
  actions.forEach(({ type, scenario: scenarioId, track: trackId }) => {
    if (type === 'load_scenario') {
      score += updateMaxScore(chapters[scenarioId], chapters, stack)
    }
    if (type === 'load_track') {
      const trackChapter = chapters[trackId]
      const track = trackChapter && trackChapter.track
      if (track && ['horde', 'chase', 'photo_hunt'].indexOf(track.type) >= 0) {
        score += updateMaxScore(trackChapter, chapters, stack)
      }
    }
  })
  return score
}
const updateMaxScore = (chapter, chapters, stack) => {
  stack = stack || []
  if (!chapter) {
    return 0
  }
  if (stack.indexOf(chapter._id) >= 0) {
    return 0
  }
  stack.push(chapter._id)
  if (chapter.score) {
    return chapter.score
  }
  let score = 0
  switch (chapter.type) {
    case 'experience':
      const experience = chapter.experience
      score = updateMaxScoreFromActions(experience.onStart, chapters, stack)
      if (experience.timers) {
        Object.keys(experience.timers).forEach(key => {
          const timer = experience.timers[key]
          const timerScore = updateMaxScoreFromActions(timer.actions, chapters, stack)
          score += timerScore
        })
      }

      score += Object.keys(chapters)
        .map(key => chapters[key])
        .filter(({ track }) => track && ['horde', 'chase', 'photo_hunt'].indexOf(track.type) < 0)
        .map(chapter => updateMaxScore(chapter, chapters, stack))
        .reduce((total, value) => value + total, 0)
      chapter.score = score
      return score
    case 'track':
      const track = chapter.track

      score = Object.keys(chapters)
        .map(key => chapters[key])
        .filter(({ point }) => point && point.trackId === chapter._id)
        .reduce((acc, moduleChapter) => {
          const score = updateMaxScore(moduleChapter, chapters, stack)
          return score + acc
        }, 0)
      track.events &&
        track.events.forEach(({ actions }) => {
          score += updateMaxScoreFromActions(actions, chapters, stack)
        })
      track.menu &&
        track.menu.forEach(({ actions }) => {
          score += updateMaxScoreFromActions(actions, chapters, stack)
        })
      if (track.scenario) {
        score += updateMaxScore(chapters[track.scenario], chapters, stack)
      }
      score += track.maxScore || 0
      chapter.score = score
      return score
    case 'point':
      const point = chapter.point
      score = (point.points || 0) + (point.bonus || 0) /* photo_hunt */
      if (point.check) {
        score += updateMaxScore(chapters[point.check.scenario], chapters)
        score += updateMaxScore(chapters[point.check.clue], chapters)
      }
      score += updateMaxScore(chapters[point.scenario], chapters)
      chapter.score = score
      return score
    case 'scenario': // the score of a scenario is the total of the module's score
      const scenario = chapter.scenario
      score = Object.keys(chapters)
        .map(key => chapters[key])
        .filter(({ module }) => module && module.scenarioId === chapter._id)
        .reduce((acc, moduleChapter) => {
          const score = updateMaxScore(moduleChapter, chapters, stack)
          return score + acc
        }, 0)
      // add onEnd

      score += updateMaxScoreFromActions(scenario.onEnd, chapters, stack)
      chapter.maxScore = score || 0

      return score

    case 'module':
      const module = chapter.module
      switch (module.type) {
        case 'choice':
          score = (module.choices || []).reduce((currentMax, { actions }) => {
            const score = updateMaxScoreFromActions(actions, chapters, stack)
            return Math.max(score || 0, currentMax)
          }, 0)
          break
        case 'differences_photosphere':
        case 'differences':
          score = (module.differences || []).reduce((total, { points }) => total + (parseInt(points, 10) || 0), 0)
          break
        case 'dragdrop':
          score = (module.drags || []).reduce((total, { points }) => (points || 0) + total, 0)
          break
        case 'image_mcq':
        case 'mcq':
          score = (module.answers || []).length * (module.points || 0)
          break
        case 'puzzle':
          score = (module.pieces || []).length * (module.points || 0)
          break
        case 'qrcode_hunt':
          score = (module.codes || []).length * (module.points || 0)
          break
        // case 'level':
        // score = module.(module.reward || 0) * (module.codes || []).length;
        // break;
        default:
          score = module.points || 0
      }
      if (module.toolButton) {
        score += updateMaxScoreFromActions([module.toolButton.action], chapters, stack)
      }
      chapter.score = score
      return score

    default:
      console.warn('chapter of type ' + chapter.type + ' unkonw in compute max score')
      return 0
  }
}
const updateUsedIn = chapters => {
  const update = (obj, chapters, path) => {
    if (!obj) {
      return []
    }
    let founds = []
    if (typeof obj === 'object') {
      Object.keys(obj)
        .filter(
          key =>
            (path || '').split('/').lengh > 1 ||
            ['_id', 'id', 'experienceId', 'experienceUid', 'scenarioId', 'scenarioUid', 'trackId'].indexOf(key) < 0
        )
        .forEach(key => (founds = founds.concat(update(obj[key], chapters, (path || '') + '/' + key))))
      return founds
    }
    if (chapters[obj]) {
      chapters[obj].usedBy = (chapters[obj].usedBy || []).concat([path])
    }
    return []
  }
  Object.keys(chapters)
    .map(key => chapters[key])
    .forEach(chapter => {
      const obj = chapter[chapter.type]
      update(obj, chapters, obj._id)
    })

  Object.keys(chapters)
    .map(key => chapters[key])
    .forEach(chapter => {
      const agglomeratedUsage = {}
      ;(chapter.usedBy || []).forEach(usage => {
        const [id, ...path] = usage.split('/')
        agglomeratedUsage[id] = (agglomeratedUsage[id] || []).concat(path.join('/'))
      })
      chapter.usedBy = agglomeratedUsage || {}
    })
}

export default (siteUid, experienceUid) => {
  const data = [
    apiCache.tracks.getFrom('experiences', experienceUid),
    apiCache.scenarios.getFrom('experiences', experienceUid),
    apiCache.modules.getFrom('experiences', experienceUid),
    apiCache.points.getFrom('experiences', experienceUid),
    apiCache.experiences.get(experienceUid)
  ]
  const [tracks, scenarios, modules, points, experience] = data.map(arr =>
    Array.isArray(arr) ? arr.map(o => toJS(o)) : toJS(arr)
  )
  if (!tracks || !scenarios || !modules || !points || !experience) return null

  let currentChapter = 2
  let currentScene = 1

  function searchScenario(json) {
    if (!json) return []
    if (typeof json === 'object') {
      let founds = []
      Object.keys(json)
        .filter(k => k !== 'scenarioId')
        .map(k => json[k])
        .forEach(sub => {
          founds = founds.concat(searchScenario(sub))
        })
      return founds
    }
    return scenarios.filter(s => s._id === json)
  }

  function addTrack(track) {
    if (!track) return
    if (typeof track !== 'object') track = tracks.find(t => t._id === track)
    if (!track || res[track._id]) return
    res[track._id] = {
      type: 'track',
      track,
      _id: track._id,
      chapter: currentChapter,
      url: '/site/' + siteUid + '/experience/' + experienceUid + '/track/' + track._id
    }
    currentChapter++
    points
      .filter(p => p.trackId === track._id)
      .sort((p1, p2) => p1.order - p2.order)
      .forEach(point => {
        addPoint(point)
      })
    track.failure && addScenario(track.failure, track._id)
    track.scenario && addScenario(track.scenario, track._id)
  }

  function addPoint(point) {
    if (!point || res[point._id]) return
    res[point._id] = {
      type: 'point',
      point,
      _id: point._id,
      chapter: currentChapter,
      url: '/site/' + siteUid + '/experience/' + experienceUid + '/track/' + point.trackId + '/point/' + point._id
    }
    currentChapter++
    point.check && point.check.clue && addScenario(point.check.clue, point.trackId, true)
    point.check && point.check.scenario && addScenario(point.check.scenario, point.trackId, true)
    point.scenario && addScenario(point.scenario)
  }

  function addScenario(scenario, trackOrigin, sameChapter = false) {
    if (!scenario) return
    if (typeof scenario !== 'object') scenario = scenarios.find(s => s._id === scenario)
    if (!scenario || res[scenario._id]) return
    const scenarioModules = modules.filter(({ scenarioId }) => scenarioId === scenario._id).sort((m1, m2) => m1.order - m2.order)
    if (scenarioModules.length === 0 && (!scenario.onEnd || scenario.onEnd.length === 0)) return
    if (
      scenarioModules.length === 1 &&
      scenarioModules[0].type === 'gallery' &&
      (!scenarioModules[0].pictures || scenarioModules[0].pictures.length === 0)
    )
      return // empty clue scenario
    res[scenario._id] = {
      type: 'scenario',
      scenario,
      _id: scenario._id,
      chapter: currentChapter,
      trackId: trackOrigin,
      url: '/site/' + siteUid + '/experience/' + experienceUid + '/scenario/' + scenario._id,
      usedBy: [], // trackOrigin ? [trackOrigin] : []
      scene: currentScene
    }
    currentChapter++
    currentScene = 1

    scenarioModules.forEach(module => {
      addModule(module)
    })
    scenario.onEnd &&
      scenario.onEnd
        .filter(({ type }) => type === 'load_scenario' || type === 'load_track')
        .forEach(({ type, scenario: scenarioId, track: trackId }) => {
          if (type === 'load_scenario') {
            scenario && addScenario(scenarioId, trackOrigin)
          }
          if (type === 'load_track' && trackId !== trackOrigin) {
            const track = tracks.find(t => t._id === trackId)
            track && addTrack(track, trackOrigin)
          }
        })
    searchScenario(scenarioModules).forEach(scenario => {
      addScenario(scenario, trackOrigin)
    })
  }

  function addModule(module) {
    res[module._id] = {
      type: 'module',
      module,
      _id: module._id,
      scene: currentScene,
      url: '/site/' + siteUid + '/experience/' + experienceUid + '/scenario/' + module.scenarioId + '/module/' + module._id
    }
    currentScene++
  }

  const res = {
    [experience._id]: {
      type: 'experience',
      experience,
      chapter: 1,
      _id: experience._id,
      url: '/site/' + siteUid + '/experience/' + experienceUid
    }
  }
  experience.onStart &&
    experience.onStart.forEach(({ type, scenario: scenarioId }) => {
      if (type === 'load_scenario' && scenarioId) {
        const scenario = scenarios.find(s => s._id === scenarioId)
        addScenario(scenario)
      }
    })
  experience.startTrack && addTrack(experience.startTrack)
  experience.timers &&
    Object.keys(experience.timers).forEach(key => {
      const timer = experience.timers[key]
      timer.actions &&
        timer.actions.forEach(({ type, scenario: scenarioId }) => {
          if (type === 'load_scenario' && scenarioId) {
            const scenario = scenarios.find(s => s._id === scenarioId)
            addScenario(scenario)
          }
        })
    })

  tracks.forEach(track => {
    addTrack(track)
  })
  scenarios.forEach(scenario => {
    // will also add module
    addScenario(scenario)
  })
  tracks.forEach(track => {
    // will also add points
    addTrack(track)
  })
  updateMaxScore(res[experience._id], res)
  updateUsedIn(res)
  return res
}
