import React, { Component } from 'react'
import { observer } from 'mobx-react'
import scrollToComponent from 'react-scroll-to-component'

import * as api from '../../../lib/api'

import { Page } from '../../'
import { NavInExperience } from '../../../components/nav'
import computeChapters from './lib/computeChapters'
import BookChapter from './chapter'
import BookModule from './module'
import BookScenario from './scenario'
import './book.css'

class Book extends Component {
  state = {
    chapters: null
  }

  chapterRefs = []
  componentDidMount() {
    this.populateIndex()
  }

  async populateIndex() {
    const { experienceUid, siteUid } = this.props.match.params
    this.setState({ loading: true })
    await Promise.all([
      api.tracks.getFrom('experiences', experienceUid),
      api.scenarios.getFrom('experiences', experienceUid),
      api.modules.getFrom('experiences', experienceUid),
      api.points.getFrom('experiences', experienceUid),
      api.experiences.get(experienceUid),
      api.resources.getFrom('experiences', experienceUid)
    ])
    const chapters = computeChapters(siteUid, experienceUid)
    this.setState({ loading: false, chapters })
  }

  componentWillReceiveProps(nextProps) {
    let hash = nextProps.location.hash
    if (!hash) {
      return
    }
    hash = parseInt(hash.substr(1, hash.length - 1), 10)
    const ref = this.chapterRefs[hash]
    if (ref) {
      scrollToComponent(ref, {
        align: 'top'
      })
    }
  }

  render() {
    const { chapters } = this.state
    return (
      <Page>
        <NavInExperience />
        <div id="content">
          {!chapters && 'Ecriture en cours'}
          {chapters && (
            <div className="book">
              {Object.keys(chapters)
                .map(key => chapters[key])
                .filter(({ chapter }) => !!chapter)
                .sort((p1, p2) => p1.chapter - p2.chapter)
                .map((chapter, index) => {
                  const { type, _id, point, scenario } = chapter
                  let children = null
                  if (type === 'scenario') {
                    children = Object.keys(chapters)
                      .map(key => chapters[key])
                      .filter(({ type, module: m }) => {
                        return type === 'module' && m.scenarioId === _id
                      })
                      .sort(({ scene: sceneModule1 }, { scene: sceneModule2 }) => sceneModule1 - sceneModule2)
                      .map(moduleChapter => {
                        return <BookModule {...moduleChapter} chapters={chapters} key={moduleChapter._id} />
                      })
                    const cluePoint = Object.values(chapters).find(
                      ({ type: t, point: p }) => t === 'point' && p?.check?.clue === scenario?._id
                    )
                    if (cluePoint) return null
                  } else if (type === 'point') {
                    const clueScenario = Object.values(chapters).find(
                      ({ type: t, scenario: s }) => t === 'scenario' && point?.check?.clue === s?._id
                    )
                    if (clueScenario) {
                      const childrenScenario = Object.values(chapters)
                        .filter(({ type: t, module: m }) => t === 'module' && m.scenarioId === point?.check?.clue)
                        .sort(({ scene: sceneModule1 }, { scene: sceneModule2 }) => sceneModule1 - sceneModule2)
                        .map(moduleChapter => {
                          return <BookModule {...moduleChapter} chapters={chapters} key={moduleChapter._id} />
                        })
                      children = (
                        <BookScenario {...clueScenario} chapters={chapters} key={clueScenario._id}>
                          {childrenScenario}
                        </BookScenario>
                      )
                    }
                  }
                  return (
                    <div
                      ref={ref => {
                        this.chapterRefs[chapter.chapter] = ref
                      }}
                      key={chapter._id || index}
                    >
                      <BookChapter {...chapter} chapters={chapters}>
                        {children}
                      </BookChapter>
                    </div>
                  )
                })}
            </div>
          )}
          {/* {chapters && (
            <div className="book">
              <BookChapter type="conversations" conversations={{ name: 'Conversations' }} chapters={chapters}>
                <h1>Conversations</h1>
                {Object.values(chapters)
                  .filter(({ type, module }) => {
                    return type === 'module' && (module.type === 'conversation' || module.type === 'conversation_score')
                  })
                  .sort((p1, p2) => p1.chapter - p2.chapter)
                  .map((chapter, index) => {
                    const { type, _id, point, scenario } = chapter
                    console.log('test', chapter)
                    return (
                      <div
                        ref={ref => {
                          this.chapterRefs[chapter.chapter] = ref
                        }}
                        key={chapter._id || index}
                      >
                        <BookModule {...chapter} chapters={chapters} key={chapter._id} />
                      </div>
                    )
                  })}
              </BookChapter>
            </div>
          )} */}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}

export default observer(Book)
