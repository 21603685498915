import React, { Component } from 'react'

import Header from '../components/header'
import Footer from '../components/footer'
import Notifications from '../components/notifications'

export default class Page extends Component {
  render () {
    return (
      <div id='app'>
        <Notifications />
        <Header />
        <div>{this.props.children}</div>
        <Footer />
      </div>
    )
  }
}
