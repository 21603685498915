import React, { Component } from 'react'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'react-leaflet-fullscreen/dist/styles.css'
import 'react-google-places-autocomplete/dist/assets/index.css'

import L from 'leaflet'
import {
  Map,
  TileLayer,
  LayersControl,
  ImageOverlay,
  Polyline
} from 'react-leaflet'
import _ from 'react-leaflet-draw' // eslint-disable-line no-unused-vars
import FullscreenControl from 'react-leaflet-fullscreen'

import Control from 'react-leaflet-control'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-google-places-autocomplete'

import * as api from '../lib/api'
import { toJS } from 'mobx'
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default class BaseMap extends Component {
  state = {}
  constructor (props) {
    super(props)
    this.ref = React.createRef()
  }

  async componentDidMount () {
    const overlays = {}

    const { track } = this.props
    if (!track) {
      return
    }
    const trackMap = Object.entries(track.maps || {})
      .sort(([key1, order1], [key2, order2]) => order1 - order2)
      .map(([key]) => key)
    for (const mapUid of trackMap) {
      const map = await api.maps.get(mapUid)
      if (
        map &&
        map.type === 'ground' &&
        map.resource &&
        map.northEastBound[1] &&
        map.northEastBound[0] &&
        map.southWestBound[0] &&
        map.southWestBound[1]
      ) {
        const resource = await api.resources.get(map.resource)
        if (resource) {
          var url = api.resources.getUrl(
            resource.hd ? resource.hd.file : resource.original.file
          )
          overlays[map._id] = {
            url,
            _id: map._id,
            name: map.name,
            bounds: [toJS(map.northEastBound), toJS(map.southWestBound)]
          }
        }
      }
    }
    const routes = []
    for (const route of toJS(track.routes || [])) {
      const segment = route.segment && (await api.segments.get(route.segment))
      if (segment) {
        routes.push({
          segment: toJS(segment),
          color: route.color
        })
      }
    }
    this.setState({ overlays, routes })
  }

  pixelDistance (latlng1, latlng2) {
    const map = this.ref.current.leafletElement
    const p1 = map.latLngToLayerPoint(latlng1)
    const p2 = map.latLngToLayerPoint(latlng2)
    return p1.distanceTo(p2)
  }

  render () {
    const { overlays, routes } = this.state
    const currentZoom =
      (this.ref &&
        this.ref.current &&
        this.ref.current.leafletElement &&
        this.ref.current.leafletElement.getZoom()) ||
      this.props.zoom ||
      12

    return (
      <Map {...this.props} zoom={currentZoom} ref={this.ref}>
        <FullscreenControl position='topright' />
        <LayersControl position='topright'>
          <LayersControl.BaseLayer name='Mapbox.StreetsSatellite' checked>
            <TileLayer
              attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
              url='https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/512/{z}/{x}/{y}{r}?access_token=pk.eyJ1IjoiYWxlaXN0b3IiLCJhIjoiY2pqcGpkMWxtN2hoNzNrcjVnNmp1cW9kMyJ9.RTUhct0VcO_98yheNV9b1A'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name='Mapbox.Streets'>
            <TileLayer
              attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
              url='https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/512/{z}/{x}/{y}{r}?access_token=pk.eyJ1IjoiYWxlaXN0b3IiLCJhIjoiY2pqcGpkMWxtN2hoNzNrcjVnNmp1cW9kMyJ9.RTUhct0VcO_98yheNV9b1A'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name='Mapbox.Outdoors'>
            <TileLayer
              attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
              url='https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/512/{z}/{x}/{y}{r}?access_token=pk.eyJ1IjoiYWxlaXN0b3IiLCJhIjoiY2pqcGpkMWxtN2hoNzNrcjVnNmp1cW9kMyJ9.RTUhct0VcO_98yheNV9b1A'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name='OpenStreetMap.Mapnik'>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
          </LayersControl.BaseLayer>
          {Object.values(overlays || {}).map(overlay => {
            return (
              <LayersControl.Overlay
                name={overlay.name}
                key={overlay._id}
                checked
              >
                <ImageOverlay
                  interactive={false}
                  url={overlay.url}
                  bounds={overlay.bounds}
                />
              </LayersControl.Overlay>
            )
          })}

          {(routes || []).map(({ segment, color }) => (
            <LayersControl.Overlay
              name={segment.name}
              key={segment._id}
              checked
            >
              <Polyline positions={segment.points} />
            </LayersControl.Overlay>
          ))}
        </LayersControl>
        {this.props.children}

        <Control position='topright'>
          <GooglePlacesAutocomplete
            onSelect={async place => {
              const address = await geocodeByPlaceId(place.place_id)
              const location = await getLatLng(address[0])
              this.ref.current.leafletElement.flyTo(location)
            }}
          />
        </Control>
      </Map>
    )
  }
}
