// from https://gist.github.com/dcollien/312bce1270a5f511bf4a
import exif from 'exif-js'

const hasBlobConstructor =
  typeof Blob !== 'undefined' &&
  (function() {
    try {
      return Boolean(new Blob())
    } catch (e) {
      return false
    }
  })()

const hasArrayBufferViewSupport =
  hasBlobConstructor &&
  typeof Uint8Array !== 'undefined' &&
  (function() {
    try {
      return new Blob([new Uint8Array(100)]).size === 100
    } catch (e) {
      return false
    }
  })()

const hasToBlobSupport = typeof HTMLCanvasElement !== 'undefined' ? HTMLCanvasElement.prototype.toBlob : false

const hasBlobSupport =
  hasToBlobSupport || (typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined')

const hasReaderSupport = typeof FileReader !== 'undefined' || typeof URL !== 'undefined'

export function resize(file, maxSize) {
  return new Promise(resolve => {
    resizeCallBack(file, maxSize, (file, processed) => {
      resolve(file)
    })
  })
}
function resizeCallBack(file, maxSize, callback) {
  if (typeof maxSize === 'function') {
    callback = maxSize
    maxSize = 1500
  }
  maxSize = maxSize || 1500

  if (!file) {
    callback(null, false)
    return false
  }
  if (!isSupported() || !file.type.match(/image.*/)) {
    callback(file, false)
    return false
  }
  if (file.type.match(/image\/gif/)) {
    // Not attempting, could be an animated gif
    callback(file, false)
    // TODO: use https://github.com/antimatter15/whammy to convert gif to webm
    return false
  }
  const image = document.createElement('img')

  image.onload = imgEvt => {
    let width = image.width
    let height = image.height
    let isTooLarge = false

    if (width >= height && width > maxSize * 2.0) {
      // width is the largest dimension, and it's too big.
      height *= maxSize / width
      width = maxSize
      isTooLarge = true
    } else if (height > maxSize * 2.0) {
      // either width wasn't over-size or height is the largest dimension and the
      // height is over-size
      width *= maxSize / height
      height = maxSize
      isTooLarge = true
    }

    if (!isTooLarge) {
      // early exit; no need to resize
      callback(file, false)
      return
    }

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    const ctx = canvas.getContext('2d')
    ctx.drawImage(image, 0, 0, width, height)

    if (hasToBlobSupport) {
      canvas.toBlob(blob => {
        callback(blob, true)
      }, file.type)
    } else {
      const blob = _toBlob(canvas, file.type)
      callback(blob, true)
    }
  }
  _loadImage(image, file)

  return true
}

function _toBlob(canvas, type) {
  const dataURI = canvas.toDataURL(type)
  const dataURIParts = dataURI.split(',')
  let byteString
  if (dataURIParts[0].indexOf('base64') >= 0) {
    // Convert base64 to raw binary data held in a string:
    byteString = atob(dataURIParts[1])
  } else {
    // Convert base64/URLEncoded data component to raw binary data:
    byteString = decodeURIComponent(dataURIParts[1])
  }
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const intArray = new Uint8Array(arrayBuffer)

  for (let i = 0; i < byteString.length; i += 1) {
    intArray[i] = byteString.charCodeAt(i)
  }

  const mimeString = dataURIParts[0].split(':')[1].split(';')[0]
  let blob = null

  if (hasBlobConstructor) {
    blob = new Blob([hasArrayBufferViewSupport ? intArray : arrayBuffer], {
      type: mimeString
    })
  } else {
    const bb = new window.BlobBuilder()
    bb.append(arrayBuffer)
    blob = bb.getBlob(mimeString)
  }

  return blob
}

function _loadImage(image, file, callback) {
  if (typeof URL === 'undefined') {
    const reader = new FileReader()
    reader.onload = function(evt) {
      image.src = evt.target.result
      if (callback) {
        callback()
      }
    }
    reader.readAsDataURL(file)
  } else {
    image.src = URL.createObjectURL(file)
    if (callback) {
      callback()
    }
  }
}

function isSupported() {
  return typeof HTMLCanvasElement !== 'undefined' && hasBlobSupport && hasReaderSupport
}

export function fixOrientationWithExif(file) {
  // from https://medium.com/wassa/handle-image-rotation-on-mobile-266b7bd5a1e6
  if (!isSupported() || !file.type.match(/image.*/) || file.type.match(/image\/gif/)) {
    return Promise.resolve(file)
  }
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')

    img.src = window.URL.createObjectURL(file)
    img.onload = () => {
      exif.getData(img, () => {
        const allMetaData = exif.getAllTags(img)
        const exifOrientation = allMetaData.Orientation
        console.log('Exif orientation', exifOrientation)
        const width = img.width

        const height = img.height
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if ([5, 7, 8].indexOf(exifOrientation) > -1) {
          canvas.width = height
          canvas.height = width
        } else {
          canvas.width = width
          canvas.height = height
        }

        // transform context before drawing image
        switch (exifOrientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0)
            break
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height)
            break
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height)
            break
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0)
            break
          // case 6:
          //   ctx.transform(0, 1, -1, 0, height, 0)
          //   break
          case 7:
            ctx.transform(0, -1, -1, 0, height, width)
            break
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width)
            break
          default:
            ctx.transform(1, 0, 0, 1, 0, 0)
        }

        // Draw img into canvas
        ctx.drawImage(img, 0, 0, width, height)
        if (hasToBlobSupport) {
          canvas.toBlob(blob => {
            resolve(blob)
          }, file.type)
        } else {
          const blob = _toBlob(canvas, file.type)
          resolve(blob)
        }
        window.URL.revokeObjectURL(img.src)
      })
    }
  })
}
