import React from 'react'
import { Link } from 'react-router-dom'

export default props => {
  const siteUid = props.formContext.siteUid || []
  const segments = props.formContext.segments || []
  const segOptions = segments.map(segment => {
    return (
      <option value={segment._id} key={segment._id}>
        {segment.name || segment._id}
      </option>
    )
  })
  const currentSegment = props.formContext.segments.find(
    s => s._id === props.formData
  )

  return (
    <div className='form-group field field-string'>
      <label className='control-label' htmlFor={props.name}>
        {props.schema.title}
      </label>
      <select
        className='form-control'
        value={props.formData}
        onChange={e => props.onChange(e.target.value)}
      >
        <option /> {segOptions}
      </select>
      {currentSegment && (
        <Link
          style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
          to={
            '/site/' +
            siteUid +
            '/experience/' +
            currentSegment.experienceId +
            '/segment/' +
            props.formData
          }
          className='btn btn-default'
        >
          Voir
        </Link>
      )}
    </div>
  )
}
