import React from 'react'

export default props => {
  const tracks = props.formContext.tracks || []
  const trackPoints = (props.formContext.points || [])
    .map(point => {
      return {
        point,
        track: tracks.find(({ _id }) => _id === point.trackId) || {
          name: '',
          type: ''
        }
      }
    })
    .sort((one, two) => {
      const labelOne =
        one.track.name + ' ' + (one.point.internalName || one.point.name || '')
      const labelTwo =
        two.track.name + ' ' + (two.point.internalName || two.point.name || '')
      return labelOne.localeCompare(labelTwo)
    })
  return (
    <div className='form-group field field-string'>
      <label className='control-label' htmlFor={props.name}>
        {props.schema.title}
      </label>
      <select
        className='form-control'
        value={props.formData}
        onChange={e => props.onChange(e.target.value)}
      >
        <option />
        {trackPoints.map(({ track, point }) => {
          return (
            <option value={point._id} key={point._id}>
              {track.name} - {point.internalName || point.name}{' '}
            </option>
          )
        })}
      </select>
    </div>
  )
}
