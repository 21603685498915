import React from 'react'
import BookUsage from './usage'

export default ({ usedBy, checks, chapters, notes }) => (
  <aside className='diagnostic'>
    {usedBy && Object.keys(usedBy).length > 0 && (
      <div className='usedBy'>
        Utilisé par
        <ul>
          {Object.keys(usedBy).map(id => {
            return (
              <li key={id}>
                <BookUsage
                  chapterId={id}
                  usages={usedBy[id]}
                  chapters={chapters}
                />
              </li>
            )
          })}
        </ul>
      </div>
    )}
    {!checks && (
      <p className='bg-info'>
        <i className='fa fa-cog fa-spin fa-3x fa-fw' />
        Contrôle qualité en cours
      </p>
    )}
    {checks &&
      checks.errors.map((e, i) => (
        <p className='bg-danger' key={i}>
          {e.text}
        </p>
      ))}
    {checks &&
      checks.warnings.map((e, i) => (
        <p className='bg-warning' key={i}>
          {e.text}
        </p>
      ))}
    {checks && (
      <p className='bg-success'>
        {checks.nb - checks.warnings.length - checks.errors.length}{' '}
        vérifications réussies
      </p>
    )}
    {notes && <p>{notes}</p>}
  </aside>
)
