import { user, conf, apiCache } from '../../stores'
import generify from './generic'

export const scopes = generify('users-service', 'scopes', 'scope')
scopes.upsert = () => {
  throw new Error('not iplemented')
}
scopes.insert = () => {
  throw new Error('not implemented')
}
scopes.update = () => {
  throw new Error('not implemented')
}
scopes.add = function (userId, scope) {
  return fetch(conf.API_URL + '/users-service/scopes/' + userId, {
    method: 'PUT',
    body: JSON.stringify({ scope }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      const cachedUser = apiCache.users.get(userId)
      if (cachedUser) {
        cachedUser.scopes.push(scope)
      }
      return json
    })
}
scopes.remove = function (userId, scope) {
  return fetch(conf.API_URL + '/users-service/scopes/' + userId, {
    method: 'DELETE',
    body: JSON.stringify({ scope }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      const cachedUser = apiCache.users.get(userId)
      if (cachedUser) {
        const index = cachedUser.scopes.findIndex(scope)
        if (index) {
          cachedUser.scopes = cachedUser.splice(index, 1)
        }
      }
      return json
    })
}
