import React from 'react'
import QRCode from './qrcode'
import ScenarioPicker from './scenariopicker'
import { ImagePicker, ResourcePicker } from './resourcepicker'

export default props => {
  const track = props.formContext.track || {}
  const type = props.formData.type || 'none'
  const id = props.id
  return (
    <div className='form-group field field-string'>
      <label className='control-label' htmlFor={props.id}>
        {props.schema.title}
      </label>
      <select
        id={id}
        className='form-control'
        value={type}
        onChange={e => {
          props.onChange({
            ...props.formData,
            type: e.target.value
          })
        }}
      >
        <option value='' />
        <option value='none'>Pas de validation</option>
        {track && track.type !== 'photo_hunt' && (
          <option value='code'>Mot code</option>
        )}
        <option value='qrcode'>QRCode</option>
        <option value='image'>Reconnaissance d'image</option>
        {track.type === 'photo_hunt' && (
          <option value='location'>Distance</option>
        )}
      </select>
      {type === 'code' && (
        <input
          type='text'
          className='form-control'
          onChange={e => {
            props.onChange({
              ...props.formData,
              code: e.target.value
            })
          }}
          value={props.formData.code || ''}
        />
      )}
      {type === 'qrcode' && (
        <QRCode
          name='code'
          title='code'
          formData={props.formData.code}
          onChange={code => {
            props.onChange({
              ...props.formData,
              code
            })
          }}
        />
      )}
      {type === 'location' && (
        <input
          type='number'
          className='form-control'
          onChange={e => {
            props.onChange({
              ...props.formData,
              distance: parseInt(e.target.value, 10)
            })
          }}
          value={props.formData.distance}
        />
      )}
      {type === 'image' && (
        <div className='form-group field field-string'>
          <label className='control-label'>
            Nom de la cible dans la base vuforia
          </label>
          <input
            type='text'
            className='form-control'
            onChange={e => {
              const current = {
                target: {},
                ...props.formData
              }
              current.target.name = e.target.value
              props.onChange(current)
            }}
            value={props.formData.target && props.formData.target.name}
          />
          <label className='control-label'>Cible</label>
          <ImagePicker
            onChange={id => {
              const current = {
                target: {},
                ...props.formData
              }
              current.target.resource = id
              props.onChange(current)
            }}
            formData={props.formData.target && props.formData.target.resource}
            formContext={props.formContext}
          />
          <label className='control-label'>Image de remplacement</label>
          <ImagePicker
            onChange={id => {
              const current = {
                target: {},
                ...props.formData
              }
              current.target.overlay = id
              props.onChange(current)
            }}
            formData={props.formData.target && props.formData.target.overlay}
            formContext={props.formContext}
          />

          <label className='control-label'>Base de données Vuforia</label>
          <ResourcePicker
            onChange={id => {
              const current = {
                ...props.formData,
                database: id
              }
              props.onChange(current)
            }}
            formData={props.formData.target && props.formData.database}
            formContext={props.formContext}
          />
        </div>
      )}
      {track && track.type !== 'photo_hunt' && (
        <ScenarioPicker
          formData={props.formData.clue}
          uiSchema={{}}
          schema={{
            title: "Scenario d'indices pour aider à trouver le point"
          }}
          formContext={{
            ...props.formContext
          }}
          onChange={uid => {
            props.onChange({
              ...props.formData,
              clue: uid
            })
          }}
        />
      )}

      {type !== 'none' && (
        <ScenarioPicker
          formData={props.formData.scenario}
          uiSchema={{}}
          schema={{
            title: "Scénario lancé une fois que l'utilisateur à validé"
          }}
          formContext={{
            ...props.formContext
          }}
          onChange={uid => {
            props.onChange({
              ...props.formData,
              scenario: uid
            })
          }}
        />
      )}
    </div>
  )
}
