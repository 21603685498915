import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { observer } from 'mobx-react'

import * as api from '../lib/api'
import { apiCache, websocket } from '../stores'
import { locale, t } from 'stores/i18n.store'

class RouterHeader extends Component {
  state = {
    cache: {},
    path: {}
  }

  translationMap = [
    {
      collection: 'applications',
      key: 'applicationId',
      itemName: 'application',
      label: t('header-app')
    },
    {
      collection: 'variants',
      key: 'variantId',
      itemName: 'variant',
      label: t('header-variant')
    },
    {
      collection: 'sites',
      key: 'siteUid',
      itemName: 'site',
      label: t('header-site')
    },
    {
      collection: 'experiences',
      key: 'experienceUid',
      itemName: 'experience',
      label: t('header-experience')
    },
    {
      collection: 'tracks',
      key: 'trackUid',
      itemName: 'track',
      label: t('header-track')
    },
    {
      collection: 'points',
      key: 'pointUid',
      itemName: 'point',
      label: t('header-point')
    },
    {
      collection: 'scenarios',
      key: 'scenarioUid',
      itemName: 'scenario',
      label: t('header-scenario')
    },
    {
      collection: 'modules',
      key: 'moduleUid',
      itemName: 'module',
      label: t('header-module')
    },
    {
      collection: 'maps',
      key: 'mapUid',
      itemName: 'map',
      label: t('header-map')
    },
    {
      collection: 'segments',
      key: 'segmentUid',
      itemName: 'segment',
      label: t('header-segment')
    }
  ]

  populateState(props) {
    const promises = this.translationMap
      .map(tm => {
        if (!props.match.params[tm.key]) {
          return null
        }
        return api[tm.collection]
          .get(props.match.params[tm.key])
          .then(o => {
            const s = this.state
            s.cache[o._id] = o
            this.setState(s)
          })
          .catch(e => {})
      })
      .filter(i => !!i)

    return Promise.all(promises)
  }

  componentWillReceiveProps(newProps) {
    websocket.experienceId = newProps.match.params.experienceUid
    let shouldPopulate = false
    this.translationMap.forEach(tm => {
      shouldPopulate = shouldPopulate || newProps.match.params[tm.key] !== this.props.match.params[tm.key]
    })

    shouldPopulate && this.populateState(newProps)
  }

  componentDidMount() {
    websocket.experienceId = this.props.match.params.experienceUid
    this.populateState(this.props)
  }

  logout = () => {
    api.auth.logout().then(() => {
      // registration worked

      this.props.history.push('/')
      document && document.location.reload(true)
    })
  }

  render() {
    const params = this.props.match.params
    const { siteUid, experienceUid, trackUid } = params
    const path = [
      <NavLink to="/" exact key="home" className="home">
        <i className="fa fa-home" />
      </NavLink>
    ]
    let link = ''
    this.translationMap.forEach(tm => {
      if (!params[tm.key]) {
        return null
      }

      if (tm.collection === 'scenarios' && !trackUid) {
        path.push(
          <NavLink key="scenarios" exact to={link + '/scenario/list/'} style={{ paddingTop: '0.8em', position: 'relative' }}>
            scenarios
          </NavLink>
        )
      }

      const uid = params[tm.key]
      link += '/' + tm.itemName + '/' + uid
      const cached = apiCache[tm.collection].get(uid)
      if (!cached) {
        return '...'
      }
      if (tm.collection === 'applications') {
        path.push(
          <NavLink key="applications" exact to="/applications" style={{ paddingTop: '0.8em', position: 'relative' }}>
            applications
          </NavLink>
        )
      }
      if (tm.collection === 'users') {
        path.push(
          <NavLink key="users" exact to="/users" style={{ paddingTop: '0.8em', position: 'relative' }}>
            utilisateurs
          </NavLink>
        )
      }

      path.push(
        <NavLink key={uid} exact to={link} style={{ paddingTop: '0.8em', position: 'relative' }}>
          <small
            style={{
              position: 'absolute',
              top: 0,
              left: '2.25em',
              right: 0,
              fontSize: '70%'
            }}
          >
            {tm.label} :
          </small>
          {cached.internalName || cached.name || cached.title || cached._id}
        </NavLink>
      )
    })

    return (
      <header>
        <button id="buttonToggleLeftNav" onClick={e => document.body.classList.toggle('showNav')}>
          <i className="fa fa-bars" />
        </button>
        <div className="path">{path}</div>

        <SelectLocale />
        <div className="tools">
          {params.experienceUid && (
            <Link title="déployer" to={'/site/' + siteUid + '/experience/' + experienceUid + '/deploy'}>
              <i className="fa fa-cloud-upload" />
            </Link>
          )}
        </div>
        <button title="Disconnect" onClick={this.logout}>
          <i className="fa fa-sign-out" />
        </button>
      </header>
    )
  }
}

const SelectLocale = () => {
  const onChange = lang => {
    window.localStorage.setItem('lang', lang)
    window.location.href = window.location.pathname
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 25 }}>
      <LocaleIcon locale={'fr'} selected={locale.value === 'fr'} onClick={() => onChange('fr')} />
      <LocaleIcon locale={'en'} selected={locale.value === 'en'} onClick={() => onChange('en')} />
      <LocaleIcon locale={'de'} selected={locale.value === 'de'} onClick={() => onChange('de')} />
    </div>
  )
}

export default withRouter(observer(RouterHeader))

const LocaleIcon = ({ locale, selected, onClick }) => (
  <div
    style={{ marginLeft: 10, cursor: selected ? 'default' : 'pointer', opacity: selected ? 1 : 0.4 }}
    onClick={selected ? undefined : onClick}
  >
    <img src={`/img/flag-${locale}.png`} alt={locale} style={{ width: 30, height: 30 }} />
  </div>
)
