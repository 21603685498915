import React, { Component } from 'react'
import { toJS } from 'mobx'
import * as api from '../../lib/api'
import { Page } from '../'
import { NavInExperience } from '../../components/nav'

export default class extends Component {
  setupGraph = (graph, { d3adaptor: colaAdaptor, makeEdgeBetween }, d3) => {
    var width = 2000

    var height = 1500

    var cola = colaAdaptor(d3)
      .linkDistance(100)
      .avoidOverlaps(true)
      .handleDisconnected(true)
      .size([width, height])

    var svg = d3
      .select('#magic-graph-container')
      .append('svg')
      .attr('width', width)
      .attr('viewbox', '0 0 ' + width + ' ' + height)
      .attr('height', height)

    svg
      .append('defs')
      .append('marker')
      .attrs({
        id: 'arrowhead',
        viewBox: '-0 -5 10 10',
        refX: 13,
        refY: 0,
        orient: 'auto',
        markerWidth: 13,
        markerHeight: 13,
        xoverflow: 'visible'
      })
      .append('svg:path')
      .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
      .attr('fill', '#999')
      .style('stroke', 'none')

    cola
      .nodes(graph.nodes)
      .links(graph.links)
      .groups(graph.groups)
      .start(10, 30, 20)

    var group = svg
      .selectAll('.group')
      .data(graph.groups)
      .enter()
      .append('rect')
      .attr('rx', 8)
      .attr('ry', 8)
      .attr('class', 'group')
      .style('fill', 'gray')
      .call(cola.drag)
    const linkColor = link => {
      switch (link.type) {
        case 'enable_point':
        case 'show_point':
          return '#FB8B24'
        case 'disable_point':
        case 'hide_point':
          return '#9A031E'
        case 'clues':
          return '#E36414'
        case 'game':
          return '#0F4C5C'
        case 'aftergame':
          return '#5F0F40'
        case 'trackspoints':
          return 'transparent'
        default:
          return '#000000'
      }
    }
    var link = svg
      .selectAll('.link')
      .data(graph.links)
      .enter()
      .append('line')
      .attr('class', 'link')
      .style('stroke', linkColor)
      .attr('marker-end', d => {
        return d.type !== 'trackspoints' ? 'url(#arrowhead)' : null
      })
    link.append('title').text(function (d) {
      return d.type
    })

    const edgepaths = svg
      .selectAll('.edgepath')
      .data(graph.links)
      .enter()
      .append('path')
      .attrs({
        class: 'edgepath',
        'fill-opacity': 0,
        'stroke-opacity': 0,
        id: function (d, i) {
          return 'edgepath' + i
        }
      })
      .style('pointer-events', 'none')

    const edgelabels = svg
      .selectAll('.edgelabel')
      .data(graph.links)
      .enter()
      .append('text')
      .style('fill', linkColor)
      .style('pointer-events', 'none')
      .attrs({
        class: 'edgelabel',
        id: function (d, i) {
          return 'edgelabel' + i
        },
        'font-size': 12
      })

    edgelabels
      .append('textPath')
      .attr('xlink:href', function (d, i) {
        return '#edgepath' + i
      })
      .style('text-anchor', 'middle')
      .style('pointer-events', 'none')
      .attr('startOffset', '50%')
      .text(function (d) {
        return d.type
      })

    var pad = 3
    var node = svg
      .selectAll('.node')
      .data(graph.nodes)
      .enter()
      .append('rect')
      .attr('class', 'node')
      .attr('width', function (d) {
        return d.width - 2 * pad
      })
      .attr('height', function (d) {
        return d.height - 2 * pad
      })
      .attr('rx', 5)
      .attr('ry', 5)
      .style('fill', function (d) {
        switch (d.type) {
          case 'tracks':
            return 'transparent'
          case 'points':
            return 'green'
          case 'experiences':
            return 'black'
          case 'scenarios':
            return 'darkOrange'
          default:
            return 'pink'
        }
      })
      .call(cola.drag)

    var label = svg
      .selectAll('.label')
      .data(graph.nodes)
      .enter()
      .append('text')
      .attr('class', 'label')
      .style('text-anchor', 'middle')
      .style('fill', 'white')
      .text(function (d) {
        return d.name
      })
      .call(cola.drag)

    node.append('title').text(function (d) {
      return d.name
    })

    cola.on('tick', function () {
      node.each(function (d) {
        d.innerBounds = d.bounds.inflate(0)
      })

      link.each(function (d) {
        d.route = makeEdgeBetween(
          d.source.innerBounds,
          d.target.innerBounds,
          -10
        )
      })
      edgepaths.each(function (d) {
        d.route = makeEdgeBetween(
          d.source.innerBounds,
          d.target.innerBounds,
          -10
        )
      })

      link
        .attr('x1', function (d) {
          return d.route.sourceIntersection.x
        })
        .attr('y1', function (d) {
          return d.route.sourceIntersection.y
        })
        .attr('x2', function (d) {
          return d.route.arrowStart.x
        })
        .attr('y2', function (d) {
          return d.route.arrowStart.y
        })

      node
        .attr('x', function (d) {
          return d.x - d.width / 2 + pad
        })
        .attr('y', function (d) {
          return d.y - d.height / 2 + pad
        })

      group
        .attr('x', function (d) {
          return d.bounds.x
        })
        .attr('y', function (d) {
          return d.bounds.y
        })
        .attr('width', function (d) {
          return d.bounds.width()
        })
        .attr('height', function (d) {
          return d.bounds.height()
        })

      label
        .attr('x', function (d) {
          return d.x
        })
        .attr('y', function (d) {
          var h = this.getBBox().height
          return d.y + h / 4
        })

      edgepaths.attr('d', function (d) {
        return (
          'M ' +
          d.route.sourceIntersection.x +
          ' ' +
          d.route.sourceIntersection.y +
          ' L ' +
          d.route.targetIntersection.x +
          ' ' +
          d.route.targetIntersection.y
        )
      })

      edgelabels.attr('transform', function (d) {
        if (d.target.x < d.source.x) {
          const bbox = this.getBBox()

          const rx = bbox.x + bbox.width / 2
          const ry = bbox.y + bbox.height / 2
          return 'rotate(180 ' + rx + ' ' + ry + ')'
        } else {
          return 'rotate(0)'
        }
      })
    })
  };

  populateGraphData = () => {
    const { experienceUid } = this.props.match.params
    console.log('experience ', experienceUid)
    return Promise.all([
      api.tracks.getFrom('experiences', experienceUid),
      api.scenarios.getFrom('experiences', experienceUid),
      api.modules.getFrom('experiences', experienceUid),
      api.points.getFrom('experiences', experienceUid),
      api.experiences.get(experienceUid)
    ]).then(([tracks, scenarios, modules, points, experience]) => {
      const graph = {
        nodes: [],
        links: [],
        groups: []
      }

      const keyToIndex = {}

      let index = 0
      graph.nodes.push({
        width: 140,
        height: 40,
        name: experience.name,
        type: 'experiences'
      })
      keyToIndex[experience._id] = index++

      scenarios
        .filter(
          s =>
            modules.find(m => m.scenarioId === s._id) ||
            (s.onEnd && s.onEnd.length > 0)
        )
        .filter(s => (s.name || '').toLowerCase().indexOf('indice') === -1)
        .forEach(scenario => {
          graph.nodes.push({
            width: 160,
            height: 40,
            name: scenario.name || '',
            type: 'scenarios'
          })
          keyToIndex[scenario._id] = index++
        })
      /*
          modules
            .sort((m1,m2)=>m1.scenarioId === m2.scenarioId ? m1.order-m2.order : m1.scenarioId.localeCompare(m2.scenarioId))
            .forEach((m,counter,arr)=>{
            graph.nodes.push({
              width:5,
              height:5,
              name:"" ,
              type:"modules"
            })
            keyToIndex[m._id] = index++
            if(counter > 1){
              let prec = arr[counter-1]
              if(prec.scenarioId !== m.scenarioId){
                graph.links.push({
                  source:keyToIndex[m.scenarioId],
                  target:keyToIndex[m._id],
                  type:"scenariomodule"
                })
              }else {
                graph.links.push({
                  source:keyToIndex[prec._id],
                  target:keyToIndex[m._id],
                  type:"modulemodule",
                  length:10
                })

              }
            }
          })
*/
      tracks.forEach(track => {
        graph.nodes.push({
          width: 160,
          height: 40,
          name: track.name || track.type,
          type: 'tracks'
        })
        keyToIndex[track._id] = index++
        if (track.scenario && keyToIndex[track.scenario]) {
          graph.links.push({
            source: keyToIndex[track._id],
            target: keyToIndex[track.scenario],
            type: ''
          })
        }
        if (track.failure && keyToIndex[track.failure]) {
          graph.links.push({
            source: keyToIndex[track._id],
            target: keyToIndex[track.failure],
            type: 'failure'
          })
        }
        track.entities && track.entities.forEach(entity => {})

        /* track.entities && track.entities.forEach(entity=>{
              keyToIndex[entity._id] = index++
              graph.nodes.push({
                width:120,
                height:40,
                name:entity.name ||entity.type,
                type:"entity"
              })
            }) */
      })
      if (experience.startTrack) {
        graph.links.push({
          source: keyToIndex[experience._id],
          target: keyToIndex[experience.startTrack],
          type: 'experiencetrack'
        })
      } else {
        graph.links.push({
          source: keyToIndex[experience._id],
          target: keyToIndex[tracks[0]._id],
          type: 'experiencetrack'
        })
      }

      points.forEach(point => {
        graph.nodes.push({
          width: 140,
          height: 40,
          name: point.internalName || point.name || '',
          type: 'points'
        })
        keyToIndex[point._id] = index++

        graph.links.push({
          source: keyToIndex[point.trackId],
          target: keyToIndex[point._id],
          type: 'trackspoints'
        })
        if (point.check) {
          if (point.check.clue && keyToIndex[point.check.clue]) {
            /* graph.links.push({
                  source:keyToIndex[point._id],
                  target:keyToIndex[point.check.clue],
                  type:"clues"
                }) */
          }
          if (point.check.scenario && keyToIndex[point.check.scenario]) {
            graph.links.push({
              source: keyToIndex[point._id],
              target: keyToIndex[point.check.scenario],
              type: 'game'
            })
          }
        }
        if (point.scenario && keyToIndex[point.scenario]) {
          graph.links.push({
            source: keyToIndex[point._id],
            target: keyToIndex[point.scenario],
            type: 'aftergame'
          })
        }
      })

      modules.forEach(m => {
        const searchAction = (o, k) => {
          if (!o) {
            return
          }
          if (typeof o === 'object') {
            if (
              o.type &&
              [
                'load_scenario',
                'load_track',
                'enable_point',
                'disable_point',
                'hide_point',
                'show_point'
              ].indexOf(o.type) >= 0
            ) {
              // maybe an action
              graph.links.push({
                source: keyToIndex[m.scenarioId],
                target: keyToIndex[o.track || o.scenario || o.point],
                type: ''
              })
              console.log('found', o)
            } else {
              Object.keys(o).forEach(k => searchAction(o[k], k))
            }
          }
        }
        searchAction(toJS(m))
      })
      experience.onStart &&
        experience.onStart.forEach(({ type, scenario, track, point }) => {
          if (
            type &&
            [
              'load_scenario',
              'load_track',
              'enable_point',
              'disable_point',
              'hide_point',
              'show_point'
            ].indexOf(type) >= 0
          ) {
            // maybe an action
            graph.links.push({
              source: keyToIndex[experience._id],
              target: keyToIndex[scenario || track || point],
              type: ''
            })
          }
        })
      tracks.forEach(t => {
        const trackLeaves = [keyToIndex[t._id]]
        points
          .filter(p => p.trackId === t._id)
          .forEach(p => trackLeaves.push(keyToIndex[p._id]))
        // t.entities && t.entities.forEach(entity=>trackLeaves.push(keyToIndex[entity._id]))
        graph.groups.push({
          leaves: trackLeaves
        })
        t.menu &&
          t.menu.forEach(({ action }) => {
            const { type, scenario, track, point } = action
            if (
              type &&
              [
                'load_scenario',
                'load_track',
                'enable_point',
                'disable_point',
                'hide_point',
                'show_point'
              ].indexOf(type) >= 0 &&
              keyToIndex[scenario || track || point]
            ) {
              // maybe an action
              graph.links.push({
                source: keyToIndex[t._id],
                target: keyToIndex[scenario || track || point],
                type: ''
              })
            }
          })
      })

      scenarios.forEach(s => {
        if (!keyToIndex[s._id]) {
          return
        } /*
            let scenarioLeaves = [keyToIndex[s._id]]
            modules
              .filter(m=>m.scenarioId === s._id)
              .forEach(m=>scenarioLeaves.push(keyToIndex[m._id]))
            graph.groups.push({
              leaves:scenarioLeaves,
              type:'scenario'
            }) */
        s.onEnd &&
          s.onEnd.forEach(({ type, scenario, point, track }) => {
            if (scenario || point || track) {
              graph.links.push({
                source: keyToIndex[s._id],
                target: keyToIndex[scenario || point || track],
                type: ''
              })
            }
          })
      })

      graph.links = graph.links.filter(l => l.source && l.target)
      graph.nodes.forEach((node, index) => {
        if (!graph.links.find(l => l.source === index || l.target === index)) {
          node.type = 'single'
          node.width = 6
          node.height = 6
        }
      })
      console.log('graph', graph)
      return graph
    })
    // return Promise.resolve(graph)
  };

  componentDidMount () {
    Promise.all([
      this.populateGraphData(),
      import('webcola'),
      import('d3'),
      import('d3-selection-multi')
    ]).then(([graph, cola, d3]) => {
      this.setupGraph(graph, cola, d3)
    })
  }

  render () {
    return (
      <Page>
        <NavInExperience />
        <div id='content' style={{ overflow: 'scroll' }}>
          <div id='magic-graph-container' />
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}
