import React, { Component } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { Site, Statistic } from '../'

import Application from './application'
import Applications from './applications'
import User from './user'
import Users from './users'
import Sites from './sites'
import Variant from './variant'
import { NoMatch } from './noMatch'

import BundleList from '../bundle/list'
import BundleForm from '../bundle/form'

export default class RoutedSites extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Sites} />
          <Route path="/bundle/list" component={BundleList} />
          <Route path="/bundle/:bundleUid" component={BundleForm} />
          <Route path="/bundle" component={BundleForm} />
          <Route path="/site/:siteUid?" component={Site} />
          <Route path="/user/:userUid?" component={User} />
          <Route path="/users/" component={Users} />
          <Route path="/statistics" component={Statistic} />
          <Route path="/applications" component={Applications} />
          <Route path="/application/:applicationId/variant/:variantId?" component={Variant} />
          <Route path="/application/:applicationId?" component={Application} />
          <Route path="*" component={NoMatch} />
        </Switch>
      </Router>
    )
  }
}
