const checkPath = (json, path, separator) => {
  if (typeof path === 'string') {
    separator = separator || '°|°'
    path = path.split(separator)
  }
  if (path.length === 0) {
    return !!json
  }
  if (!json) {
    return false
  }
  const key = path.shift()
  return checkPath(json[key], path)
}

export default checkPath
