import React, { Component } from 'react'
import BaseMap from './map'

import { Marker } from 'react-leaflet'
class Coordinates extends Component {
  constructor (props) {
    super(props)
    this.refmarker = React.createRef()
  }

  updatePosition = () => {
    const marker = this.refmarker && this.refmarker.current
    if (marker != null) {
      const { lat, lng } = marker.leafletElement.getLatLng()
      this.props.onChange([lat, lng])
    }
  }

  render () {
    const hasCoord =
      this.props.formData &&
      Array.isArray(this.props.formData) &&
      this.props.formData.length === 2
    const center = hasCoord
      ? {
          lat: parseFloat(this.props.formData[0]) || 0.0,
          lng: parseFloat(this.props.formData[1]) || 0.0
        }
      : { lat: 46.3796, lng: 4.7436 }

    return (
      <>
        <BaseMap
          center={center}
          zoom={12}
          style={{ flex: 1 }}
          onClick={({ latlng }) => {
            if (!hasCoord) {
              const { lat, lng } = latlng
              this.props.onChange([lat, lng])
            }
          }}
          formContext={this.props.formContext}
        >
          <Marker
            draggable
            onDragend={this.updatePosition}
            position={center}
            ref={this.refmarker}
          />
        </BaseMap>
      </>
    )
  }
}
export default class extends Component {
  state = {
    latitude: '',
    longitude: ''
  }

  componentDidMount () {
    if (this.props.formData && this.props.formData.length) {
      this.setState({
        latitude: this.props.formData[0],
        longitude: this.props.formData[1]
      })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.formData && nextProps.formData.length) {
      const { latitude, longitude } = this.state
      if (
        parseFloat(latitude) !== parseFloat(nextProps.formData[0]) ||
        parseFloat(longitude) !== parseFloat(nextProps.formData[1])
      ) {
        this.setState({
          latitude: nextProps.formData[0],
          longitude: nextProps.formData[1]
        })
      }
    }
  }

  render () {
    const props = this.props
    const formData = props.formData || []
    const style = props.style || {}
    const schema = props.schema || {}
    const title = schema.title || 'Coordonnées'
    const mapContainerStyle = props.mapContainerStyle || {}
    const { latitude, longitude } = this.state

    return (
      <div
        className='form-group field '
        style={{ flex: 1, display: 'flex', flexDirection: 'column', ...style }}
      >
        {title && (
          <label className='control-label' htmlFor={props.name}>
            {title}
          </label>
        )}

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <label
            className='control-label'
            style={{ flex: 1 }}
            htmlFor={'lat' + props.name}
          >
            Latitude
          </label>
          <input
            className='form-control'
            style={{ flex: 1 }}
            step='0.000001'
            type='number'
            id={'lat' + props.name}
            value={latitude}
            onChange={e => {
              this.setState({ latitude: e.target.value })
              parseFloat(e.target.value) &&
                props.onChange([parseFloat(e.target.value), formData[1]])
            }}
          />
          <label
            className='control-label'
            style={{ flex: 1 }}
            htmlFor={'lng' + props.name}
          >
            Longitude
          </label>
          <input
            style={{ flex: 1 }}
            className='form-control'
            type='number'
            step='0.000001'
            id={'lng' + props.name}
            value={longitude}
            onChange={e => {
              this.setState({ longitude: e.target.value })
              parseFloat(e.target.value) &&
                props.onChange([formData[0], parseFloat(e.target.value)])
            }}
          />
        </div>
        <div
          className='row'
          style={{
            minHeight: '400px',
            width: '80%',
            margin: '0 auto',
            display: 'flex',
            ...mapContainerStyle
          }}
        >
          <Coordinates {...props} />
        </div>
      </div>
    )
  }
}
