import * as api from '../api'
import { toJS } from 'mobx'
import checkAction from './action'

export default async m => {
  const checkResult = {
    warnings: [],
    errors: [],
    nb: 0
  }
  const xp = await api.experiences.get(m.experienceId)
  const xpLink = '/site/' + xp.siteId + '/experience/' + xp._id
  const scenario = await api.scenarios.get(m.scenarioId)
  checkResult.nb++
  if (m.title && m.title[0].toUpperCase() !== m.title[0]) {
    checkResult.warnings.push({
      text: `le module ${m.internalName || m.title} du scenario ${scenario.name}(${m.type}) a un titre en miniscule`,
      link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
      _id: m._id
    })
  }
  if (
    [
      'choice',
      'differences',
      'differences_photosphere',
      'dragdrop',
      'image_mcq',
      'level',
      'mcq',
      'puzzle',
      'qrcode_hunt',
      'quizz',
      'scratch',
      'shake_contest',
      'slasher'
    ].indexOf(m.type) >= 0
  ) {
    checkResult.nb++
    if (!m.instruction) {
      checkResult.warnings.push({
        text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${m.type}) n'a pas d'instructions`,
        link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
        _id: m._id
      })
    }
    checkResult.nb++
    const next = toJS(await api.modules.getFrom('scenarios', m.scenarioId)).find(other => other.order === m.order + 1)
    if (next && next.canGoBack) {
      checkResult.warnings.push({
        text: `le module   ${next.internalName || next.name || next.title} du scenario ${scenario.name ||
          scenario.title} est après un module de jeu, mais accepte le retour en arrière`,
        link: xpLink + '/scenario/' + scenario._id + '/module/' + next._id,
        _id: next._id
      })
    }
  }

  if (
    ['image_mcq', 'level', 'mcq', 'puzzle', 'qrcode_hunt', 'quizz', 'scratch', 'shake_contest', 'slasher'].indexOf(m.type) >= 0
  ) {
    checkResult.nb++
    if (!m.points) {
      checkResult.warnings.push({
        text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${m.type}) n'a pas de score`,
        link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
        _id: m._id
      })
    }
  }
  if (['level', 'shake_contest', 'slasher'].indexOf(m.type) >= 0) {
    checkResult.nb++
    if (!m.objective) {
      checkResult.warnings.push({
        text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
          m.type
        }) n'a pas d'objectif de points`,
        link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
        _id: m._id
      })
    }
  }
  if (['level', 'shake_contest', 'slasher'].indexOf(m.type) >= 0) {
    checkResult.nb++
    if (!m.timeLimit) {
      checkResult.warnings.push({
        text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
          m.type
        }) n'a pas de limite de temps`,
        link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
        _id: m._id
      })
    }
  }

  checkResult.nb++
  if (m.title && m.title.indexOf('(copie)') > 0) {
    checkResult.warnings.push({
      text: `le module   ${m.internalName || m.name || m.title} (${m.type}) du scenario ${scenario.name} a copie dans son titre`,
      link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
      _id: m._id
    })
  }
  if (m.order === null) {
    checkResult.warnings.push({
      text: `le module   ${m.internalName || m.name || m.title} du scenario ${scenario.name || scenario.title} n'a pas d'ordre`,
      link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
      _id: m._id
    })
  }
  checkResult.nb++

  if (m.toolButton) {
    checkResult.nb++
    if (!m.toolButton && !m.icon) {
      checkResult.warnings.push({
        text: `le bouton d'action du module  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
          scenario.name
        } n'a pas d'icône ni de titre`,
        link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
        _id: m._id
      })
    }
    checkResult.nb++
    if (!m.toolButton.action) {
      checkResult.warnings.push({
        text: `le bouton d'action du module  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
          scenario.name
        } n'a pas d'action`,
        link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
        _id: m._id
      })
    } else {
      checkResult.nb++
      if (m.toolButton.action.type === 'load_scenario' && m.toolButton.action.scenario) {
        const scenario = await api.scenarios.get(m.toolButton.action.scenario)
        const modules = await api.modules.getFrom('scenarios', m.toolButton.action.scenario)
        modules.forEach(submodule => {
          if (!submodule.canGoBack) {
            checkResult.errors.push({
              text: `le module module  ${submodule.internalName || submodule.name || submodule.title}(${
                submodule.type
              }) du scenario ${scenario.name} lancé par un bouton d'action n'autorise pas le retour arrière`,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + submodule._id,
              _id: submodule._id
            })
          }
        })
      }
      const actionRes = await checkAction(m.toolButton.action, xpLink + '/scenario/' + scenario._id + '/module/' + m._id)
      checkResult.warnings = checkResult.warnings.concat(actionRes.warnings)
      checkResult.errors = checkResult.errors.concat(actionRes.errors)
      checkResult.nb += actionRes.nb
    }
  }

  switch (m.type) {
    case 'augmented_reality':
      checkResult.nb++
      if (!m.database || !m.database.dat || !m.database.xml) {
        checkResult.errors.push({
          text: `le module  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } n'a pas de base de données vuforia `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      checkResult.nb++
      if (!m.targets || m.targets.length === 0) {
        checkResult.warnings.push({
          text: `le module  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${scenario.name} n'a pas de cibles `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        const usedNames = {}
        m.targets.forEach(({ name, type, source, resource }, index) => {
          const { resource: sourceFile, width } = source || {}
          checkResult.nb++
          if (!width) {
            checkResult.errors.push({
              text: `le module  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${scenario.name} sa cible ${index +
                1} qui n'a pas de largeur `,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
          checkResult.nb++
          if (!name) {
            checkResult.errors.push({
              text: `le module  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
                scenario.name
              } a des cibles sans nom `,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
          if (usedNames[name]) {
            checkResult.errors.push({
              text: `le module  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
                scenario.name
              } à plusieurs cibles avec le même nom ${name} `,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
          usedNames[name] = true
          if (!resource) {
            checkResult.warnings.push({
              text: `la cible ${name} du module  ${m.internalName || m.name || m.title}(${
                m.type
              }) n'a pas de ressource de type ${type} de remplacement `,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }

          checkResult.nb++
          if (!sourceFile) {
            checkResult.warnings.push({
              text: `la cible ${name} du module  ${m.internalName || m.name || m.title}(${
                m.type
              }) n'a pas de ressource de'image source `,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
        })
      }
      break

    case 'choice':
      checkResult.nb++
      if (!m.instruction) {
        checkResult.warnings.push({
          text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${m.type}) n'a pas d'instructions`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      checkResult.nb++
      if (!m.choices) {
        checkResult.errors.push({
          text: `le module  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${scenario.name} n'a pas de choix `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        for (let index = 0; index < m.choices.length; index++) {
          const { label, picture, actions } = m.choices[index]
          checkResult.nb++
          if (!label && !picture) {
            checkResult.warnings.push({
              text: `le choix  ${index + 1} du module ${m.internalName || m.name || m.title}(${m.type}) n'a ni image , ni label `,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
          checkResult.nb++
          if (!actions || actions.length === 0) {
            checkResult.warnings.push({
              text: `le choix  ${index + 1} du module ${m.internalName || m.name || m.title}(${
                m.type
              }) n'a pas d'action associées`,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          } else {
            for (let counter = 0; counter < actions.length; counter++) {
              const actionRes = await checkAction(actions[counter], xpLink + '/scenario/' + scenario._id + '/module/' + m._id)
              checkResult.warnings = checkResult.warnings.concat(actionRes.warnings)
              checkResult.errors = checkResult.errors.concat(actionRes.errors)
              checkResult.nb += actionRes.nb
            }
          }
        }
      }
      checkResult.nb++
      if (!m.defaultChoice) {
        checkResult.warnings.push({
          text: `le module  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } n'a pas de choix par défaut `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      break
    case 'conversation':
    case 'score_conversation':
      const dialogs = m.dialogs || []
      let lastCharacter = null

      let lastPosition = null
      const missingPosition = []
      const animationErrors = []
      const missingVoices = []
      const withCaptions = []
      const withCharacterName = []

      checkResult.nb++
      if (dialogs.length === 0) {
        checkResult.warnings.push({
          text: `le module conversation  ${m.internalName || m.name || ''} n'a aucun dialogue`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }

      checkResult.nb++
      const prec = toJS(await api.modules.getFrom('scenarios', m.scenarioId)).find(other => other.order === m.order - 1)
      if (!m.canGoBack && m.order > 0 && m.type === 'conversation') {
        if (prec && prec.type === 'conversation') {
          checkResult.warnings.push({
            text: `le module conversation  ${m.internalName || m.name || m.title} du scenario ${scenario.name ||
              scenario.title} est après un autre conversation mais n'accepte pas le retour arrière`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      }

      if (prec && prec.type === 'score_conversation' && m.type === 'score_conversation') {
        checkResult.errors.push({
          text: `le module score conversation  ${m.internalName || m.name || m.title} du scenario ${scenario.name ||
            scenario.title} est après un autre score conversation `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      let allDialogsHaveBackground = true
      dialogs.forEach(({ animate, position, character, icon, sound, text, background }, index) => {
        allDialogsHaveBackground = allDialogsHaveBackground && !!background
        checkResult.nb++
        if (animate && !position && lastCharacter) {
          missingPosition.push(index + 1)
        }
        checkResult.nb++
        if (character && icon && lastCharacter && lastCharacter === character) {
          if (lastPosition !== position || animate) {
            animationErrors.push(index + 1)
          }
        }
        checkResult.nb++
        if (true /* hasVoice */ && character && !sound && (text || '').length >= 5) {
          missingVoices.push(index + 1)
        }
        checkResult.nb++
        if ((text || '').indexOf('[') >= 0) {
          withCaptions.push(index + 1)
        }
        checkResult.nb++
        if ((text || '').indexOf('{') >= 0) {
          withCharacterName.push(index + 1)
        }
        lastCharacter = character
        lastPosition = position
      })
      checkResult.nb++
      if (!m.background && !allDialogsHaveBackground) {
        checkResult.warnings.push({
          text: `la conversation  ${m.internalName || m.name || m.title || ''} du scenario ${
            scenario.name
          } n'a pas d'image de fond`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      if (missingPosition.length) {
        checkResult.warnings.push({
          text: `le(s) dialogue(s) ${missingPosition.join(',')} du module ${m.type} ${m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } est animé , mais n'a pas de position finale `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      if (animationErrors.length) {
        checkResult.warnings.push({
          text: `le(s) dialogue(s) ${animationErrors.join(',')} du module ${m.type} ${m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } sont animés bizarrement `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      if (missingVoices.length) {
        checkResult.warnings.push({
          text: `le(s) dialogue(s) ${missingVoices.join(',')} du module ${m.type} ${m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } ont un personnage, mais pas de voix `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      if (withCaptions.length) {
        checkResult.warnings.push({
          text: `le(s) dialogue(s) ${missingVoices.join(',')} du module ${m.type} ${m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } ont des didascalies  entre [ ] `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      if (withCharacterName.length) {
        checkResult.warnings.push({
          text: `le(s) dialogue(s) ${missingVoices.join(',')} du module ${m.type} ${m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } ont des noms de personnages entre { } `,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      break

    case 'diaporama':
    case 'score_diaporama':
      checkResult.nb++
      checkResult.nb++
      if (!m.pictures || m.pictures.length === 0) {
        checkResult.warnings.push({
          text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${scenario.name} n'a pas de d'images`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        checkResult.nb += m.pictures.length
        const missingResources = m.pictures.filter(({ file }) => !file).map((_, index) => index + 1)
        if (missingResources.length) {
          checkResult.errors.push({
            text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
              scenario.name
            } a des images sans fichier  : ${missingResources.join(',')}`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
        const missingTime = m.pictures.filter(({ time }) => !time)

        checkResult.nb += m.pictures.length
        if (missingTime.length > 1 /* une seul en zéro */ && missingTime.length !== m.pictures.length /* pas toutes */) {
          checkResult.warnings.push({
            text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
              scenario.name
            } a des images avec un temps de chargement et des images sans`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      }
      break
    case 'differences':
    case 'differences_photosphere':
      checkResult.nb++
      if (!m.differences || m.differences.length === 0) {
        checkResult.warnings.push({
          text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${scenario.name} n'a pas de différence`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        checkResult.nb++
        const missingPoints = m.differences.filter(({ points }) => !points).map((_, index) => index + 1)
        if (missingPoints.length) {
          checkResult.warnings.push({
            text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
              scenario.name
            } a des différences qui ne rapportent pas de points : ${missingPoints.join(',')}`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      }

      if (!m.orientation && m.type === 'differences_photosphere') {
        checkResult.warnings.push({
          text: `les modules photosphere_difference doivent avoir une orientation : ${m.internalName ||
            m.name ||
            m.title}  du scenario ${scenario.name}`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      break
    case 'dragdrop':
      checkResult.nb++
      if (!m.drops || m.drops.length === 0) {
        checkResult.errors.push({
          text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } n'a pas de zone de drop`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        for (const d1 of m.drops) {
          checkResult.nb++
          if (Math.abs(d1.top - d1.bottom) < 0.001) {
            checkResult.errors.push({
              text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
                scenario.name
              } a une zone de dépose sans hauteur`,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
          checkResult.nb++
          if (Math.abs(d1.left - d1.right) < 0.001) {
            checkResult.errors.push({
              text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
                scenario.name
              } a une zone de dépose sans largeur`,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
          for (const d2 of m.drops) {
            if (d1.top > d2.bottom && d2.bottom > d1.top && d1.right > d2.left && d2.left < d1.right) {
              checkResult.errors.push({
                text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
                  scenario.name
                } a plusieurs zones de dépose qui se chevauchent`,
                link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
                _id: m._id
              })
            }
          }
        }
      }
      checkResult.nb++
      if (!m.drags || m.drags.length === 0) {
        checkResult.errors.push({
          text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } n'a pas d'image à faire glisser`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        checkResult.nb++
        const missingPoints = m.drags.filter(({ points }) => !points).map((val, index) => index + 1)
        if (missingPoints.length) {
          checkResult.warnings.push({
            text: `le drag and drop ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
              m.type
            }) n'a pas de score pour les objets suivant ${missingPoints.join(',')} `,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      }
      break
    case 'gallery':
      checkResult.nb++
      if (!m.pictures || m.pictures.length === 0) {
        checkResult.warnings.push({
          text: `la gallerie  ${m.internalName || m.name || m.title}(${m.type}) du scenario ${scenario.name} n'a pas d'image`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        m.pictures.forEach(({ file }, index) => {
          checkResult.nb++
          if (!file) {
            checkResult.warnings.push({
              text: `l'image ${index + 1} de la gallerie ${m.internalName || m.name || m.title} du scenario ${
                scenario.name
              } n'a pas de fichier`,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
        })
      }
      break

    case 'image_mcq':
      checkResult.nb++
      if (!m.images || m.images.length === 0) {
        checkResult.errors.push({
          text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${m.type}) n'a pas de proposition`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        checkResult.nb++
        if (!m.answers || m.answers.length === 0) {
          checkResult.warnings.push({
            text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
              m.type
            }) n'a pas de réponse juste`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        } else {
          m.answers.forEach(answer => {
            checkResult.nb++
            if (!m.images.find(i => i._id === answer)) {
              checkResult.warnings.push({
                text: `la réponse ${answer} du module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
                  scenario.name
                } ne fait pas partie des propositions`,
                link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
                _id: m._id
              })
            }
          })
        }
      }
      break

    case 'level':
      checkResult.nb++
      if ((!m.codes || m.codes.length === 0) && (!m.newCodes || m.newCodes.length === 0)) {
        checkResult.errors.push({
          text: `le niveau à bulle  ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
            m.type
          }) n'a pas de  code valide`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        checkResult.nb++
        const duplicates = m.codes
          .filter((code, index) => m.codes.find((otherCode, otherIndex) => otherIndex > index && code === otherCode))
          .map((code, index) => index + 1)

        if (duplicates.length) {
          checkResult.warnings.push({
            text: `le niveau à bulle ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
              m.type
            }) a des Qrcodes en double ${duplicates.join(',')}`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      }
      break
    case 'markdown':
      checkResult.nb++
      if ((m.code || '').trim().length === 0) {
        checkResult.warnings.push({
          text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${m.type}) n'a pas de texte`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      break
    case 'mcq':
      checkResult.nb++
      if (!m.propositions || m.propositions.length === 0) {
        checkResult.warnings.push({
          text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${m.type}) n'a pas de proposition`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        checkResult.nb++
        if (!m.answers || m.answers.length === 0) {
          checkResult.warnings.push({
            text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
              m.type
            }) n'a pas de réponse juste`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        } else {
          m.answers.forEach(answer => {
            checkResult.nb++
            if (!m.propositions.includes(answer)) {
              checkResult.warnings.push({
                text: `la réponse ${answer} du module ${m.name || m.title}(${m.type}) du scenario ${
                  scenario.name
                } ne fait pas partie des propositions`,
                link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
                _id: m._id
              })
            }
          })
        }
      }
      break
    case 'puzzle':
      checkResult.nb++
      if (!m.picture) {
        checkResult.errors.push({
          text: `le puzzle ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
            m.type
          }) n'a pas d'image de fond du puzzle'`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      checkResult.nb++
      if (!m.pieces || m.pieces.length === 0) {
        checkResult.errors.push({
          text: `le puzzle ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
            m.type
          }) n'a pas de pièce de puzzle`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      break
    case 'qrcode_hunt':
      checkResult.nb++
      if ((!m.codes || m.codes.length === 0) && (!m.newCodes || m.newCodes.length === 0)) {
        checkResult.errors.push({
          text: `la chasse au qrcode  ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
            m.type
          }) n'a pas de  code valide`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        checkResult.nb += m.codes.length
        const duplicates = m.codes
          .filter((code, index) => m.codes.find((otherCode, otherIndex) => otherIndex > index && code === otherCode))
          .map((code, index) => index + 1)
        if (duplicates.length) {
          checkResult.warnings.push({
            text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
              m.type
            }) a des Qrcodes en double ${duplicates.join(',')}`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
        const demos = m.codes.filter(code => code === 'demo' || code === 'démo')
        checkResult.nb += m.codes.length
        if (demos.length) {
          checkResult.warnings.push({
            text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
              m.type
            }) accepte démo en validation`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      }
      break
    case 'picture_slider':
      checkResult.nb++
      if (!m.pictures || m.pictures.length < 2) {
        checkResult.errors.push({
          text: `le module picture_slider ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } doit avoir au moins 2 images`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        checkResult.nb += m.pictures.length
        const missingResources = m.pictures.filter(({ file }) => !file).map((_, index) => index + 1)
        if (missingResources.length) {
          checkResult.errors.push({
            text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
              scenario.name
            } a des images sans fichier  : ${missingResources.join(',')}`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      }
      break
    case 'photosphere':
      checkResult.nb++
      if (!m.orientation) {
        checkResult.warnings.push({
          text: `les modules photosphère doivent avoir une orientation : ${m.internalName || m.name || m.title}  du scenario ${
            scenario.name
          }`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      break
    case 'quizz':
      checkResult.nb++
      if (!m.answers || m.answers.length === 0) {
        checkResult.warnings.push({
          text: `le module quizz ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } n'a pas de réponse`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        checkResult.nb++
        const areAllNumbers = m.answers.reduce((arePrecedentNumbers, curr) => {
          return arePrecedentNumbers && !!curr.match(/^[0-9]+$/)
        }, true)
        if (areAllNumbers && m.inputType !== 'number') {
          checkResult.warnings.push({
            text: `le module quizz ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
              scenario.name
            } n'acepte que des chiffres en réponse mais à un clavier de type texte`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      }
      break
    case 'rating':
      checkResult.nb++
      if (!m.ratings || m.ratings.length === 0) {
        checkResult.errors.push({
          text: `le module notation ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } n'a pas de notes`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      break
    case 'scratch':
      checkResult.nb++
      if (!m.foreground) {
        checkResult.warnings.push({
          text: `le module grattage ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } n'a pas de surcouche`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      break
    case 'selfie':
      checkResult.nb++
      if (!m.tags || m.tags.length !== 2) {
        checkResult.warnings.push({
          text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
            scenario.name
          } n'a pas de photo de surcouche`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        m.tags.forEach((tag, index) => {
          checkResult.nb++
          if (!tag.file) {
            checkResult.warnings.push({
              text: `le tag ${index} du selfie ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
                scenario.name
              } n'a pas de fichier `,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
        })
      }
      if (m.toolButton && m.toolButton.length > 0 && m.allowSharing) {
        checkResult.warnings.push({
          text: `les modules photo/selfie ne peuvent pas avoir de toolbutton s'il permettent le partage sur IOS: ${m.internalName ||
            m.name ||
            m.title}(${m.type}) du scenario ${scenario.name}`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      if (m.timeLimit > 0) {
        checkResult.warnings.push({
          text: `les modules sphoto/selfie ne peuvent pas avoir de timer : ${m.internalName || m.name || m.title}(${
            m.type
          }) du scenario ${scenario.name}`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      }
      break
    case 'slasher':
      checkResult.nb++
      if (!m.entities || m.entities.length === 0) {
        checkResult.warnings.push({
          text: `le module ${m.internalName || m.name || m.title} du scenario ${scenario.name}(${
            m.type
          }) n'a pas d'entités à couper`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        m.entities.forEach(({ normalFrames, slashedFrames }, index) => {
          checkResult.nb++
          if (!normalFrames || normalFrames.length === 0) {
            checkResult.errors.push({
              text: `l'entité  ${index + 1} du module ${m.internalName || m.name || m.title}(${m.type}) n'a pas d'image normale `,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
          checkResult.nb++
          if (!slashedFrames || slashedFrames.length === 0) {
            checkResult.errors.push({
              text: `l'entité  ${index + 1} du module ${m.internalName || m.name || m.title}(${
                m.type
              }) n'a pas d'image après avoir été coupée `,
              link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
              _id: m._id
            })
          }
        })
      }
      break

    case 'video':
      checkResult.nb++
      checkResult.nb++
      if (!m.video) {
        checkResult.errors.push({
          text: `le module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${scenario.name} n'a pas de vidéo`,
          link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
          _id: m._id
        })
      } else {
        const resource = await api.resources.get(m.video)
        checkResult.nb++
        if (!resource.hd) {
          checkResult.errors.push({
            text: `la vidéo module ${m.internalName || m.name || m.title}(${m.type}) du scenario ${
              scenario.name
            } n'a pas été traitée par la plateforme, merci de la réuploader`,
            link: xpLink + '/scenario/' + scenario._id + '/module/' + m._id,
            _id: m._id
          })
        }
      }
      break
    default: {
    }
  }

  return checkResult
}
