import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'react-leaflet-fullscreen/dist/styles.css'

import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Map, TileLayer, LayersControl, GeoJSON } from 'react-leaflet'
import Control from 'react-leaflet-control'
import simplify from 'simplify-js'

import FullscreenControl from 'react-leaflet-fullscreen'

import { NavInExperience } from '../components/nav'
import * as api from '../lib/api'
import { Form } from '../components/form'
import Loading from '../components/loading'
import { Page } from './'
import { apiCache } from '../stores'

const Rects = ({ rects }) => {
  if (!rects) {
    return null
  }
  const bounds = { north: -90, south: 90, west: 180, east: -180 }
  rects.forEach(({ coordinates }) => {
    coordinates[0].forEach(coordinates => {
      const [lng, lat] = coordinates
      bounds.north = Math.max(bounds.north, lat)
      bounds.south = Math.min(bounds.south, lat)
      bounds.west = Math.min(bounds.west, lng)
      bounds.east = Math.max(bounds.east, lng)
    })
  })
  return (
    <Map zoom={12} center={[(bounds.north + bounds.south) / 2, (bounds.east + bounds.west) / 2]} style={{ height: '800px' }}>
      <FullscreenControl position="topright" />
      <LayersControl position="topright">
        <LayersControl.BaseLayer name="Mapbox.StreetsSatellite" checked>
          <TileLayer
            attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
            url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/512/{z}/{x}/{y}{r}?access_token=pk.eyJ1IjoiYWxlaXN0b3IiLCJhIjoiY2pqcGpkMWxtN2hoNzNrcjVnNmp1cW9kMyJ9.RTUhct0VcO_98yheNV9b1A"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Mapbox.Streets">
          <TileLayer
            attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
            url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/512/{z}/{x}/{y}{r}?access_token=pk.eyJ1IjoiYWxlaXN0b3IiLCJhIjoiY2pqcGpkMWxtN2hoNzNrcjVnNmp1cW9kMyJ9.RTUhct0VcO_98yheNV9b1A"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Mapbox.Outdoors">
          <TileLayer
            attribution='Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
            url="https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/512/{z}/{x}/{y}{r}?access_token=pk.eyJ1IjoiYWxlaXN0b3IiLCJhIjoiY2pqcGpkMWxtN2hoNzNrcjVnNmp1cW9kMyJ9.RTUhct0VcO_98yheNV9b1A"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      {rects.map(geojson => (
        <GeoJSON
          data={geojson}
          options={{
            style: {
              fillOpacity: 0.15,
              stroke: 'orange',
              weight: 1,
              color: 'orange'
            }
          }}
        />
      ))}

      <Control position="topleft">
        <div
          style={{
            backgroundColor: 'white',
            fontSize: '150%'
          }}
        >
          {rects.length} tuiles
        </div>
      </Control>
    </Map>
  )
}

class MapForm extends Component {
  state = {
    map: {},
    loading: true,
    type: 'ground',
    minZoom: 14,
    maxZoom: 18
  }

  async populateState(experienceUid, mapUid) {
    this.setState({ loading: true })

    await api.maps.getFrom('experiences', experienceUid)
    await api.segments.getFrom('experiences', experienceUid)

    if (mapUid) {
      let resource
      let points = []
      const map = await api.maps.get(mapUid)
      let { minZoom, maxZoom } = this.state
      // if (map.type === 'ground' && map.resource) {
      resource = map.resource && (await api.resources.get(map.resource))
      const tracks = await api.tracks.getFrom('experiences', experienceUid)
      const tracksWithMap = tracks.filter(({ maps }) => {
        return !!(maps || {})[mapUid]
      })
      for (let counter = 0; counter < tracksWithMap.length; counter++) {
        points = points.concat(
          (await api.points.getFrom('tracks', tracksWithMap[counter]._id))
            .filter(({ location }) => !!location)
            .map(point => {
              return toJS(point.location).reverse()
            })
        )
      }

      tracks.forEach(({ minZoom: trackMinZoom, maxZoom: trackMaxZoom }) => {
        minZoom = trackMinZoom && trackMinZoom < minZoom ? trackMinZoom : minZoom
        maxZoom = trackMaxZoom && trackMaxZoom > maxZoom ? trackMaxZoom : maxZoom
      })
      // }
      this.setState({
        loading: false,
        map,
        resource,
        points,
        type: map.type,
        minZoom,
        maxZoom
      })
    } else {
      this.setState({ loading: false, map: { type: 'ground' } })
    }
  }

  componentDidMount() {
    const { experienceUid, mapUid } = this.props.match.params
    this.populateState(experienceUid, mapUid)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.match.params.mapUid !== this.state.map._id) {
      const { experienceUid, mapUid } = newProps.match.params
      this.populateState(experienceUid, mapUid)
    }
  }

  change = formData => {
    this.setState({ type: formData.type })
  }

  coords2bbox = multipolygon => {
    let north = -90
    let south = 90
    let east = -180
    let west = 180
    for (const polygon of multipolygon) {
      for (const [lat, lng] of polygon) {
        north = Math.max(lat, north)
        south = Math.min(lat, south)
        west = Math.min(lng, west)
        east = Math.max(lng, east)
      }
    }
    return { north, south, east, west }
  }

  save = async formData => {
    formData.experienceId = this.props.match.params.experienceUid
    if (formData.boundingBox) {
      formData.northEastBound = [formData.boundingBox.north, formData.boundingBox.east]
      formData.southWestBound = [formData.boundingBox.south, formData.boundingBox.west]
    }
    formData.progress = {}
    const map = await api.maps.upsert(formData)

    if (
      formData.type === 'tiles' &&
      this.props.match.params.mapUid &&
      !formData.resource &&
      formData.source &&
      formData.source.groundOverlay
    ) {
      const map = apiCache.maps.get(formData.source.groundOverlay)
      const { northEastBound, southWestBound } = map
      const points = toJS(await api.points.getFromExperience(formData.experienceId)).map(({ location }) => location)
      let routes = toJS(await api.segments.getFromExperience(formData.experienceId)).map(({ points }) => {
        const simplified = simplify(
          points.map(([x, y]) => ({ x, y })),
          0.00001
        ).map(({ x, y }) => [x, y])
        return simplified
      })

      const source = {
        points: points,
        northEastBound: [northEastBound[1], northEastBound[0]],
        southWestBound: [southWestBound[1], southWestBound[0]],
        routes,
        ...formData.source
      }
      const simulation = await api.tiles.simulate(source)
      const rects = []
      Object.values(simulation.tiles).forEach(coordinatesByZoom => {
        coordinatesByZoom.forEach(coordinates => {
          rects.push(coordinates)
        })
      })
      this.setState({ rects })
    }

    const url = this.props.match.url + (this.props.match.params.mapUid ? '' : map._id)
    this.props.history.push(url)
  }

  rollBack = patchId => {
    const { mapUid } = this.props.match.params
    return api.maps.rollBackToPatch(mapUid, patchId).then(map => {
      this.setState({ map })
    })
  }

  delete = () => {
    const { siteUid, experienceUid, mapUid } = this.props.match.params
    return api.maps.remove(mapUid).then(() => {
      const url = '/site/' + siteUid + '/experience/' + experienceUid
      this.props.history.push(url)
    })
  }

  duplicate = () => {
    const { siteUid, experienceUid } = this.props.match.params
    const clone = toJS(this.state.map)
    delete clone._id
    clone.name = clone.name + ' (copie)'
    api.maps.create(clone).then(mapCreated => {
      const url = '/site/' + siteUid + '/experience/' + experienceUid + '/map/' + mapCreated._id
      this.props.history.push(url)
    })
  }

  /* mobx will force a new render */
  componentWillReact() {
    const { mapUid } = this.props.match.params
    if (mapUid && this.state.map) {
      const map = toJS(apiCache.maps.get(mapUid))
      if (map && this.state.map.updatedAt !== map.updatedAt) {
        this.setState({ map, type: map.type })
      }
    }
  }

  generateTiles = async e => {
    e.preventDefault()
    const map = await api.maps.get(this.props.match.params.mapUid)
    if (map.progress) {
      return
    }

    const { northEastBound, southWestBound } = apiCache.maps.get(map.source.groundOverlay)
    const points = toJS(await api.points.getFromExperience(map.experienceId)).map(({ location }) => location)
    const routes = toJS(await api.segments.getFromExperience(map.experienceId)).map(({ points }) => {
      const simplified = simplify(
        points.map(([x, y]) => ({ x, y })),
        0.00001
      ).map(({ x, y }) => [x, y])
      return simplified
    })
    const source = {
      points: points,
      northEastBound: toJS(northEastBound),
      southWestBound: toJS(southWestBound),
      routes,
      ...map.source
    }
    const ground = await api.maps.get(map.source.groundOverlay)
    const image = await api.resources.get(ground.resource)
    source.url = api.resources.getUrl(image.original.file)
    source.tileSetId = map._id
    source.experienceId = this.props.match.params.experienceUid
    api.tiles.create(source)
    this.setState({ rects: null })
  }

  render() {
    const { mapUid, experienceUid } = this.props.match.params
    let { loading, map, type, rects } = this.state

    mapUid && apiCache.maps.get(mapUid) // to inform mobx I need to watch this in this component

    // if (type === 'ground') {
    let { northEastBound, southWestBound, ...other } = map
    if (!northEastBound || northEastBound.length !== 2) {
      northEastBound = [47, 4.5]
    }
    if (!southWestBound || southWestBound.length !== 2) {
      southWestBound = [46, 4]
    }
    map = {
      ...other,
      boundingBox: {
        north: northEastBound[0],
        south: southWestBound[0],
        east: northEastBound[1],
        west: southWestBound[1]
      }
    }

    const segments = apiCache.segments.getFromExperience(experienceUid)
    const maps = toJS(apiCache.maps.getFromExperience(experienceUid) || []).filter(m => m.type === 'ground')

    // }

    const googleMapURL =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDrzBnG06FzM-F5Oo5tyTSUhv_a7PRl' +
      'VYk&v=3.exp&libraries=geometry,drawing,places'
    const progress = map.progress
    return (
      <Page>
        <NavInExperience />
        <div id="content">
          {loading && <Loading />}
          {progress && (
            <div>
              {progress.message}
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={progress.progress / (progress.total || 1)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                    width: (100 * progress.progress) / (progress.total || 1) + '%'
                  }}
                >
                  <span className="sr-only">{progress.progress / (progress.total || 1)}% Complete</span>
                </div>
              </div>
            </div>
          )}
          {!loading && !progress && !rects && (
            <Form
              formPath={'maps/' + type}
              onSubmit={this.save}
              onDelete={this.delete}
              onDuplicate={this.duplicate}
              initialFormData={map}
              key={mapUid}
              onChange={this.change}
              listHistory={() => api.maps.listAvailableRollBack(mapUid)}
              rollBackToPatch={this.rollBack}
              formContext={{
                experienceUid,
                segments,
                maps
              }}
            />
          )}
          {rects && !progress && (
            <>
              <Rects
                googleMapURL={googleMapURL}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '600px', position: 'relative' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                rects={rects}
              />
              <a className="btn btn-primary" onClick={this.generateTiles}>
                Générer
              </a>
              <a className="btn btn-warning" onClick={() => this.setState({ rects: null })}>
                Annuler
              </a>
            </>
          )}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}

export default observer(MapForm)
