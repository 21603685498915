import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class ExperienceChooser extends Component {
  change = e => {
    this.props.onChange(e.target.value)
  };

  render () {
    const {
      uiSchema,
      showSite,
      formContext,
      formData,
      name,
      schema
    } = this.props
    const { experiences, sites } = formContext || {}
    let xpOptions
    const uiOptions = (uiSchema && uiSchema['ui:options']) || {}
    if (uiOptions.showSite || showSite) {
      xpOptions = (sites || []).map(site => {
        return (
          <optgroup label={site.name} key={site._id}>
            {(experiences || [])
              .filter(xp => xp.siteId === site._id)
              .map(xp => {
                return (
                  <option value={xp._id} key={xp._id}>
                    {xp.name || xp._id} - {xp.locale}
                  </option>
                )
              })}
          </optgroup>
        )
      })
    } else {
      xpOptions = (experiences || []).map(xp => {
        return (
          <option value={xp._id} key={xp._id}>
            {xp.name || xp._id}{' '}
          </option>
        )
      })
    }
    const currExperience =
      formData && experiences
        ? experiences.find(({ _id }) => _id === formData)
        : null
    return (
      <div className='form-group field field-string'>
        <label className='control-label' htmlFor={name}>
          {schema.title}
        </label>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <select
            className='form-control'
            value={formData}
            onChange={this.change}
          >
            <option />
            {xpOptions}
          </select>
          {currExperience && (
            <Link
              style={{ flex: '0 1 50px', marginLeft: '0.5em' }}
              to={'/site/' + currExperience.siteId + '/experience/' + formData}
              className='btn btn-default'
            >
              Voir
            </Link>
          )}
        </div>
      </div>
    )
  }
}
