import * as api from '../../api'
import findMissingLoadTrack from './findmissingloadtrack'

export default async chase => {
  const entities = chase.entities || []
  const checkResult = {
    warnings: [],
    errors: [],
    nb: 0
  }
  const xp = await api.experiences.get(chase.experienceId)
  const xpLink = '/site/' + xp.siteId + '/experience/' + xp._id

  if (entities.length === 0) {
    checkResult.errors.push({
      text: `la course poursuite ${chase.name}, n'a pas de poursuivant(entités)`,
      link: xpLink + '/track/' + chase._id,
      _id: chase._id
    })
  }
  checkResult.nb++

  entities.forEach((entity, index) => {
    if (!entity.marker || !entity.marker.resource) {
      checkResult.warnings.push({
        text: `l'entité ${index + 1} de la course poursuite ${
          chase.name
        }, n'a pas d'icône`,
        link: xpLink + '/track/' + chase._id,
        _id: chase._id
      })
    }
    checkResult.nb++
    if (!entity.segment) {
      checkResult.errors.push({
        text: `l'entité de la course poursuite ${chase.name} n'a pas de tracé GPS `,
        link: xpLink + '/track/' + chase._id,
        _id: chase._id
      })
    }
    checkResult.nb++
  })
  if (!chase.maxScore) {
    checkResult.warnings.push({
      text: `la course poursuite ${chase.name}, n'a pas de score`,
      link: xpLink + '/track/' + chase._id,
      _id: chase._id
    })
  }

  if (!chase.failure) {
    checkResult.errors.push({
      text: `le scenario d'echec  de la course poursuite ${chase.name} n'existe pas `,
      link: xpLink + '/track/' + chase._id,
      _id: chase._id
    })
    checkResult.nb++
  } else {
    const scenario = await api.scenarios.get(chase.failure)
    if (!scenario) {
      checkResult.warnings.push({
        text: `le scenario d'echec de  ${chase.name} de la course poursuite ${chase.name}, n'existe pas `,
        link: xpLink + '/track/' + chase._id,
        _id: chase._id
      })
    } else {
      const modules = await api.modules.getFrom('scenarios', chase.failure)
      if (modules.length === 0 && (!scenario.onEnd || !scenario.onEnd.length)) {
        checkResult.errors.push({
          text: `le scenario de fin de  ${chase.name} est vide `,
          link: xpLink + '/track/' + chase._id,
          _id: chase._id
        })
      }

      checkResult.nb++

      checkResult.nb++
      const missings = await findMissingLoadTrack(chase.failure)
      for (let counter = 0; counter < missings.length; counter++) {
        const missingScenario = await api.scenarios.get(missings[counter])
        checkResult.warnings.push({
          text: `le scenario d'echec de  ${missingScenario.name} a la fin de ${chase.name} ne contient pas de retour vers un parcours.`,
          link: xpLink + '/scenario/' + missingScenario._id,
          _id: missingScenario._id
        })
      }
    }
  }
  checkResult.nb++

  const objectives = (await api.points.getFrom('tracks', chase._id)) || []
  for (let counter = 0; counter < objectives.length; counter++) {
    const objective = objectives[counter]
    checkResult.nb++
    if (!objective.scenario) {
      checkResult.warnings.push({
        text: `l'objectif ${objective.name} de la course poursuite ${chase.name}, n'a pas de scenario de fin`,
        link: xpLink + '/track/' + chase._id,
        _id: chase._id
      })
    } else {
      const scenario = api.scenarios.get(objective.scenario)
      checkResult.nb++
      if (!scenario) {
        checkResult.warnings.push({
          text: `le scenario lancé par objectif ${objective.name} de la course poursuite ${chase.name}, n'existe pas `,
          link: xpLink + '/track/' + chase._id,
          _id: chase._id
        })
      } else {
        const modules = await api.modules.getFrom(
          'scenarios',
          objective.scenario
        )
        checkResult.nb++
        if (
          modules.length === 0 &&
          (!scenario.onEnd || !scenario.onEnd.length)
        ) {
          checkResult.errors.push({
            text: `le scenario lancé par objectif ${objective.name} de la course poursuite ${chase.name} est vide `,
            link: xpLink + '/track/' + chase._id,
            _id: chase._id
          })
        } else {
          const missings = await findMissingLoadTrack(objective.scenario)
          for (let counterMissing = 0; counterMissing < missings.length; counterMissing++) {
            const missingScenario = await api.scenarios.get(missings[counterMissing])
            checkResult.warnings.push({
              text: `le scenario lancé par objectif ${objective.name} de la course poursuite ${chase.name} ne contient pas de retour vers un parcours.`,
              link: xpLink + '/scenario/' + missingScenario._id,
              _id: missingScenario._id
            })
          }
        }
      }
    }
  }

  return checkResult
}
