import React from 'react'
import { t } from 'stores/i18n.store'

import { Page } from '../'
import { NavInSites } from '../../components/nav'

export const NoMatch = () => {
  return (
    <Page>
      <NavInSites />
      <div style={{ margin: 5 }}>{t('no-match')}</div>
    </Page>
  )
}
