import React from 'react'
import { toJS } from 'mobx'
import * as api from '../../../lib/api'
import BookChapter from './chapter'
import computeChapters from './lib/computeChapters'
import { Page } from '../../'

const translate = (object, map) => {
  if (!object) {
    return object
  }
  if (typeof object !== 'object') {
    return map[object] || object
  }
  const res = Array.isArray(object) ? [] : {}
  for (const key in object) {
    res[key] = translate(object[key], map)
  }
  return res
}
const recursiveDifferences = (data1, data2, ignored) => {
  if (!data1) {
    // !data1 && data2 && console.log('nullish data1:', data1, ' data2:', data2)
    return !data2 ? null : { __is_diff: true, source: data1, dest: data2 }
  }

  if (typeof data1 === 'object' /* object or array */) {
    return Object.keys(data1)
      .filter(key => !ignored || ignored.indexOf(key) < 0)
      .reduce(
        (acc, key) => {
          const diff = recursiveDifferences(data1[key], data2 && data2[key]) // only top level properties can be ignored
          if (diff && Object.keys(diff).length > 0) {
            // console.log(' child comparaison of ', key, 'is different', data1[key], data2 && data2[key], { ...acc, [key]: diff })
            if (Array.isArray(acc)) {
              acc[key] = diff
              return acc
            } else {
              return { ...acc, [key]: diff }
            }
          } else {
            return acc
            // console.log(' child comparaison of ', key, 'is IDEM', data1[key], data2 && data2[key])
          }
        },
        Array.isArray(data1) ? [] : {}
      )
  }
  // scalar
  if (data1 !== data2) {
    // console.log('scalar data1:', data1, ' data2:', data2)
  }
  return data1 === data2
    ? null
    : { __is_diff: true, source: data1, dest: data2 }
}

const computeObjectDifferences = (source, dest, map) => {
  source = toJS(source)
  dest = toJS(dest)
  if (source.updatedAt === dest.updatedAt) {
    return null // fast track
  }
  const ignored = ['updatedBy', 'updatedAt', 'createdAt', 'originalId']
  const translatedDest = translate(dest, map)
  const diffs = recursiveDifferences(translatedDest, source, ignored)
  return !diffs || Object.keys(diffs).length === 0 ? null : diffs
}
/*
const isEqual = (source, dest, map) => {
  source = toJS(source)
  dest = toJS(dest)
  if (source.updatedAt === dest.updatedAt) {
    return true // fast track
  }
  const ignored = ['updatedBy', 'updatedAt', 'createdAt', 'originalId']
  const translatedDest = translate(dest, map)
  const diffs = recursiveDifferences(translatedDest, source, ignored)
  console.log('diffs ', diffs)
  return !diffs || Object.keys(diffs).length === 0
}
*/
export const CHANGE_TYPES = {
  CREATED_IN_DESCENDANT: 1,
  FATHER_MODIFIED: 2,
  DESCENDANT_MODIFIED: 3,
  DELETED: 4,
  CREATED_IN_FATHER: 5,
  BOTH_MODIFIED: 6
}
const computeDifferences = async (source, dest) => {
  console.log('will compute differences ', source, dest)
  const collections = [
    'resources',
    'experiences',
    'tracks',
    'points',
    'scenarios',
    'modules',
    'maps',
    'segments'
  ]

  // preload source
  // don't handle case where descendant have been deleted.
  const deleted = {}
  for (const collection of collections) {
    console.log('check collection ', collection)
    const data =
      collection === 'experiences'
        ? [await api.experiences.get(source)]
        : await api[collection].getFrom('experiences', source)
    data.forEach(({ _id }) => {
      if (collection !== 'resources') {
        deleted[_id] = collection // until proved innocent
      }
    })
  }
  const map = {} // id => originalId
  const resourceResourcesHashes = {}
  for (const resource of await api.resources.getFrom('experiences', source)) {
    if (resource.ld) {
      resourceResourcesHashes[resource.ld.hash] = resource._id
    } else {
      if (resource.original) {
        resourceResourcesHashes[resource.original.hash] = resource._id
      }
    }
  }
  for (const collection of collections) {
    const data =
      collection === 'experiences'
        ? [await api.experiences.get(dest)]
        : await api[collection].getFrom('experiences', dest)
    for (const { _id, originalId, ld, original } of data) {
      if (collection === 'resources') {
        const hash = ld ? ld.hash : original.hash
        map[_id] = resourceResourcesHashes[hash]
      } else {
        delete deleted[originalId]
        map[_id] = originalId
      }
    }
  }
  const changes = [] // {source, dest, type C,U,D}
  for (const collection of collections) {
    const data =
      collection === 'experiences'
        ? [await api.experiences.get(dest)]
        : await api[collection].getFrom('experiences', dest)
    for (const datum of data) {
      const { _id, originalId, updatedAt, createdAt } = datum
      if (!originalId) {
        changes.push({
          dest: _id,
          type: CHANGE_TYPES.CREATED_IN_DESCENDANT,
          collection
        })
      } else {
        const original = await api[collection].get(originalId)
        if (!original) {
          changes.push({
            dest: _id,
            type: CHANGE_TYPES.CREATED_IN_DESCENDANT,
            collection
          })
        } else {
          const differences = computeObjectDifferences(original, datum, map)
          if (differences) {
            if (createdAt === original.createdAt) {
              // old duplications
              if (original.updatedAt > updatedAt) {
                changes.push({
                  source: originalId,
                  dest: _id,
                  type: CHANGE_TYPES.FATHER_MODIFIED,
                  collection,
                  differences
                })
              } else {
                if (original.updatedAt < updatedAt) {
                  changes.push({
                    source: originalId,
                    dest: _id,
                    type: CHANGE_TYPES.DESCENDANT_MODIFIED,
                    collection,
                    differences
                  })
                } else {
                  console.log(' not equal, but same updatedAt ? WTF')
                }
              }
            } else {
              // duplications after 2019/12/19
              if (original.updatedAt > createdAt) {
                // original have changed since duplication
                if (updatedAt > createdAt) {
                  changes.push({
                    source: originalId,
                    dest: _id,
                    type: CHANGE_TYPES.BOTH_MODIFIED,
                    collection,
                    differences
                  })
                } else {
                  changes.push({
                    source: originalId,
                    dest: _id,
                    type: CHANGE_TYPES.FATHER_MODIFIED,
                    collection,
                    differences
                  })
                }
              } else {
                if (updatedAt > createdAt) {
                  changes.push({
                    source: originalId,
                    dest: _id,
                    type: CHANGE_TYPES.DESCENDANT_MODIFIED,
                    collection,
                    differences
                  })
                } else {
                  console.log(" not equal, but descendant hasn't changed ? WTF")
                }
              }
            }
          }
        }
      }
    }
  }
  Object.keys(deleted).forEach(_id => {
    const collection = deleted[[_id]]
    changes.push({ source: _id, type: CHANGE_TYPES.DELETED, collection })
  })
  return changes
}

export default class extends React.Component {
  state = {}
  async componentDidMount () {
    const { experienceUid: source, dest, siteUid } = this.props.match.params
    const changes = await computeDifferences(source, dest)
    const chaptersSource = computeChapters(siteUid, source)
    const chaptersDest = changes && computeChapters(siteUid, dest)
    this.setState({ changes, chaptersSource, chaptersDest })
  }

  render () {
    const { changes, chaptersSource, chaptersDest } = this.state
    return (
      <Page>
        <div id='content'>
          {!chaptersDest && 'Ecriture en cours'}
          {chaptersDest && (
            <div className='book'>
              {Object.keys(chaptersDest)
                .map(key => chaptersDest[key])
                .filter(({ _id }) => changes.find(({ dest }) => dest === _id))
                .map((chapter, index) => {
                  const { source, type, differences } =
                    changes.find(({ dest }) => dest === chapter._id) || {}
                  const chapterSource = source && chaptersSource[source]
                  const backgroundFather = 'transparent'
                  const backgroundDescendant = 'transparent'
                  let fatherClassName = ''
                  let descendantClassName = ''
                  switch (type) {
                    case CHANGE_TYPES.BOTH_MODIFIED:
                      fatherClassName = 'modified'
                      descendantClassName = 'modified'
                      break
                    case CHANGE_TYPES.CREATED_IN_FATHER:
                    case CHANGE_TYPES.FATHER_MODIFIED:
                      fatherClassName = 'modified'
                      descendantClassName = 'source'
                      // backgroundFather = 'rgba(142,230,141,0.2)'
                      break
                    case CHANGE_TYPES.CREATED_IN_DESCENDANT:
                      descendantClassName = 'created'
                      // backgroundDescendant = 'rgba(142,230,141,0.15)'
                      break
                    case CHANGE_TYPES.DESCENDANT_MODIFIED:
                      fatherClassName = 'source'
                      descendantClassName = 'modified'
                      // backgroundDescendant = 'rgba(142,230,141,0.15)'
                      break
                    default: {
                      console.log('default ', type)
                    }
                  }
                  return (
                    <div
                      key={chapter._id || index}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <div
                        style={{ flex: 1, backgroundColor: backgroundFather }}
                        className={fatherClassName}
                      >
                        {chapterSource && (
                          <BookChapter
                            {...chapterSource}
                            chapters={chaptersSource}
                            nonRecursive
                            nonDiagnostic
                            highlights={differences}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          backgroundColor: backgroundDescendant
                        }}
                        className={descendantClassName}
                      >
                        <BookChapter
                          {...chapter}
                          chapters={chaptersDest}
                          nonRecursive
                          nonDiagnostic
                          highlights={differences}
                        />
                      </div>
                    </div>
                  )
                })}
              {changes
                .filter(({ type }) => type === CHANGE_TYPES.DELETED)
                .map(change => {
                  const { source } = change
                  const chapter = chaptersSource[source]
                  if (!chapter) {
                    return null
                  }
                  return (
                    <div
                      key={chapter._id}
                      style={{ display: 'flex', flexDirection: 'row' }}
                      className='deleted'
                    >
                      <div style={{ flex: 1 }}>
                        <BookChapter
                          {...chapter}
                          chapters={chaptersSource}
                          nonDiagnostic
                        />
                      </div>
                      <div style={{ flex: 1 }}>Effacé</div>
                    </div>
                  )
                })}
              {changes.length === 0 && <span>Aucun changement</span>}
            </div>
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}
