import { observable, intercept } from 'mobx'

const notifications = observable.map({})

export default notifications

intercept(notifications, changes => {
  if (changes.type === 'add' && !changes.newValue.animateDeleting) {
    changes.newValue.animateDeleting = false // to have it monitored by mobx
  }
  if (changes.type === 'add') {
    if (changes.newValue.autoClose) {
      setTimeout(() => {
        const n = notifications.get(changes.name)
        n.animateDeleting = true
        notifications.set(n.id, n)
      }, changes.newValue.autoClose)
    }
  }
  if (changes && changes.newValue && changes.newValue.animateDeleting) {
    setTimeout(() => {
      // cleanup collection
      notifications.delete(changes.name)
    }, 1000)
  }

  return changes
})
