import { createState, useState } from '@hookstate/core'
import { conf, user } from '.'

let interval = undefined

const clipboardState = createState(JSON.parse(localStorage.getItem('clipboard') || '{}')) // { type: 'module'; date: number; value: string }
/** Hook pour accéder au state réactif du presse-papier. */
export const useClipboard = () => useState(clipboardState)

/** Fonction créant un interval pour supprimer les données du state et du localStorage. */
const setExpirationInterval = () => {
  const callback = () => {
    const { date } = JSON.parse(localStorage.getItem('clipboard') || '{}')
    if (Date.now() - (date || 0) > 1000 * 60) {
      clearInterval(interval)
      interval = undefined
      localStorage.removeItem('clipboard')
      clipboardState.set({}) // Nettoyage du state.
    }
  }
  if (!interval) {
    callback()
    interval = setInterval(callback, 5000)
  }
}

/** Efface les données du state et du localStorage. */
export const clearClipboard = () => {
  localStorage.removeItem('clipboard')
  clipboardState.set({})
}

/**
 * Fonction permettant d'enregistrer les informations d'un élément à copier. En pratique un type, une valeur et une date
 * sont simplement enregistrés dans le state et le localStorage.
 */
export const copyElement = (type, value) => {
  // La copie ne fonctionne que pour un type connu, et une valeur qui soit un ObjectID mongoDB.
  if (['module'].includes(type) && /^[a-fA-F0-9]{24}$/.test(value)) {
    const data = { type, date: Date.now(), value }
    localStorage.setItem('clipboard', JSON.stringify(data))
    clipboardState.set(data)
    setExpirationInterval()
  }
}

/** Fonction permettant de coller un module copier précédement, avec une requête sur le service d'expériences. */
export const pasteModule = async (scenarioId, history) => {
  const { type, value } = clipboardState.get() || {} // Récupération des valeurs du state.
  // Nettoyage immédiat avant de faire le "fetch".
  localStorage.removeItem('clipboard')
  clipboardState.set({})

  if (type === 'module' && value && scenarioId && conf.API_URL) {
    await fetch(conf.API_URL + `/experiences-service/modules/${value}/duplicate`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user.token,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ scenarioId })
    })
      .then(res => res.json())
      .then(res => {
        // Si l'objet "history" existe, la fonction tente une navigation vers le module copié.
        const path = history?.location?.pathname?.replace(/(\/module\/[a-fA-F0-9]{24})$/, '')
        if (history && path && res.module._id) history.push(`${path}/module/${res.module._id}`)
      })
      .catch(error => console.log('Error duplicating module: ', error))
  }
}

// Abonnements
setExpirationInterval()
window.addEventListener('storage', event => {
  if (event.key === 'clipboard') clipboardState.set(JSON.parse(event.newValue || '{}'))
})
