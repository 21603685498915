// import {toJS} from 'mobx'
import React, { Component } from 'react'

import { toJS } from 'mobx'
import { NavInSite } from '../../components/nav'
import Loading from '../../components/loading'
import * as api from '../../lib/api'
import { Page } from '../'

const translateTag = {
  pedestrian: 'walk',
  cyclist: 'bike',
  mental_handicap: 'intellectual disability',
  hearing_impairment: 'hearing impairment',
  visually_impaired: 'visual impairment',
  physical_disability: 'physical disability',
  multiple_handicap: 'handicap'
}

export class Explorrando extends Component {
  state = {
    processing: false,
    json: '',
    error: null
  };

  existing = {};

  loadExistingData () {
    const { siteUid } = this.props.match.params
    this.setState({ loading: true })
    api.experiences
      .getFrom('sites', siteUid)
      .then(experiences => {
        this.existing.experiences = toJS(experiences)
        const promises = []
        experiences.forEach(experience => {
          [
            'scenarios',
            'resources',
            'modules',
            'maps',
            'segments',
            'tracks',
            'points'
          ].forEach(collectionName => {
            promises.push(
              api[collectionName]
                .getFrom('experiences', experience._id)
                .then(collection => {
                  if (collectionName === 'resources') {
                    console.log('resource', collection.length)
                  }
                  if (!this.existing[collectionName]) {
                    this.existing[collectionName] = []
                  }
                  this.existing[collectionName] = this.existing[collectionName].concat(toJS(collection))
                })
            )
          })
        })
        return Promise.all(promises)
      })
      .then(() => {
        console.log(this.existing)
        this.setState({ loading: false })
      })
  }

  componentDidMount () {
    this.loadExistingData()
  }

  upsertPending = null;

  upsert (collectionName, value) {
    let found
    const copy = {
      ...value
    }
    if (
      this.existing[collectionName] &&
      !copy._id &&
      (found = this.existing[collectionName].find(
        o => o.__sourceId === value.__sourceId
      ))
    ) {
      copy._id = found._id
    }

    if (collectionName === 'resources') {
      if (found) {
        return Promise.resolve(found)
      }
      return api.resources.upload(copy)
    }
    this.upsertPending = this.upsertPending || Promise.resolve()
    const q = this.upsertPending.then(() => api[collectionName].upsert(copy))
    this.upsertPending = q
    return q
  }

  upsertExperience (e) {
    const { siteUid } = this.props.match.params
    let experienceId
    const {
      picture,
      id,
      latitude,
      longitude,
      categories,
      pointsDifficulty,
      fieldDifficulty,
      distance,
      ...experience
    } = e
    experience.length = distance
    experience.__sourceId = id
    experience.siteId = siteUid
    experience.waitForDownload = true
    experience.authorizations = {
      tracks: ['normal'],
      modules: [
        'diaporama',
        'video',
        'photosphere',
        'gallery',
        'picture_slider'
      ]
    }
    if (longitude && latitude) {
      experience.location = [longitude, latitude]
      experience.startLocation = [latitude, longitude]
    }
    experience.tags = categories && categories.map(c => translateTag[c] || c)
    experience.deleted = false
    if (pointsDifficulty && fieldDifficulty) {
      experience.difficulty = Math.floor(
        (parseInt(pointsDifficulty) + parseInt(fieldDifficulty)) / 2.0
      )
    }
    return this.upsert('experiences', experience)
      .then(createdExperience => {
        experienceId = createdExperience._id
        return this.upsert('resources', {
          experienceId: createdExperience,
          type: 'image',
          __sourceId: picture,
          url: picture
        })
      })
      .then(res => {
        return this.upsert('experiences', {
          _id: experienceId,
          picture: res._id
        })
      })
  }

  upsertClue (experience, track, name, clues) {
    let scenario
    return this.upsert('scenarios', {
      __sourceId: 'Indices ' + name,
      experienceId: experience._id,
      name: 'Indices ' + name
    })
      .then(s => {
        scenario = s
        // insertion des images du diap
        const promises = clues.map(clue => {
          return this.upsert('resources', {
            experienceId: experience._id,
            type: 'image',
            __sourceId: clue.file,
            url: clue.file
          })
        })
        return Promise.all(promises)
      })
      .then(resources => {
        return this.upsert('modules', {
          experienceId: experience._id,
          scenarioId: scenario._id,
          __sourceId: 'Indices ' + name,
          type: 'gallery',
          title: 'Indices',
          canGoBack: true,
          pictures: clues.map(picture => {
            const file = resources.find(r => r.__sourceId === picture.file)
            const fileId = file ? file._id : null
            return {
              file: fileId,
              caption: picture.caption,
              credits: picture.credits,
              time: picture.time
            }
          })
        })
      })
      .then(() => scenario)
  }

  upsertDiaporama (experience, name, scenario, information) {
    const promises = information.pictures.map(picture => {
      return this.upsert('resources', {
        experienceId: experience._id,
        type: 'image',
        __sourceId: picture.file,
        url: picture.file
      })
    })
    if (information.sound) {
      promises.push(
        this.upsert('resources', {
          experienceId: experience._id,
          type: 'audio',
          __sourceId: information.sound,
          url: information.sound
        })
      )
    }
    return Promise.all(promises).then(resources => {
      const sound = information.sound
        ? resources.find(r => r.__sourceId === information.sound)
        : null
      const soundId = sound ? sound._id : null
      return this.upsert('modules', {
        experienceId: experience._id,
        scenarioId: scenario._id,
        __sourceId: information.id,
        type:
          information.transition === 'slider' ? 'picture_slider' : 'diaporama',
        title: name,
        sound: soundId,
        pictures: information.pictures.map(picture => {
          const file = resources.find(r => r.__sourceId === picture.file)
          const fileId = file ? file._id : null
          return {
            file: fileId,
            caption: picture.caption,
            credits: picture.credits,
            time: parseInt(picture.time || 0, 10)
          }
        }),
        text: information.text,
        autoplay: information.autoplay === '1'
      })
    })
  }

  upsertVideo (experience, name, scenario, information) {
    return this.upsert('resources', {
      experienceId: experience._id,
      type: 'video',
      __sourceId: information.video,
      url: information.video
    }).then(resource => {
      return this.upsert('modules', {
        experienceId: experience._id,
        scenarioId: scenario._id,
        __sourceId: information.id,
        type: 'video',
        title: name,
        video: resource._id,
        text: information.text,
        autoplay: information.autoplay === '1'
      })
    })
  }

  upsertInformation (experience, track, name, information) {
    let scenario
    return this.upsert('scenarios', {
      __sourceId: information.id,
      experienceId: experience._id,
      name: 'scenario ' + name
    })
      .then(s => {
        scenario = s
        if (information.video) {
          return this.upsertVideo(experience, name, scenario, information)
        }
        return this.upsertDiaporama(experience, name, scenario, information)
      })
      .then(() => scenario)
  }

  upsertPhotosphere (experience, track, name, photosphere) {
    let picture, scenario
    return this.upsert('scenarios', {
      __sourceId: photosphere.id,
      experienceId: experience._id,
      name: 'scenario ' + name
    })
      .then(s => {
        scenario = s
        return this.upsert('resources', {
          experienceId: experience._id,
          type: 'image',
          __sourceId: photosphere.picture,
          url: photosphere.picture,
          options: {
            maxSize: 8192
          }
        })
      })
      .then(res => {
        picture = res
        const promises = photosphere.zones.map(
          ({ id, x, y, z, radius, ...zone }) => {
            return this.upsertInformation(
              experience,
              track,
              zone.name,
              zone.information
            ).then(scenarioInformation => {
              return {
                _id: id,
                x,
                y,
                z,
                type: 'image',
                width: radius * 2,
                height: radius * 2,
                onTouch: [
                  {
                    type: 'load_scenario',
                    scenario: scenarioInformation._id
                  }
                ]
              }
            })
          }
        )
        return Promise.all(promises)
      })
      .then(widgets => {
        return this.upsert('modules', {
          experienceId: experience._id,
          scenarioId: scenario._id,
          __sourceId: photosphere.id,
          type: 'photosphere',
          title: name,
          picture: picture._id,
          orientation: parseInt(photosphere.orientation || 0, 10),
          widgets
        })
      })
      .then(() => scenario)
  }

  upsertPoint (experience, track, point, index) {
    console.log('upsertPoint', point)
    let triggers = []
    let gameScenario, clueScenario
    let p
    let markers = null
    switch (point.launchType) {
      case 'manual':
        triggers = [
          {
            type: 'manual'
          }
        ]
        break
      case 'semi-automatic':
        triggers = [
          {
            type: 'manual'
          },
          {
            type: 'location',
            distance: parseInt(point.launchDistance, 10)
          }
        ]
        break
      case 'automatic':
        triggers = [
          {
            type: 'location',
            distance: parseInt(point.launchDistance, 10)
          }
        ]
        break
      default:
        triggers = [
          {
            type: 'manual'
          }
        ]
    }

    if (point.information) {
      console.log('got information')
      p = this.upsertInformation(
        experience,
        track,
        point.name,
        point.information
      )
    } else {
      if (point.photosphere) {
        console.log('got photosphere')
        p = this.upsertPhotosphere(
          experience,
          track,
          point.name,
          point.photosphere
        )
      } else {
        console.log('got nothing')
        p = Promise.resolve()
      }
    }
    p = p.then(scenario => {
      console.log('scenario apres jeu créé', p)
      gameScenario = scenario
      if (point.cluePictures) {
        console.log('il y a un scenario d indices')
        return this.upsertClue(
          experience,
          track,
          point.name,
          point.cluePictures
        ).then(scenario => {
          console.log('scenario d indice créé', scenario)

          clueScenario = scenario
        })
      }
    })
    if (point.markerFile) {
      p = p
        .then(() => {
          return this.upsert('resources', {
            experienceId: experience._id,
            type: 'image',
            __sourceId: point.markerFile,
            url: point.markerFile
          })
        })
        .then(r => {
          markers = {
            normal: {
              size: 1,
              resource: r._id,
              anchor: {
                x: point.markerAnchorX || 0.5,
                y: point.markerAnchorY || 1.0
              }
            }
          }
        })
    }
    p.then(() => {
      let validation
      console.log('creation du point', clueScenario && clueScenario._id)
      switch (point.validationType) {
        case 'code':
          validation = {
            type: 'code',
            code: point.validationCode,
            clue: clueScenario && clueScenario._id,
            scenario: gameScenario && gameScenario._id
          }
          break
        case 'qrcode':
          validation = {
            type: 'qrcode',
            code: point.validationCode,
            clue: clueScenario && clueScenario._id,
            scenario: gameScenario && gameScenario._id
          }
          break
        default:
          console.log(' no validation')
          validation = null
      }
      return this.upsert('points', {
        __sourceId: point.id,
        order: index,
        trackId: track._id,
        experienceId: experience._id,
        name: point.name,
        card: {
          title: point.name,
          text: point.description
        },
        location: [point.latitude, point.longitude],
        markers,
        validation,
        check: validation,
        triggers,
        active: point.active === '1',
        scenario: gameScenario && !validation ? gameScenario._id : null
      })
    })
  }

  upsertPoints (experience, points, maps, routes, minZoom, maxZoom) {
    console.log('upasertPoints', points)
    if (points.length === 0) {
      return Promise.resolve()
    }
    let track

    this.upsert('tracks', {
      __sourceId: points[0].track_id,
      experienceId: experience._id,
      type: 'normal',
      routes: routes,
      maps: maps,
      minZoom: minZoom || 14,
      maxZoom: maxZoom || 18,
      name: 'Parcours ' + points[0].track_id
    }).then(t => {
      track = t
      const promises = points.map((point, index) => {
        return this.upsertPoint(experience, track, point, index)
      })

      return Promise.all(promises)
    })
  }

  upsertLayer (experience, layer) {
    let promise, type

    switch (layer.type) {
      case 'custom':
        type = 'tiles'
        promise = this.upsert('resources', {
          experienceId: experience._id,
          __sourceId: layer.archive,
          url: layer.archive,
          type: 'other'
        })
        break
      case 'plan':
        type = 'normal'
        promise = Promise.resolve()
        break
      default:
        type = layer.type
        promise = Promise.resolve()
        break
    }
    return promise.then(res => {
      return this.upsert('maps', {
        type,
        name: 'layer ' + layer.id,
        __sourceId: layer.id,
        experienceId: experience._id,
        resource: type === 'tiles' ? res._id : null
      })
    })
  }

  upsertAgglomeratedExperience (ae) {
    let { route, points, layer, ...experience } = ae

    let segment, map
    return this.upsertExperience(experience)
      .then(e => {
        experience = e
        return this.upsert('segments', {
          __sourceId: route.id,
          experienceId: experience._id,
          name: 'route-' + route.id,
          color: route.color,
          width: route.width === '0' ? null : parseInt(route.width, 10),
          points: route.points.map(p => [p.latitude, p.longitude])
        })
      })
      .then(s => {
        segment = s
        return this.upsertLayer(experience, layer)
      })
      .then(m => {
        map = m
        return this.upsert('experiences', {
          _id: experience._id,
          routes: [
            {
              zIndex: 2,
              segment: segment._id
            }
          ]
        })
      })
      .then(experience => {
        this.upsertPoints(
          experience,
          points,
          {
            [map._id]: 1
          },
          [
            {
              segment: segment._id
            }
          ],
          layer.minZoom,
          layer.maxZoom
        )
      })
  }

  process = () => {
    let json
    this.setState({ processing: true })
    try {
      json = JSON.parse(this.state.json)
    } catch (e) {
      this.setState({ error: 'JSON invalide' })
      return
    }
    console.log('json ok ')
    // this.upsertAgglomeratedExperience(json[0])
    return Promise.all(
      json
        .filter(ae => !ae.dummy)
        .map(ae => this.upsertAgglomeratedExperience(ae))
    ).then(() => {
      this.setState({ processing: false })
    })
  };

  render () {
    const { loading, processing } = this.state
    if (loading) {
      return <Loading />
    }
    return (
      <Page>
        <NavInSite />
        <div
          id='content'
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <textarea
            style={{
              flex: 1
            }}
            placeholder='copier ici le contenu du json a intégrer'
            onChange={e => this.setState({ json: e.target.value })}
          />
          <div style={{}}>
            {!processing && (
              <button
                className='btn btn-primary btn-block'
                onClick={this.process}
              >
                Traiter
              </button>
            )}
            {processing && <span>processing</span>}
          </div>
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}
