import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import * as api from '../../lib/api'
import { NavInExperience } from '../../components/nav'
import Page from '../page'
import { apiCache } from '../../stores'
import { Form } from '../../components/form'

export class CharactersStatic extends Component {
  populate(experienceUid) {
    api.experiences.get(experienceUid)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.experienceUid !== nextProps.match.params.experienceUid) {
      this.populate(nextProps.match.params.experienceUid)
    }
  }

  componentDidMount() {
    this.populate(this.props.match.params.experienceUid)
  }

  async updateResource(character, newResourceId, newBackgroundId, newLabel) {
    const experienceId = this.props.match.params.experienceUid
    const resources = await api.resources.getFrom('experiences', experienceId)

    const oldIcon = character.image ? resources.find(r => r._id === character.image) : null
    const newIcon = newResourceId ? resources.find(r => r._id === newResourceId) : null
    const newIconUrl = newIcon ? api.resources.getUrl(newIcon.original.file) : null

    const oldBackground = character.background ? resources.find(r => r._id === character.background) : null
    const newBackground = newResourceId ? resources.find(r => r._id === newBackgroundId) : null
    const newBackgroundUrl = newBackground ? api.resources.getUrl(newBackground.original.file) : null

    const modules = (await api.modules.getFrom('experiences', experienceId)).filter(
      m => m.type === 'conversation' || m.type === 'score_conversation'
    )
    const toBeChanged = []
    const getDialogCharacterLastId = id => id && id.split('°|°').pop() // fonctionne avec les id de character ET les variantes
    for (let counter = 0; counter < modules.length; counter++) {
      const { _id, dialogs } = modules[counter]
      // image du personnage
      dialogs &&
        dialogs
          .filter(({ character: dialogCharacter }) => character._id === getDialogCharacterLastId(dialogCharacter))
          .filter(({ icon: iconId }) => {
            const icon = resources.find(r => r._id === iconId)
            return icon && oldIcon && icon.original.hash === oldIcon.original.hash
          })
          .forEach(({ icon: iconId }) => {
            toBeChanged.push({ _id: iconId, url: newIconUrl })
          })

      // image de fond personnage
      dialogs &&
        dialogs
          .filter(({ character: dialogCharacter }) => character._id === getDialogCharacterLastId(dialogCharacter))
          .filter(({ background: backgroundId }) => {
            const background = resources.find(r => r._id === backgroundId)
            return background && oldBackground && background.original.hash === oldBackground.original.hash
          })
          .forEach(({ background: backgroundId }) => {
            toBeChanged.push({ _id: backgroundId, url: newBackgroundUrl })
          })

      const iconToBeCreated =
        dialogs &&
        dialogs
          .filter(({ character: dialogCharacter }) => character._id === getDialogCharacterLastId(dialogCharacter))
          .filter(({ icon }) => !icon)

      if (newIconUrl && iconToBeCreated && iconToBeCreated.length > 0) {
        while (iconToBeCreated.length > 0) {
          const dialog = iconToBeCreated.pop()
          const resource = await api.resources.upload({
            experienceId: experienceId,
            type: 'image',
            immediate: true,
            url: newIconUrl
          })
          dialog.icon = resource._id
        }
      }

      const backgroundToBeCreated =
        dialogs &&
        dialogs
          .filter(({ character: dialogCharacter }) => character._id === dialogCharacter)
          .filter(({ background }) => !background)

      if (newBackgroundUrl && backgroundToBeCreated && backgroundToBeCreated.length > 0) {
        while (backgroundToBeCreated.length > 0) {
          const dialog = backgroundToBeCreated.pop()
          const resource = await api.resources.upload({
            experienceId: experienceId,
            type: 'image',
            immediate: true,
            url: newBackgroundUrl
          })
          dialog.background = resource._id
        }
      }

      const nameToBeCreated =
        dialogs &&
        dialogs
          .filter(({ character: dialogCharacter }) => dialogCharacter?.includes(character._id))
          .filter(({ name }) => !name || name !== newLabel)

      if (newLabel && nameToBeCreated && nameToBeCreated.length > 0) {
        while (nameToBeCreated.length > 0) {
          const dialog = nameToBeCreated.pop()
          dialog.name = newLabel
        }
      }

      await api.modules.upsert({ _id, dialogs: toJS(dialogs) })

      while (toBeChanged.length > 0) {
        const { _id, url } = toBeChanged.pop()
        await api.resources.update(_id, {
          url,
          immediate: true,
          type: 'image'
        })
      }
    }
  }

  save = async characters => {
    const { experienceUid } = this.props.match.params
    const experience = apiCache.experiences.get(experienceUid)
    const oldCharacters = experience.characters || []
    for (const old of oldCharacters) {
      const newChar = characters.find(c => old._id === c._id)
      if (
        newChar &&
        (!old || old.image !== newChar.image || old.background !== newChar.background || old.label !== newChar.label)
      ) {
        await this.updateResource(old, newChar.image, newChar.background, newChar.label)
      }
      for (const variant of old.variants || []) {
        const newVariant = (newChar.variants || []).find(v => variant._id === v._id)
        if (
          newVariant &&
          (!variant ||
            variant.image !== newVariant.image ||
            variant.background !== newVariant.background ||
            old.label !== newChar.label)
        ) {
          await this.updateResource(variant, newVariant.image, newVariant.background, newChar.label)
        }
      }
    }
    return api.experiences.update(experienceUid, {
      _id: experienceUid,
      characters
    })
  }

  render() {
    const { experienceUid } = this.props.match.params
    const experience = apiCache.experiences.get(experienceUid)
    if (!experience) {
      return null
    }
    const characters = experience.characters || []
    return (
      <Page>
        <NavInExperience />
        <div id="content">
          <Form
            formPath="characters"
            initialFormData={characters}
            onSubmit={this.save}
            formContext={{
              experienceUid
            }}
          />
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}

export default observer(CharactersStatic)
