import React, { Component } from 'react'
import Page from '../page'
import { NavInExperience, NavInSite, NavInSites } from '../../components/nav'
import * as api from '../../lib/api'
import formatDate from './formatdate'
import Counters from './counters'
import PerLocale from './perlocale'
import PerPointsChecked from './perpointschecked'
import PerHour from './perhour'
import PerTeamSize from './perteamsize'
import PerScore from './perscore'
import PerSystem from './persystem'
import PerWeek from './perweek'
import PerWeekDay from './perweekday'
import { t } from 'stores/i18n.store'

export default class extends Component {
  state = {
    sessions: [],
    from: new Date(Date.now() - 365 * 24 * 3600 * 1000).toISOString(),
    to: null,
    excludeZeros: true,
    experiences: [],
    experienceIds: []
  }

  componentDidMount() {
    const { siteUid, experienceUid } = this.props.match.params
    const query = new URLSearchParams(this.props.location.search)

    if (siteUid && !experienceUid) {
      api.experiences.getFrom('sites', siteUid).then(experiences => {
        const experienceIds = query.get('experienceIds') ? query.get('experienceIds').split(',') : experiences.map(x => x._id)
        this.setState({ experiences: experiences, experienceIds })
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const query = new URLSearchParams(nextProps.location.search)
    this.setState({
      experienceIds: (query.get('experienceIds') || '').split(',')
    })
  }

  render() {
    const { siteUid, experienceUid } = this.props.match.params
    const { from, to, excludeZeros, experienceIds, experiences } = this.state

    const nav = siteUid ? experienceUid ? <NavInExperience /> : <NavInSite /> : <NavInSites />
    return (
      <Page>
        <div style={{ width: 250 }}>{nav}</div>
        <div style={{ marginLeft: 'auto', marginRight: 'auto', flex: 0.95, textAlign: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <label htmlFor="from" style={{ flex: 1 }}>
              {t('page-stats-date-from')}
            </label>
            <input
              id="from"
              className="form-control"
              type="date"
              style={{ flex: 1 }}
              onChange={e => this.setState({ from: new Date(e.target.value).toISOString() })}
              value={formatDate(from)}
            />
            <label htmlFor="to" style={{ flex: 1 }}>
              {t('page-stats-date-to')}
            </label>
            <input
              style={{ flex: 1 }}
              id="to"
              className="form-control"
              type="date"
              onChange={e => this.setState({ to: new Date(e.target.value).toISOString() })}
              value={formatDate(to)}
            />
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={excludeZeros} onChange={e => this.setState({ excludeZeros: e.target.checked })} />
                {t('page-stats-exclude')}
              </label>
            </div>
          </div>

          <Counters
            siteId={siteUid}
            experienceId={experienceUid}
            experienceIds={experienceIds}
            from={from}
            to={to}
            excludeZeros={excludeZeros}
            key={siteUid + JSON.stringify(experienceIds)}
          />

          <h3>{t('page-stats-over-time')}</h3>
          <PerWeek
            siteId={siteUid}
            experienceId={experienceUid}
            experienceIds={experienceIds}
            from={from}
            to={to}
            excludeZeros={excludeZeros}
          />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>
              <h3>{t('page-stats-points-checked')}</h3>
              <PerPointsChecked
                siteId={siteUid}
                experienceId={experienceUid}
                experienceIds={experienceIds}
                from={from}
                to={to}
                excludeZeros={excludeZeros}
              />
            </div>
            <div style={{ flex: 1 }}>
              <h3>{t('team-size')}</h3>
              <PerTeamSize
                siteId={siteUid}
                experienceId={experienceUid}
                experienceIds={experienceIds}
                from={from}
                to={to}
                excludeZeros={excludeZeros}
              />
            </div>
          </div>
          <h3>{t('page-stats-score')}</h3>
          <PerScore
            siteId={siteUid}
            experienceId={experienceUid}
            experienceIds={experienceIds}
            from={from}
            to={to}
            excludeZeros={excludeZeros}
          />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>
              <h3>{t('page-stats-start-time')}</h3>
              <PerHour
                siteId={siteUid}
                experienceId={experienceUid}
                experienceIds={experienceIds}
                from={from}
                to={to}
                excludeZeros={excludeZeros}
              />
            </div>
            <div style={{ flex: 1 }}>
              <h3>{t('page-stats-week-day')}</h3>
              <PerWeekDay
                siteId={siteUid}
                experienceId={experienceUid}
                experienceIds={experienceIds}
                from={from}
                to={to}
                excludeZeros={excludeZeros}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>
              <h3>{t('page-stats-os')}</h3>
              <PerSystem
                siteId={siteUid}
                experienceId={experienceUid}
                experienceIds={experienceIds}
                from={from}
                to={to}
                excludeZeros={excludeZeros}
              />
            </div>
            <div style={{ flex: 1 }}>
              <h3>{t('page-stats-lang')}</h3>
              <PerLocale
                siteId={siteUid}
                experienceId={experienceUid}
                experienceIds={experienceIds}
                from={from}
                to={to}
                excludeZeros={excludeZeros}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {experiences.map(xp => (
              <div className="checkbox" style={{ flex: '0 1 300px', marginTop: 0 }} key={xp._id}>
                <label htmlFor={'experience_' + xp._id}>
                  <input
                    type="checkbox"
                    id={'experience_' + xp._id}
                    checked={experienceIds.indexOf(xp._id) >= 0}
                    onChange={e => {
                      if (e.target.checked) experienceIds.push(xp._id)
                      else {
                        const pos = experienceIds.indexOf(xp._id)
                        if (pos >= 0) experienceIds.splice(pos, 1)
                      }
                      const location = this.props.location
                      this.props.history.push(location.pathname + '?experienceIds=' + experienceIds.join(','))
                    }}
                  />{' '}
                  {xp.name + '-' + xp.locale}
                </label>
              </div>
            ))}
          </div>
        </div>
      </Page>
    )
  }
}
