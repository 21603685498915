import React, { Component } from 'react'
import { Doughnut } from 'react-chartjs-2'
import * as api from '../../lib/api'
import getRandomColor from './getrandomcolor'

export default class extends Component {
  state = {
    stats: []
  };

  populate (props) {
    const {
      siteId,
      experienceId,
      experienceIds,
      from,
      to,
      excludeZeros
    } = props
    api.sessions
      .stats('locales', {
        from:
          from || new Date(Date.now() - 365 * 24 * 3600 * 1000).toISOString(),
        to: to,
        limit: 100000,
        siteId,
        experienceId,
        experienceIds,
        excludeZeros,
        axisProperties: ['locale']
      })
      .then(stats => this.setState({ stats }))
  }

  componentDidMount () {
    this.populate(this.props)
  }

  componentWillReceiveProps (nextProps) {
    this.populate(nextProps)
  }

  render () {
    const stats = this.state.stats || []

    if (!stats.length) {
      return null
    }
    const data = {
      labels: stats.map(s => s.locale),
      datasets: [
        {
          backgroundColor: stats.map(getRandomColor),
          data: stats.map(s => s.people)
        }
      ]
    }

    return (
      <div>
        <Doughnut
          data={data}
          height={150}
          options={{
            legend: {
              display: false
            },
            cutoutPercentage: 50
          }}
        />
      </div>
    )
  }
}
