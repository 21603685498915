import React, { Component } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { t } from 'stores/i18n.store'
import * as api from '../../lib/api'

export default class extends Component {
  state = {
    stats: []
  }

  populate(props) {
    const { siteId, experienceId, from, to, excludeZeros, experienceIds } = props

    api.sessions
      .stats('systems', {
        from: from || new Date(Date.now() - 365 * 24 * 3600 * 1000).toISOString(),
        to: to,
        limit: 100000,
        siteId,
        experienceId,
        experienceIds,
        excludeZeros,
        axisProperties: ['system', 'version']
      })
      .then(stats => this.setState({ stats }))
  }

  componentDidMount() {
    this.populate(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.populate(nextProps)
  }

  render() {
    const stats = this.state.stats || []

    if (!stats.length) {
      return null
    }
    const versions = {}
    stats.forEach(s => {
      let sv
      if (s.system === 'android') {
        sv = s.system + '/' + s.version
      } else {
        let v = typeof s.version === 'string' ? s.version.split('.')[0] : 'unknown'
        if (parseInt(v, 10) < 10) v = '0' + v
        sv = s.system + '/' + v
      }
      versions[sv] = (versions[sv] || 0) + s.people
    })

    const orderSystems = ['android', 'ios'].map(system =>
      stats
        .filter(s => s.system === system)
        .reduce((acc, value) => {
          return acc + value.people
        }, 0)
    )
    const dataSystems = {
      datasets: [
        {
          label: 'versions',
          backgroundColor: Object.keys(versions)
            .sort()
            .map(v => (v.indexOf('android') === 0 ? '#82d25ade' : '#b6a09aaa')),
          data: Object.keys(versions)
            .sort()
            .map(sv => versions[sv])
        },
        {
          label: 'systems',
          backgroundColor: ['#82d25ade', '#b6a09aaa'],
          data: orderSystems
        }
      ]
    }

    return (
      <div>
        <Doughnut
          data={dataSystems}
          height={150}
          options={{
            cutoutPercentage: 50,
            borderWidth: 0,
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  if (tooltipItem.datasetIndex === 1) {
                    return ['Android', 'Ios'][tooltipItem.index] + ': ' + orderSystems[tooltipItem.index] + ' joueurs'
                  }
                  const version = Object.keys(versions).sort()[tooltipItem.index] || ''
                  const peoples = versions[version]
                  return version + ': ' + peoples + ' ' + (peoples > 1 ? t('players') : t('player'))
                }
              }
            }
          }}
        />
      </div>
    )
  }
}
