import { createState, useState } from '@hookstate/core'
import i18n from 'i18n-js'

export const startupLocal = new URLSearchParams(window.location.search).get('lang') || window.localStorage.getItem('lang') || 'fr'

i18n.fallbacks = true
i18n.defaultLocale = 'fr'
i18n.locale = startupLocal

// Fichiers de traduction.
i18n.translations = {
  fr: require('../translations/fr.json'),
  en: require('../translations/en.json'),
  de: require('../translations/de.json')
}

/** Langue actuelle de l'application. */
export const locale = createState(i18n.locale)

/** Modification de la langue de l'application. Simplement modifier le state n'est pas suffisant. */
export const setLocale = newLocale => {
  i18n.locale = newLocale
  locale.set(newLocale)
}

/** Fonction de traduction. */
export const t = (messageKey, options) => {
  const message = i18n.t(messageKey, options)

  if (/^\[missing ".*" translation\]$/.test(message)) {
    console.log(`Translation for "${messageKey}" does not exists in translations files.`)
    return messageKey
  }

  return message
}

const wrapper = locale => ({ locale, t, setLocale })

export const useI18n = () => wrapper(useState(locale))
