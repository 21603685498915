import React from 'react'
import { clearClipboard, pasteModule, useClipboard } from 'stores/clipboard.store'
import { t } from 'stores/i18n.store'

export const PasteModule = props => {
  const { scenarioId, history, refresh } = props || {}

  const { type, date } = useClipboard()

  if (type.value === 'module' && Date.now() - (date.value || 0) < 1000 * 60)
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <li
          key="copy-module"
          style={{ cursor: 'pointer', flex: 1 }}
          // Début de la copie au clic. Après l'exécution la fonction "refresh" est exécutée si elle existe, avec un délai.
          onClick={() => {
            pasteModule(scenarioId, history).finally(() => (refresh ? setTimeout(refresh, 500) : undefined))
          }}
        >
          <i className="fa fa-paste" /> {t('btn-paste-module')}
        </li>
        <li
          key="clear-copy"
          style={{ cursor: 'pointer', padding: 0, paddingLeft: 5, paddingRight: 5, margin: 0, marginLeft: 5, margingRight: 5 }}
          onClick={() => clearClipboard()}
        >
          <i className="fa fa-times" />
        </li>
      </div>
    )
  else return null
}
