import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'

export const ResizeModePicker = ({ formData, onChange, schema }) => {
  const options = useRef([
    { value: undefined, label: 'Non défini' },
    { value: 'contain', label: "Visible en totalité (n'occupe pas forcément tout l'espace)" },
    { value: 'cover', label: 'Recouvre tout (peut dépasser de la zone visible)' }
  ]).current

  const [value, setValue] = useState()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValue(options.find(option => option.value === formData)), [formData])

  return (
    <div style={{ flexDirection: 'row' }}>
      <label className="control-label">{schema.title}</label>
      <Select
        value={value}
        onChange={option => onChange(option.value)}
        options={options}
        isMulti={false}
        placeholder={'Sélectionnez un élément'}
        menuPlacement="top"
      />
    </div>
  )
}
