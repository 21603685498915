export default {
    //compass: 'Boussole',
    //choice: 'Choix',
    //qrcode_hunt: 'Chasse au QR Code',
    differences: "Observez attentivement le XXX et trouvez les 5 différences entre l'image sur l'écran et celle en face de vous.",
    differences_photosphere: "Trouvez les 5 différences entre l'image sur l'écran et celle autour de vous.",
    dragdrop: 'Placez l(es) élément(s) au bon endroit.',
    level: 'Scannez un maximum de Qr-codes, sans que la bille ne touche les bords du cercle.', 
    //rating: 'Notation ',
    //object3d: 'Objet en 3D',
    //photosphere: 'Photosphère',
    picture_slider: 'Déplacez le curseur de droite à gauche et observez les changements.',
    puzzle: "Reconstituez l’image en vous aidant de l’original.",
    //image_mcq: 'Quizz Image',
    //mcq: 'Quizz : question à choix multiples',
    quizz: "Résolvez l'énigme et écrivez le mot code correspondant.",
    augmented_reality: 'Placez la tablette devant le visuel pour révéler son secret.',
    scratch: 'Grattez pour découvrir le numéro manquant.',
    shake_contest: "Secouez l’appareil le plus fort possible.",
    //session: 'Session de jeu',
    silence: 'Cliquez sur le bouton en bas à droite pour commencer. Cherchez et scannez les QRcodes sans faire de bruit pendant 4 minutes.',
    //slasher: 'Slasher',
    sonometer: 'Lachez-vous, vous avez 30 secondes pour faire le plus de bruit possible !',
}