import * as api from '../../lib/api'

export const duplicateActionsInAfterGame = async trackId => {
  const points = await api.points.getFrom('tracks', trackId)
  for (let counter = 0; counter < points.length; counter++) {
    const point = points[counter]
    if (!point.check || !point.check.scenario) {
      continue
    }
    const game = await api.scenarios.get(point.check.scenario)
    const actions = (game.onEnd || []).filter(
      ({ type }) =>
        [
          'enable_point',
          'enable_all_points',
          'disable_all_points',
          'disable_point',
          'show_point',
          'hide_point',
          'show_all_points',
          'hide_all_points',
          'load_track'
        ].indexOf(type) >= 0
    )
    if (actions.length > 0) {
      const afterGame = point.scenario && (await api.scenarios.get(point.scenario))
      if (!afterGame) {
        const scenario = await api.scenarios.create({
          experienceId: point.experienceId,
          name: 'Après jeu ' + point.name,
          onEnd: actions
        })
        await api.points.upsert({ _id: point._id, scenario: scenario._id })
      } else {
        if (afterGame.onEnd && afterGame.onEnd.length > 0) {
          console.error(' le scenario de fin à dejà des actions ', afterGame, actions)
        } else {
          await api.scenarios.upsert({ _id: afterGame._id, onEnd: actions })
        }
      }
    }
  }
}
