import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { toJS } from 'mobx'
import { NavInSite } from '../../components/nav'
import { Experience, Page, Statistic } from '../'

import SiteForm from './form'
import { Explorrando } from './migrate'
import * as api from '../../lib/api'

class Tags extends Component {
  populateInitialData () {}

  render () {
    return (
      <Page>
        <NavInSite />
        <div id='content'>
          <h2>Tags</h2>
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default class Site extends Component {
  render () {
    const { match } = this.props
    return (
      <Switch>
        <Route
          path='/site/:siteUid/experience/:experienceUid?'
          component={Experience}
        />
        <Route path='/site/:siteUid/tags' component={Tags} />
        <Route
          path='/site/:siteUid/integration/explorrando'
          component={Explorrando}
        />
        <Route path='/site/:siteUid/statistics' component={Statistic} />
        <Route path='/site/:siteUid/merge' component={Compare} />

        <Route
          path={match.path + '/leaderboard'}
          component={() => {
            window.location.href =
              'https://scores.explor.games/leaderboard/site/' +
              match.params.siteUid
            return null
          }}
        />
        <Route path='/site/:siteUid?' component={SiteForm} />
      </Switch>
    )
  }
}

class Compare extends Component {
  findMatching (collections, source, dest) {
    if (!source || !dest) {
      return []
    }

    if (typeof source === typeof dest && typeof source === 'object') {
      let matched = []
      Object.keys(source)
        // .filter(key=>["_id","trackId","experienceId","scenarioId","siteId","uid","id"].indexOf(key) === -1)
        .forEach(key => {
          if (
            dest.hasOwnProperty(key) &&
            typeof source[key] === typeof dest[key]
          ) {
            matched = matched.concat(
              this.findMatching(collections, toJS(source[key]), toJS(dest[key]))
            )
          }
        })
      return matched
    } else {
      // is it an id ?
      let objectSource, objectSourceCollection
      Object.keys(collections).forEach(collectionName => {
        const found = collections[collectionName].source.find(
          o => o._id === source
        )
        if (found) {
          objectSource = found
          objectSourceCollection = collectionName
        }
      })

      if (objectSource) {
        const objectDest = collections[objectSourceCollection].dest.find(
          o => o._id === dest
        )
        if (objectDest) {
          return [
            {
              sourceId: source,
              destId: dest,
              collectionName: objectSourceCollection
            }
          ]
        }
      }
    }
    return []
  }

  /*
  diff(source,dest){
    if(!source){
      return !!dest
    }
    if(typeof source === "object"){
      return Object.keys(source)
        .filter(key=>["updatedAt","updatedBy"].indexOf(key) === -1 )
        .filter(key=>!key.match(/[0-9a-f]{24}/))
        .filter(key=>typeof source[key] !== "string" || !source[key].match(/[0-9a-f]{24}/))
        .map(key=>{
          if(this.diff(source[key],dest[key])){
            console.log("diff",key,source[key],dest[key])

          }
          return this.diff(source[key],dest[key])
        })
        .reduce((acc,curr)=>{
          return acc || curr
        },false)
    }
    return source !== dest
  }
  */

  merge (xpSourceId, xpDestId) {
    const promises = []

    promises.push(api.experiences.get(xpSourceId))
    promises.push(api.experiences.get(xpDestId))
    promises.push(api.tracks.getFrom('experiences', xpSourceId))
    promises.push(api.tracks.getFrom('experiences', xpDestId))
    promises.push(api.points.getFrom('experiences', xpSourceId))
    promises.push(api.points.getFrom('experiences', xpDestId))
    promises.push(api.scenarios.getFrom('experiences', xpSourceId))
    promises.push(api.scenarios.getFrom('experiences', xpDestId))
    promises.push(api.modules.getFrom('experiences', xpSourceId))
    promises.push(api.modules.getFrom('experiences', xpDestId))

    const sourceToDest = {} // id  of source object : id of the atched objet in the target
    const destToSource = {} // id  of source object : id of the atched objet in the target
    let backlog = [] // a matched object have been found , should try to extract all the mlinks

    return Promise.all(promises).then(
      ([
        xpSource,
        xpDest,
        tracksSource,
        tracksDest,
        pointsSource,
        pointsDest,
        scenriosSource,
        scenarioDest,
        modulesSource,
        modulesDest
      ]) => {
        backlog.push({
          collectionName: 'experiences',
          sourceId: xpSourceId,
          destId: xpDestId
        })
        const collections = {
          experiences: {
            source: [toJS(xpSource)],
            dest: [toJS(xpDest)]
          },
          tracks: {
            source: toJS(tracksSource),
            dest: toJS(tracksDest)
          },
          points: {
            source: toJS(pointsSource),
            dest: toJS(pointsDest)
          },
          scenarios: {
            source: toJS(scenriosSource),
            dest: toJS(scenarioDest)
          },
          modules: {
            source: toJS(modulesSource),
            dest: toJS(modulesDest)
          }
        }
        while (backlog.length > 0) {
          const { collectionName, sourceId, destId } = backlog.pop()
          if (sourceToDest[sourceId]) {
            continue
          }

          const source = collections[collectionName].source.find(
            o => o._id === sourceId
          )
          const dest = collections[collectionName].dest.find(
            o => o._id === destId
          )
          const objectMatched = this.findMatching(collections, source, dest)
          backlog = backlog.concat(objectMatched || [])
          sourceToDest[sourceId] = destId
          destToSource[destId] = sourceId

          if (collectionName === 'tracks' && sourceToDest[sourceId]) {
            const destTrackId = sourceToDest[sourceId]
            collections.points.source
              .filter(p => p.trackId === sourceId)
              .map(p => toJS(p))
              .forEach(sourcePoint => {
                const dest = collections.points.dest
                  .map(p => toJS(p))
                  .filter(p => p.trackId === destTrackId)
                  .find(p => {
                    if (
                      sourcePoint.location &&
                      p.location &&
                      sourcePoint.location[0] === p.location[0] &&
                      sourcePoint.location[1] === p.location[1]
                    ) {
                      if (
                        p.active === sourcePoint.active &&
                        p.visible === sourcePoint.visible &&
                        p.order === sourcePoint.order
                      ) {
                        return true
                      }
                    }
                    return false
                  })
                if (dest) {
                  backlog.push({
                    sourceId: sourcePoint._id,
                    destId: dest._id,
                    collectionName: 'points'
                  })
                }
              })
          }

          if (collectionName === 'scenarios' && sourceToDest[sourceId]) {
            const destScenarioId = sourceToDest[sourceId]
            collections.modules.source
              .filter(m => m.scenarioId === sourceId)
              .map(m => toJS(m))
              .forEach(sourceModule => {
                const destModule = collections.modules.dest
                  .map(m => toJS(m))
                  .filter(m => m.scenarioId === destScenarioId)
                  .find(m => m.order === sourceModule.order)

                if (destModule) {
                  backlog.push({
                    sourceId: sourceModule._id,
                    destId: destModule._id,
                    collectionName: 'modules'
                  })
                }
              })
          }
          //
        }

        Object.keys(sourceToDest).forEach(sourceId => {
          const collectionName = Object.keys(collections).find(
            name => !!collections[name].source.find(o => o._id === sourceId)
          )
          const source = collections[collectionName].source.find(
            o => o._id === sourceId
          )
          const dest = collections[collectionName].dest.find(
            o => o._id === sourceToDest[sourceId]
          )
          if (source && dest && source.updatedAt > dest.updatedAt) {
            console.log(
              'changed',
              collectionName,
              source.internalName || source.title || source.name || source
            )
          }
        })
      }
    )
  }

  componentDidMount () {
    this.merge('5c40bb6d99e127001b02bc75', '5b4f29b05dd8400d51442018')
  }

  handleChangeExperience = () => {};
  render () {
    return null
  }
}
