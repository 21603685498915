import React, { Component } from 'react'
import { moduleLabel, trackLabel } from '../../stores'

export default class extends Component {
  render () {
    const experience = this.props.formContext.experience
    return (
      <div className='form-group field field-string'>
        <label className='control-label' htmlFor={this.props.name}>
          {this.props.schema.title}
        </label>
        <select
          className='form-control'
          value={this.props.formData}
          onChange={e => this.props.onChange(e.target.value)}
        >
          <option />
          <optgroup label='module'>
            {experience.authorizations.modules
              .sort((m1, m2) =>
                (moduleLabel[m1] || m1).localeCompare(moduleLabel[m2] || m2)
              )
              .map(m => {
                return (
                  <option value={'module-' + m} key={'module-' + m}>
                    {moduleLabel[m] || m}{' '}
                  </option>
                )
              })}
          </optgroup>
          <optgroup label='parcours'>
            {experience.authorizations.tracks
              .sort((t1, t2) =>
                (trackLabel[t1] || t1).localeCompare(trackLabel[t2] || t2)
              )
              .map(m => {
                return (
                  <option value={'track-' + m} key={'track-' + m}>
                    {trackLabel[m] || m}{' '}
                  </option>
                )
              })}
          </optgroup>
        </select>
      </div>
    )
  }
}
