/* eslint-disable camelcase */
import { observable } from 'mobx'

const cache = observable.map({})
let cachedQueries = {}

const apiCache = {
  upsert: (serviceName, collectionName, id, item) => {
    cachedQueries[serviceName + '/' + collectionName + '/' + id] = true
    return cache.set(serviceName + '/' + collectionName + '/' + id, item)
  },
  get: (serviceName, collectionName, id) => {
    return cache.has(serviceName + '/' + collectionName + '/' + id)
      ? cache.get(serviceName + '/' + collectionName + '/' + id)
      : null
  },
  // try to get a id of a module/points/whatever in a duplicated experience
  getFromOriginalId: (serviceName, collectionName, experienceId, id) => {
    if (
      !cachedQueries[serviceName + '/' + collectionName] &&
      // eslint-disable-next-line
      !cachedQueries[serviceName + '/' + collectionName + '/experiences/' + experienceId] &&
      // eslint-disable-next-line
      !cachedQueries[serviceName + '/' + collectionName + '/' + experienceId] /* looking for originalid of an experience */
    ) {
      return null
    }
    let foundItem
    cache.forEach((item, key) => {
      if (key.indexOf(serviceName + '/' + collectionName + '/') === 0) {
        if (
          item.originalId === id &&
          ((item.experienceId === experienceId && collectionName !== 'experiences') ||
            (collectionName === 'experiences' && item._id === experienceId))
        ) {
          foundItem = item
        }
      }
      if (collectionName === 'experiences' && key.indexOf(serviceName + '/' + collectionName + '/') === 0) {
      }
    })
    return foundItem
  },
  createList: (serviceName, collectionName, showDeleted, items) => {
    const map = {}
    items.forEach(item => {
      map[serviceName + '/' + collectionName + '/' + item._id] = item
    })
    cache.merge(map)
    cachedQueries[serviceName + '/' + collectionName] = true
  },

  list: (serviceName, collectionName, showDeleted) => {
    if (!cachedQueries[serviceName + '/' + collectionName]) {
      return null
    }

    const filtered = observable([])
    cache.forEach((item, key) => {
      if (key.indexOf(serviceName + '/' + collectionName + '/') === 0) {
        if (showDeleted || !item.deleted) {
          filtered.push(item)
        }
      }
    })
    return filtered
  },

  getFrom: (serviceName, collectionName, parentCollectionName, parentId, showDeleted) => {
    let cached =
      !!cachedQueries[serviceName + '/' + collectionName] ||
      // eslint-disable-next-line
      !!cachedQueries[serviceName + '/' + collectionName + '/' + parentCollectionName + '/' + parentId]

    if (serviceName === 'experiences-service') {
      if (parentCollectionName !== 'experiences') {
        const parent = apiCache.get(serviceName, parentCollectionName, parentId)
        if (parent && parent.experienceId) {
          if (
            // eslint-disable-next-line
            cachedQueries[serviceName + '/' + collectionName + '/experiences/' + parent.experienceId]
          ) {
            cached = true
          }
        }
      }
    }

    if (!cached) {
      return null
    }

    const parentItemName = parentCollectionName.substring(0, parentCollectionName.length - 1) + 'Id'
    const filtered = observable([])
    cache.forEach((item, key) => {
      if (key.indexOf(serviceName + '/' + collectionName + '/') === 0) {
        if (item[parentItemName] === parentId) {
          if (showDeleted || !item.deleted) {
            filtered.push(item)
          }
        }
      }
    })
    return filtered
  },

  setFrom: (serviceName, collectionName, parentCollectionName, parentId, showDeleted, items) => {
    const actual = apiCache.getFrom(serviceName, collectionName, parentCollectionName, parentId, showDeleted)
    // removed
    actual &&
      actual.forEach((item, key) => {
        const existing = items.find(itemCollection => itemCollection._id === item._id)
        if (!existing) {
          cache.delete(key)
        }
      })

    // modified or created
    const map = {}
    items.forEach(item => {
      map[serviceName + '/' + collectionName + '/' + item._id] = item
    })
    cache.merge(map)
    // eslint-disable-next-line
    cachedQueries[serviceName + '/' + collectionName + '/' + parentCollectionName + '/' + parentId] = true
  },

  remove: (serviceName, collectionName, id) => {
    const item = cache.get(serviceName + '/' + collectionName + '/' + id)
    if (item) {
      item.deleted = true
      cache.set(serviceName + '/' + collectionName + '/' + id, item)
    }
  },
  clear: () => {
    cache.clear()
    cachedQueries = {}
  },
  dirty: () => {
    cachedQueries = {}
  }
}

function generate(serviceName, collectionName) {
  return {
    upsert: (id, item) => apiCache.upsert(serviceName, collectionName, id, item),
    get: id => apiCache.get(serviceName, collectionName, id),
    getFromOriginalId: (experienceId, id) => apiCache.getFromOriginalId(serviceName, collectionName, experienceId, id),
    createList: (showDeleted, items) => apiCache.createList(serviceName, collectionName, showDeleted, items),
    list: showDeleted => apiCache.list(serviceName, collectionName, showDeleted),
    getFrom: (parentCollectionName, parentId, showDeleted) =>
      apiCache.getFrom(serviceName, collectionName, parentCollectionName, parentId, showDeleted),
    setFrom: (serviceName, collectionName, parentCollectionName, parentId, showDeleted, items) =>
      apiCache.setFrom(parentCollectionName, parentId, showDeleted, items),
    getFromExperience: (experienceId, showDeleted) =>
      apiCache.getFrom(serviceName, collectionName, 'experiences', experienceId, showDeleted),
    remove: id => apiCache.remove(serviceName, collectionName, id)
  }
}

apiCache.experiences = generate('experiences-service', 'experiences')
apiCache.maps = generate('experiences-service', 'maps')
apiCache.modules = generate('experiences-service', 'modules')
apiCache.points = generate('experiences-service', 'points')
apiCache.resources = generate('experiences-service', 'resources')
apiCache.scenarios = generate('experiences-service', 'scenarios')
apiCache.segments = generate('experiences-service', 'segments')
apiCache.sites = generate('experiences-service', 'sites')
apiCache.tags = generate('experiences-service', 'tags')
apiCache.tracks = generate('experiences-service', 'tracks')
apiCache.bundles = generate('experiences-service', 'bundles')
apiCache.users = generate('users-service', 'users')
apiCache.applications = generate('applications-service', 'applications')
apiCache.variants = generate('applications-service', 'variants')

export default apiCache
