import { toJS } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import uid from 'uuid'

import { NavInTrack } from '../../components/nav'
import * as api from '../../lib/api'
import { Form } from '../../components/form'
import Loading from '../../components/loading'
import { Page } from '../'
import { apiCache } from '../../stores'

class HordeEntityForm extends Component {
  state = {
    entity: {},
    track: {}
  };

  populateState (experienceUid, trackUid, entityUid) {
    this.setState({ loading: true })
    api.scenarios
      .getFromExperience(experienceUid)
      .then(scenarios => {
        this.setState({ scenarios })
        return api.tracks.get(trackUid)
      })
      .then(track => {
        track = toJS(track)
        const entity =
          (track.entities || []).find(e => '' + e._id === entityUid) || {}
        entity.updatedAt = track.updatedAt
        console.log('found entity', entity, track.entities)
        this.setState({ entity, track, loading: false })
      })
  }

  componentDidMount () {
    const { experienceUid, trackUid, entityUid } = this.props.match.params
    this.populateState(experienceUid, trackUid, entityUid)
  }

  componentWillReceiveProps (nextProps) {
    const { experienceUid, trackUid, entityUid } = nextProps.match.params
    this.populateState(experienceUid, trackUid, entityUid)
  }

  save = formData => {
    const {
      siteUid,
      experienceUid,
      trackUid,
      entityUid
    } = this.props.match.params
    const { track } = this.state
    const entities = toJS(track.entities || [])
    const entityIndex = (track.entities || []).findIndex(
      e => e._id === formData._id
    )
    formData._id = formData._id || uid.v4()
    if (entityIndex >= 0) {
      entities[entityIndex] = formData
    } else {
      entities.push(formData)
    }

    return api.tracks
      .upsert({ _id: track._id, entities: entities })
      .then(() => {
        if (!entityUid) {
          const url =
            '/site/' +
            siteUid +
            '/experience/' +
            experienceUid +
            '/track/' +
            trackUid +
            '/entity/' +
            formData._id
          this.props.history.push(url)
        }
      })
  };

  delete = () => {
    const {
      siteUid,
      experienceUid,
      trackUid,
      entityUid
    } = this.props.match.params
    const { track } = this.state
    const entityIndex = track.entities.findIndex(e => e._id === entityUid)
    if (entityIndex >= 0) {
      const entities = toJS(track.entities)
      entities.splice(entityIndex, 1)

      return api.tracks.update(track._id, { entities }).then(() => {
        const url =
          '/site/' +
          siteUid +
          '/experience/' +
          experienceUid +
          '/track/' +
          trackUid
        this.props.history.push(url)
      })
    }

    throw new Error('Entity does not exists')
  };

  componentWillReact () {
    const { trackUid, entityUid } = this.props.match.params
    if (trackUid && this.state.track) {
      const track = toJS(apiCache.tracks.get(trackUid))

      if (track && this.state.track.updatedAt !== track.updatedAt) {
        const entity =
          (track.entities || []).find(e => '' + e._id === entityUid) || {}
        entity.updatedAt = track.updatedAt

        this.setState({ entity, track })
      }
    }
  }

  duplicate = () => {
    const { siteUid, experienceUid, trackUid } = this.props.match.params
    const clone = { ...toJS(this.state.entity) }
    clone._id = uid.v1()
    clone.name += ' (copie)'
    const track = this.state.track
    const entities = toJS(track.entities || [])
    entities.push(clone)
    return api.tracks
      .update(track._id, { entities })

      .then(() => {
        const url =
          '/site/' +
          siteUid +
          '/experience/' +
          experienceUid +
          '/track/' +
          trackUid +
          '/entity/' +
          clone._id
        this.props.history.push(url)
      })
  };

  render () {
    const { siteUid, experienceUid, trackUid } = this.props.match.params
    const { entity, loading, scenarios } = this.state
    trackUid && apiCache.tracks.get(trackUid)
    return (
      <Page>
        <NavInTrack />
        <div id='content'>
          {loading && <Loading />}
          {!loading && (
            <Form
              formPath='points/hordeentity'
              initialFormData={entity}
              onSubmit={this.save}
              onDelete={this.delete}
              onDuplicate={this.duplicate}
              experienceUid={experienceUid}
              formContext={{
                scenarios,
                siteUid
              }}
            />
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default observer(HordeEntityForm)
