import React, { Component } from 'react'
import * as api from '../../lib/api'
import Page from '../page'
import { Form } from '../../components/form'
import { NavInTrack } from '../../components/nav'
import { apiCache } from 'stores'

/**
 * type de point
 *  contenu
 *  jeu
 *    validation : qrcode demo
 *    type de jeu : liste déroulante
 */
class PointWizardForm extends Component {
  state = {
    loading: true,
    saving: false
  };

  populateState (experienceUid) {
    this.setState({ loading: true })
    api.scenarios
      .getFromExperience(experienceUid)
      .then(() => {
        return api.tags.getFromExperience(experienceUid)
      })
      .then(tags => {
        this.setState({ tags })
        return api.tracks.getFromExperience(experienceUid)
      })
      .then(tracks => {
        this.setState({ loading: false })
      })
  }

  componentDidMount () {
    this.populateState(
      this.props.match.params.experienceUid,
      this.props.match.params.trackUid,
      this.props.match.params.pointUid
    )
  }

  componentWillReceiveProps (newProps) {
    if (newProps.match.params.trackUid !== this.props.match.params.trackUid) {
      this.populateState(
        newProps.match.params.experienceUid,
        newProps.match.params.trackUid,
        newProps.match.params.pointUid
      )
    }
  }

  save = formData => {
    console.log('will save ', formData)

    const { siteUid, experienceUid, trackUid } = this.props.match.params

    let { check: checkAndSteps, name, ...otherPoint } = formData
    let { steps, ...check } = checkAndSteps || {}
    name = name || 'point ' + new Date()
    if (check.type === 'none') {
      check = null
    } else {
      check.code = check.code || 'demo'
    }

    let p = api.points.getFrom('tracks', trackUid)

    let currentScenarioId

    let currentPointId
    p.then(points => {
      otherPoint.order = points.length + 1
    })

    if (check && check.type === 'location') {
      otherPoint.triggers = [
        {
          type: 'location',
          distance: 12
        },
        {
          type: 'manual'
        }
      ]
    }

    if (check && check.type === 'beacon') {
      otherPoint.triggers = [
        {
          type: 'beacon'
        },
        {
          type: 'manual'
        }
      ]
    }
    console.log('check ', check, 'steps', steps)
    p = p
      .then(() => {
        return api.points.create({
          experienceId: experienceUid,
          trackId: trackUid,
          name,
          active: true,
          visible: true,
          ...otherPoint
        })
      })
      .then(p => (currentPointId = p._id))

    if (check && check.type !== 'location' && check.type !== 'beacon') {
      p = p
        .then(() => {
          return api.scenarios
            .create({
              name: 'jeu ' + name,
              experienceId: experienceUid
            })
            .then(s => {
              check.scenario = s._id
              currentScenarioId = s._id
              return api.scenarios.create({
                name: 'indices ' + name,
                experienceId: experienceUid
              })
            })
        })
        .then(s => {
          check.clue = s._id
          return api.modules.create({
            name: 'Indices',
            type: 'gallery',
            experienceId: experienceUid,
            scenarioId: s._id,
            canGoBack: true
          })
        })
        .then(m => {
          return api.points.upsert({ _id: currentPointId, check })
        })
    }

    if (!check || check.type === 'location' || check.type === 'beacon') {
      const scenarioName = !check ? 'info ' + name : 'jeu ' + name
      p = p.then(() => {
        return api.scenarios
          .create({ name: scenarioName, experienceId: experienceUid })
          .then(s => {
            currentScenarioId = s._id
            return api.points.upsert({ _id: currentPointId, scenario: s._id })
          })
      })
    }

    steps &&
      steps.forEach((step, order) => {
        if (!step.type) {
          return
        }

        if (step.type.indexOf('module-') === 0) {
          const type = step.type.substring(7)
          p = p.then(() => {
            api.modules.create({
              title: step.title || type,
              type: type,
              experienceId: experienceUid,
              scenarioId: currentScenarioId || check.scenario,
              order
            })
          })
        }
        if (step.type.indexOf('track-') === 0) {
          const type = step.type.substring(6)
          const title = step.title || type + ' ' + name
          let currentTrack
          const isNotLastTrack = steps
            .filter((_, otherStepOrder) => otherStepOrder > order)
            .find(step => step.type.indexOf('track-') === 0)
          console.log('step is lasttrack ? ', isNotLastTrack, step)
          const onEnd = isNotLastTrack
            ? []
            : [
              {
                type: 'load_track',
                track: trackUid
              }
            ]
          p = p
            .then(() => {
              return api.tracks.create({
                name: title,
                type: type,
                experienceId: experienceUid,
                order
              })
            })
            .then(track => {
              currentTrack = track
              return api.scenarios.upsert({
                _id: currentScenarioId,
                onEnd: [
                  {
                    type: 'load_track',
                    track: track._id
                  }
                ]
              })
            })
          if (type === 'chase') {
            p = p
              .then(() => {
                return api.scenarios.create({
                  experienceId: experienceUid,
                  name: 'réussite ' + title,
                  onEnd
                })
              })
              .then(sucessScenario => {
                currentScenarioId = sucessScenario._id
                return api.points.upsert({
                  name: 'objectif',
                  experienceId: experienceUid,
                  trackId: currentTrack._id,
                  scenario: sucessScenario._id
                })
              })
              .then(() => {
                return api.scenarios.create({
                  experienceId: experienceUid,
                  name: 'échec ' + title,
                  onEnd
                })
              })
              .then(failScenario => {
                return api.tracks.upsert({
                  _id: currentTrack._id,
                  failure: failScenario._id
                })
              })
          }

          if (type === 'photo_hunt') {
            p = p
              .then(() => {
                // check if it's the last track in the step
                return api.scenarios.create({
                  experienceId: experienceUid,
                  name: 'fin de  ' + title,
                  onEnd
                })
              })
              .then(sucessScenario => {
                currentScenarioId = sucessScenario._id
                return api.tracks.upsert({
                  _id: currentTrack._id,
                  scenario: sucessScenario._id
                })
              })
          }

          if (type === 'horde') {
            p = p
              .then(() => {
                return api.scenarios.create({
                  experienceId: experienceUid,
                  name: 'réussite ' + name,
                  onEnd
                })
              })
              .then(endScenario => {
                currentScenarioId = endScenario._id
                return api.points.upsert({
                  name: 'objectif',
                  experienceId: experienceUid,
                  trackId: currentTrack._id,
                  scenario: endScenario._id
                })
              })
          }
        }
      })

    p.then(() => {
      const url =
        '/site/' +
        siteUid +
        '/experience/' +
        experienceUid +
        '/track/' +
        trackUid +
        '/point/' +
        currentPointId
      this.props.history.push(url)
    })
  };

  render () {
    const { siteUid, experienceUid } = this.props.match.params
    const experience = apiCache.experiences.get(experienceUid)
    return (
      <Page>
        <NavInTrack />
        <div id='content'>
          <Form
            formPath='points/wizard'
            initialFormData={{
              check: {
                type: 'qrcode'
              }
            }}
            onSubmit={this.save}
            key={this.props.match.url}
            experienceUid={experienceUid}
            formContext={{
              siteUid,
              experience
            }}
          />
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default PointWizardForm
