import React from 'react'

export default ({ id, formData, name, formContext, onChange, schema }) => {
  const type = formData && formData.type ? formData.type : ''
  const uuid = formData && formData.uuid ? formData.uuid : ''
  const macAdress = formData && formData.macAdress ? formData.macAdress : ''
  const distance = formData && formData.distance ? formData.distance : 10
  let otherField = null
  switch (type) {
    case 'location':
      otherField = [
        <input
          className='form-control'
          value={distance}
          type='number'
          key='distance'
          placeholder='distance'
          onChange={e => onChange({ ...formData, distance: e.target.value })}
        />
      ]
      break
    case 'beacon':
      otherField = [
        <input
          className='form-control'
          value={uuid}
          key='uuid'
          placeholder='UUid'
          onChange={e => onChange({ ...formData, uuid: e.target.value })}
        />,
        <input
          className='form-control'
          value={macAdress}
          key='macAdress'
          placeholder='mac address'
          onChange={e => onChange({ ...formData, macAdress: e.target.value })}
        />
      ]
      break
    default:
      otherField = null
  }
  return (
    <div className='form-group field field-string'>
      <label className='control-label' htmlFor={id}>
        {schema.title}
      </label>
      <select
        className='form-control'
        value={type}
        onChange={e => {
          onChange({ ...formData, type: e.target.value })
        }}
      >
        {!type && <option value='' />}
        <option value='manual'>Au clic sur le point</option>
        <option value='location'>Par la position de l'utilisateur</option>
        <option value='beacon'>Balise bluetooth</option>
      </select>
      {otherField}
    </div>
  )
}
