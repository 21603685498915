import { toJS } from 'mobx'
import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { NavInExperience } from '../../components/nav'
import * as api from '../../lib/api'
import Loading from '../../components/loading'
import { Page } from '../'
import { apiCache, moduleLabel, trackLabel } from '../../stores'

class ExperienceAuthorization extends Component {
  state = {
    experience: {},
    loading: true,
    tracks: [],
    modules: []
  }

  populateState(experienceUid) {
    this.setState({ loading: true })

    if (experienceUid) {
      api.experiences.get(experienceUid).then(experience => {
        this.setState({
          loading: false,
          tracks: experience.authorizations.tracks,
          modules: experience.authorizations.modules,
          experience
        })
      })
    } else {
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    this.populateState(this.props.match.params.experienceUid)
  }

  changeTrack = e => {
    let tracks = this.state.tracks.slice()
    if (e.target.checked) {
      tracks.push(e.target.name)
    } else {
      tracks = tracks.filter(i => {
        return i !== e.target.name
      })
    }
    this.setState({ tracks })
  }

  changeModule = e => {
    let modules = this.state.modules.slice()
    if (e.target.checked) {
      modules.push(e.target.name)
    } else {
      modules = modules.filter(i => {
        return i !== e.target.name
      })
    }
    this.setState({ modules })
  }

  save = e => {
    e.preventDefault()
    const { experience, tracks, modules } = this.state

    this.setState({ saving: true })
    return api.experiences
      .upsert({
        _id: experience._id,
        authorizations: {
          tracks: toJS(tracks),
          modules: toJS(modules)
        }
      })
      .then(res => {
        this.setState({ saving: false })
      })
  }

  componentWillReact() {
    const { experienceUid } = this.props.match.params
    if (experienceUid && this.state.experience) {
      const experience = toJS(apiCache.experiences.get(experienceUid))

      if (experience && this.state.experience.updatedAt !== experience.updatedAt) {
        this.setState({
          experience: experience,
          tracks: experience.authorizations.tracks,
          modules: experience.authorizations.modules
        })
      }
    }
  }

  render() {
    const { loading, tracks, modules } = this.state
    // to force mobx to update this view if experience changes
    apiCache.experiences.get(this.props.match.params.experienceUid)
    return (
      <Page>
        {this.props.match.params.experienceUid && <NavInExperience />}
        <div id="content">
          <h2>Parcours autorisés</h2>
          {loading && <Loading />}
          {!loading && (
            <form onSubmit={this.save}>
              {Object.keys(trackLabel)
                .sort((t1, t2) => (trackLabel[t1] || '').localeCompare(trackLabel[t2] || ''))
                .map(type => {
                  const name = trackLabel[type]
                  return (
                    <div className="form-group field field-boolean" key={type}>
                      <div className="checkbox ">
                        <label>
                          <input type="checkbox" name={type} onChange={this.changeTrack} checked={tracks.indexOf(type) >= 0} />
                          <span>{name}</span>
                        </label>
                      </div>
                      <div />
                      <div />
                    </div>
                  )
                })}
              <h2>Modules autorisés</h2>

              {Object.keys(moduleLabel)
                .sort((t1, t2) => (moduleLabel[t1] || '').localeCompare(moduleLabel[t2] || ''))
                .map(type => {
                  const name = moduleLabel[type]
                  return (
                    <div className="form-group field field-boolean" key={type}>
                      <div className="checkbox ">
                        <label>
                          <input type="checkbox" name={type} onChange={this.changeModule} checked={modules.indexOf(type) >= 0} />
                          <span>{name}</span>
                        </label>
                      </div>
                      <div />
                      <div />
                    </div>
                  )
                })}
              {!this.state.saving && <input type="submit" className="btn btn-primary" value="Mettre à jour les autorisations" />}
            </form>
          )}
        </div>
        <div id="app-preview" />
      </Page>
    )
  }
}

export default observer(ExperienceAuthorization)
