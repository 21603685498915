import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { saveAs } from 'file-saver'

import Loading from '../loading'

import * as api from '../../lib/api'
export default class NavInApplicationStatic extends Component {
  state = {
    application: {},
    variants: [],
    loading: false,
    downloading: false
  }

  componentDidMount() {
    const applicationId = this.props.match.params.applicationId
    if (applicationId) {
      this.setState({ loading: true })
      api.applications
        .get(this.props.match.params.applicationId)
        .then(application => {
          this.setState({ application })
          return api.variants.getFrom('applications', applicationId)
        })
        .then(variants => {
          this.setState({ variants, loading: false })
        })
    }
  }

  render() {
    const { applicationId } = this.props.match.params
    const { loading, variants, downloading } = this.state
    if (loading) {
      return <Loading />
    }

    return (
      <nav>
        <div className="nav-block">
          <h3>Application</h3>
          <ul>
            <li>
              <NavLink exact to={'/application/' + applicationId}>
                Détail
              </NavLink>
            </li>
            <li>
              {downloading && 'Téléchargement en cours'}
              {!downloading && (
                <button
                  className="btn btn-block btn-primary"
                  onClick={e => {
                    this.setState({ downloading: true })
                    api.applications.package(applicationId).then(blob => {
                      this.setState({ downloading: false })
                      saveAs(blob, 'package.zip')
                    })
                  }}
                  title="Télécharger le zip"
                >
                  <i className="fa fa-download" />
                  Télécharger le zip
                </button>
              )}
            </li>
          </ul>
        </div>

        <div className="nav-block">
          <h3>Variantes</h3>
          <ul>
            {(variants || []).map(variant => {
              return (
                <li key={variant._id}>
                  <NavLink to={'/application/' + applicationId + '/variant/' + variant._id}>
                    {variant.name || variant._id}({variant.language})
                  </NavLink>
                </li>
              )
            })}

            {/* {application.type === 'android' && (
              <li key='add'>
                <NavLink
                  exact
                  to={'/application/' + applicationId + '/variant/'}
                >
                  <i className='fa fa-upload' />
                  Ajouter une variante
                </NavLink>
              </li>
            )} */}
          </ul>
        </div>
      </nav>
    )
  }
}
