import { observable, runInAction } from 'mobx'
import uuid from 'uuid'

export const uploadStatus = observable({
  activeNumber: 0,
  uploadLoaded: 0,
  downloadLoaded: 0,
  uploadSpeed: 0.0,
  downloadSpeed: 0.0,
  uploadTotal: 0,
  downloadTotal: 0,
  uploadEta: null,
  downloadEta: null
})
export const uploadHistory = observable.map({})

uploadStatus.updateSum = function() {
  const downloadSpeed = 0.0

  let downloadTotal = 0

  let downloadLoaded = 0

  let uploadTotal = 0

  let uploadSpeed = 0.0

  let uploadLoaded = 0

  uploadHistory
    .filter(upload => upload.status === 'upload' && upload.uploadSpeed)
    .forEach(upload => {
      uploadLoaded += upload.uploadLoaded
      uploadTotal += upload.uploadTotal
      uploadSpeed += 0.0 + upload.uploadSpeed
    })

  uploadHistory
    .filter(upload => upload.status === 'download' && upload.downloadTotal)
    .forEach(upload => {
      downloadLoaded += upload.downloadLoaded
      downloadTotal += upload.downloadTotal
      uploadSpeed += 0.0 + (upload.uploadLoaded * 1000) / (Date.now() - upload.start)
    })
  runInAction(() => {
    uploadStatus.uploadLoaded = uploadLoaded
    uploadStatus.uploadTotal = uploadTotal
    uploadStatus.uploadSpeed = uploadSpeed
    uploadStatus.downloadLoaded = downloadLoaded
    uploadStatus.downloadTotal = downloadTotal
    uploadStatus.downloadSpeed = downloadSpeed
    uploadStatus.uploadEta = uploadSpeed ? (uploadTotal - uploadLoaded) / uploadSpeed : null
    uploadStatus.downloadEta = downloadSpeed ? (downloadTotal - downloadLoaded) / downloadSpeed : null
  })
}

uploadStatus.add = function(query, transfertId) {
  const { url, method, headers, formData } = query
  const clientUid = transfertId || uuid.v4()

  const xhr = new XMLHttpRequest()

  uploadHistory.set(clientUid, {
    clientUid,
    query,
    status: 'idle',
    start: Date.now(),
    uploadStart: Date.now(),
    downloadStart: null,
    uploadLoaded: 0,
    downloadLoaded: 0,
    uploadTotal: 0,
    downloadTotal: 0,
    uploadEta: 0,
    downloadEta: 0,
    xhr,
    serverUid: null
  })

  var promise = new Promise(function(resolve, reject) {
    xhr.upload.addEventListener(
      'loadstart',
      e => {
        const u = uploadHistory.get(clientUid)
        u.status = 'loadstart'
        uploadStatus.updateSum()
      },
      false
    )

    xhr.upload.addEventListener(
      'progress',
      e => {
        const u = uploadHistory.get(clientUid)
        u.status = 'upload'
        if (e.lengthComputable) {
          u.uploadTotal = 0.0 + e.total
          u.uploadLoaded = 0.0 + e.loaded
          u.uploadSpeed = 0.0 + (e.loaded * 1000) / (Date.now() - u.start)
          u.uploadEta = (e.total - e.loaded) / u.uploadSpeed
        }
        uploadStatus.updateSum()
      },
      false
    )

    xhr.upload.addEventListener(
      'abort',
      e => {
        const u = uploadHistory.get(clientUid)
        u.status = 'aborted'
        reject(new Error('aborted'))
      },
      false
    )

    xhr.upload.addEventListener(
      'error',
      e => {
        const u = uploadHistory.get(clientUid)
        u.status = 'errored'

        reject(new Error('error'))
      },
      false
    )

    xhr.upload.addEventListener(
      'load',
      e => {
        const u = uploadHistory.get(clientUid)
        u.status = 'uploaded'
        uploadStatus.updateSum()
      },
      false
    )

    xhr.addEventListener('readystatechange', e => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const u = uploadHistory.get(clientUid)
        u.status = 'done'

        uploadStatus.updateSum()
        try {
          const json = resolve(JSON.parse(xhr.responseText))
          resolve(json)
        } catch (e) {
          reject(new Error('response is no json ' + xhr.responseText))
        }
      }
    })
    xhr.addEventListener('progress', e => {
      const u = uploadHistory.get(clientUid)
      u.status = 'download'

      if (e.lengthComputable) {
        u.downloadTotal = e.total
        u.downloadLoaded = e.loaded
        u.downloadSpeed = 0.0 + (e.loaded * 1000) / (Date.now() - u.start)
        u.downloadEta = (e.total - e.loaded) / u.downloadSpeed
      }
      uploadStatus.updateSum()
    })

    xhr.open(method, url, true)
    Object.keys(headers || {}).forEach(header => {
      xhr.setRequestHeader(header, headers[header])
    })
    xhr.send(formData)
  })

  promise.__transfert_uid = clientUid

  return promise
}

export default uploadStatus
